import {isNumber} from 'lodash'

import {ARRAY_PARAMETER_SEPARATION_CHAR, EMPTY_ARRAY_PARAM} from './fromParameterUtils'

export function intToParameter(num: number | undefined | null): string | undefined {
  return isNumber(num) ? String(num) : undefined
}

export const arrayToParameter = <T extends string = string>(selected?: T[]): string | undefined => {
  if (!selected) return undefined

  return typeof selected === 'string'
    ? selected
    : selected.join(ARRAY_PARAMETER_SEPARATION_CHAR) || EMPTY_ARRAY_PARAM
}
