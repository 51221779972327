import {DateTimeRecord, NullableDateTimeRecord} from '@predict/UtilsLib/dateTime'
import {Overwrite} from '@predict/UtilsLib/general'

export interface ChartData {
  id: string
  actualValues: DateTimeRecord[]
  predictions?: DateTimeRecord[]
  name: string
  unit?: string
  color: string
  predictionColor?: string
}

export type NullableChartData = Overwrite<
  ChartData,
  {actualValues: NullableDateTimeRecord[]; predictions?: NullableDateTimeRecord[]}
>
