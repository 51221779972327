import {AxiosError} from 'axios'
import {useMutation} from 'react-query'

import {SensorMetaData} from '../../declarations/SensorMetaData'
import {trimSensorMetaDataFields} from '../../utils/sensorMetaDataUtils'
import {useInvalidateSensorData} from '../useInvalidateSensorData'

import {PlantSpecificProcessMetricsApi} from '@predict/DomainsLib/gen-api/PlantSpecificProcessMetrics'
import {usePlantId, useProductionLine} from '@predict/DomainsLib/plants'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export async function updateSensorMetaData(
  plantId: string,
  productionLineId: string,
  metaData: SensorMetaData
): Promise<void> {
  const log = logger.context('updateSensorMetaData', plantId, JSON.stringify(metaData))
  try {
    await PlantSpecificProcessMetricsApi.plantsProcessMetricsPlantSpecificUpdate(
      plantId,
      metaData.plantSpecificTag,
      {
        plantId,
        plantTag: metaData.plantSpecificTag,
        uniformTag: metaData.uniformTag,
        productionLineId
      }
    )
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}
export function useUpdateSensorMetaDataMutation(optionsInjector = dummyMutationInjector) {
  const plantId = usePlantId()
  const {productionLineId} = useProductionLine()
  const invalidateSensorData = useInvalidateSensorData({unifiedSensorMetaData: false})

  return useMutation(
    optionsInjector({
      mutationFn: async (metaData: SensorMetaData) => {
        const dataToSave = trimSensorMetaDataFields(metaData)
        await updateSensorMetaData(plantId, productionLineId, dataToSave)
      },
      onSuccess: invalidateSensorData
    })
  )
}
