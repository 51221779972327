import {FirstPage, LastPage, NavigateBefore, NavigateNext} from '@mui/icons-material'
import {IconButton, styled} from '@mui/material'
import type {TablePaginationActionsProps} from '@mui/material/TablePagination/TablePaginationActions'
import classNames from 'classnames'
import {clamp} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {stripNonNumbers} from '@predict/UtilsLib/general'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {TextField} from '@predict/WebUILib/molecules/TextField/TextField'

const NavigationButton = styled(IconButton)(({theme}) => ({
  minWidth: 'auto',
  padding: 0,
  width: 40,
  height: 40,
  color: theme.palette.primary.main,
  marginLeft: 1,
  marginRight: 1
}))

const NavigationText = styled(Text)({
  marginLeft: 1,
  marginRight: 1
})

function pageToString(page: number): string {
  return `${page + 1}`
}

export function PaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
  backIconButtonProps,
  nextIconButtonProps
}: TablePaginationActionsProps) {
  const {t} = useTranslation()
  const [pageFromInput, setPageFromInput] = useState(pageToString(page))

  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1)

  const onFirstPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0)
    },
    [onPageChange]
  )

  const onPrevPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, page - 1))
    },
    [onPageChange, page]
  )

  const onNextPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.min(page + 1, lastPage))
    },
    [lastPage, onPageChange, page]
  )

  const onLastPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, lastPage)
    },
    [lastPage, onPageChange]
  )

  const onPageNumberEntered = useCallback(() => {
    const pageNumber = +pageFromInput
    const newPage = isNaN(pageNumber) ? page : clamp(pageNumber - 1, 0, lastPage)
    setPageFromInput(pageToString(newPage))
    onPageChange(null, newPage)
  }, [lastPage, onPageChange, page, pageFromInput])

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageFromInput(stripNonNumbers(event.target.value))
    },
    []
  )

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onPageNumberEntered()
        event.preventDefault()
      }
    },
    [onPageNumberEntered]
  )

  useEffect(() => {
    setPageFromInput(pageToString(page))
  }, [page])

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <NavigationButton
        {...backIconButtonProps}
        className={classNames(backIconButtonProps?.className)}
        aria-label={t('UILibrary.GeneralTable.pagination.firstPage')}
        disabled={page === 0}
        onClick={onFirstPage}
        data-test-id="pagination-actions-btn-first"
      >
        <FirstPage />
      </NavigationButton>
      <NavigationButton
        {...backIconButtonProps}
        className={classNames(backIconButtonProps?.className)}
        aria-label={t('UILibrary.GeneralTable.pagination.previousPage')}
        disabled={page === 0}
        onClick={onPrevPage}
        data-test-id="pagination-actions-btn-prev"
      >
        <NavigateBefore />
      </NavigationButton>
      <NavigationText variant="body2" data-test-id="pagination-actions-displayed-page">
        {t('UILibrary.GeneralTable.pagination.displayedPage', {
          from: Math.min(page * rowsPerPage + 1, count),
          to: Math.min((page + 1) * rowsPerPage, count),
          total: count
        })}
      </NavigationText>
      <TextField
        sx={{width: 64, marginLeft: 1, marginRight: 1}}
        value={pageFromInput}
        onChange={onTextChange}
        onKeyUp={onKeyUp}
        onBlur={onPageNumberEntered}
        inputProps={{sx: {textAlign: 'center', padding: 1}}}
        data-test-id="pagination-actions-page-input"
      />
      <NavigationText variant="body2">
        {t('UILibrary.GeneralTable.pagination.ofTotal', {total: lastPage + 1})}
      </NavigationText>
      <NavigationButton
        {...nextIconButtonProps}
        className={classNames(nextIconButtonProps?.className)}
        aria-label={t('UILibrary.GeneralTable.pagination.nextPage')}
        disabled={page === lastPage}
        onClick={onNextPage}
        data-test-id="pagination-actions-btn-next"
      >
        <NavigateNext />
      </NavigationButton>
      <NavigationButton
        {...nextIconButtonProps}
        className={classNames(nextIconButtonProps?.className)}
        aria-label={t('UILibrary.GeneralTable.pagination.lastPage')}
        disabled={page === lastPage}
        onClick={onLastPage}
        data-test-id="pagination-actions-btn-last"
      >
        <LastPage />
      </NavigationButton>
    </Box>
  )
}
