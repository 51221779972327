import React from 'react'

import {GeneralTable, GeneralTableProps} from '../GeneralTable/GeneralTable'
import {ColumnConfiguration} from '../GeneralTable/helpers'

interface DataItem {
  label: string
  value?: string
}

export interface SingleValueTableProps
  extends Pick<GeneralTableProps<DataItem>, 'tableId' | 'cellBuilders'> {
  data: DataItem[]
  fontSize?: number | string
  valueCellTextId?: ColumnConfiguration<DataItem>['dataCellTestId']
}

export function SingleValueTable({
  tableId,
  valueCellTextId,
  cellBuilders,
  fontSize,
  data
}: SingleValueTableProps) {
  return (
    <GeneralTable
      tableId={tableId}
      columnsConfiguration={[
        {
          name: 'label',
          label: 'label',
          dataCellStyles: () => ({fontSize})
        },
        {
          name: 'value',
          label: 'value',
          alignment: 'right',
          dataCellStyles: () => ({fontWeight: 'bold', fontSize}),
          dataCellTestId: valueCellTextId
        }
      ]}
      data={data}
      cellBuilders={cellBuilders}
      noHeader
    />
  )
}
