import {generatePath} from 'react-router'

import type {PathParams, PredictPageId} from '../definitions/routeTypes'
import {PREDICT_PAGES} from '../routes'

import {queryParamsToString} from './queryParamsToString'

const addSearchParam = (
  path: string,
  searchParams?: Record<string, string | undefined>
): string => {
  const queryString = queryParamsToString(searchParams)
  return queryString ? `${path}?${queryString}` : path
}

export const getPageUrl = (
  pageId: PredictPageId,
  pathParams?: PathParams,
  searchParams?: Record<string, string | undefined>
): string => addSearchParam(generatePath(PREDICT_PAGES[pageId], pathParams), searchParams)
