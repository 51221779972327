import {MaterialLevel} from '../../declarations/Material'
import {StrengthLevel} from '../../declarations/MaterialData'
import {getMaterialLevel} from '../../utils/materialUtils'

import {useMaterial} from './useMaterial'

export function useMaterialLevel(
  materialId: number | undefined,
  level: StrengthLevel
): MaterialLevel | undefined {
  const material = useMaterial(materialId)
  return getMaterialLevel(material, level)
}
