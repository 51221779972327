import {CsMaterialSample} from '../declarations/MaterialData'

import {DatedCementStrengthPredictionDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {parseDate} from '@predict/UtilsLib/dateTime'
import {stripNull} from '@predict/UtilsLib/general'

export function datedCsPredictionDtoToCsSample(
  dto: DatedCementStrengthPredictionDto
): CsMaterialSample {
  return {
    id: dto.sampleIdPlant,
    millName: stripNull(dto.millName),
    datetime: parseDate(dto.datetime),
    predictedStrength1d: stripNull(dto.predictedStrength1d),
    predictedStrength2d: stripNull(dto.predictedStrength2d),
    predictedStrength3d: stripNull(dto.predictedStrength3d),
    predictedStrength7d: stripNull(dto.predictedStrength7d),
    predictedStrength28d: stripNull(dto.predictedStrength28d),
    strength1d: stripNull(dto.strength1d),
    strength2d: stripNull(dto.strength2d),
    strength3d: stripNull(dto.strength3d),
    strength7d: stripNull(dto.strength7d),
    strength28d: stripNull(dto.strength28d),
    silo: stripNull(dto.silo),
    alite: stripNull(dto.alite),
    belite: stripNull(dto.belite),
    blaine: stripNull(dto.blaine),
    sO3: stripNull(dto.sO3),
    sieve45um: stripNull(dto.sieve45um),
    psdRrLocation: stripNull(dto.psdRrLocation)
  }
}
