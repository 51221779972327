import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatFloat} from '@predict/UtilsLib/general'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {Box} from '@predict/WebUILib/layouts/Box/Box'

export interface StatsItemProps {
  headerLabel: React.ReactNode
  subInfo?: string
  value?: number
  valueUnit?: string
  valueColor?: string
  valueDigits?: number
  showPlusSign?: boolean
  hasSeparateUnit?: boolean
  isBold?: boolean
  hasSmallValue?: boolean
  'data-test-id'?: string
}

export function StatsItem({
  headerLabel,
  valueColor,
  value,
  valueUnit,
  valueDigits = 1,
  showPlusSign = false,
  hasSeparateUnit = false,
  isBold = true,
  hasSmallValue = true,
  subInfo,
  'data-test-id': dataTestId
}: StatsItemProps) {
  const palette = useThemePalette()
  const {t, i18n} = useTranslation()

  return (
    <Box p={0} data-test-id={dataTestId}>
      <Text component="p" variant="caption" color={palette.grey[700]} mb={0.25}>
        {headerLabel}
      </Text>
      <Text
        component="p"
        variant={hasSmallValue ? 'h2' : 'xl'}
        isBold={isBold}
        color={valueColor ?? palette.grey[700]}
      >
        {!isNumber(value) ? (
          t('common.na')
        ) : (
          <>
            {formatFloat(value, {
              locale: i18n.language,
              displayPlus: showPlusSign,
              fractionDigits: valueDigits
            })}
            {valueUnit &&
              (!hasSeparateUnit ? (
                valueUnit
              ) : (
                <Text component="span" variant="subtitle2" isBold ml={0.5}>
                  {valueUnit}
                </Text>
              ))}
          </>
        )}
      </Text>
      {subInfo && (
        <Text component="p" variant="subtitle2" color={valueColor ?? palette.grey[700]} mt={0.25}>
          {subInfo}
        </Text>
      )}
    </Box>
  )
}
