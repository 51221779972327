import {PredictUser} from '../declarations/PredictUser'

export const isUserQcSupervisor = (user?: PredictUser): boolean =>
  user?.roles.includes('QC_SUPERVISOR') ?? false

export const isUserAdmin = (user?: PredictUser): boolean =>
  user?.roles.includes('HPRODUCE_ADMIN') ?? false

export const userMayWriteSettings = (user?: PredictUser): boolean =>
  isUserQcSupervisor(user) || isUserAdmin(user)

export const isUserLoggedIn = (user?: PredictUser): boolean => {
  return !!user?.userId
}
