import {isString} from 'lodash'

import type {SensorMetaData, UnifiedSensorMetaData} from '../declarations/SensorMetaData'

import {isWhiteSpace} from '@predict/UtilsLib/general'

export function trimUnifiedSensorMetaDataFields(
  metaData: UnifiedSensorMetaData
): UnifiedSensorMetaData {
  return {
    uniformTag: metaData.uniformTag.trim(),
    unit: metaData.unit.trim(),
    displayName: metaData.displayName.trim(),
    description: metaData.description.trim()
  }
}

export function trimSensorMetaDataFields(metaData: SensorMetaData): SensorMetaData {
  return {
    ...trimUnifiedSensorMetaDataFields(metaData),
    plantSpecificTag: metaData.plantSpecificTag.trim()
  }
}

export function isSensorMetaDataPlantSpecificTagValid(plantSpecificTag: string) {
  return !isWhiteSpace(plantSpecificTag)
}
export function isUnifiedSensorMetaDataUniformTagValid(uniformTag: string) {
  return !isWhiteSpace(uniformTag)
}
export function isUnifiedSensorMetaDataDescriptionValid(description: string) {
  return !isWhiteSpace(description)
}

export function isUnifiedSensorMetaDataValid({
  uniformTag,
  description
}: UnifiedSensorMetaData): boolean {
  return (
    isUnifiedSensorMetaDataUniformTagValid(uniformTag) &&
    isUnifiedSensorMetaDataDescriptionValid(description)
  )
}

export function isSensorMetaDataValid(metaData: SensorMetaData): boolean {
  return (
    isSensorMetaDataPlantSpecificTagValid(metaData.plantSpecificTag) &&
    isUnifiedSensorMetaDataValid(metaData)
  )
}

function isUnifiedSensorMetaData(metaData: unknown): metaData is UnifiedSensorMetaData {
  if (!metaData) {
    return false
  }
  return (
    isString((metaData as UnifiedSensorMetaData).uniformTag) &&
    isString((metaData as UnifiedSensorMetaData).unit) &&
    isString((metaData as UnifiedSensorMetaData).description) &&
    isString((metaData as UnifiedSensorMetaData).displayName)
  )
}

export function isSensorMetaData(metaData: unknown): metaData is SensorMetaData {
  return (
    isUnifiedSensorMetaData(metaData) && isString((metaData as SensorMetaData).plantSpecificTag)
  )
}
