import {useQuery, UseQueryResult} from 'react-query'

import {SensorMetaData} from '../../declarations/SensorMetaData'

import {useSensorMetaData} from './useSensorMetaData'
import {useUnifiedSensorMetaData} from './useUnifiedSensorMetaData'

import {QueryKeyName} from '@predict/DomainsLib/constants'

export function useMappedUnifiedSensorMetaData(): UseQueryResult<SensorMetaData[]> {
  const {data: unifiedSensorMetaData} = useUnifiedSensorMetaData()
  const {data: sensorMetaData} = useSensorMetaData()

  return useQuery<SensorMetaData[]>(
    [QueryKeyName.GetPlantSpecificSensorMetaData, unifiedSensorMetaData, sensorMetaData],
    () => {
      const uniformTagMap: Record<string, {plantSpecificTag?: string}> = {}

      ;(sensorMetaData ?? []).forEach((datum) => {
        uniformTagMap[datum.uniformTag] = {
          plantSpecificTag: datum.plantSpecificTag
        }
      })

      return (unifiedSensorMetaData ?? []).map((sensorMetaDatum) => ({
        ...sensorMetaDatum,
        plantSpecificTag: uniformTagMap[sensorMetaDatum.uniformTag]?.plantSpecificTag ?? ''
      }))
    }
  )
}
