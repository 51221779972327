import {TFunction} from 'i18next'
import {sortBy, uniq, upperFirst} from 'lodash'

import type {MaterialLevel} from '@predict/DomainsLib/cementStrength'
import type {LabMetaData} from '@predict/DomainsLib/labData'
import type {SelectableOptionCategory} from '@predict/WebUILib/organisms/MultiSelectButtonBar/helpers'

function getChartLevelCategory(
  currentLevel: MaterialLevel,
  materialLevels: MaterialLevel[],
  t: TFunction
): SelectableOptionCategory {
  return {
    categoryId: 'materialLevel',
    categoryLabel: t('chart.categories.cementStrength'),
    options: materialLevels.map((level) => ({
      id: level.name,
      label: t(`chart.selectableCsFields.${level.name}.label`),
      selectable: currentLevel.name !== level.name
    }))
  }
}

function getChartLabCategories(
  labMetaData: LabMetaData[],
  t: TFunction
): SelectableOptionCategory[] {
  const labCategoryNames: string[] = uniq(labMetaData.map(({category}) => category))
  return sortBy(
    labCategoryNames.map((cat) => ({
      categoryId: cat,
      categoryLabel: t(`chart.categories.${cat.toLowerCase()}`, upperFirst(cat)),
      options: sortBy(
        labMetaData
          .filter(({category}) => category === cat)
          .map(({uniformTag, displayName}) => ({
            id: uniformTag,
            label: displayName,
            selectable: true
          })),
        (o) => o.label
      )
    })),
    (cat) => cat.categoryLabel
  )
}

export function getCsSelectableOptionCategories(
  currentLevel: MaterialLevel,
  materialLevels: MaterialLevel[],
  labMetaData: LabMetaData[],
  t: TFunction
): SelectableOptionCategory[] {
  const levelCategory: SelectableOptionCategory = getChartLevelCategory(
    currentLevel,
    materialLevels,
    t
  )
  const labCategories: SelectableOptionCategory[] = getChartLabCategories(labMetaData, t)

  return [levelCategory, ...labCategories]
}
