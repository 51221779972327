import {isString} from 'lodash'
import {useMemo} from 'react'
import {useQuery} from 'react-query'

import type {LabMetaData} from '../declarations/labMetaData'
import {dtoToPlantLabMetaData} from '../utils/dtoToPlantLabMetaData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {LabMetricsApi} from '@predict/DomainsLib/gen-api/LabMetrics'
import {usePlantId} from '@predict/DomainsLib/plants'

export function useMaterialLabMetaData(): LabMetaData[] {
  const plantId = usePlantId()
  const {data} = useQuery([QueryKeyName.GetLabMetaMetrics, plantId], async () => {
    const response = await LabMetricsApi.getplantsLabMetrics(plantId)
    const metaData = response.data
      .filter((dto) => isString(dto.plantSpecificTag))
      .map(dtoToPlantLabMetaData)
    return {metaData}
  })
  return useMemo(() => data?.metaData ?? [], [data])
}
