import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip
} from '@mui/material'
import React from 'react'

interface IconButtonProps extends MuiIconButtonProps {
  toolTipTitle: string
  onClick?: () => void
}

export function IconButton({toolTipTitle, sx, onClick, ...rest}: IconButtonProps) {
  return (
    <Tooltip disableInteractive title={toolTipTitle}>
      <MuiIconButton
        onClick={onClick}
        sx={mergeSxs(
          {
            border: 0,
            backgroundColor: 'transparent',
            color: 'primary.main',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          },
          sx
        )}
        {...rest}
      />
    </Tooltip>
  )
}
