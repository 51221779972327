import React from 'react'
import {useTranslation} from 'react-i18next'

import {PageTitle} from '../../WebUILib/atoms/Headline/PageTitle'
import {PageLayout} from '../../WebUILib/layouts/PageLayout/PageLayout'
import {ErrorMessage} from '../../WebUILib/molecules/ErrorMessage/ErrorMessage'

export function ErrorPage() {
  const {t} = useTranslation()

  return (
    <PageLayout>
      <PageTitle>{t('errors.noPageFoundHeader')}</PageTitle>
      <ErrorMessage>{t('errors.noPageFoundDesc')}</ErrorMessage>
    </PageLayout>
  )
}
