import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ClinkerSample, useLabClinkerSamples} from '../hooks/useLabClinkerSamples'

import {usePlantFreeLimeConfig} from '@predict/DomainsLib/freeLime'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate} from '@predict/UtilsLib/dateTime'
import {calcOpacityColor, formatFloatOrNa, inNumberRange} from '@predict/UtilsLib/general'
import {StatefulGeneralTable} from '@predict/WebApp/modules/general'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'
import {Skeleton} from '@predict/WebUILib/molecules/Skeleton/Skeleton'
import {
  lowerCaseStringFilter,
  lowerCaseStringSort,
  TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE
} from '@predict/WebUILib/organisms/GeneralTable/helpers'
import {ColumnCalculationProps} from '@predict/WebUILib/organisms/GeneralTable/helpers/types'

const COLUMN_KEYS: (keyof ClinkerSample)[] = [
  'datetime',
  'id',
  'lsf',
  'sr',
  'ar',
  'freelime',
  'alite',
  'belite',
  'aluminate',
  'ferrite'
]

export function ClinkerSamplesTable() {
  const palette = useThemePalette()
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const {min, max} = usePlantFreeLimeConfig()

  const {data, isLoading, isError} = useLabClinkerSamples()

  const errorCellStyles = useMemo(
    () => ({
      color: palette.error.main,
      backgroundColor: calcOpacityColor(palette.error.main, 0.1)
    }),
    [palette]
  )

  if (isLoading) {
    return <Skeleton variant="table" height={400} count={10} />
  }

  if (!data || isError) {
    return (
      <DefaultBox width="100%" height={560}>
        <ErrorMessage height="65%">{t('errors.noDataAvailable')}</ErrorMessage>
      </DefaultBox>
    )
  }

  return (
    <StatefulGeneralTable
      tableId="clinker-samples-table"
      data={data}
      rowKey={({id}) => id}
      columnsConfiguration={COLUMN_KEYS.map((key, index) => {
        return {
          name: key,
          label: t(`clinkerSamplesTable.header.${key}`),
          alignment: index >= 2 ? 'right' : 'left',
          dataCellStyles: ({rowData: sample}: ColumnCalculationProps<ClinkerSample>) =>
            key === 'freelime' && sample.freelime && !inNumberRange({min, max}, sample.freelime)
              ? errorCellStyles
              : undefined
        }
      })}
      cellBuilders={{
        datetime: ({datetime}) =>
          formatTimeZoneDate(datetime, timeZone, t('clinkerSamplesTable.datetime')),
        id: ({id}) => id,
        lsf: ({lsf}) => formatFloatOrNa(lsf, t('common.na')),
        sr: ({sr}) => formatFloatOrNa(sr, t('common.na')),
        ar: ({ar}) => formatFloatOrNa(ar, t('common.na')),
        freelime: ({freelime}) => formatFloatOrNa(freelime, t('common.na')),
        alite: ({alite}) => formatFloatOrNa(alite, t('common.na')),
        belite: ({belite}) => formatFloatOrNa(belite, t('common.na')),
        aluminate: ({aluminate}) => formatFloatOrNa(aluminate, t('common.na')),
        ferrite: ({ferrite}) => formatFloatOrNa(ferrite, t('common.na'))
      }}
      sortMethod={lowerCaseStringSort}
      filterMethod={lowerCaseStringFilter}
      defaultRowsPerPage={TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE}
      hasColumnBorders
    />
  )
}
