import {Check, Undo} from '@mui/icons-material'
import {styled} from '@mui/material'
import React, {PropsWithChildren, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Box} from '../Box/Box'

import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {useBreakPoints} from '@predict/WebUILib/helpers/hooks/useBreakPoints'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {usePrompt} from '@predict/WebUILib/organisms/PromptProvider'

interface FormBoxProps extends PropsWithChildren<unknown> {
  title: string
  isFormInvalid?: boolean
  isSaving?: boolean
  disabled?: boolean
  onRevert: () => void
  onSave: () => void
  'data-test-id'?: string
}

const FormBoxButton = styled(ColorButton)(({theme}) => ({
  flexGrow: 1,
  [theme.breakpoints.down('xs')]: {
    '& [class*="MuiButton-startIcon"]': {
      margin: 0
    }
  }
}))

export function FormBox({
  title,
  onRevert,
  onSave,
  disabled = false,
  isSaving = false,
  isFormInvalid = false,
  children,
  'data-test-id': dataTestId
}: FormBoxProps) {
  const {t} = useTranslation()
  const palette = useThemePalette()

  const breakpoint = useBreakPoints()
  const isMobile = breakpoint === 'xs'

  const enablePrompt = usePrompt()
  useEffect(() => {
    enablePrompt(!disabled, {
      message: t('settings.leaveWithoutSaving.message'),
      title: t('settings.leaveWithoutSaving.title'),
      cancelButtonLabel: t('settings.leaveWithoutSaving.cancelButton'),
      confirmButtonLabel: t('settings.leaveWithoutSaving.confirmButton')
    })
  }, [disabled, enablePrompt, t])

  const safeOnSave = () => {
    if (disabled || isFormInvalid || isSaving) return

    onSave()
  }

  return (
    <DefaultBox width="100%" data-test-id={dataTestId}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          marginBottom: theme.spacing(3),
          flexDirection: 'column',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center'
          }
        })}
      >
        <Text
          variant="h6"
          sx={(theme) => ({
            flex: 1,
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              textAlign: 'left',
              marginBottom: 0
            }
          })}
        >
          {title}
        </Text>
        <Box
          sx={(theme) => ({
            flex: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            columnGap: theme.spacing(1),
            rowGap: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              flexWrap: 'nowrap',
              marginBottom: 0
            }
          })}
        >
          <FormBoxButton
            disabled={disabled || isSaving}
            color="secondary"
            onClick={onRevert}
            startIcon={<Undo />}
            data-test-id="form-box-revert-button"
          >
            {!isMobile ? t('formBox.revertButton') : ''}
          </FormBoxButton>
          <FormBoxButton
            disabled={disabled || isFormInvalid || isSaving}
            onClick={safeOnSave}
            btnColor={palette.primary.main}
            startIcon={<Check />}
            data-test-id="form-box-save-button"
          >
            {!isMobile ? t('formBox.saveButton') : ''}
          </FormBoxButton>
        </Box>
      </Box>
      <div>{children}</div>
    </DefaultBox>
  )
}
