import React from 'react'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface SpacingProps {
  isInline?: boolean
  height?: number
  width?: number
}

export function Spacing({height, width, isInline}: SpacingProps) {
  return (
    <Box
      component={isInline ? 'span' : 'div'}
      sx={(theme) => ({
        display: isInline ? 'inline-block' : 'block',
        height: theme.spacing(height ?? 0),
        width: theme.spacing(width ?? 0)
      })}
    />
  )
}
