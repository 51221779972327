import {TargetRange} from '../../declarations/NumberRange'

interface DeviationColorPalette {
  normal: string
  success: string
  warning: string
  error: string
}

export function calcColorFromDeviation(
  colors: DeviationColorPalette,
  deviation?: number,
  materialLevel?: TargetRange
): string {
  if (!materialLevel || !deviation) {
    return colors.normal
  }

  const absDeviation = Math.abs(deviation)
  const {target, min, max} = materialLevel
  const allowedDeviation = (Math.abs(target - min) + Math.abs(max - target)) / 2
  if (absDeviation < allowedDeviation / 2) {
    return colors.success
  }
  if (absDeviation < allowedDeviation) {
    return colors.warning
  }
  return colors.error
}
