import React from 'react'

import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'

interface CustomPromptProps {
  message: string
  show?: boolean
  title?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
}
export function CustomPrompt({
  message,
  show = false,
  title = '',
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  onConfirm,
  onCancel
}: CustomPromptProps) {
  return (
    <GeneralDialog
      title={title}
      isOpen={show}
      data-test-id="custom-prompt-dialog"
      actions={
        <DialogBasicActions
          onDelete={onConfirm}
          deleteTestId="custom-prompt-confirm-button"
          deleteBtnLabel={confirmButtonLabel}
          onSave={onCancel}
          saveTestId="custom-prompt-cancel-button"
          saveBtnLabel={cancelButtonLabel}
        />
      }
    >
      {message}
    </GeneralDialog>
  )
}
