export enum QueryKeyName {
  GetUserManual = 'getUserManual',
  GetUnfilteredCsSamples = 'getUnfilteredCsSamples',
  GetCementStrengthForMaterial = 'getCementStrengthForMaterial',
  GetSampleDetails = 'getSampleDetails',
  GetFreeLime = 'getFreeLime',
  GetFreeLimePerformance = 'getFreeLimePerformance',
  GetFreeLimeModels = 'getFreeLimeModels',
  GetLabData = 'getLabData',
  GetLabMetaMetrics = 'getLabMetaMetrics',
  GetUnifiedSensorMetaData = 'GetUnifiedSensorMetaData',
  GetSensorMetaData = 'GetSensorMetaData',
  GetPlantSpecificSensorMetaData = 'GetPlantSpecificSensorMetaData',
  GetSensorData = 'GetSensorData',
  GetMaterials = 'getMaterials',
  GetPlants = 'getPlants',
  GetFreeLimeLabData = 'getFreeLimeLabData',
  GetFreeLimeLabMetaData = 'getFreeLimeLabMetaData'
}

export const PROCESS_METRICS_MAX_POINTS_RESOLUTION = 1000
export const LAB_METRICS_MAX_POINTS_RESOLUTION = 1000

export const FL_FETCH_INTERVAL_MS = 60 * 1000
