import {uniq} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {PREDEFINED_CHART_TAGS} from '../constants'
import {getFlSelectableOptionViews} from '../utils/getFlSelectableOptions'

import {useKilnLabMetaData, useLabDataDomainContext} from '@predict/DomainsLib/labData'
import {usePlantProductionLineIds, usePlantTimeZone} from '@predict/DomainsLib/plants'
import {useSensorDataDomainContext, useSensorMetaData} from '@predict/DomainsLib/sensorData'
import {DateTimeParam, FL_TIME_HORIZON_IDS} from '@predict/UtilsLib/dateTime'
import {TimeRangePickerButton, ZoomButtonGroup} from '@predict/WebApp/modules/dateTime'
import {
  PredictionViewButtonGroup,
  usePredictionViewSearchParam,
  useSelectedTagIdsSearchParam,
  useTimeRangeSearchParams
} from '@predict/WebApp/modules/navigation'
import {ProductionLineSelect} from '@predict/WebApp/modules/plants'
import {ActionBarButton} from '@predict/WebUILib/atoms/ActionBarButton/ActionBarButton'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {Add} from '@predict/WebUILib/helpers/icons'
import {ActionBar} from '@predict/WebUILib/layouts/ActionBar/ActionBar'
import {MultiSelectDataDialog} from '@predict/WebUILib/molecules/MultiSelectDataDialog/MultiSelectDataDialog'

interface FreeLimeActionBarProps {
  title: string
}

export function ClinkerActionBar({title}: FreeLimeActionBarProps) {
  const {t} = useTranslation()
  const {start, end} = useLabDataDomainContext()
  const {selectedTagIds: labDataSelectedTagIds} = useLabDataDomainContext()
  const {selectedTagIds: sensorDataSelectedTagIds} = useSensorDataDomainContext()

  const selectedTagIds = uniq([...labDataSelectedTagIds, ...sensorDataSelectedTagIds])

  // Time Range
  const {setTimeRange: onTimeRangeChanged} = useTimeRangeSearchParams()
  const timeZone = usePlantTimeZone()

  // Production Lines Select
  const productionLineNumbers = usePlantProductionLineIds()

  // Chart data
  const [isDataDialogOpen, setIsDataDialogOpen] = useState(false)

  const {data: processMetaData} = useSensorMetaData()
  const labMetaData = useKilnLabMetaData()

  const categories = useMemo(
    () => getFlSelectableOptionViews(processMetaData ?? [], labMetaData, t),
    [processMetaData, labMetaData, t]
  )
  const {setSelectedTagIds: onTagIdsChanged} = useSelectedTagIdsSearchParam()

  const onTagsSelected = useCallback(
    (selected: string[]) => {
      onTagIdsChanged(selected.filter((tag) => !PREDEFINED_CHART_TAGS.includes(tag)))
    },
    [onTagIdsChanged]
  )

  const handleDataDialogApply = (selected: string[]) => {
    onTagsSelected(selected)
    setIsDataDialogOpen(false)
  }

  // Prediction View
  const {predictionView} = usePredictionViewSearchParam()

  return (
    <ActionBar title={<PageTitle>{title}</PageTitle>}>
      {productionLineNumbers.length > 1 ? <ProductionLineSelect /> : null}

      <TimeRangePickerButton
        start={start}
        end={end}
        timeZone={timeZone}
        selectableTimeHorizons={FL_TIME_HORIZON_IDS}
        onTimeRangeChanged={(start: DateTimeParam, end: DateTimeParam) =>
          onTimeRangeChanged(start, end)
        }
      />

      <ZoomButtonGroup
        timeRange={{
          start: start,
          end: end
        }}
        onTimeRangeChanged={onTimeRangeChanged}
      />

      {predictionView === 'time' && (
        <ActionBarButton
          onClick={() => setIsDataDialogOpen(true)}
          data-test-id="chart-add-data-action-bar-btn"
          startIcon={<Add />}
        >
          {t('chartData.addData')}
        </ActionBarButton>
      )}
      <MultiSelectDataDialog
        isOpen={isDataDialogOpen}
        title={t('chartData.addData')}
        onApply={handleDataDialogApply}
        onClose={() => setIsDataDialogOpen(false)}
        options={categories}
        selectedOptions={selectedTagIds}
      />

      <PredictionViewButtonGroup showAccuracyView={false} />
    </ActionBar>
  )
}
