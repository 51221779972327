import {isArray, isString, orderBy} from 'lodash'

import {ManipulatorCriteria, SortType} from './types'

export function isManipulatorCriteria<D>(value: unknown): value is ManipulatorCriteria<D>[] {
  if (isArray(value)) {
    const arr = value as ManipulatorCriteria<D>[]
    return arr.every((obj) => Boolean(obj) && isString(obj.field) && isString(obj.expression))
  }
  return false
}

export function isSortCriteria<D>(value: unknown): value is ManipulatorCriteria<D, SortType>[] {
  const types: SortType[] = ['asc', 'desc']

  return (
    isManipulatorCriteria<D>(value) &&
    value.every((obj) => types.includes(obj.expression as SortType))
  )
}

function defaultTransformMethod<D>(record: D, field: keyof D): string {
  return String(record[field])
}

export function lowerCaseStringFilter<D>(
  data: D[],
  criteria: ManipulatorCriteria<D>[],
  transformMethod: (record: D, field: keyof D) => string = defaultTransformMethod
): D[] {
  return data.filter((record) =>
    criteria.every(({field, expression}) =>
      transformMethod(record, field).toLowerCase().includes(expression)
    )
  )
}

export function lowerCaseStringSort<D>(
  data: D[],
  criteria: ManipulatorCriteria<D, SortType>[],
  transformMethod: (record: D, field: keyof D) => string = defaultTransformMethod
): D[] {
  return orderBy(
    data,
    criteria.map(
      ({field}) =>
        (record) =>
          transformMethod(record, field).trim().toLowerCase()
    ),
    criteria.map(({expression}) => expression)
  )
}
