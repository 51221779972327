import {mergeSxs} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {Text, TextProps} from '../Text/Text'

export type HeadlineTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface HeadlineStyles {
  topSpacing?: number
  bottomSpacing?: number
  isCapitalized?: boolean
}

export interface HeadlineProps extends TextProps, HeadlineStyles {
  tag?: HeadlineTag
}

export function Headline({
  tag = 'h1',
  topSpacing = 0,
  bottomSpacing = 2,
  isCapitalized = false,
  sx,
  ...rest
}: HeadlineProps) {
  return (
    <Text
      variant={tag}
      sx={mergeSxs(
        [
          {
            textTransform: 'none',
            marginTop: topSpacing,
            marginBottom: bottomSpacing
          },
          isCapitalized && {
            textTransform: 'capitalize'
          }
        ],
        sx
      )}
      {...rest}
    />
  )
}
