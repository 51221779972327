/**
 * This lists the images used in the in-app user manual.
 * To make it work correctly it's important to stick to the naming rule,
 * which is to export the image using the name of the file without the extension.
 *
 * If a file for example is named 'anExampleImage.jpg' then export it with the key named 'anExampleImage'.
 * Example:
 * import freeLimePage from './freeLime/images/freeLimePage.png'
 * export {freeLimePage}
 */

export {}
