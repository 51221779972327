import type {Plant} from '../declarations/Plant'
import {usePlantsDomainContext} from '../providers/PlantsDomainProvider'

import {usePlantsList} from './usePlantsList'

export function usePlant(): Plant | undefined {
  const {selectedPlantId: param} = usePlantsDomainContext()
  const {data: plants} = usePlantsList()
  // Find plant by ID
  let plant: Plant | undefined = plants?.find((p) => p.id === param)

  // Find plant by Name (Back compatibility)
  if (!plant) {
    plant = plants?.find((p) => p.name === param)
  }

  return plant
}
