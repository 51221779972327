import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {Checkbox, Collapse, List, ListItem, ListItemText, ListSubheader} from '@mui/material'
import {debounce, isUndefined} from 'lodash'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TextField} from '../TextField/TextField'

import {
  SelectableOptionCategory,
  SelectableOption,
  isOptionSelected,
  toSelectableOptionCategories,
  filterSelectableOptionCategories,
  SelectableOptionView,
  toSelectableOptionViews
} from './helpers'

import {addOrRemove, createFilterTerms, replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Tabs} from '@predict/WebUILib/atoms/Tabs/Tabs'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'

interface MultiSelectDataDialogProps {
  isOpen: boolean
  title: string
  options: SelectableOption[] | SelectableOptionCategory[] | SelectableOptionView[]
  selectedOptions: string[]
  onApply: (selectedOptions: string[]) => void
  onClose?: () => void
  searchPlaceholder?: string
}

const SEARCH_DELAY_MS = 500

export function MultiSelectDataDialog({
  isOpen,
  title,
  options,
  selectedOptions,
  onApply,
  onClose,
  searchPlaceholder
}: MultiSelectDataDialogProps) {
  const {t} = useTranslation()
  // views
  const views = useMemo(() => toSelectableOptionViews(options), [options])
  const [selectedViewId, setSelectedViewId] = useState<string | undefined>(views[0]?.viewId)
  const selectedView = useMemo<SelectableOptionView | undefined>(
    () => views.find(({viewId}) => viewId === selectedViewId),
    [selectedViewId, views]
  )

  // Reset filters when closed
  useLayoutEffect(() => {
    if (views.length > 0 && isUndefined(selectedView)) setSelectedViewId(views[0]?.viewId)
  }, [selectedView, views])

  // categories and options
  const [selected, setSelected] = useState(selectedOptions)
  const [filter, setFilter] = useState<string[]>([])
  const filteredCategories = useMemo(
    () =>
      filterSelectableOptionCategories(
        toSelectableOptionCategories(selectedView?.options ?? []),
        filter
      ),
    [filter, selectedView]
  )

  const debounced = useRef(
    debounce((searchTerm: string) => {
      setFilter(createFilterTerms(searchTerm))
    }, SEARCH_DELAY_MS)
  )

  useEffect(() => {
    const debouncedFunc = debounced.current
    return () => debouncedFunc.cancel()
  }, [])

  const handleApply = useCallback(() => {
    onApply(selected)
  }, [onApply, selected])

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') onClose()
  }, [onClose])

  const handleFilterValueChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const {value} = event.target
      debounced.current(value)
    },
    []
  )

  const onEnter = () => {
    setSelected(selectedOptions)
    setFilter([])
  }

  const onExit = () => {
    debounced.current.cancel()
  }

  // Collapse
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState<string[]>([])

  return (
    <GeneralDialog
      title={title}
      isOpen={isOpen}
      onClose={handleClose}
      titleId="multi-select-data-dialog-title"
      onEnter={onEnter}
      onExit={onExit}
      actions={<DialogBasicActions onCancel={handleClose} onSave={handleApply} />}
      data-test-id="multi-select-data-dialog"
    >
      {views.length > 1 && (
        <>
          <Tabs
            views={views}
            defaultView={views[0]}
            selectedView={selectedView ?? views[0]}
            tabLabel={({viewLabel}) => viewLabel}
            tabKey={({viewId}) => viewId}
            onChange={({viewId}) => setSelectedViewId(viewId)}
            tabTestId={({viewId}) => `multi-select-data-dialog-tabs-${viewId}`}
            data-test-id="multi-select-data-dialog-tabs"
            variant="fullWidth"
          />
          <Spacing height={2} />
        </>
      )}
      <Box mb={3}>
        <TextField
          type="search"
          placeholder={searchPlaceholder ?? t('UILibrary.MultiSelectDataDialog.filterList')}
          onChange={handleFilterValueChange}
          fullWidth
          data-test-id="multi-select-data-dialog-search-field"
        />
      </Box>

      <Box
        pt={0}
        sx={{
          overflowY: 'auto',
          height: '400px'
        }}
      >
        {filteredCategories.length === 0 ? (
          <Text>{t('UILibrary.MultiSelectDataDialog.noEntries')}</Text>
        ) : (
          <List disablePadding>
            {filteredCategories.map(({categoryId, categoryLabel, options}) => {
              const isCollapsed = collapsedCategoryIds.includes(categoryId)
              return (
                <React.Fragment key={categoryId}>
                  {categoryId && (
                    <ListSubheader
                      sx={(theme) => ({
                        background:
                          theme.palette.mode === 'dark'
                            ? theme.palette.background.paper
                            : theme.palette.grey[50],
                        borderRadius: 0,
                        border: 0,
                        borderStyle: 'solid',
                        borderBottomWidth: 2,
                        borderColor:
                          theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : theme.palette.divider
                      })}
                    >
                      <ColorButton
                        variant="text"
                        startIcon={isCollapsed ? <ExpandMore /> : <ExpandLess />}
                        onClick={() =>
                          setCollapsedCategoryIds((selectedIds) =>
                            addOrRemove(selectedIds, categoryId, !isCollapsed)
                          )
                        }
                        data-collapsed={String(isCollapsed)}
                        sx={(theme) => ({
                          color: theme.palette.text.primary,
                          width: '100%',
                          justifyContent: 'flex-start',

                          // Adjust height to match parent subHeader
                          lineHeight: 'inherit',
                          paddingTop: 0,
                          paddingBottom: 0,
                          verticalAlign: 'bottom',

                          // Keep the same color on hover
                          '&:hover': {
                            background: 'transparent'
                          }
                        })}
                      >
                        {categoryLabel}
                      </ColorButton>
                    </ListSubheader>
                  )}
                  <Collapse in={!isCollapsed}>
                    {options.map((item) => (
                      <ListItem key={item.id} disabled={!item.selectable}>
                        <Checkbox
                          color="secondary"
                          data-test-id={`multi-select-data-dialog-item-${replaceNonWordCharacters(
                            item.id
                          )}`}
                          checked={!item.selectable || isOptionSelected(item, selected)}
                          disabled={!item.selectable}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setSelected(addOrRemove(selected, item.id, event.target.checked))
                          }
                        />
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}
                  </Collapse>
                </React.Fragment>
              )
            })}
          </List>
        )}
      </Box>
    </GeneralDialog>
  )
}
