import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react'

import {usePlantsDomainContext} from '@predict/DomainsLib/plants'
import {DateTimeParam, TimeRange} from '@predict/UtilsLib/dateTime'

interface LDDContext {
  start: DateTimeParam
  end: DateTimeParam
  selectedTagIds: string[]
  timeRange: TimeRange<DateTimeParam>
}

const LabDataDomainContext = createContext<LDDContext | undefined>(undefined)

export function useLabDataDomainContext(): LDDContext {
  const context = useContext(LabDataDomainContext)
  if (!context) {
    throw new Error('useLabDataDomainContext used outside of LabDataDomainProvider')
  }

  return context
}

export const LabDataDomainConsumer = LabDataDomainContext.Consumer

interface LDDProviderProps
  extends PropsWithChildren<Omit<LDDContext, 'selectedTagIds' | 'timeRange'>> {
  selectedTagIds?: string[]
}

export function LabDataDomainProvider({
  start,
  end,
  selectedTagIds = [],
  children
}: LDDProviderProps) {
  // Ensure upstream domains
  usePlantsDomainContext()

  const timeRange = useMemo(() => ({start, end}), [start, end])

  return (
    <LabDataDomainContext.Provider
      value={{
        start: start,
        end: end,
        selectedTagIds,
        timeRange
      }}
    >
      {children}
    </LabDataDomainContext.Provider>
  )
}
