import {useMemo} from 'react'

import {usePlantsDomainContext} from '../providers/PlantsDomainProvider'

import {usePlantProductionLines} from './usePlantProductionLines'

export function useProductionLine() {
  const {selectedProductionLineId} = usePlantsDomainContext()
  const plantProductionLines = usePlantProductionLines()
  const defaultProductionLine = plantProductionLines[0]
  const productionLine = useMemo(
    () =>
      plantProductionLines.find((pl) => pl.id === selectedProductionLineId) ??
      defaultProductionLine,
    [defaultProductionLine, plantProductionLines, selectedProductionLineId]
  )
  return {
    productionLine,
    productionLineId: productionLine.id
  }
}
