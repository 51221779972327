import {KilnLabMetaData, LabData} from '@predict/DomainsLib/labData'
import {COLOR_ACTUAL_VALUES} from '@predict/UtilsLib/general/constants'
import {ChartData} from '@predict/WebUILib/Charts/helpers'

export const createChartDataFromKilnLabData = (labData: LabData<KilnLabMetaData>[]): ChartData[] =>
  labData.map(({uniformTag, unit, displayName, records, materialType}) => {
    const id = `${materialType}:${uniformTag}`
    return {
      id,
      name: displayName,
      color: COLOR_ACTUAL_VALUES,
      unit,
      actualValues: records
    }
  })
