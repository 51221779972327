import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

export function useSelectedSourcesSearchParam() {
  const [selectedSources, setSelectedSources] = useTypedURLSearchParam(
    'selectedSources',
    ParamTypes.array
  )
  return {
    selectedSources,
    setSelectedSources
  }
}
