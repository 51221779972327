import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PREDEFINED_CHART_TAGS} from '../constants'
import {useLabChartData} from '../hooks/useLabChartData'
import {useSensorChartData} from '../hooks/useSensorChartData'

import {LabAndSensorCharts} from './LabAndSensorCharts'

import {useLabDataDomainContext} from '@predict/DomainsLib/labData'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {useSelectedTagIdsSearchParam} from '@predict/WebApp/modules/navigation'
import {AsyncContainer} from '@predict/WebUILib/layouts/AsyncContainer/AsyncContainer'

export function KilnChartsContainer() {
  const {t} = useTranslation()

  const timeZone = usePlantTimeZone()
  // This should be the same for lab and sensor data
  const {timeRange} = useLabDataDomainContext()

  const {
    data: labChartData,
    isLoading: labIsLoading,
    isSuccess: labIsSuccess,
    isError: labIsError
  } = useLabChartData()
  const {
    data: sensorChartData,
    isLoading: sensorIsLoading,
    isSuccess: sensorIsSuccess,
    isError: sensorIsError
  } = useSensorChartData()

  const chartData = useMemo(
    () => [...labChartData, ...sensorChartData],
    [labChartData, sensorChartData]
  )

  const {selectedTagIds, setSelectedTagIds: onTagIdsChanged} = useSelectedTagIdsSearchParam()

  const onClose = useCallback(
    (chartId: string) => {
      onTagIdsChanged(
        (selectedTagIds ?? []).filter(
          (tag) => !PREDEFINED_CHART_TAGS.includes(tag) && tag !== chartId
        )
      )
    },
    [selectedTagIds, onTagIdsChanged]
  )

  return (
    <AsyncContainer
      isLoading={labIsLoading && sensorIsLoading}
      isSuccess={labIsSuccess || sensorIsSuccess}
      isError={labIsError || sensorIsError}
      errorMessage={t('errors.noData')}
      loaderType="rectangular"
      loaderHeight={400}
      loaderMargin={0}
    >
      <LabAndSensorCharts
        data={chartData}
        onClose={onClose}
        timeZone={timeZone}
        timeRange={timeRange}
        isLoading={labIsLoading || sensorIsLoading}
      />
    </AsyncContainer>
  )
}
