/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {PlantConfigurationDto, ProblemDetails} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class PlantApi {
  /**
   * No description
   *
   * @tags Plant
   * @name Getplants
   * @summary Returns the configuration data for a specfic plant
   * @request GET:/predict/plants/{plantId}
   * @secure
   */
  static getplants = (plantId: string, params: RequestParams = {}) =>
    genApiClient.request<PlantConfigurationDto, ProblemDetails | void>({
      path: `/predict/plants/${plantId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Plant
   * @name GetplantsList
   * @summary Returns the configuration data of all plants the user has access to
   * @request GET:/predict/plants
   * @secure
   */
  static getplantsList = (params: RequestParams = {}) =>
    genApiClient.request<PlantConfigurationDto[], ProblemDetails | void>({
      path: `/predict/plants`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
}
