export const DEFAULT_ZOOM_OUT_FACTOR = 2
export const DEFAULT_ZOOM_IN_FACTOR = 0.5

export const COLOR_PREDICTIONS = '#8C3CC9'
export const COLOR_ACTUAL_VALUES = '#0080D6'

export const COLORS_POSITIVE = ['#00aa50']
export const COLORS_NEUTRAL = ['#54708c']
export const COLORS_WARNING = ['#ee8b00']
export const COLORS_NEGATIVE = ['#DA0A00', '#950400', '#460300']
