import {useQuery} from 'react-query'

import {useFreeLimeDomainContext} from '../../providers/FreeLimeDomainProvider'

import {FL_FETCH_INTERVAL_MS, QueryKeyName} from '@predict/DomainsLib/constants'
import {FreelimePerformanceApi} from '@predict/DomainsLib/gen-api/FreelimePerformance'
import {usePlantId, usePlantTimeZone, useProductionLine} from '@predict/DomainsLib/plants'
import {
  isRelativeDateTimeParam,
  padCalendarTimeRange,
  timeRangeFromStartEnd
} from '@predict/UtilsLib/dateTime'

export function useFreeLimePerformanceData() {
  const plantId = usePlantId()
  const {productionLineId} = useProductionLine()
  const {start, end, resolution} = useFreeLimeDomainContext()
  const timeZone = usePlantTimeZone()
  const rng = padCalendarTimeRange(timeRangeFromStartEnd(start, end), resolution, timeZone)
  return useQuery(
    [QueryKeyName.GetFreeLimePerformance, plantId, start, end, resolution, productionLineId],
    async () =>
      (
        await FreelimePerformanceApi.getplantsFreeLimePerformance(plantId, {
          resolution: resolution,
          from: rng.start.toJSON(),
          until: rng.end.toJSON(),
          productionLineId
        })
      ).data,
    {
      refetchInterval: isRelativeDateTimeParam(end) && FL_FETCH_INTERVAL_MS,
      staleTime: 0
    }
  )
}
