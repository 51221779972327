import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import React, {PropsWithChildren, ReactNode} from 'react'

import {Text} from '@predict/WebUILib/atoms/Text/Text'

interface GeneralDialogProps extends PropsWithChildren<unknown> {
  title: string
  isOpen: boolean
  actions?: ReactNode
  width?: number
  titleId?: string
  onClose?: () => void
  onEnter?: () => void
  onExit?: () => void
  'data-test-id'?: string
}

export function GeneralDialog({
  title,
  isOpen,
  children,
  actions,
  width = 500,
  onClose,
  onEnter,
  onExit,
  titleId = 'general-dialog-title',
  'data-test-id': testId
}: GeneralDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby={titleId}
      PaperProps={{
        sx: {
          width
        }
      }}
      TransitionProps={{onEnter, onExit}}
      data-test-id={testId}
    >
      <DialogTitle id={titleId}>
        <Text component="span" fontSize={20}>
          {title}
        </Text>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: 0,
          paddingBottom: 3
        }}
      >
        {children}
      </DialogContent>
      {actions}
    </Dialog>
  )
}
