import React, {ReactNode, PropsWithChildren} from 'react'

import {Box} from '../Box/Box'
import {GeneralDialog} from '../GeneralDialog/GeneralDialog'

export interface TwoColumnDialogProps extends PropsWithChildren<unknown> {
  title: string
  isOpen: boolean
  sideContent: ReactNode
  actions?: ReactNode
  onClose?: () => void
  onEnter?: () => void
  onExit?: () => void
}

export function TwoColumnDialog({
  title,
  isOpen,
  children,
  sideContent,
  actions,
  onClose,
  onEnter,
  onExit
}: TwoColumnDialogProps) {
  return (
    <GeneralDialog
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onEnter={onEnter}
      onExit={onExit}
      titleId="two-column-dialog-title"
      width={600}
      actions={actions}
    >
      <Box
        sx={(theme) => ({
          marginBottom: 3,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
          }
        })}
      >
        <Box
          sx={(theme) => ({
            flexGrow: 2,
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              marginBottom: 0,
              marginRight: theme.spacing(2)
            }
          })}
        >
          {children}
        </Box>
        <Box
          sx={(theme) => ({
            flexGrow: 1,
            borderColor: theme.shadows[9],
            borderStyle: 'solid',
            borderWidth: 0,
            borderTopWidth: 1,
            paddingTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              paddingTop: 0,
              paddingLeft: theme.spacing(2),
              borderTopWidth: 0,
              borderLeftWidth: 1
            }
          })}
        >
          {sideContent}
        </Box>
      </Box>
    </GeneralDialog>
  )
}
