import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Button, ButtonProps} from '@mui/material'
import React, {forwardRef} from 'react'

import {calcButtonColorStyle} from '@predict/WebUILib/helpers/utils/calcButtonColorStyle'

export interface ColorButtonProps extends ButtonProps {
  btnColor?: string
  textColor?: string
  colorizeWhenDisabled?: boolean
  squareDimension?: number
  width?: number | string
}

export const ColorButton = forwardRef<HTMLButtonElement, ColorButtonProps>(
  (
    {
      btnColor,
      textColor,
      squareDimension,
      sx,
      width,
      colorizeWhenDisabled = false,
      ...other
    }: ColorButtonProps,
    ref
  ) => {
    return (
      <Button
        ref={ref}
        sx={mergeSxs(
          [
            {width},
            !!btnColor &&
              ((theme) =>
                calcButtonColorStyle(
                  btnColor,
                  theme.palette.common.black,
                  theme.palette.common.white,
                  colorizeWhenDisabled
                )),
            !!textColor && {color: textColor},
            !!squareDimension && {
              minWidth: 'auto',
              padding: 0,
              width: squareDimension,
              height: squareDimension
            }
          ],
          sx
        )}
        {...other}
      />
    )
  }
)
