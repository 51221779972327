import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

import {FLP_TICK_RESOLUTIONS} from '@predict/UtilsLib/dateTime'

export function useResolutionSearchParam() {
  const [resolution, setResolution] = useTypedURLSearchParam(
    'resolution',
    ParamTypes.oneOf,
    FLP_TICK_RESOLUTIONS
  )
  return {
    resolution,
    setResolution
  }
}
