export function addHandle(handle: string) {
  return (map: Record<string, boolean>) => {
    const newMap = {...map}
    newMap[handle] = false
    return newMap
  }
}

export function removeHandle(handle: string) {
  return (map: Record<string, boolean>) => {
    const newMap = {...map}
    delete newMap[handle]
    return newMap
  }
}

export function enableHandle(handle: string, enable: boolean) {
  return (map: Record<string, boolean>) => {
    const newMap = {...map}
    newMap[handle] = enable
    return newMap
  }
}

export function isHandleEnabled(map: Record<string, boolean>) {
  return Object.values(map).some(Boolean)
}
