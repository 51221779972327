import {useMemo} from 'react'

import {AggregatedMaterialData} from '../../declarations/MaterialData'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'
import {aggregateMaterialData} from '../../utils/aggregateMaterialData'

import {useCementStrengthData} from './useCementStrengthData'

import {filterSamplesByTimeRangeWithMargin, timeRangeFromStartEnd} from '@predict/UtilsLib/dateTime'

export function useAggregatedCemStrengthData(materialId?: number) {
  const {selectedSources, strength, start, end} = useCementStrengthDomainContext()
  const query = useCementStrengthData(materialId)
  const {data} = query
  const aggregated = useMemo(
    () =>
      data &&
      aggregateMaterialData(data, {
        sourcesToAggregate: selectedSources,
        strengthLevel: strength
      }),
    [data, selectedSources, strength]
  )

  return useMemo(() => {
    if (aggregated) {
      return {
        ...query,
        data: {
          ...aggregated,
          samples: filterSamplesByTimeRangeWithMargin(
            aggregated.samples,
            timeRangeFromStartEnd(start, end)
          )
        } as AggregatedMaterialData
      }
    }
    return {...query, data: undefined}
  }, [aggregated, end, start, query])
}
