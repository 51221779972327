import React from 'react'

import {LoaderIcon} from '@predict/WebUILib/atoms/LoaderIcon/LoaderIcon'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'

interface LoaderProps {
  height?: string | number
  minWidth?: number | string
  margin?: number | string
  marginBottom?: number | string
}

export function Loader({height, minWidth, margin = 3, marginBottom}: LoaderProps) {
  return (
    <DefaultBox
      display="flex"
      p={2}
      m={margin}
      mb={marginBottom}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={height ?? '100%'}
      minWidth={minWidth}
    >
      <LoaderIcon />
    </DefaultBox>
  )
}
