import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  UnifiedSensorMetaData,
  useDeleteUnifiedSensorMetaDataMutation
} from '@predict/DomainsLib/sensorData'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

interface UnifiedSensorMetaDataDeleteDialogProps {
  open?: boolean
  items: UnifiedSensorMetaData[]
  title?: string
  onClose?: () => void
}

function SensorEntries({entries}: {entries: string[]}) {
  return (
    <>
      {entries.map((entry) => (
        <React.Fragment key={entry}>
          <br />
          <b>{entry}</b>
        </React.Fragment>
      ))}
    </>
  )
}

export function UnifiedSensorMetaDataDeleteDialog({
  items,
  open = false,
  title = '',
  onClose
}: UnifiedSensorMetaDataDeleteDialogProps) {
  const {t} = useTranslation()
  const tagsToDelete = items.map(({uniformTag}) => uniformTag).sort()
  const {mutate, isLoading} = useDeleteUnifiedSensorMetaDataMutation(
    useSnackbarMutationInjector(
      t('unifiedSensorMetaDataDeleteDialog.deletionSucceeded'),
      t('unifiedSensorMetaDataDeleteDialog.deletionFailed')
    )
  )

  const onDelete = () => {
    mutate(items, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  return (
    <GeneralDialog
      title={title}
      isOpen={open}
      onClose={onClose}
      data-test-id="unified-sensor-meta-data-delete-dialog"
      actions={<DialogBasicActions onCancel={onCancel} onDelete={onDelete} isLoading={isLoading} />}
    >
      <Text>
        <Trans
          i18nKey="unifiedSensorMetaDataDeleteDialog.deleteQuestion"
          values={{
            count: items.length
          }}
          components={{
            scomp: <SensorEntries entries={tagsToDelete} />
          }}
        />
      </Text>
    </GeneralDialog>
  )
}
