import {isString} from 'lodash'

import {DateTimeParam} from '@predict/UtilsLib/dateTime'

export const ARRAY_PARAMETER_SEPARATION_CHAR = ','
export const EMPTY_ARRAY_PARAM = 'empty'

export function arrayFromParameter(urlParamValue?: string): string[] | undefined {
  if (urlParamValue === EMPTY_ARRAY_PARAM) {
    return []
  }
  if (urlParamValue) {
    return urlParamValue.split(ARRAY_PARAMETER_SEPARATION_CHAR).filter(Boolean)
  }
}

export function getValueFromArray<T>(param: T | undefined, types: T[]): T | undefined {
  if (param) {
    return types.find((t) => String(t) === String(param))
  }
}

export function getDateTimeParam(strVal?: string): DateTimeParam | undefined {
  if (isString(strVal) && strVal.trim().length > 0) {
    const num = +strVal
    return isNaN(num) ? strVal : num
  }
  return strVal
}

export function objFromString<T>(
  param: string | undefined,
  isT: (value: unknown) => value is T
): T | undefined {
  if (isString(param)) {
    try {
      const parsed = JSON.parse(param)
      if (isT(parsed)) {
        return parsed
      }
    } catch (e: unknown) {
      return undefined
    }
  }
}

export function toInt(str?: string): number | undefined {
  if (!str) {
    return undefined
  }
  const parsed = parseInt(str, 10)
  return isNaN(parsed) ? undefined : parsed
}
