import {Alert, AlertColor, Snackbar} from '@mui/material'
import React, {PropsWithChildren} from 'react'

export interface SnackbarConfig {
  autoHideDuration?: number
  severity?: AlertColor
  onClose?: () => void
}

interface CustomSnackbar extends SnackbarConfig, PropsWithChildren<unknown> {
  show?: boolean
}

export function CustomSnackbar({
  show = false,
  autoHideDuration = 5000,
  severity = 'error',
  onClose,
  children
}: CustomSnackbar) {
  return (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      autoHideDuration={autoHideDuration}
      open={show}
      onClose={onClose}
    >
      <Alert severity={severity} onClose={onClose}>
        {children}
      </Alert>
    </Snackbar>
  )
}
