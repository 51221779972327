import {UserManualHookReturn} from '../declarations/UserManualHook'
import introductionManualDe from '../manuals/introduction/de_DE.md'
import introductionManualEn from '../manuals/introduction/en_US.md'

import {useUserManualByLanguage} from './useUserManualByLanguage'

export function useIntroductionUserManual(): UserManualHookReturn {
  return {
    manual: useUserManualByLanguage(introductionManualEn, introductionManualDe)
  }
}
