import * as d3 from 'd3'
import React, {useCallback} from 'react'

import {AccuracyTargetLines} from '../../atoms/AccuracyTargetLines/AccuracyTargetLines'

import {AccuracyPointRecord} from './helpers/AccuracyChartTypes'
import {AccuracyPoint} from './subComponents/AccuracyPoint'
import {ClampedPoint} from './subComponents/ClampedPoint'

import {TargetRange} from '@predict/UtilsLib/general'
import {AxisLabel} from '@predict/WebUILib/Charts/atoms/AxisLabel/AxisLabel'
import {ChartBorder} from '@predict/WebUILib/Charts/atoms/ChartBorder/ChartBorder'
import {ChartGrid} from '@predict/WebUILib/Charts/atoms/ChartGrid/ChartGrid'
import {
  CEMENT_STRENGTH_ACCURACY_OFFSET,
  COLOR_GRID_LINES,
  COLOR_TREND_AXIS,
  COLOR_TREND_BORDER,
  SUGGESTED_TRENDS_TICK_COUNT
} from '@predict/WebUILib/Charts/helpers'
import {
  SvgWrapper,
  SvgWrapperRenderProps
} from '@predict/WebUILib/Charts/layouts/SvgWrapper/SvgWrapper'
import {Axis} from '@predict/WebUILib/Charts/molecules/Axis/Axis'

export interface AccuracyChartProps {
  chartId: string
  chartData: AccuracyPointRecord[]
  targetRange: TargetRange
  pointsColor: string
  chartLabels: {
    x: string
    y: string
  }
  margin: {
    left: number
    top: number
    right: number
    bottom: number
  }
  onSelectSample?: (selectedId?: string) => void
  selectedRecordId?: string
  height?: number
  disableMouse?: boolean
  'data-test-id'?: string
}

interface AccuracyChartContentProps {
  chartData: AccuracyPointRecord[]
  targetRange: TargetRange
  pointsColor: string
  chartLabels: {
    x: string
    y: string
  }
  graphWidth: number
  graphHeight: number
  Clipped: React.FC<{children: React.ReactNode}>
  onSelectSample?: (selectedId?: string) => void
  selectedRecordId?: string
  disableMouse?: boolean
  'data-test-id'?: string
}

function AccuracyChartContent({
  chartData,
  targetRange,
  pointsColor,
  chartLabels,
  graphHeight,
  graphWidth,
  Clipped,
  selectedRecordId,
  onSelectSample,
  disableMouse,
  'data-test-id': dataTestId
}: AccuracyChartContentProps) {
  const domain = [targetRange.min, targetRange.max]
  const x = d3.scaleLinear().domain(domain).range([0, graphWidth])
  const xTicks = x.ticks(SUGGESTED_TRENDS_TICK_COUNT)
  const y = d3.scaleLinear().domain(domain).range([graphHeight, 0])
  const yTicks = y.ticks(SUGGESTED_TRENDS_TICK_COUNT)

  return (
    <>
      <ChartGrid
        type="full"
        xScale={x}
        color={COLOR_GRID_LINES}
        height={graphHeight}
        xTickValues={xTicks}
        yScale={y}
        width={graphWidth}
        yTickValues={yTicks}
      />
      <Clipped>
        <AccuracyTargetLines
          height={graphHeight}
          width={graphWidth}
          rangeOffset={y(0) - y(CEMENT_STRENGTH_ACCURACY_OFFSET)}
          targetPos={{x: x(targetRange.target), y: y(targetRange.target)}}
        />
      </Clipped>
      <ChartBorder chartHeight={graphHeight} chartWidth={graphWidth} color={COLOR_TREND_BORDER} />
      <Axis scale={y} position="left" tickValues={yTicks} color={COLOR_TREND_AXIS} />
      <AxisLabel
        position="left"
        height={graphHeight}
        width={graphWidth}
        label={chartLabels.y}
        color={COLOR_TREND_AXIS}
      />
      <Axis
        scale={x}
        position="bottom"
        tickValues={xTicks}
        posY={graphHeight}
        color={COLOR_TREND_AXIS}
      />
      <AxisLabel
        position="bottom"
        height={graphHeight}
        width={graphWidth}
        label={chartLabels.x}
        color={COLOR_TREND_AXIS}
        offset={40}
      />
      {chartData.map((sr) => {
        const isSelected = sr.id === selectedRecordId
        return (
          <ClampedPoint
            key={sr.id}
            posX={x(sr.actual)}
            posY={y(sr.predicted)}
            maxX={graphWidth}
            maxY={graphHeight}
            color={pointsColor}
            data-test-id={`${dataTestId}-clamped-point-${sr.id}`}
            render={({x: clampedX, y: clampedY}) => (
              <AccuracyPoint
                data-test-id={`${dataTestId}-point-${sr.id}`}
                posX={clampedX}
                posY={clampedY}
                outerRadius={disableMouse ? 0 : isSelected ? 12 : 8}
                innerColor={pointsColor}
                onClick={!disableMouse && !isSelected ? () => onSelectSample?.(sr.id) : undefined}
                outerSx={(theme) =>
                  isSelected
                    ? {
                        fill: theme.palette.info.light,
                        fillOpacity: 0.75
                      }
                    : {}
                }
              />
            )}
          />
        )
      })}
    </>
  )
}

export function AccuracyChart({
  chartId,
  chartData,
  targetRange,
  pointsColor,
  chartLabels,
  margin,
  onSelectSample,
  height,
  selectedRecordId,
  disableMouse,
  'data-test-id': dataTestId
}: AccuracyChartProps) {
  const render = useCallback(
    ({innerWidth, innerHeight, Clipped}: SvgWrapperRenderProps) => (
      <AccuracyChartContent
        graphWidth={innerWidth}
        graphHeight={innerHeight}
        Clipped={Clipped}
        chartData={chartData}
        chartLabels={chartLabels}
        targetRange={targetRange}
        pointsColor={pointsColor}
        onSelectSample={onSelectSample}
        selectedRecordId={selectedRecordId}
        disableMouse={disableMouse}
        data-test-id={dataTestId}
      />
    ),
    [
      chartData,
      chartLabels,
      targetRange,
      pointsColor,
      onSelectSample,
      selectedRecordId,
      disableMouse,
      dataTestId
    ]
  )

  return (
    <SvgWrapper
      svgId={chartId}
      render={render}
      height={height}
      margin={margin}
      data-test-id={dataTestId}
    />
  )
}
