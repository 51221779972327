import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {HasDisabled} from '../declarations/helperTypes'

import {
  SensorMetaData,
  SensorMetaDataKey,
  useMappedUnifiedSensorMetaData
} from '@predict/DomainsLib/sensorData'
import {replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {StatefulGeneralTable} from '@predict/WebApp/modules/general'
import {
  MappedSensorMetaDataDeleteDialog,
  MappedSensorMetaDataDialog
} from '@predict/WebApp/modules/sensorData'
import {IconButton} from '@predict/WebUILib/atoms/IconButton/IconButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Delete, Edit} from '@predict/WebUILib/helpers/icons'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'
import {
  lowerCaseStringFilter,
  lowerCaseStringSort,
  TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE
} from '@predict/WebUILib/organisms/GeneralTable/helpers'
import {useSnackbar} from '@predict/WebUILib/organisms/SnackbarProvider'

const COLUMN_KEYS: SensorMetaDataKey[] = ['plantSpecificTag', 'uniformTag', 'unit', 'description']

export function SensorMetaDataSettings({disabled}: HasDisabled) {
  const {t} = useTranslation()
  const {showError} = useSnackbar()

  const {data: metaData} = useMappedUnifiedSensorMetaData()
  const initiallyOrderedMetaData = useMemo(
    () =>
      metaData ? lowerCaseStringSort(metaData, [{field: 'uniformTag', expression: 'asc'}]) : [],
    [metaData]
  )

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<SensorMetaData>()

  if (!metaData) return <Loader />

  return (
    <DefaultBox width="100%" data-test-id="process-meta-data-settings">
      <Text variant="h6" isBold>
        {t('sensorSettings.title')}
      </Text>
      <Spacing height={3} />
      <StatefulGeneralTable
        tableId="sensor-settings-table"
        columnsConfiguration={COLUMN_KEYS.map((key) => ({
          name: key,
          label: t(`processMetaData.${key}`)
        }))}
        data={initiallyOrderedMetaData}
        rowKey={({uniformTag}) => replaceNonWordCharacters(uniformTag)}
        cellBuilders={{
          plantSpecificTag: ({plantSpecificTag}) => plantSpecificTag,
          uniformTag: ({uniformTag}) => uniformTag,
          unit: ({unit}) => unit,
          description: ({description}) => description,
          tableActions: disabled
            ? undefined
            : (item) => (
                <>
                  <IconButton
                    toolTipTitle={t('button.edit')}
                    onClick={() => {
                      setSelectedItem(item)
                      setOpenEditDialog(true)
                    }}
                    data-test-id={`sensor-settings-table-edit-btn-${replaceNonWordCharacters(
                      item.uniformTag
                    )}`}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    toolTipTitle={t('button.delete')}
                    onClick={() => {
                      if (item.plantSpecificTag) {
                        setSelectedItem(item)
                        setOpenDeleteDialog(true)
                      } else {
                        showError(t('mappedSensorMetaDataDeleteDialog.noMapping'))
                      }
                    }}
                    data-test-id={`sensor-settings-table-delete-btn-${replaceNonWordCharacters(
                      item.uniformTag
                    )}`}
                  >
                    <Delete />
                  </IconButton>
                </>
              )
        }}
        sortMethod={lowerCaseStringSort}
        filterMethod={lowerCaseStringFilter}
        defaultRowsPerPage={TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE}
      />
      {selectedItem ? (
        <>
          <MappedSensorMetaDataDialog
            open={openEditDialog}
            onClose={() => {
              setSelectedItem(undefined)
              setOpenEditDialog(false)
            }}
            item={selectedItem}
            title={t('sensorTableEditButton.title')}
          />
          <MappedSensorMetaDataDeleteDialog
            item={selectedItem}
            open={openDeleteDialog}
            onClose={() => {
              setSelectedItem(undefined)
              setOpenDeleteDialog(false)
            }}
          />
        </>
      ) : null}
    </DefaultBox>
  )
}
