import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Collapse, TableCell, TableCellProps} from '@mui/material'
import React from 'react'

import {useTableStyles} from '../helpers/useTableStyles'

interface TableBodyCellProps extends TableCellProps {
  isCollapsed?: boolean
  alwaysCellBorder?: boolean
  isCompact?: boolean
}

export function GeneralTableBodyCell({
  isCollapsed = false,
  alwaysCellBorder,
  children,
  sx,
  isCompact = false,
  ...rest
}: TableBodyCellProps) {
  const tableSx = useTableStyles(isCompact)
  return (
    <TableCell
      sx={mergeSxs(
        [
          tableSx.cell,
          alwaysCellBorder ? tableSx.alwaysCellBorder : tableSx.cellBorder,
          isCollapsed && {
            padding: 0,
            height: 0,
            border: 0
          }
        ],
        sx
      )}
      {...rest}
    >
      <Collapse in={!isCollapsed}>{children}</Collapse>
    </TableCell>
  )
}
