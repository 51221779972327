import {AxiosError} from 'axios'
import {useMutation} from 'react-query'

import {UnifiedSensorMetaData} from '../../declarations/SensorMetaData'
import {trimUnifiedSensorMetaDataFields} from '../../utils/sensorMetaDataUtils'
import {useInvalidateSensorData} from '../useInvalidateSensorData'

import {UniformProcessMetricsApi} from '@predict/DomainsLib/gen-api/UniformProcessMetrics'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export async function updateUnifiedSensorMetaData(metaData: UnifiedSensorMetaData): Promise<void> {
  const log = logger.context('updateUnifiedSensorMetaData', JSON.stringify(metaData))
  try {
    await UniformProcessMetricsApi.processMetricsUniformUpdate(metaData.uniformTag, {
      uniformTag: metaData.uniformTag,
      unit: metaData.unit,
      description: metaData.description,
      displayName: metaData.displayName || metaData.description
    })
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export function useUpdateUnifiedSensorMetaDataMutation(optionsInjector = dummyMutationInjector) {
  const invalidateSensorData = useInvalidateSensorData()

  return useMutation(
    optionsInjector({
      mutationFn: async (metaData: UnifiedSensorMetaData) => {
        const dataToSave = trimUnifiedSensorMetaDataFields(metaData)
        await updateUnifiedSensorMetaData(dataToSave)
      },
      onSuccess: invalidateSensorData
    })
  )
}
