import {useQuery, UseQueryResult} from 'react-query'

import {CementStrengthSample} from '../../declarations/CsSamples'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'
import {rawDataDtoToCsSample} from '../../utils/rawDataDtoToCSSample'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {CementStrengthApi} from '@predict/DomainsLib/gen-api/CementStrength'
import {usePlantId} from '@predict/DomainsLib/plants'
import {timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'

export function useAllCementStrengthSamples(): UseQueryResult<CementStrengthSample[]> {
  const plantId = usePlantId()
  const {timeRange, strength} = useCementStrengthDomainContext()
  return useQuery<CementStrengthSample[]>(
    [QueryKeyName.GetUnfilteredCsSamples, plantId, timeRange, strength],
    async () => {
      const range = timeRangeFromDateTimeParams(timeRange)
      const response = await CementStrengthApi.getplantsCementStrengthsAll(plantId, {
        startTime: range.start.toJSON(),
        endTime: range.end.toJSON()
      })
      return rawDataDtoToCsSample(response.data, strength)
    }
  )
}
