import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {
  getStrengthLevelInDays,
  StrengthLevel,
  usePlantCementStrengthConfig
} from '@predict/DomainsLib/cementStrength'
import {StringValueSelect} from '@predict/WebUILib/atoms/StringValueSelect/StringValueSelect'

interface PredictionStrengthSelectProps {
  strengthLevel: StrengthLevel
  onStrengthLevelChanged: (strengthLevel: StrengthLevel) => void
  disabled?: boolean
}

export function PredictionStrengthSelect({
  strengthLevel,
  onStrengthLevelChanged,
  disabled
}: PredictionStrengthSelectProps) {
  const {t} = useTranslation()
  const {supportedStrengthLevels: levels} = usePlantCementStrengthConfig()

  const valueLabel = useCallback(
    (itemValue: StrengthLevel) =>
      t('strengthSelect.strength', {count: getStrengthLevelInDays(itemValue)}),
    [t]
  )

  return (
    <StringValueSelect
      onSelected={onStrengthLevelChanged}
      value={strengthLevel}
      values={levels}
      fieldId="predict-select-strength"
      data-test-id="predict-select-strength"
      valueLabel={valueLabel}
      label={t('pageActionBar.actionPredictionStrengthLabel')}
      disabled={disabled}
    />
  )
}
