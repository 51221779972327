import {Breadcrumbs} from '@mui/material'
import React from 'react'

import {InAppLink} from '../../atoms/InAppLink/InAppLink'

import {replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {Text} from '@predict/WebUILib/atoms/Text/Text'

export interface BreadcrumbEntry {
  title: string
  url: string
}

interface PageBreadcrumbsProps {
  current: string
  pathEntries?: BreadcrumbEntry[]
}

export function PageBreadcrumbs({current, pathEntries = []}: PageBreadcrumbsProps) {
  return (
    <Breadcrumbs
      sx={(theme) => ({
        color: theme.palette.grey[400],
        marginBottom: 1
      })}
    >
      {pathEntries.map((entry) => (
        <InAppLink
          key={entry.url}
          to={entry.url}
          sx={(theme) => ({
            color: 'text.secondary',
            '&:hover': {
              color: theme.palette.grey[400],
              textDecoration: 'none'
            }
          })}
          data-test-id={`breadcrumbs-link-${replaceNonWordCharacters(entry.title)}`}
        >
          {entry.title}
        </InAppLink>
      ))}
      <Text
        sx={(theme) => ({
          color: theme.palette.grey[400]
        })}
      >
        {current}
      </Text>
    </Breadcrumbs>
  )
}
