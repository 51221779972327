import moment from 'moment'

import {AxisFormatFn, ScaleFn} from '../declarations/scaleFn'

import {formatTimeZoneDate} from '@predict/UtilsLib/dateTime'

interface DurationBreakpoints {
  days: number
  weeks: number
  months: number
  years: number
}

function getDurationBreakpoints(): DurationBreakpoints {
  return {
    days: moment.duration(23, 'hours').asMilliseconds(),
    weeks: moment.duration(6.5, 'days').asMilliseconds(),
    months: moment.duration(27, 'days').asMilliseconds(),
    years: moment.duration(364, 'days').asMilliseconds()
  } as const
}

export function hasLabel(
  tickIndex: number,
  slotWidth: number,
  maxLabelWidth = 64,
  minSlotWidth = 8
): boolean {
  if (slotWidth < minSlotWidth) {
    return false
  }

  if (slotWidth > maxLabelWidth) {
    return true
  }

  const slotsPerLabel = Math.ceil(maxLabelWidth / slotWidth)
  const offset = Math.floor(slotsPerLabel / 2)
  return (tickIndex - offset) % slotsPerLabel === 0
}

function calcTimeFormatStrings(
  ticks: number[],
  t: (key: string) => string
): {main: string; sub: string} {
  if (ticks.length < 2) {
    return {main: '', sub: ''}
  }

  const duration = ticks[ticks.length - 1] - ticks[0]
  const breakpoints = getDurationBreakpoints()

  const avgDurationPerTick = duration / (ticks.length - 1)
  if (avgDurationPerTick > breakpoints.years) {
    return {main: t('chartTimeFormats.years.main'), sub: t('chartTimeFormats.years.sub')}
  }
  if (avgDurationPerTick > breakpoints.months) {
    return {main: t('chartTimeFormats.months.main'), sub: t('chartTimeFormats.months.sub')}
  }
  if (avgDurationPerTick > breakpoints.weeks) {
    return {main: t('chartTimeFormats.weeks.main'), sub: t('chartTimeFormats.weeks.sub')}
  }
  if (avgDurationPerTick > breakpoints.days) {
    return {main: t('chartTimeFormats.days.main'), sub: t('chartTimeFormats.days.sub')}
  }
  return {main: t('chartTimeFormats.hours.main'), sub: t('chartTimeFormats.hours.sub')}
}

export function averageWidth(ticks: number[], x: ScaleFn) {
  if (ticks.length < 2) {
    return 0
  }

  const width = (x(ticks[ticks.length - 1]) ?? 0) - (x(ticks[0]) ?? 0)
  return width / (ticks.length - 1)
}
function axisFormat(
  xTimeTicks: number[],
  x: ScaleFn,
  timeZone: string,
  formatStr: string
): AxisFormatFn {
  return (dt, idx) =>
    hasLabel(idx, averageWidth(xTimeTicks, x)) ? formatTimeZoneDate(dt, timeZone, formatStr) : ''
}

export function timeAxisFormatter(
  ticks: number[],
  x: ScaleFn,
  timeZone: string,
  tFunc: (key: string) => string
): {format: AxisFormatFn; extraFormat?: AxisFormatFn} {
  const {main, sub} = calcTimeFormatStrings(ticks, tFunc)

  return {
    format: axisFormat(ticks, x, timeZone, main),
    extraFormat: sub ? axisFormat(ticks, x, timeZone, sub) : undefined
  }
}
