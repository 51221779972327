import {isNumber} from 'lodash'
import {useMemo} from 'react'

import {levelPredictionMap, levelStrengthMap} from '../../constants'
import {CsMaterialSample} from '../../declarations/MaterialData'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'

import {filterSamplesByTimeRange, timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'

export function useMaterialSamplesInTimeRange(
  samples: CsMaterialSample[],
  hasActualAndPredicted: boolean
): CsMaterialSample[] {
  const {timeRange, strength} = useCementStrengthDomainContext()
  return useMemo(() => {
    const filtered = filterSamplesByTimeRange(samples, timeRangeFromDateTimeParams(timeRange))
    return hasActualAndPredicted
      ? filtered.filter(
          (s) =>
            isNumber(s[levelPredictionMap[strength]]) && isNumber(s[levelStrengthMap[strength]])
        )
      : filtered
  }, [hasActualAndPredicted, samples, strength, timeRange])
}
