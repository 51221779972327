import type {Location} from 'history'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {useHistory} from 'react-router-dom'
import {v4 as uuid} from 'uuid'

import {CustomPrompt} from './CustomPrompt'
import {addHandle, enableHandle, isHandleEnabled, removeHandle} from './utils/promptUtils'

export interface PromptOptions {
  title?: string
  message?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
}

type ProvidersEnablePromptFunc = (handle: string, enable: boolean, options?: PromptOptions) => void
export type EnablePromptFunc = (enable: boolean, options?: PromptOptions) => void

export interface PromptContext {
  enablePrompt: ProvidersEnablePromptFunc
  addHandle: () => string
  removeHandle: (handle: string) => void
}

const PromptProviderContext = createContext<PromptContext | undefined>(undefined)

export const usePromptContext = () => {
  const context = useContext(PromptProviderContext)
  if (!context) {
    throw new Error('usePromptContext used outside of PromptProvider')
  }
  return context
}

export function PromptProvider({children}: PropsWithChildren<unknown>) {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [location, setLocation] = useState<Location>()
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>()
  const [confirmButtonLabel, setConfirmButtonLabel] = useState<string>()
  const [cancelButtonLabel, setCancelButtonLabel] = useState<string>()
  const unblockRef = useRef<() => void>()
  const [handleMap, setHandleMap] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (isHandleEnabled(handleMap)) {
      unblockRef.current?.()
      unblockRef.current = history.block((location) => {
        setLocation(location)
        setShowModal(true)
        return false
      })
    } else {
      // unblock navigation
      unblockRef.current?.()
    }
  }, [history, handleMap])

  const onCancel = () => {
    setShowModal(false)
    setLocation(undefined)
  }

  const onConfirm = () => {
    setShowModal(false)
    if (location) {
      unblockRef.current?.()
      history.push(location)
    }
  }

  const value = useMemo(
    () => ({
      enablePrompt: (handle: string, enable: boolean, options?: PromptOptions) => {
        setHandleMap(enableHandle(handle, enable))
        setMessage(options?.message ?? '')
        setTitle(options?.title)
        setConfirmButtonLabel(options?.confirmButtonLabel)
        setCancelButtonLabel(options?.cancelButtonLabel)
      },
      addHandle: () => {
        const handle = uuid()
        setHandleMap(addHandle(handle))
        return handle
      },
      removeHandle: (handle: string) => {
        setHandleMap(removeHandle(handle))
      }
    }),
    []
  )

  return (
    <PromptProviderContext.Provider value={value}>
      {children}
      <CustomPrompt
        show={showModal}
        message={message}
        title={title}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </PromptProviderContext.Provider>
  )
}
