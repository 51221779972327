import {useTranslation} from 'react-i18next'

import {
  DateTimeParam,
  TimeRange,
  formatTimeZoneDate,
  isNowInTimeRange,
  timeRangeFromDateTimeParams
} from '@predict/UtilsLib/dateTime'
import {NumberRange, TargetRange, replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {CHART_MARGIN, getChartNow} from '@predict/WebApp/modules/general/utils/chartHelpers'
import {useTimeRangeSearchParams} from '@predict/WebApp/modules/navigation'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Headline} from '@predict/WebUILib/atoms/Headline/Headline'
import {LoaderIcon} from '@predict/WebUILib/atoms/LoaderIcon/LoaderIcon'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {ChartData} from '@predict/WebUILib/Charts/helpers'
import {StepChart} from '@predict/WebUILib/Charts/organisms/StepChart/StepChart'
import {useIsMobile, useIsNarrow} from '@predict/WebUILib/helpers/hooks/useBreakPoints'
import {Close} from '@predict/WebUILib/helpers/icons'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {HorizontalScrollableWrapper} from '@predict/WebUILib/layouts/HorizontalScrollableWrapper/HorizontalScrollableWrapper'
import {Skeleton} from '@predict/WebUILib/molecules/Skeleton/Skeleton'
import {StatsItem} from '@predict/WebUILib/molecules/StatsItem/StatsItem'

export interface LabAndSensorChartData extends ChartData {
  canBeClosed?: boolean
  isReloading?: boolean
  targetRange?: TargetRange
  axisRange?: NumberRange
}

interface LabAndSensorChartsProps {
  data: LabAndSensorChartData[]
  onClose: (tagId: string) => void
  timeZone: string
  timeRange: TimeRange<DateTimeParam>
  isLoading?: boolean
}

const GRAPH_HEIGHT = 250 as const

export function LabAndSensorCharts({
  data,
  onClose,
  timeZone,
  timeRange,
  isLoading = false
}: LabAndSensorChartsProps) {
  const {setTimeRange: onTimeRangeChanged} = useTimeRangeSearchParams()
  const {t} = useTranslation()

  const isNarrowViewPort = useIsNarrow()
  const isMobileViewPort = useIsMobile()

  const now = getChartNow()
  const isNow = isNowInTimeRange(timeRange)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${isNarrowViewPort ? 1 : 2}, 1fr)`,
        columnGap: 2,
        rowGap: 2
      }}
    >
      {data.map((chartData) => {
        const {id, name, isReloading, actualValues, color, targetRange, axisRange, canBeClosed} =
          chartData
        const key = replaceNonWordCharacters(id)
        const lastSample = actualValues[actualValues.length - 1]
        return (
          <DefaultBox
            key={key}
            data-test-id={`optional-data-chart-box-${key}`}
            minHeight={GRAPH_HEIGHT}
            display="flex"
            flexDirection="column"
            sx={{overflowX: 'auto'}}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" height={45}>
              <Box display="flex" alignItems="baseline">
                <Headline tag="h4" bottomSpacing={1} mr={1}>
                  {name}
                </Headline>
                {isReloading && <LoaderIcon size="1rem" />}
              </Box>
              {canBeClosed && (
                <ColorButton
                  onClick={() => onClose(id)}
                  startIcon={<Close />}
                  data-test-id={`optional-data-chart-${key}-close-button`}
                >
                  {t('button.close')}
                </ColorButton>
              )}
            </Box>

            <Spacing height={1} />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <HorizontalScrollableWrapper>
                <StepChart
                  chartId={key}
                  height={GRAPH_HEIGHT}
                  margin={CHART_MARGIN}
                  timeZone={timeZone}
                  data-test-id={`optional-data-chart-${key}`}
                  timeRange={timeRangeFromDateTimeParams(timeRange, now)}
                  mainChartData={chartData}
                  mainTargetRange={targetRange}
                  mainAxisRange={axisRange}
                  onTimeRangeChanged={(timeRange: TimeRange<number>) => {
                    onTimeRangeChanged(timeRange.start, timeRange.end)
                  }}
                />
              </HorizontalScrollableWrapper>
              {isNow && !isMobileViewPort && (
                <Box
                  width={120}
                  height={GRAPH_HEIGHT}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignContent="center"
                  data-test-id={`optional-data-chart-${key}-info-box`}
                >
                  <StatsItem
                    headerLabel={t('chart.latestSampleLabel')}
                    subInfo={
                      lastSample
                        ? formatTimeZoneDate(lastSample.datetime, timeZone, t('chart.timeFormat'))
                        : undefined
                    }
                    value={lastSample?.value}
                    valueUnit={chartData.unit}
                    valueColor={color}
                    valueDigits={2}
                    hasSeparateUnit
                    hasSmallValue={false}
                    isBold={false}
                    data-test-id={`optional-data-chart-${key}-info-box-latest-sample`}
                  />
                </Box>
              )}
            </Box>
          </DefaultBox>
        )
      })}
      {isLoading && <Skeleton height={GRAPH_HEIGHT} margin={0} />}
    </Box>
  )
}
