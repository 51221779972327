import {ButtonGroup, Tooltip} from '@mui/material'
import React from 'react'

import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'

export interface IconButtonGroupItem {
  label: string
  icon: React.ReactNode
  onClick: () => void
  isSelected?: boolean
  testId?: string
}

interface IconButtonGroupProps {
  items: IconButtonGroupItem[]
  'data-test-id'?: string
}

export function IconButtonGroup({items, 'data-test-id': groupTestId}: IconButtonGroupProps) {
  const palette = useThemePalette()
  return (
    <ButtonGroup
      sx={{
        marginBottom: 1
      }}
      data-test-id={groupTestId}
    >
      {items.map(({isSelected, label, icon, onClick, testId}) => (
        <Tooltip disableInteractive key={label} title={label}>
          <ColorButton
            btnColor={isSelected ? palette.primary.main : undefined}
            onClick={!isSelected ? onClick : undefined}
            aria-label={label}
            data-test-id={testId}
            color="secondary"
          >
            {icon}
          </ColorButton>
        </Tooltip>
      ))}
    </ButtonGroup>
  )
}
