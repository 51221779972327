import {isString} from 'lodash'

import type {MaterialLabData} from '../declarations/labData'

import {dtoToPlantLabMetaData} from './dtoToPlantLabMetaData'

import type {LabMaterialMetrics} from '@predict/DomainsLib/gen-api/data-contracts'
import type {TimeRange} from '@predict/UtilsLib/dateTime'

export function dtoToPlantLabData(
  metricsDto: LabMaterialMetrics,
  range: TimeRange
): MaterialLabData {
  return {
    range,
    plantId: metricsDto.plantId ?? '',
    materialId: Number(metricsDto.materialId),
    data:
      metricsDto.metrics
        ?.filter((dto) => isString(dto.plantSpecificTag))
        .map((metric) => ({
          ...dtoToPlantLabMetaData(metric),
          records:
            metric.timeseries.map(({value, timestamp, sampleId}) => ({
              value: Number(value),
              // convert to milliseconds
              datetime: +new Date(timestamp),
              sampleId
            })) ?? []
        })) ?? []
  }
}
