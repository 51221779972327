import React from 'react'
import {useTranslation} from 'react-i18next'

import {CementStrengthDomainProvider, useMaterial} from '@predict/DomainsLib/cementStrength'
import {CementStrengthDomainConsumer} from '@predict/DomainsLib/cementStrength/providers/CementStrengthDomainProvider'
import {LabDataDomainProvider} from '@predict/DomainsLib/labData'
import {usePlantFullName} from '@predict/DomainsLib/plants'
import {
  CementStrengthActionBar,
  CementStrengthSamplesTable
} from '@predict/WebApp/modules/cementStrength'
import {
  usePlantNavigation,
  useMaterialIdParameter,
  useTimeRangeSearchParams,
  useStrengthSearchParam,
  useSelectedSourcesSearchParam,
  useSampleIdSearchParam,
  usePredictionViewSearchParam,
  useSelectedTagIdsSearchParam
} from '@predict/WebApp/modules/navigation'
import {PlantPageShell} from '@predict/WebApp/modules/plants'
import {CementStrengthMaterialChartsContainer} from '@predict/WebApp/pages/CementStrengthTrendsPage/components/CementStrengthMaterialChartsContainer'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'
import {PageBreadcrumbs} from '@predict/WebUILib/molecules/PageBreadcrumbs/PageBreadcrumbs'

export function CementStrengthTrendsPage() {
  const materialIdParameter = useMaterialIdParameter()
  const {start, end} = useTimeRangeSearchParams()
  const {strength} = useStrengthSearchParam()
  const {selectedTagIds} = useSelectedTagIdsSearchParam()
  const {sampleId} = useSampleIdSearchParam()
  const {selectedSources} = useSelectedSourcesSearchParam()

  return (
    <CementStrengthDomainProvider
      materialId={materialIdParameter}
      sampleId={sampleId}
      strength={strength}
      selectedSources={selectedSources}
      start={start}
      end={end}
    >
      <CementStrengthDomainConsumer>
        {(csContextValue) =>
          csContextValue ? (
            <LabDataDomainProvider
              start={csContextValue.start}
              end={csContextValue.end}
              selectedTagIds={selectedTagIds}
            >
              <CementStrengthTrendsPageContent />
            </LabDataDomainProvider>
          ) : (
            <Loader />
          )
        }
      </CementStrengthDomainConsumer>
    </CementStrengthDomainProvider>
  )
}

function CementStrengthTrendsPageContent() {
  const {t} = useTranslation()
  const navigation = usePlantNavigation()
  const plantName = usePlantFullName()
  const {predictionView} = usePredictionViewSearchParam()
  const material = useMaterial()

  return (
    <PlantPageShell>
      <PageBreadcrumbs
        current={material?.name || t('navigation.cementTrends')}
        pathEntries={[
          {
            title: t('navigation.cement'),
            url: navigation.getPlantUrl({
              plantPage: 'cement'
            })
          }
        ]}
      />
      {material ? (
        <>
          <CementStrengthActionBar
            title={`${plantName} - ${material.name}`}
            showZoomOptions={predictionView === 'time'}
            showDataSelector={predictionView !== 'table'}
          />
          {predictionView === 'table' ? (
            <DefaultBox width="100%">
              <CementStrengthSamplesTable materialId={material.materialId} />
            </DefaultBox>
          ) : (
            <CementStrengthMaterialChartsContainer
              material={material}
              predictionView={predictionView}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </PlantPageShell>
  )
}
