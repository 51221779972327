import {mean} from 'lodash'

export function inNumberRange(min: number, value: number, max: number): boolean {
  return min <= value && value <= max
}

export interface IntervalCalculationMethod {
  (positions: number[], index: number, maxPos: number): [number, number]
}

export const calculateIntervalAround: IntervalCalculationMethod = (positions, index, maxPos) => {
  if (positions.length < 2) {
    return [0, maxPos]
  }

  const pos = positions[index]
  return [
    index === 0 ? 0 : mean([positions[index - 1], pos]),
    index === positions.length - 1 ? maxPos : mean([pos, positions[index + 1]])
  ]
}

export const calculateIntervalAfter: IntervalCalculationMethod = (positions, index, maxPos) => {
  if (positions.length < 2) {
    return [0, maxPos]
  }

  const pos = positions[index]
  return [pos, index === positions.length - 1 ? maxPos : positions[index + 1]]
}
