import {isNumber} from 'lodash'
import {useQuery} from 'react-query'

import {MaterialData} from '../../declarations/MaterialData'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'
import {csPredictionDataDtoToMaterialData} from '../../utils/csPredictionDataDtoToMaterialData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {CementStrengthPredictionsApi} from '@predict/DomainsLib/gen-api/CementStrengthPredictions'
import {usePlantId} from '@predict/DomainsLib/plants'
import {padTimeRange, TimeRange, timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'
import {DEFAULT_TIME_MARGIN_FACTOR} from '@predict/UtilsLib/dateTime/constants'

export async function getCementStrengthByMaterial(
  plantId: string,
  materialId: number,
  range: TimeRange
): Promise<MaterialData | undefined> {
  const {data} = await CementStrengthPredictionsApi.getplantsCementStrengthsPredictions(plantId, {
    unifiedMaterialId: materialId,
    startTime: range.start.toJSON(),
    endTime: range.end.toJSON()
  })

  if (data.length === 0) {
    return
  }
  const [dto] = data
  return csPredictionDataDtoToMaterialData(dto, range)
}

export function useCementStrengthData(materialId?: number) {
  const plantId = usePlantId()
  const {timeRange} = useCementStrengthDomainContext()
  const paddedRange = padTimeRange(
    timeRangeFromDateTimeParams(timeRange),
    DEFAULT_TIME_MARGIN_FACTOR
  )

  return useQuery(
    [QueryKeyName.GetCementStrengthForMaterial, plantId, materialId, timeRange],
    async () => {
      if (!isNumber(materialId)) {
        return undefined
      }
      return await getCementStrengthByMaterial(plantId, materialId, paddedRange)
    }
  )
}
