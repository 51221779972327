import {Link} from '@mui/icons-material'
import {kebabCase} from 'lodash'
import React, {useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useLocation} from 'react-router-dom'

import {Headline, HeadlineProps} from '../../atoms/Headline/Headline'

import {InAppLink} from '@predict/WebUILib/atoms/InAppLink/InAppLink'

export function ClickableHeadline({id, children, ...rest}: HeadlineProps) {
  const [isIconVisible, setIsIconVisible] = useState(false)
  const headlineId = id ?? kebabCase(String(children))
  const {pathname, search} = useLocation()

  const relativeUrl = `${pathname}${search}#${headlineId}`
  const absoluteUrl = `${window?.location.origin ?? ''}${relativeUrl}`

  return (
    <CopyToClipboard text={absoluteUrl}>
      <Headline id={headlineId} {...rest}>
        <InAppLink
          onMouseEnter={() => setIsIconVisible(true)}
          onMouseLeave={() => setIsIconVisible(false)}
          underline="none"
          to={relativeUrl}
          sx={{
            color: 'text.primary'
          }}
        >
          {children}
          {isIconVisible && (
            <Link
              data-test-id="clickable-headline-link-icon"
              fontSize="inherit"
              sx={{
                marginLeft: 1,
                verticalAlign: 'middle'
              }}
            />
          )}
        </InAppLink>
      </Headline>
    </CopyToClipboard>
  )
}
