import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  useSelectedSourcesSearchParam,
  useStrengthSearchParam,
  useTimeRangeSearchParams
} from '../../modules/navigation'
import {PlantPageShell} from '../../modules/plants'

import {MaterialChartsContainer} from './components/MaterialChartsContainer'

import {CementStrengthDomainProvider} from '@predict/DomainsLib/cementStrength'
import {CementStrengthActionBar} from '@predict/WebApp/modules/cementStrength'

export function CementStrengthPage() {
  const {t} = useTranslation()
  const {start, end} = useTimeRangeSearchParams()
  const {strength} = useStrengthSearchParam()
  const {selectedSources} = useSelectedSourcesSearchParam()

  return (
    <CementStrengthDomainProvider
      strength={strength}
      selectedSources={selectedSources}
      start={start}
      end={end}
    >
      <PlantPageShell>
        <CementStrengthActionBar title={t('cementStrengthPredictionPage.title')} />
        <MaterialChartsContainer />
      </PlantPageShell>
    </CementStrengthDomainProvider>
  )
}
