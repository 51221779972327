import type {WithNullableValue, WithValue} from '../../general'

export type TimeHorizonId =
  | 'last3Hours'
  | 'last6Hours'
  | 'last12Hours'
  | 'last24Hours'
  | 'last3Days'
  | 'lastWeek'
  | 'lastMonth'
  | 'last3Months'
  | 'last6Months'
  | 'lastYear'
  | 'last2Years'
  | 'last5Years'
  | 'last10Years'

export interface WithDateTime {
  datetime: number
}

export interface DateTimeRecord extends WithDateTime, WithValue {}

export interface NullableDateTimeRecord extends WithDateTime, WithNullableValue {}

// the date time parameter can be a timestamp or a string like 'now' or 'now-P6M'
export type DateTimeParam = string | number

interface TimeRangeOptEnd<D extends Date | string | number | DateTimeParam = Date> {
  start: D
  end?: D
}

export type TimeRange<D extends Date | string | number | DateTimeParam = Date> = Required<
  TimeRangeOptEnd<D>
>
