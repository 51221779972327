import * as d3 from 'd3'

export type BaseScaleFnType = number

export type TimeScaleFn<
  Range extends BaseScaleFnType = BaseScaleFnType,
  Output = Range,
  Unknown = never
> = d3.ScaleTime<Range, Output, Unknown>

export type LinearScaleFn<
  Range extends BaseScaleFnType = BaseScaleFnType,
  Output = Range,
  Unknown = never
> = d3.ScaleLinear<Range, Output, Unknown>

export type BandScaleFn<Domain extends BaseScaleFnType = BaseScaleFnType> = d3.ScaleBand<Domain>

export type ScaleFn<Range extends number = number, Output = Range, Unknown = never> =
  | TimeScaleFn<Range, Output, Unknown>
  | LinearScaleFn<Range, Output, Unknown>
  | BandScaleFn<Range>

export type AxisFormatFn = (value: number, idx: number) => string
