import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CementStrengthAccuracyChart} from './CementStrengthAccuracyChart'
import {CementStrengthSampleDetails} from './CementStrengthSampleDetails'
import {LabCharts} from './LabCharts'

import {
  Material,
  useCementStrengthDomainContext,
  useMaterialLevel,
  useMaterialSamplesInTimeRange,
  CsMaterialSample,
  findNextSample,
  findPrevSample,
  useAggregatedCemStrengthData
} from '@predict/DomainsLib/cementStrength'
import {timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'
import {COLOR_ACTUAL_VALUES, COLOR_PREDICTIONS} from '@predict/UtilsLib/general/constants'
import {PredictionView, useSampleIdSearchParam} from '@predict/WebApp/modules/navigation'
import {CementStrengthMaterialChart} from '@predict/WebApp/pages/CementStrengthTrendsPage/components/CementStrengthMaterialChart'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {AsyncContainer} from '@predict/WebUILib/layouts/AsyncContainer/AsyncContainer'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {ChartContainer} from '@predict/WebUILib/layouts/ChartContainer/ChartContainer'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {ChartBox} from '@predict/WebUILib/molecules/ChartBox/ChartBox'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'

interface CementStrengthMaterialChartsContainerProps {
  material: Material
  predictionView: PredictionView
}

const isFirstSample = (samples: CsMaterialSample[], sampleId?: string) =>
  Boolean(sampleId) && samples[0]?.id === sampleId

const isLastSample = (samples: CsMaterialSample[], sampleId?: string) =>
  Boolean(sampleId) && samples[samples.length - 1]?.id === sampleId

const DETAILS_BOX_WIDTH = 360
const GRAPH_HEIGHT = 250

export function CementStrengthMaterialChartsContainer({
  material,
  predictionView
}: CementStrengthMaterialChartsContainerProps) {
  const {t} = useTranslation()
  const {materialId} = material

  const {sampleId, strength: level, timeRange} = useCementStrengthDomainContext()
  const {data, isLoading, isError} = useAggregatedCemStrengthData(materialId)
  const materialLevel = useMaterialLevel(materialId, level)
  const {setSampleId: onSelectSample} = useSampleIdSearchParam()

  const visibleSamples = useMaterialSamplesInTimeRange(
    data?.samples ?? [],
    predictionView !== 'time'
  )

  const isFirst = isFirstSample(visibleSamples, sampleId)
  const isLast = isLastSample(visibleSamples, sampleId)

  const selectedSample: CsMaterialSample | undefined = useMemo(() => {
    if (sampleId && data) {
      return visibleSamples.find((s) => s.id === sampleId)
    }
  }, [sampleId, data, visibleSamples])

  const onPrevSample = useCallback(
    (currentSampleId: string) => {
      const prevSample = findPrevSample(visibleSamples, currentSampleId)
      if (prevSample) {
        onSelectSample(prevSample.id)
      }
    },
    [visibleSamples, onSelectSample]
  )

  const onNextSample = useCallback(
    (currentSampleId: string) => {
      const nextSample = findNextSample(visibleSamples, currentSampleId)
      if (nextSample) {
        onSelectSample(nextSample.id)
      }
    },
    [visibleSamples, onSelectSample]
  )

  const asyncLoaderProps = {
    isLoading,
    isSuccess: !!data,
    isError: isError || !data
  }

  return (
    <ChartContainer
      sideContent={
        <AsyncContainer
          {...asyncLoaderProps}
          loaderType="table"
          loaderMinWidth={DETAILS_BOX_WIDTH}
          loaderMargin={0}
          loaderMarginBottom={3}
        >
          <DefaultBox minWidth={DETAILS_BOX_WIDTH}>
            {selectedSample ? (
              <CementStrengthSampleDetails
                material={material}
                sample={selectedSample}
                isFirstSample={isFirst}
                isLastSample={isLast}
                onPrev={onPrevSample}
                onNext={onNextSample}
              />
            ) : (
              <Box display="flex" height="60%" justifyContent="center" flexDirection="column">
                <Text variant="body1" align="center" color="secondary">
                  {t('sampleDetails.placeholder')}
                </Text>
              </Box>
            )}
          </DefaultBox>
        </AsyncContainer>
      }
    >
      {materialLevel ? (
        <>
          <AsyncContainer
            {...asyncLoaderProps}
            errorMessage={t('errors.noDataAvailable')}
            loaderType="rectangular"
            loaderHeight={500}
            loaderMargin={0}
          >
            {data && predictionView === 'time' ? (
              <ChartBox
                coloredData={[
                  {
                    text: t('legend.actualValues'),
                    color: COLOR_ACTUAL_VALUES
                  },
                  {
                    text: t('legend.predictions'),
                    color: COLOR_PREDICTIONS
                  }
                ]}
              >
                <CementStrengthMaterialChart
                  materialData={data}
                  materialLevel={materialLevel}
                  timeRange={timeRangeFromDateTimeParams(timeRange)}
                  selectedSample={selectedSample}
                  onSelectSample={onSelectSample}
                  height={GRAPH_HEIGHT}
                />
              </ChartBox>
            ) : (
              <DefaultBox>
                <CementStrengthAccuracyChart
                  samples={visibleSamples}
                  materialId={materialId}
                  materialLevel={materialLevel}
                  selectedSample={selectedSample}
                  onSelectSample={onSelectSample}
                />
              </DefaultBox>
            )}
          </AsyncContainer>
          <Spacing height={2} />
          <LabCharts
            materialLevel={materialLevel}
            materialData={data}
            selectedSample={selectedSample}
            onSelectSample={onSelectSample}
          />
        </>
      ) : (
        <ErrorMessage height="100%">{t('errors.noMaterialLevel')}</ErrorMessage>
      )}
    </ChartContainer>
  )
}
