import {useQuery} from 'react-query'

import {SensorMetaData} from '../../declarations/SensorMetaData'
import {dtoToSensorMetaData} from '../../utils/dtoToSensorMetaData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {PlantSpecificProcessMetricsApi} from '@predict/DomainsLib/gen-api/PlantSpecificProcessMetrics'
import {usePlantId, useProductionLine} from '@predict/DomainsLib/plants'

export function useSensorMetaData() {
  const plantId = usePlantId()
  const {productionLineId} = useProductionLine()
  return useQuery<SensorMetaData[]>(
    [QueryKeyName.GetSensorMetaData, plantId, productionLineId],
    async () => {
      const response = await PlantSpecificProcessMetricsApi.getplantsProcessMetricsPlantSpecific(
        plantId,
        {productionLineId}
      )
      return response.data.filter(({uniformTag}) => Boolean(uniformTag)).map(dtoToSensorMetaData)
    }
  )
}
