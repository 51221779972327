import {UserManualHookReturn} from '../declarations/UserManualHook'
import cementManualDe from '../manuals/cement/de_DE.md'
import cementManualEn from '../manuals/cement/en_US.md'

import {useUserManualByLanguage} from './useUserManualByLanguage'

export function useCementUserManual(): UserManualHookReturn {
  return {
    manual: useUserManualByLanguage(cementManualEn, cementManualDe)
  }
}
