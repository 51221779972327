import React, {PropsWithChildren} from 'react'
import {Redirect} from 'react-router'

import {NavigationPositionHandler} from '../../navigation/components/NavigationPositionHandler'

import {usePlant} from '@predict/DomainsLib/plants'
import {getPageUrl} from '@predict/WebApp/modules/navigation'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {PageLayout} from '@predict/WebUILib/layouts/PageLayout/PageLayout'

interface PlantPageShellProps extends PropsWithChildren<unknown> {
  title?: string
  headerActionContent?: React.ReactNode
  pageClassName?: string
}

export function PlantPageShell({title, children}: PlantPageShellProps) {
  const plant = usePlant()

  return (
    <PageLayout>
      {title && <PageTitle data-test-id="plant-shell-page-title">{title}</PageTitle>}
      {plant ? (
        <NavigationPositionHandler>{children}</NavigationPositionHandler>
      ) : (
        <Redirect to={getPageUrl('plants')} />
      )}
    </PageLayout>
  )
}
