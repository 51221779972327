import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAuthContext} from '@predict/DomainsLib/users'
import {LandingPage} from '@predict/WebUILib/pages/LandingPage/LandingPage'

export function LoginPage() {
  const {login} = useAuthContext()
  const {t} = useTranslation()

  return (
    <LandingPage
      buttonLabel={t('login.button')}
      onButtonClick={() => void login()}
      footerLabel={t('footer.poweredBy')}
      footerCopyrightNote={t('footer.copyrightNote')}
    />
  )
}
