import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  DateTimeParam,
  TimeHorizonId,
  TimeRange,
  dateTimeParamsFromHorizon
} from '@predict/UtilsLib/dateTime'
import {ListSelector} from '@predict/WebUILib/atoms/ListSelector/ListSelector'

interface QuickHorizonSelectionProps {
  selectableTimeHorizons: TimeHorizonId[]
  onSelect: (params: TimeRange<DateTimeParam>) => void
}

export function QuickHorizonSelection({
  selectableTimeHorizons,
  onSelect
}: QuickHorizonSelectionProps) {
  const {t} = useTranslation()

  return (
    <ListSelector
      title={t('quickSelection.title')}
      items={selectableTimeHorizons.map((horizon) => ({
        value: horizon,
        name: t(`timeHorizons.${horizon}`)
      }))}
      onSelect={(horizon) => onSelect(dateTimeParamsFromHorizon(horizon as TimeHorizonId))}
    />
  )
}
