import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import type {DateTimeParam, TimeRange} from '@predict/UtilsLib/dateTime'
import {zoom} from '@predict/UtilsLib/dateTime'
import {DEFAULT_ZOOM_IN_FACTOR, DEFAULT_ZOOM_OUT_FACTOR} from '@predict/UtilsLib/general/constants'
import {ZoomIn, ZoomOut} from '@predict/WebUILib/helpers/icons'
import {
  IconButtonGroup,
  IconButtonGroupItem
} from '@predict/WebUILib/molecules/IconButtonGroup/IconButtonGroup'

interface ZoomButtonGroupProps {
  timeRange: TimeRange<DateTimeParam>
  onTimeRangeChanged: (string: DateTimeParam, end: DateTimeParam) => void
  maxEnd?: DateTimeParam
}

export function ZoomButtonGroup({timeRange, onTimeRangeChanged, maxEnd}: ZoomButtonGroupProps) {
  const {t} = useTranslation()

  const onZoom = useCallback(
    (isZoomingIn = false) => {
      const zoomed = zoom(
        timeRange,
        isZoomingIn ? DEFAULT_ZOOM_IN_FACTOR : DEFAULT_ZOOM_OUT_FACTOR,
        maxEnd
      )
      onTimeRangeChanged(zoomed.start, zoomed.end)
    },
    [maxEnd, onTimeRangeChanged, timeRange]
  )

  const predictionViewBtnData: IconButtonGroupItem[] = useMemo(
    () => [
      {
        label: t('pageActionBar.actionZoomIn'),
        icon: <ZoomIn />,
        onClick: () => onZoom(true)
      },
      {
        label: t('pageActionBar.actionZoomOut'),
        icon: <ZoomOut />,
        onClick: () => onZoom(false)
      }
    ],
    [onZoom, t]
  )

  return <IconButtonGroup items={predictionViewBtnData} />
}
