import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {QuickHorizonSelection} from './QuickHorizonSelection'

import type {DateTimeParam, TimeHorizonId, TimeRange} from '@predict/UtilsLib/dateTime'
import {isValidDateTimeParamsTimeRange} from '@predict/UtilsLib/dateTime'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {TwoColumnDialog} from '@predict/WebUILib/layouts/TwoColumnDialog/TwoColumnDialog'
import {DateInputField} from '@predict/WebUILib/molecules/DateInputField/DateInputField'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'

interface TimeRangePickerDialogProps {
  open: boolean
  onClose: (selectedRange?: TimeRange<DateTimeParam>) => void
  start: DateTimeParam
  end: DateTimeParam
  selectableTimeHorizons: TimeHorizonId[]
  timeZone: string
}

export function TimeRangePickerDialog({
  open,
  onClose,
  start,
  end,
  selectableTimeHorizons,
  timeZone
}: TimeRangePickerDialogProps) {
  const {t} = useTranslation()
  const [startDateTime, setStartDateTime] = useState(start)
  const [endDateTime, setEndDateTime] = useState(end)

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleApply = useCallback(() => {
    onClose({start: startDateTime, end: endDateTime})
  }, [endDateTime, onClose, startDateTime])

  const handleQuickSelect = useCallback(
    (range: TimeRange<DateTimeParam>) => {
      onClose(range)
    },
    [onClose]
  )

  const onEnter = () => {
    setStartDateTime(start)
    setEndDateTime(end)
  }

  const placeholder = t('timeRangePickerDialog.placeholder')

  return (
    <TwoColumnDialog
      title={t('timeRangePickerDialog.title')}
      isOpen={open}
      onClose={handleClose}
      onEnter={onEnter}
      sideContent={
        <QuickHorizonSelection
          selectableTimeHorizons={selectableTimeHorizons}
          onSelect={handleQuickSelect}
        />
      }
      actions={
        <DialogBasicActions
          onCancel={handleClose}
          onSave={handleApply}
          saveDisabled={!isValidDateTimeParamsTimeRange({start: startDateTime, end: endDateTime})}
        />
      }
    >
      <DateInputField
        value={startDateTime}
        timeZone={timeZone}
        onChange={setStartDateTime}
        dateFormat={t('dateFormat.datetime')}
        placeholder={placeholder}
        label={t('datePicker.startDate')}
      />
      <Spacing height={3} />
      <DateInputField
        value={endDateTime}
        timeZone={timeZone}
        onChange={setEndDateTime}
        dateFormat={t('dateFormat.datetime')}
        nowButtonLabel={t('timeRangePickerDialog.now')}
        placeholder={placeholder}
        label={t('datePicker.endDate')}
      />
    </TwoColumnDialog>
  )
}
