import {useCallback, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export const setSearchParam = (search: string, key: string, value?: string): string => {
  const urlParams = new URLSearchParams(search)
  if (value) {
    urlParams.set(key, value)
  } else {
    urlParams.delete(key)
  }
  return urlParams.toString()
}

export function useURLSearchParam(
  paramName: string
): [string | undefined, (value?: string) => void] {
  // Get Param
  const {search} = useLocation()

  const value = useMemo(() => {
    const urlParams = new URLSearchParams(search)
    return urlParams.get(paramName)
  }, [paramName, search])

  // Set Param
  const history = useHistory()

  const setValue = useCallback(
    (value?: string) => {
      history.push({search: setSearchParam(history.location.search, paramName, value)})
    },
    [history, paramName]
  )

  return [value ?? undefined, setValue]
}
