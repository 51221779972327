import {UserManualHookReturn} from '../declarations/UserManualHook'
import clinkerManualDe from '../manuals/clinker/de_DE.mdx'
import clinkerManualEn from '../manuals/clinker/en_US.mdx'

import {useUserManualByLanguage} from './useUserManualByLanguage'

export function useClinkerUserManual(): UserManualHookReturn {
  return {
    manual: useUserManualByLanguage(String(clinkerManualEn), String(clinkerManualDe))
  }
}
