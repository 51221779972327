import React, {PropsWithChildren, ReactNode} from 'react'

import {Box} from '../Box/Box'

interface ChartContainerProps extends PropsWithChildren<unknown> {
  sideContent: ReactNode
}

export function ChartContainer({sideContent, children}: ChartContainerProps) {
  const margin = 1.5

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        marginTop: -margin,
        marginLeft: -margin,
        marginRight: -margin,
        [theme.breakpoints.down('md')]: {
          flexWrap: 'wrap'
        }
      })}
    >
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          padding: margin,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        })}
      >
        {children}
      </Box>
      <Box
        sx={(theme) => ({
          padding: margin,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          },
          '& > *': {
            maxWidth: '100%',
            marginBottom: margin * 2,
            [theme.breakpoints.down('md')]: {
              width: '100%'
            },
            '&:last-child': {
              marginBottom: 0
            }
          }
        })}
      >
        {sideContent}
      </Box>
    </Box>
  )
}
