import {useQuery, UseQueryResult} from 'react-query'

import {UnifiedSensorMetaData} from '../../declarations/SensorMetaData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {UniformProcessMetricsApi} from '@predict/DomainsLib/gen-api/UniformProcessMetrics'

export function useUnifiedSensorMetaData(): UseQueryResult<UnifiedSensorMetaData[]> {
  return useQuery(QueryKeyName.GetUnifiedSensorMetaData, async () => {
    const response = await UniformProcessMetricsApi.getprocessMetricsUniformList()
    return response.data.map(({uniformTag, unit, description, displayName}) => ({
      uniformTag: uniformTag,
      unit: unit ?? '',
      displayName: displayName ?? '',
      description: description ?? ''
    }))
  })
}
