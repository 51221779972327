import React, {PropsWithChildren} from 'react'

import {Box} from '../Box/Box'

interface HorizontalScrollableWrapperProps extends PropsWithChildren<unknown> {
  squareDimension?: number
  minWidth?: number
  isCentered?: boolean
}

export const MIN_WIDTH = 400

export function HorizontalScrollableWrapper({
  minWidth = MIN_WIDTH,
  squareDimension,
  isCentered = true,
  children
}: HorizontalScrollableWrapperProps) {
  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%'
      }}
    >
      <Box
        sx={{
          margin: isCentered ? 'auto' : undefined,
          minWidth: minWidth,
          width: squareDimension,
          height: squareDimension
        }}
        data-test-id="horizontal-scrollable-wrapper-inner"
      >
        {children}
      </Box>
    </Box>
  )
}
