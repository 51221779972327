import {NumberRange} from '@predict/UtilsLib/general'

export const getStrengthScaleRange = (
  min: number,
  max: number,
  margin: number,
  stepSize = 1
): NumberRange => ({
  min: Math.max(0, Math.floor((min - margin) / stepSize)) * stepSize,
  max: Math.ceil((max + margin) / stepSize) * stepSize
})
