import React, {PropsWithChildren} from 'react'

import {ColoredData} from '@predict/WebUILib/atoms/ColoredData/ColoredData'
import {Headline} from '@predict/WebUILib/atoms/Headline/Headline'
import {InfoPopOverButton} from '@predict/WebUILib/atoms/InfoPopOverButton/InfoPopOverButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'

export interface ColoredData {
  color: string
  text: string
}

interface ChartBoxProps extends PropsWithChildren<unknown> {
  title?: string
  infoPopOverContent?: React.ReactNode
  'data-test-id'?: string
  titleTestId?: string
  subHeader?: React.ReactNode
  coloredData?: ColoredData[]
}

export function ChartBox({
  title,
  infoPopOverContent,
  'data-test-id': testId,
  titleTestId,
  coloredData,
  subHeader,
  children
}: ChartBoxProps) {
  return (
    <DefaultBox data-test-id={testId}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <div>
          {title && (
            <Headline variant="h3" bottomSpacing={0} isBold data-test-id={titleTestId}>
              {title}
              {infoPopOverContent && (
                <>
                  <Spacing isInline width={1} />
                  <InfoPopOverButton data-test-id="chart-box-info-pop-over-icon">
                    <Text>{infoPopOverContent}</Text>
                  </InfoPopOverButton>
                </>
              )}
            </Headline>
          )}
          {subHeader && (
            <>
              {title && <Spacing height={1} />}
              {subHeader}
            </>
          )}
        </div>
        {coloredData && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
          >
            {coloredData.map(({color, text}, index) => (
              <React.Fragment key={text}>
                <ColoredData color={color}>{text}</ColoredData>
                {index !== coloredData.length - 1 && <Spacing width={1} />}
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
      {children}
    </DefaultBox>
  )
}
