import {isNumber, isEmpty, intersection, isNil} from 'lodash'
import {useMemo} from 'react'
import {useQuery, UseQueryResult} from 'react-query'

import {MaterialLabData} from '../declarations/labData'
import {useLabDataDomainContext} from '../providers/LabDataDomainProvider'
import {dtoToPlantLabData} from '../utils/dtoToPlantLabData'

import {useMaterialLabMetaData} from './useMaterialLabMetaData'
import {usePlantLabDataConfig} from './usePlantLabDataConfig'

import {QueryKeyName, LAB_METRICS_MAX_POINTS_RESOLUTION} from '@predict/DomainsLib/constants'
import {LabMetricsApi} from '@predict/DomainsLib/gen-api/LabMetrics'
import {usePlantTimeZone, usePlantId} from '@predict/DomainsLib/plants'
import {
  isRelativeDateTimeParam,
  padTimeRange,
  timeRangeFromStartEnd
} from '@predict/UtilsLib/dateTime'
import type {TimeRange} from '@predict/UtilsLib/dateTime'
import {DEFAULT_TIME_MARGIN_FACTOR} from '@predict/UtilsLib/dateTime/constants'

export async function getMaterialLabData(
  plantId: string,
  materialId: number,
  tagIds: string[],
  millNames: string[],
  siloNames: string[],
  range: TimeRange
): Promise<MaterialLabData> {
  const emptyLabData: MaterialLabData = {range, plantId, materialId, data: []}
  if (isEmpty(tagIds)) {
    return emptyLabData
  }

  const {data: materialMetricsDto} = await LabMetricsApi.getplantsLabMetricsData(plantId, {
    from: range.start.toJSON(),
    until: range.end.toJSON(),
    unifiedMaterialId: materialId,
    maxPoints: LAB_METRICS_MAX_POINTS_RESOLUTION,
    tags: tagIds,
    millNames,
    siloNames
  })

  if (isNil(materialMetricsDto)) {
    return emptyLabData
  }

  if (!isNumber(materialMetricsDto.materialId)) {
    throw new Error('material ID not set')
  }
  return dtoToPlantLabData(materialMetricsDto, range)
}

export function useMaterialLabData(
  materialId?: number,
  selectedMills?: string[],
  selectedSilos?: string[],
  refetchInterval?: number
): UseQueryResult<MaterialLabData> {
  const {selectedTagIds, start, end} = useLabDataDomainContext()
  const {defaultMills, defaultSilos} = usePlantLabDataConfig()

  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const metaData = useMaterialLabMetaData()
  const ids = useMemo(
    () =>
      intersection(
        metaData.map((data) => data.uniformTag),
        selectedTagIds
      ),
    [metaData, selectedTagIds]
  )

  const millNames = selectedMills ?? defaultMills
  const siloNames = selectedSilos ?? defaultSilos

  return useQuery(
    [QueryKeyName.GetLabData, plantId, ids, millNames, siloNames, timeZone, start, end],
    () => {
      if (!isNumber(materialId)) {
        throw new Error('material ID is not set')
      }
      return getMaterialLabData(
        plantId,
        materialId,
        ids,
        millNames,
        siloNames,
        padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR)
      )
    },
    {
      refetchInterval: isRelativeDateTimeParam(end) && refetchInterval,
      staleTime: 0
    }
  )
}
