import {useTranslation} from 'react-i18next'

import {useUserManual} from './useUserManual'

export function useUserManualByLanguage(englishFile: string, germanFile?: string) {
  const langToFolderMap = {
    'en-US': englishFile,
    'de-DE': germanFile
  } as const

  const {i18n} = useTranslation()
  const manualFile: string = langToFolderMap[i18n.language] ?? englishFile
  return useUserManual(manualFile)
}
