import {isEmpty} from 'lodash'

import {DEFAULT_LAB_DATA_CONFIG} from '../constants'
import {LabDataConfig} from '../declarations/LabDataConfig'

import {PlantConfigurationDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {
  FESampleSource,
  SAMPLE_SOURCES_MAP,
  SILO_SAMPLE_SOURCE,
  SampleSourceDto
} from '@predict/DomainsLib/plants'

export function getMillNames(sampleTypeSources: SampleSourceDto): string[] {
  if (!sampleTypeSources) return DEFAULT_LAB_DATA_CONFIG.millNames

  let millNames: string[] = []
  Object.entries(sampleTypeSources).forEach(([type, names]) => {
    if (type !== SILO_SAMPLE_SOURCE) {
      millNames = [...millNames, ...names]
    }
  })
  return millNames
}

export function getDefaultMills(
  defaultSources?: FESampleSource[],
  sampleTypeSources?: SampleSourceDto
): string[] {
  if (!sampleTypeSources || !defaultSources) return []

  const defaultMillSources = defaultSources
    .map((s) => SAMPLE_SOURCES_MAP[s])
    .filter((s) => s !== SILO_SAMPLE_SOURCE)

  if (isEmpty(defaultMillSources)) return []

  let millNames: string[] = []
  defaultMillSources.forEach((source) => {
    millNames = [...millNames, ...(sampleTypeSources[source] ?? [])]
  })

  return millNames
}

export function getDefaultSilos(
  defaultSources?: FESampleSource[],
  sampleTypeSources?: SampleSourceDto
): string[] {
  if (!sampleTypeSources || !defaultSources) return []

  const isSiloDefault = defaultSources
    .map((s) => SAMPLE_SOURCES_MAP[s])
    .includes(SILO_SAMPLE_SOURCE)

  return isSiloDefault
    ? sampleTypeSources[SILO_SAMPLE_SOURCE] ?? DEFAULT_LAB_DATA_CONFIG.siloNames
    : []
}

export function plantConfigDtoToLabDataConfig(plant: PlantConfigurationDto): LabDataConfig {
  return {
    id: plant.plantId,
    millNames: getMillNames(plant.supportedSampleTypeSources),
    siloNames:
      plant.supportedSampleTypeSources?.[SILO_SAMPLE_SOURCE] ?? DEFAULT_LAB_DATA_CONFIG.siloNames,
    defaultMills: getDefaultMills(
      plant.defaultSampleTypeSources as FESampleSource[],
      plant.supportedSampleTypeSources
    ),
    defaultSilos: getDefaultSilos(
      plant.defaultSampleTypeSources as FESampleSource[],
      plant.supportedSampleTypeSources
    )
  }
}
