import {MenuItem} from '@mui/material'
import React, {useCallback} from 'react'

import {ActionBarSelect, ActionBarSelectRenderProps} from './ActionBarSelect'

interface StringValueSelectProps<T> {
  onSelected: (value: T) => void
  value: T
  values: T[]
  fieldId: string
  label?: string
  valueLabel?: (value: T) => string
  selectClassName?: string
  'data-test-id'?: string
  disabled?: boolean
}

const defaultValueLabel = <T extends string>(value: T) => value

export const StringValueSelect = <T extends string>({
  onSelected,
  value,
  values,
  label,
  fieldId,
  valueLabel = defaultValueLabel,
  selectClassName,
  'data-test-id': dataTestId,
  disabled
}: StringValueSelectProps<T>) => {
  const render = useCallback(
    ({itemValue}: ActionBarSelectRenderProps<T>) => (
      <MenuItem key={itemValue} value={itemValue} data-test-id={`${fieldId}-value-${itemValue}`}>
        {valueLabel(itemValue)}
      </MenuItem>
    ),
    [fieldId, valueLabel]
  )

  return (
    <ActionBarSelect
      onSelected={onSelected}
      value={value}
      values={values}
      render={render}
      label={label}
      fieldId={fieldId}
      className={selectClassName}
      data-test-id={dataTestId}
      disabled={disabled}
    />
  )
}
