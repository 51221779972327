import {flatten, isEqual} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {usePrevious} from 'react-use'

import type {HasDisabled, TargetRangeSettingsEntry} from '../declarations/helperTypes'

import {
  CementStrengthTargetLevelUpdateSettings,
  getMaterialLevel,
  getStrengthLevelInDays,
  StrengthLevel,
  useCementStrengthTargetLevelMutation,
  useMaterials,
  usePlantCementStrengthConfig
} from '@predict/DomainsLib/cementStrength'
import {isValidTargetRange, TargetRange} from '@predict/UtilsLib/general'
import {FormBox} from '@predict/WebUILib/layouts/FormBox/FormBox'
import {NumberField} from '@predict/WebUILib/molecules/NumberField/NumberField'
import {GeneralTable} from '@predict/WebUILib/organisms/GeneralTable/GeneralTable'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

export function CementStrengthTargetSettings({disabled}: HasDisabled) {
  const {t} = useTranslation()

  const {data: materials} = useMaterials()
  const {supportedStrengthLevels} = usePlantCementStrengthConfig()
  const {mutate, isLoading} = useCementStrengthTargetLevelMutation(
    useSnackbarMutationInjector(t('settings.settingsSaved'), t('settings.settingsNotSavedError'))
  )

  const backendSettings = useMemo<TargetRangeSettingsEntry[]>(
    () =>
      flatten(
        materials?.map((mat) =>
          supportedStrengthLevels.map((strengthLevel) => ({
            ...(getMaterialLevel(mat, strengthLevel) ?? {min: 0, max: 0, target: 0}),
            id: `${mat.materialId}__${strengthLevel}`,
            group: mat.name,
            name: t('strengthLevel.strength', {count: getStrengthLevelInDays(strengthLevel)})
          }))
        ) ?? []
      ),
    [materials, supportedStrengthLevels, t]
  )
  const prevBESettings = usePrevious(backendSettings)
  const [targetRangeSettings, setTargetRangeSettings] =
    useState<TargetRangeSettingsEntry[]>(backendSettings)

  useEffect(() => {
    if (!isEqual(prevBESettings, backendSettings)) {
      setTargetRangeSettings(backendSettings)
    }
  }, [backendSettings, prevBESettings])

  const onNumberChanged = useCallback(
    (entry: TargetRangeSettingsEntry, value: number | undefined, field: keyof TargetRange) => {
      setTargetRangeSettings(
        targetRangeSettings.map((e) =>
          e.id === entry.id
            ? {
                ...entry,
                [field]: value
              }
            : e
        )
      )
    },
    [targetRangeSettings]
  )

  const unit = t('cementStrengthTargetSettings.tableHeaderUnit')

  const isPristine = isEqual(backendSettings, targetRangeSettings)
  const isValid = targetRangeSettings.every(isValidTargetRange)

  const onSave = () => {
    if (isLoading || isPristine || !isValid) return

    mutate(
      targetRangeSettings.map<CementStrengthTargetLevelUpdateSettings>(({id, min, max, target}) => {
        const [materialId, strengthLevel] = id.split('__')
        return {
          materialId: Number(materialId),
          strengthLevel: strengthLevel as StrengthLevel,
          targetRange: {min, target, max}
        }
      })
    )
  }

  return (
    <FormBox
      title={t('cementStrengthTargetSettings.title')}
      onRevert={() => setTargetRangeSettings(backendSettings)}
      onSave={onSave}
      isFormInvalid={!isValid}
      disabled={isPristine}
      isSaving={isLoading}
      data-test-id="cement-strength-target-settings-form-box"
    >
      <GeneralTable
        tableId="cement-strength-target-settings-table"
        columnsConfiguration={[
          {
            name: 'name',
            label: t('cementStrengthTargetSettings.tableHeaderLabel')
          },
          {
            name: 'min',
            label: t('targetRangeTableForm.minHeader', {unit}),
            alignment: 'right'
          },
          {
            name: 'target',
            label: t('targetRangeTableForm.targetHeader', {unit}),
            alignment: 'right'
          },
          {
            name: 'max',
            label: t('targetRangeTableForm.maxHeader', {unit}),
            alignment: 'right'
          }
        ]}
        data={targetRangeSettings}
        rowsGroupBy="group"
        rowsGroupByBuilder={(group) => group}
        cellBuilders={{
          name: ({name}) => name,
          min: (entry) => (
            <NumberField
              value={entry.min}
              error={!isValidTargetRange(entry)}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'min')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-min-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-min-input-${entry.id}`
              }}
            />
          ),
          target: (entry) => (
            <NumberField
              value={entry.target}
              error={!isValidTargetRange(entry)}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'target')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-target-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-target-input-${entry.id}`
              }}
            />
          ),
          max: (entry) => (
            <NumberField
              value={entry.max}
              error={!isValidTargetRange(entry)}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'max')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-max-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-max-input-${entry.id}`
              }}
            />
          )
        }}
      />
    </FormBox>
  )
}
