import {styled} from '@mui/material'
import React, {useCallback, useLayoutEffect, useRef, useState} from 'react'

import {useWindowResizeEvent} from '../../helpers/hooks/useWindowResizeEvent'
import {ClippedWrapper} from '../ClippedWrapper/ClippedWrapper'

import {IS_TEST_ENV} from '@predict/WebUILib/Charts/helpers/testingUtils'

export interface SvgWrapperRenderProps {
  innerWidth: number
  innerHeight: number
  Clipped: React.FC<{children: React.ReactNode}>
}

export interface SvgWrapperProps {
  svgId: string
  render: (renderProps: SvgWrapperRenderProps) => React.ReactNode
  height?: number
  margin?: {
    top: number
    bottom: number
    left: number
    right: number
  }
  clipExtension?: number
  'data-test-id'?: string
}

const calcInnerDimension = (dimension: number, marginStart = 0, marginEnd = 0) =>
  dimension - (marginStart + marginEnd)

const Wrapper = styled('div')({
  overflowX: 'hidden',
  minHeight: 1
})

/**
 * It renders an SVG element with a margin, and provides a render prop that allows you to render
 * content within the margin
 * @param {SvgWrapperProps}  - `height` - the height of the SVG. If not provided, the width will be
 * used.
 */
export function SvgWrapper({
  svgId,
  render,
  height: h,
  margin = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  clipExtension = 0,
  'data-test-id': dataTestId
}: SvgWrapperProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const onWindowResize = useCallback(() => {
    // allow container to resize
    setWidth(0)
    // set the correct width in the next tick
    setTimeout(() => setWidth(ref.current?.offsetWidth ?? 0), 0)
  }, [])
  useWindowResizeEvent(onWindowResize)

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0)
  }, [])

  const height = h ?? width
  const innerWidth = calcInnerDimension(width, margin.left, margin.right)
  const innerHeight = calcInnerDimension(height, margin.top, margin.bottom)
  const clipPathId = `${svgId}-clipPath`

  return (
    <Wrapper ref={ref} data-test-id={dataTestId}>
      {(width > 0 || IS_TEST_ENV) && (
        <svg id={svgId} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g
            data-test-id={`${svgId}-margin-group`}
            transform={`translate(${margin.left}, ${margin.top})`}
          >
            <defs>
              <clipPath id={clipPathId}>
                <rect
                  x={-clipExtension}
                  y={-clipExtension}
                  height={innerHeight + clipExtension * 2}
                  width={innerWidth + clipExtension * 2}
                />
              </clipPath>
            </defs>

            {render({
              innerWidth,
              innerHeight,
              Clipped: ({children}) => (
                <ClippedWrapper clipPathId={clipPathId}>{children}</ClippedWrapper>
              )
            })}
          </g>
        </svg>
      )}
    </Wrapper>
  )
}
