import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect} from 'react-router'

import {usePlantsList} from '../../DomainsLib/plants'
import {PageTitle} from '../../WebUILib/atoms/Headline/PageTitle'
import {Selector} from '../../WebUILib/atoms/Selector/Selector'
import {DefaultBox} from '../../WebUILib/layouts/DefaultBox/DefaultBox'
import {NarrowCenteredLayout} from '../../WebUILib/layouts/NarrowCenteredLayout/NarrowCenteredLayout'
import {ErrorMessage} from '../../WebUILib/molecules/ErrorMessage/ErrorMessage'

import {getPageUrl, usePlantNavigation} from '@predict/WebApp/modules/navigation'

export const useNavigateToPlantPageCallback = () => {
  const {data: plants} = usePlantsList()
  const navigation = usePlantNavigation()

  return useCallback(
    (plantId: string) => {
      if (!plantId) {
        return
      }
      // navigate to plant view
      const selectedPlant = plants?.find((p) => p.id === plantId)
      if (selectedPlant) {
        navigation.navigate({
          plantPage: 'cement',
          pathParams: {plant: selectedPlant.id},
          resetQueryParams: true
        })
      }
    },
    [navigation, plants]
  )
}

export function PlantsPage() {
  const {t} = useTranslation()
  const {data: plants, error} = usePlantsList()

  const onPlantSelected = useNavigateToPlantPageCallback()

  const showError = error || !plants || plants.length === 0

  if (plants?.length === 1) {
    return <Redirect to={getPageUrl('cement', {plant: plants[0].id})} />
  }

  return (
    <NarrowCenteredLayout>
      <PageTitle align="center">{t('plantsPage.title')}</PageTitle>
      <DefaultBox>
        {showError ? (
          <ErrorMessage>{t('errors.plantsError')}</ErrorMessage>
        ) : (
          <Selector
            items={plants.map((plant) => ({name: plant.fullName, value: plant.id}))}
            onSelect={onPlantSelected}
            label={t('plantsPage.selectPlant')}
            caption={t('plantsPage.desc')}
            id="predict-select-plant"
          />
        )}
      </DefaultBox>
    </NarrowCenteredLayout>
  )
}
