import {flatten, isEmpty} from 'lodash'
import {useMemo} from 'react'

import {LabAndSensorChartData} from '../components/LabAndSensorCharts'
import {FREELIME_TAG, PREDEFINED_CHART_TAGS} from '../constants'
import {createChartDataFromKilnLabData} from '../utils/createChartDataFromKilnLabData'

import {usePlantFreeLimeConfig} from '@predict/DomainsLib/freeLime'
import {KilnLabData, useKilnLabData, useLabDataDomainContext} from '@predict/DomainsLib/labData'
import {ChartData} from '@predict/WebUILib/Charts/helpers'

const calcLabChartData = (kilnLabData: KilnLabData[] | undefined): ChartData[] => {
  return kilnLabData
    ? flatten(kilnLabData.map((labData) => createChartDataFromKilnLabData(labData.data ?? [])))
    : []
}

export function useLabChartData(): {
  data: LabAndSensorChartData[]
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  refetch: () => void
} {
  const {target, min, max, axisMin, axisMax} = usePlantFreeLimeConfig()
  const {data, isLoading, isRefetching, isSuccess, isError, refetch} = useKilnLabData()

  const {selectedTagIds} = useLabDataDomainContext()

  const chartData: LabAndSensorChartData[] = useMemo(
    () =>
      !data
        ? []
        : calcLabChartData(data)
            .filter(({id}) => selectedTagIds.includes(id))
            .map<LabAndSensorChartData>((d) => ({
              ...d,
              canBeClosed: !PREDEFINED_CHART_TAGS.includes(d.id),
              isReloading: isRefetching,
              targetRange: d.id === FREELIME_TAG ? {min, target, max} : undefined,
              axisRange: d.id === FREELIME_TAG ? {min: axisMin, max: axisMax} : undefined
            }))
            .sort((a, b) => {
              const isAPredefined = PREDEFINED_CHART_TAGS.includes(a.id)
              const isBPredefined = PREDEFINED_CHART_TAGS.includes(b.id)

              if (isAPredefined && !isBPredefined) return -1
              if (!isAPredefined && isBPredefined) return 1
              if (isAPredefined && isBPredefined)
                return PREDEFINED_CHART_TAGS.indexOf(a.id) - PREDEFINED_CHART_TAGS.indexOf(b.id)

              return a.name.localeCompare(b.name)
            }),
    [data, selectedTagIds, isRefetching, target, min, max, axisMin, axisMax]
  )

  return {
    isLoading: isLoading || (isEmpty(chartData) && isRefetching),
    isSuccess: isSuccess,
    isError: isError || !chartData,
    data: chartData,
    refetch: () => void refetch()
  }
}
