/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {CementStrengthDataDto, ProblemDetails, RawLabdataDto} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class CementStrengthApi {
  /**
 * No description
 * 
 * @tags CementStrength
 * @name GetplantsCementStrengths
 * @summary Retrieves the cement strength by certain parameters
To get the cement strength one has to give a plant and material type, and optionally a start and end date and a
mill
 * @request GET:/predict/plants/{plantId}/cementStrengths
 * @secure
 */
  static getplantsCementStrengths = (
    plantId: string,
    query?: {
      /**
       * unified material id
       * @format int32
       * @example 3
       */
      unifiedMaterialId?: number
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      startTime?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      endTime?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<CementStrengthDataDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementStrengths`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags CementStrength
   * @name GetplantsCementStrengthsAll
   * @summary Retrieves all the cement strength by certain parameters without filtering. Output data is equal to Excel Export
   * @request GET:/predict/plants/{plantId}/cementStrengths/all
   * @secure
   */
  static getplantsCementStrengthsAll = (
    plantId: string,
    query?: {
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      startTime?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      endTime?: string
      /**
       * The material type defaults to "cement"
       * @default "cement"
       * @example "cement"
       */
      materialType?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<RawLabdataDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementStrengths/all`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
