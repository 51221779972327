import React from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialStats} from './MaterialStats'

import {
  getActualValues,
  getPredictedValues,
  getMaterialLevel,
  getStrengthLevelInDays,
  Material,
  useAggregatedCemStrengthData,
  useCementStrengthDomainContext,
  useMaterialSamplesInTimeRange
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate, TimeRange} from '@predict/UtilsLib/dateTime'
import {COLOR_ACTUAL_VALUES, COLOR_PREDICTIONS} from '@predict/UtilsLib/general/constants'
import {csMaterialSampleToStatsRecordsByTimeRange} from '@predict/WebApp/modules/cementStrength/utils/csMaterialSampleToStatsRecords'
import {getStrengthScaleRange} from '@predict/WebApp/modules/cementStrength/utils/getStrengthScaleRange'
import {PredictionView, usePlantNavigation} from '@predict/WebApp/modules/navigation'
import {Headline} from '@predict/WebUILib/atoms/Headline/Headline'
import {InAppLink} from '@predict/WebUILib/atoms/InAppLink/InAppLink'
import {SideInfo} from '@predict/WebUILib/atoms/SideInfo/SideInfo'
import {CEMENT_STRENGTH_DOMAIN_MARGIN} from '@predict/WebUILib/Charts/helpers'
import {AccuracyChart} from '@predict/WebUILib/Charts/organisms/AccuracyChart/AccuracyChart'
import {StepChart} from '@predict/WebUILib/Charts/organisms/StepChart/StepChart'
import {AsyncContainer} from '@predict/WebUILib/layouts/AsyncContainer/AsyncContainer'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'

interface MaterialChartProps {
  material: Material
  timeRange: TimeRange
  predictionView: Extract<PredictionView, 'time' | 'accuracy'>
}

const CHART_MARGIN = {left: 48, top: 24, bottom: 32, right: 0} as const
const MATERIAL_CHART_BOX_HEIGHT = 340

export function MaterialChartBox({material, timeRange, predictionView}: MaterialChartProps) {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const isTimeView = predictionView === 'time'
  const plantNavigation = usePlantNavigation()

  const {data, isLoading, isError} = useAggregatedCemStrengthData(material.materialId)
  const visibleSamples = useMaterialSamplesInTimeRange(data?.samples ?? [], !isTimeView)
  const lastSample = visibleSamples[visibleSamples.length - 1]

  const {strength: strengthLevel} = useCementStrengthDomainContext()
  const materialLevel = getMaterialLevel(material, strengthLevel)

  const latestSampleId = data?.samples[data.samples.length - 1]?.id

  return (
    <DefaultBox
      height={isTimeView ? MATERIAL_CHART_BOX_HEIGHT : undefined}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        height={32}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Headline tag="h3" isBold>
          {material.name}
        </Headline>
        {isTimeView && lastSample && (
          <SideInfo data-test-id="material-chart-latest-sample-box">
            {t('chart.latestSampleLabel')}
            <br />
            {formatTimeZoneDate(
              new Date(lastSample.datetime),
              timeZone,
              t('chart.latestSampleDate')
            )}
          </SideInfo>
        )}
      </Box>
      {materialLevel ? (
        <AsyncContainer
          isLoading={isLoading}
          isError={isError || !data}
          isSuccess={Boolean(data)}
          errorMessage={<ErrorMessage height="100%">{t('errors.noDataAvailable')}</ErrorMessage>}
        >
          {data && (
            <>
              <InAppLink
                to={plantNavigation.getPlantUrl({
                  plantPage: 'cementTrends',
                  pathParams: {materialId: String(material.materialId)},
                  queryParams: {
                    sampleId: latestSampleId ? String(latestSampleId) : undefined
                  }
                })}
                data-test-id={`material-chart-${material.materialId}`}
              >
                {isTimeView ? (
                  <StepChart
                    chartId={String(data.materialId)}
                    height={200}
                    margin={CHART_MARGIN}
                    timeZone={timeZone}
                    timeRange={timeRange}
                    mainTargetRange={materialLevel}
                    mainAxisRange={{
                      min: materialLevel.min - CEMENT_STRENGTH_DOMAIN_MARGIN,
                      max: materialLevel.max + CEMENT_STRENGTH_DOMAIN_MARGIN
                    }}
                    mainChartData={{
                      id: String(data.materialId),
                      actualValues: getActualValues(data.samples, materialLevel.name),
                      predictions: getPredictedValues(data.samples, materialLevel.name),
                      name: t('chart.axisCementStrengthLabel', {
                        count: getStrengthLevelInDays(strengthLevel)
                      }),
                      color: COLOR_ACTUAL_VALUES,
                      predictionColor: COLOR_PREDICTIONS
                    }}
                    axisLabelBaseOffset={-32}
                    disableMouse
                    isSmallChart
                  />
                ) : (
                  <AccuracyChart
                    chartId={`${data.materialId}-accuracy`}
                    pointsColor={COLOR_ACTUAL_VALUES}
                    chartData={csMaterialSampleToStatsRecordsByTimeRange(
                      data.samples,
                      strengthLevel,
                      timeRange
                    )}
                    targetRange={{
                      ...getStrengthScaleRange(
                        materialLevel.min,
                        materialLevel.max,
                        CEMENT_STRENGTH_DOMAIN_MARGIN
                      ),
                      target: materialLevel.target
                    }}
                    chartLabels={{
                      x: t('chart.axisCementStrengthLabelActual', {
                        count: getStrengthLevelInDays(materialLevel.name)
                      }),
                      y: t('chart.axisCementStrengthLabelPredicted', {
                        count: getStrengthLevelInDays(materialLevel.name)
                      })
                    }}
                    margin={{left: 48, top: 24, bottom: 48, right: 24}}
                    disableMouse
                  />
                )}
              </InAppLink>
              <MaterialStats
                predictionView={predictionView}
                data={data}
                material={material}
                strengthLevel={strengthLevel}
                timeRange={timeRange}
              />
            </>
          )}
        </AsyncContainer>
      ) : (
        <ErrorMessage height="100%">{t('errors.noMaterialLevel')}</ErrorMessage>
      )}
    </DefaultBox>
  )
}
