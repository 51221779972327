import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Typography, TypographyProps} from '@mui/material'
import React from 'react'

export interface TextProps extends Omit<TypographyProps, 'variant'> {
  component?: React.ElementType
  isBold?: boolean
  isUnderlined?: boolean
  variant?: TypographyProps['variant'] | 'xl'
}

export function Text({
  component,
  sx,
  variant,
  isBold = false,
  isUnderlined = false,
  ...rest
}: TextProps) {
  const styles = mergeSxs(
    [
      {
        fontWeight: isBold ? 'bold' : 'normal'
      },
      isUnderlined && {
        display: 'inline-block',
        paddingBottom: 4,
        borderBottomColor: 'black',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid'
      },
      variant === 'xl' && {
        fontSize: '36px',
        lineHeight: '34px'
      }
    ],
    sx
  )
  const safeVariant = variant === 'xl' ? 'h1' : variant
  if (component) {
    return <Typography component={component} sx={styles} variant={safeVariant} {...rest} />
  }
  return <Typography sx={styles} variant={safeVariant} {...rest} />
}
