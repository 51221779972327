import {GridSize, useMediaQuery, useTheme} from '@mui/material'

// for more details check out the Material ui documentation
// https://mui.com/components/use-media-query/ & https://mui.com/customization/breakpoints/

export type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type GridSetting = {
  xs?: boolean | GridSize | undefined
  sm?: boolean | GridSize | undefined
  md?: boolean | GridSize | undefined
  lg?: boolean | GridSize | undefined
  xl?: boolean | GridSize | undefined
}

export const useBreakPoints = () => {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce<BreakPoint | null>((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

export const useMobileBreakPoint = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'))
}
