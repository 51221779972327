import {compact} from 'lodash'
import {useTranslation} from 'react-i18next'

import {usePlantFullName} from '@predict/DomainsLib/plants'

export function usePageTitle(pageTitle?: string) {
  const {t} = useTranslation()
  const plantName = usePlantFullName()
  return compact([pageTitle, plantName, t('appName')]).join(' - ')
}
