import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {usePromise} from 'react-use'

import {initUser} from '../backend/initUser'
import {startLoginProcess, startLogoutProcess} from '../backend/loginProcess'
import {PredictUser} from '../declarations/PredictUser'
import {isUserLoggedIn} from '../utils/userUtils'

import {
  Env,
  WhiteList,
  whiteListByPermission
} from '@predict/UtilsLib/antiCorruptionLayers/authenticationAcl'
import {Event, logger} from '@predict/UtilsLib/logger'

export interface AuthContext {
  user?: PredictUser
  whiteList: WhiteList
  login: () => Promise<void>
  logout: () => Promise<void>
  isLoggedIn: boolean
  isLoading: boolean
}

const Context = createContext<AuthContext | undefined>(undefined)

export const useAuthContext = (): AuthContext => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useAuthContext used outside of AuthProvider')
  }
  return context
}

export const AuthConsumer = Context.Consumer

export function AuthProvider({children}: PropsWithChildren<unknown>) {
  const [user, setUser] = useState<PredictUser | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const promise = usePromise()

  useEffect(() => {
    if (isUserLoggedIn(user)) {
      setLoading(false)
      return undefined
    }

    setLoading(true)
    let subscribe = true
    initUser()
      .then((user) => {
        if (subscribe) {
          setUser(user)
          setLoading(false)
        }
      })
      .catch((error: unknown) => {
        logger.error(error as Event)
        if (subscribe) {
          setLoading(false)
        }
      })

    return () => {
      subscribe = false
    }
  }, [user])

  const whiteList = useMemo(
    () => whiteListByPermission(process.env.REACT_APP_STAGE as Env, user?.permissions || []),
    [user?.permissions]
  )

  const value = useMemo<AuthContext>(() => {
    const logout = async () => {
      await promise(startLogoutProcess())
      setUser(undefined)
    }

    return {
      user,
      whiteList,
      login: startLoginProcess,
      logout,
      isLoading: loading,
      isLoggedIn: isUserLoggedIn(user)
    }
  }, [loading, promise, user, whiteList])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
