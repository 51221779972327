/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {MaterialDto, ProblemDetails} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class MaterialsApi {
  /**
   * No description
   *
   * @tags Materials
   * @name GetplantsCementStrengthsMaterials
   * @summary To get the materials (i.e. cement types) for the cement strength overview screen for a given plant:
   * @request GET:/predict/plants/{plantId}/cementStrengths/materials
   * @secure
   */
  static getplantsCementStrengthsMaterials = (
    plantId: string,
    query?: {
      /**
       * Optionally only display materials taken as "silo" or "mill" samples; will automatically
       * determine correct type based on plant code if not provided
       */
      sampleType?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<MaterialDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementStrengths/materials`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
