import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Tooltip} from '@mui/material'
import React from 'react'

import {ColorButton, ColorButtonProps} from '../ColorButton/ColorButton'
import {LoaderIcon} from '../LoaderIcon/LoaderIcon'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface ActionBarButtonProps extends ColorButtonProps {
  isLoading?: boolean
  width?: number
  toolTipTitle?: string
}

export function ActionBarButton({
  children,
  isLoading = false,
  width,
  toolTipTitle,
  sx,
  disabled,
  ...rest
}: ActionBarButtonProps) {
  return (
    <Box mb={1}>
      <Tooltip disableInteractive title={toolTipTitle ?? ''}>
        <ColorButton
          disabled={isLoading || disabled}
          sx={mergeSxs(
            {
              width: width ?? '160px',
              maxWidth: '100%'
            },
            sx
          )}
          color="secondary"
          variant="outlined"
          {...rest}
        >
          {isLoading ? <LoaderIcon size={20} /> : children}
        </ColorButton>
      </Tooltip>
    </Box>
  )
}
