import {SimpleDatePicker} from '@hconnect/uikit/src/lib2'
import {DateRange} from '@mui/icons-material'
import React, {useCallback, useEffect, useState} from 'react'

import {TextField} from '../TextField/TextField'

import type {DateTimeParam} from '@predict/UtilsLib/dateTime'
import {NOW_PARAM} from '@predict/UtilsLib/dateTime/constants'
import {
  dateTimeParamToLocalDate,
  formatDateTimeParam,
  localTimeToAbsoluteTime,
  parseFormattedDateTimeParam
} from '@predict/UtilsLib/dateTime/utils/dateUtils'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Box} from '@predict/WebUILib/layouts/Box/Box'

const emptyFormat = () => ''

export interface DateInputFieldProps {
  value: DateTimeParam
  timeZone: string
  onChange: (value: DateTimeParam) => void
  dateFormat: string
  nowButtonLabel?: string
  placeholder?: string
  label?: string
}

export function DateInputField({
  value,
  timeZone,
  onChange,
  dateFormat,
  nowButtonLabel,
  placeholder,
  label
}: DateInputFieldProps) {
  const getFormattedDateTime = useCallback(
    (date: DateTimeParam) => formatDateTimeParam(date, timeZone, dateFormat),
    [dateFormat, timeZone]
  )

  const [dateText, setDateText] = useState(getFormattedDateTime(value))

  useEffect(() => {
    setDateText(getFormattedDateTime(value))
  }, [getFormattedDateTime, value])

  const handleNowBtn = useCallback(() => {
    onChange(NOW_PARAM)
  }, [onChange])

  const handleDateTextChanged = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const dateTimeParam = parseFormattedDateTimeParam(event.target.value, timeZone, dateFormat)
      onChange(dateTimeParam)
    },
    [onChange, dateFormat, timeZone]
  )

  const handleDatePickerChanged = useCallback(
    (date: Date | null) => {
      if (!date) return
      const newTimestamp = localTimeToAbsoluteTime(date, timeZone).getTime()
      onChange(newTimestamp)
    },
    [onChange, timeZone]
  )

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
      <Box flexGrow={1} mr={1}>
        <TextField
          value={dateText}
          onChange={(event) => {
            setDateText(event.target.value)
          }}
          onBlur={handleDateTextChanged}
          fullWidth
          placeholder={placeholder}
          label={label}
          InputProps={{
            endAdornment: nowButtonLabel ? (
              <ColorButton
                data-test-id="date-input-field-now-button"
                variant="contained"
                onClick={handleNowBtn}
                sx={{
                  padding: 0
                }}
              >
                {nowButtonLabel}
              </ColorButton>
            ) : undefined
          }}
        />
      </Box>
      <Box>
        <SimpleDatePicker
          date={dateTimeParamToLocalDate(value, timeZone)}
          handleDateChange={handleDatePickerChanged}
          datePickerButtonWithSimpleLayout
          showDatePickerButton
          datePickerButtonProps={{
            sx: {
              minWidth: '32px',
              '& span': {
                margin: 0
              }
            },
            startIcon: <DateRange />,
            variant: 'outlined'
          }}
          textFormatter={emptyFormat}
        />
      </Box>
    </Box>
  )
}
