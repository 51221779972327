import {DEFAULT_PREDICTION_VIEW, PREDICTION_VIEWS} from '../../predictionView'
import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

export function usePredictionViewSearchParam() {
  const [predictionView, setPredictionView] = useTypedURLSearchParam(
    'predictionView',
    ParamTypes.oneOf,
    PREDICTION_VIEWS
  )
  return {
    predictionView: predictionView ?? DEFAULT_PREDICTION_VIEW,
    setPredictionView
  }
}
