import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {UnifiedSensorMetaDataDeleteDialog, UnifiedSensorMetaDataDialog} from '../modules/sensorData'

import {UnifiedSensorMetaData, useUnifiedSensorMetaData} from '@predict/DomainsLib/sensorData'
import {replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {StatefulGeneralTable} from '@predict/WebApp/modules/general'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Headline} from '@predict/WebUILib/atoms/Headline/Headline'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {IconButton} from '@predict/WebUILib/atoms/IconButton/IconButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Add, Delete, DeleteOutline, Edit} from '@predict/WebUILib/helpers/icons'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'
import {PageLayout} from '@predict/WebUILib/layouts/PageLayout/PageLayout'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'
import {
  lowerCaseStringFilter,
  lowerCaseStringSort,
  TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE
} from '@predict/WebUILib/organisms/GeneralTable/helpers'

const getSelectedItem = (metaData: UnifiedSensorMetaData[], itemId?: string) =>
  metaData.find(({uniformTag}) => itemId === uniformTag)

const getSelectedItems = (metaData: UnifiedSensorMetaData[], itemIds: string[]) =>
  metaData.filter(({uniformTag}) => itemIds.includes(uniformTag))

const getSingleOrMultipleItems = (
  metaData: UnifiedSensorMetaData[],
  itemIds: string[],
  itemId?: string
) => {
  if (itemId) {
    const item = getSelectedItem(metaData, itemId)
    return item ? [item] : []
  }
  return getSelectedItems(metaData, itemIds)
}

const COLUMN_KEYS: (keyof UnifiedSensorMetaData)[] = ['uniformTag', 'unit', 'description']

export function AdminPage() {
  const {t} = useTranslation()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [singleItemId, setSingleItemId] = useState<string>()
  const {data: metaData} = useUnifiedSensorMetaData()
  const initiallyOrderedMetaData = useMemo(
    () =>
      metaData ? lowerCaseStringSort(metaData, [{field: 'uniformTag', expression: 'asc'}]) : [],
    [metaData]
  )

  const [selectedSensors, setSelectedSensors] = useState<UnifiedSensorMetaData[]>([])
  const selectedIds = useMemo<string[]>(
    () => selectedSensors.map(({uniformTag}) => uniformTag),
    [selectedSensors]
  )

  const onAddSensor = () => {
    setOpenAddDialog(true)
  }

  const onEditSensor = (item: UnifiedSensorMetaData) => {
    setSingleItemId(item.uniformTag)
    setOpenEditDialog(true)
  }

  if (!metaData) return <Loader />

  return (
    <PageLayout>
      <PageTitle data-test-id="plant-shell-page-title">{t('adminPage.title')}</PageTitle>
      <DefaultBox width="100%" data-test-id="sensor-settings-form-box">
        <GridContainer justifyContent="space-between" alignItems="center" spacing={2}>
          <GridItem xs={12} sm>
            <Headline tag="h6" isBold topSpacing={0} bottomSpacing={0}>
              {t('sensorSettings.title')}
            </Headline>
          </GridItem>
          <GridItem>
            <ColorButton
              startIcon={<DeleteOutline />}
              data-test-id="sensor-settings-multi-delete-button"
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
              disabled={selectedIds.length === 0}
            >
              {t('sensorSettings.delete', {count: selectedIds.length})}
            </ColorButton>
            <UnifiedSensorMetaDataDeleteDialog
              items={getSingleOrMultipleItems(metaData, selectedIds, singleItemId)}
              open={openDeleteDialog}
              onClose={() => {
                setSingleItemId(undefined)
                setOpenDeleteDialog(false)
              }}
            />
          </GridItem>
          <GridItem>
            <ColorButton
              startIcon={<Add />}
              onClick={onAddSensor}
              data-test-id="sensor-settings-add-button"
            >
              {t('sensorSettings.add')}
            </ColorButton>
            <UnifiedSensorMetaDataDialog
              open={openAddDialog}
              onClose={() => {
                setOpenAddDialog(false)
              }}
              title={t('sensorSettings.add')}
            />
          </GridItem>
        </GridContainer>
        <Spacing height={3} />
        <StatefulGeneralTable
          tableId="sensor-settings-table"
          columnsConfiguration={COLUMN_KEYS.map((key) => ({
            name: key,
            label: t(`processMetaData.${key}`)
          }))}
          data={initiallyOrderedMetaData}
          rowKey={({uniformTag}) => replaceNonWordCharacters(uniformTag)}
          cellBuilders={{
            uniformTag: ({uniformTag}) => uniformTag,
            unit: ({unit}) => unit,
            description: ({description}) => description,
            tableActions: (item) => (
              <>
                <IconButton
                  toolTipTitle={t('button.edit')}
                  onClick={() => onEditSensor(item)}
                  data-test-id={`sensor-settings-table-edit-btn-${replaceNonWordCharacters(
                    item.uniformTag
                  )}`}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  toolTipTitle={t('button.delete')}
                  onClick={() => {
                    setSingleItemId(item.uniformTag)
                    setOpenDeleteDialog(true)
                  }}
                  data-test-id={`sensor-settings-table-delete-btn-${replaceNonWordCharacters(
                    item.uniformTag
                  )}`}
                >
                  <Delete />
                </IconButton>
              </>
            )
          }}
          selectedItems={selectedSensors}
          onSelectItems={setSelectedSensors}
          sortMethod={lowerCaseStringSort}
          filterMethod={lowerCaseStringFilter}
          defaultRowsPerPage={TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE}
        />
        <UnifiedSensorMetaDataDialog
          open={openEditDialog}
          onClose={() => {
            setSingleItemId(undefined)
            setOpenEditDialog(false)
          }}
          item={getSelectedItem(metaData, singleItemId)}
          title={t('sensorTableEditButton.title')}
        />
      </DefaultBox>
    </PageLayout>
  )
}
