import {useSelectedSourcesSearchParam} from '../../navigation'

import {AGGREGATED_SILOS_NAME} from '@predict/DomainsLib/cementStrength'
import {usePlantLabDataConfig} from '@predict/DomainsLib/labData'

export function useSelectedSilos(): string[] {
  const {selectedSources} = useSelectedSourcesSearchParam()
  const plantConfig = usePlantLabDataConfig()

  if (!selectedSources) return plantConfig.defaultSilos

  return selectedSources.includes(AGGREGATED_SILOS_NAME) ? plantConfig.siloNames : []
}
