import {SxProps, Tab, Tabs as MuiTabs, TabsProps as MuiTabsProps, Theme} from '@mui/material'
import React from 'react'

export interface TabsProps<T = string> {
  views: T[]
  defaultView: T
  selectedView: T
  onChange: (view: T) => void
  tabLabel: (view: T) => string
  'data-test-id'?: string
  tabKey?: (view: T) => string
  tabTestId?: (view: T) => string
  className?: string
  sx?: SxProps<Theme>
  variant?: MuiTabsProps['variant']
}

export function Tabs<T = string>({
  views,
  defaultView,
  selectedView,
  onChange,
  tabLabel,
  'data-test-id': testId,
  tabKey = String,
  tabTestId,
  className,
  sx,
  variant = 'scrollable'
}: TabsProps<T>) {
  const viewToTabIndex = (view: T, defaultIndex: number): number => {
    const index = views.findIndex((v) => view === v)
    return index < 0 ? defaultIndex : index
  }

  const defaultViewTabIndex = viewToTabIndex(defaultView, 0)

  const tabIndexToView = (index: number): T => views[index] ?? defaultView

  if (views.length < 1) return <></>

  return (
    <MuiTabs
      indicatorColor="secondary"
      textColor="inherit"
      variant={variant}
      scrollButtons="auto"
      value={viewToTabIndex(selectedView, defaultViewTabIndex)}
      onChange={(e, tabIndex: number) => onChange(tabIndexToView(tabIndex))}
      className={className}
      sx={sx}
      data-test-id={testId}
    >
      {views.map((view) => (
        <Tab
          key={tabKey(view)}
          label={tabLabel(view)}
          data-test-id={tabTestId ? tabTestId(view) : undefined}
        />
      ))}
    </MuiTabs>
  )
}
