import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {
  usePlantNavigation,
  useSampleIdParameter,
  useMaterialIdParameter,
  useTimeRangeSearchParams,
  useStrengthSearchParam,
  useSelectedSourcesSearchParam
} from '../../modules/navigation'
import {PlantPageShell} from '../../modules/plants'

import {SampleDataContainer} from './components/SampleDataContainer'
import {SampleNavigationControls} from './components/SampleNavigationControls'

import {
  CementStrengthDomainProvider,
  useCementStrengthSampleDetails,
  useMaterial
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate} from '@predict/UtilsLib/dateTime'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {ActionBar} from '@predict/WebUILib/layouts/ActionBar/ActionBar'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'
import {PageBreadcrumbs} from '@predict/WebUILib/molecules/PageBreadcrumbs/PageBreadcrumbs'

export function CementStrengthSamplePage() {
  const materialIdParameter = useMaterialIdParameter()
  const {start, end} = useTimeRangeSearchParams()
  const {strength} = useStrengthSearchParam()
  const {selectedSources} = useSelectedSourcesSearchParam()

  return (
    <CementStrengthDomainProvider
      materialId={materialIdParameter}
      strength={strength}
      selectedSources={selectedSources}
      start={start}
      end={end}
    >
      <CementStrengthSamplePageContent />
    </CementStrengthDomainProvider>
  )
}

export function CementStrengthSamplePageContent() {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const sampleId = useSampleIdParameter()
  const navigation = usePlantNavigation()
  const {data, isLoading, isError} = useCementStrengthSampleDetails(sampleId)
  const material = useMaterial()

  const getPageTitle = useCallback(() => {
    if (isLoading) {
      return t('cementStrengthSamplePage.sampleLoading')
    }

    if (isError || !data) {
      return t('errors.noSample')
    }

    return t('cementStrengthSamplePage.title', {
      sampleTime: formatTimeZoneDate(data.datetime, timeZone, t('sampleDetails.dateFormat'))
    })
  }, [data, isError, isLoading, t, timeZone])

  return (
    <PlantPageShell>
      <PageBreadcrumbs
        current={t('navigation.cementSample', {id: sampleId ?? ''})}
        pathEntries={[
          {
            title: t('navigation.cement'),
            url: navigation.getPlantUrl({
              plantPage: 'cement'
            })
          },
          {
            title: material?.name || t('navigation.cementTrends'),
            url: navigation.getPlantUrl({
              plantPage: 'cementTrends',
              pathParams: {materialId: String(material?.materialId)}
            })
          }
        ]}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ActionBar
            title={
              <PageTitle data-test-id="cement-strength-sample-page-title">
                {getPageTitle()}
              </PageTitle>
            }
            data-test-id="cement-strength-action-bar"
          >
            <SampleNavigationControls
              currentSampleId={data?.id}
              materialId={material?.materialId}
            />
          </ActionBar>
          {isError ? (
            <ErrorMessage>{t('errors.noSample')}</ErrorMessage>
          ) : (
            <SampleDataContainer sample={data} />
          )}{' '}
        </>
      )}
    </PlantPageShell>
  )
}
