import {flatten, isEmpty} from 'lodash'
import {useQuery, UseQueryResult} from 'react-query'

import type {KilnLabData} from '../declarations/labData'
import {useLabDataDomainContext} from '../providers/LabDataDomainProvider'
import {dtoToKilnLabData} from '../utils/dtoToKilnLabData'

import {useKilnSelectedUniformTags} from './useKilnSelectedUniformTags'

import {
  FL_FETCH_INTERVAL_MS,
  LAB_METRICS_MAX_POINTS_RESOLUTION,
  QueryKeyName
} from '@predict/DomainsLib/constants'
import {FreelimePredictionsApi} from '@predict/DomainsLib/gen-api/FreelimePredictions'
import {usePlantId, usePlantTimeZone, useProductionLine} from '@predict/DomainsLib/plants'
import {
  isRelativeDateTimeParam,
  padTimeRange,
  TimeRange,
  timeRangeFromStartEnd
} from '@predict/UtilsLib/dateTime'
import {DEFAULT_TIME_MARGIN_FACTOR} from '@predict/UtilsLib/dateTime/constants'

export async function getKilnLabData(
  plantId: string,
  productionLineId: string,
  materialType: string,
  tagIds: string[],
  range: TimeRange
): Promise<KilnLabData> {
  const emptyLabData: KilnLabData = {range, plantId, productionLineId, materialType, data: []}
  if (isEmpty(tagIds)) {
    return emptyLabData
  }

  const {data} = await FreelimePredictionsApi.getplantsFreeLimeLabdata(plantId, {
    from: range.start.toJSON(),
    until: range.end.toJSON(),
    productionLineId: productionLineId,
    materialType: materialType,
    tags: tagIds,
    maxPoints: LAB_METRICS_MAX_POINTS_RESOLUTION
  })

  if (data.length < 1) {
    return emptyLabData
  }

  return dtoToKilnLabData(plantId, productionLineId, materialType, data, range)
}

export function useKilnLabData({
  tagIds,
  refetchInterval = FL_FETCH_INTERVAL_MS,
  shouldPadTimeRange = true
}: {
  tagIds?: string[]
  refetchInterval?: number
  shouldPadTimeRange?: boolean
} = {}): UseQueryResult<KilnLabData[]> {
  const {productionLineId} = useProductionLine()
  const {start, end} = useLabDataDomainContext()
  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const materialTags = useKilnSelectedUniformTags(tagIds)

  return useQuery(
    [
      QueryKeyName.GetFreeLimeLabData,
      plantId,
      materialTags,
      timeZone,
      start,
      end,
      productionLineId,
      refetchInterval,
      shouldPadTimeRange
    ],
    () => {
      const requests = Object.entries(materialTags).map(([materialType, uniformTags]) =>
        getKilnLabData(
          plantId,
          productionLineId,
          materialType,
          uniformTags,
          shouldPadTimeRange
            ? padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR)
            : timeRangeFromStartEnd(start, end)
        )
      )
      return Promise.all(requests).then(flatten)
    },
    {
      refetchInterval: isRelativeDateTimeParam(end) && refetchInterval,
      staleTime: 0
    }
  )
}
