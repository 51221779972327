/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FreelimeOutput,
  LabMetric,
  LabMetricsMetadata,
  ProblemDetails,
  TimeSpan
} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class FreelimePredictionsApi {
  /**
   * No description
   *
   * @tags FreelimePredictions
   * @name GetplantsFreeLimePredictions
   * @summary Get freelime preditions
   * @request GET:/predict/plants/{plantId}/freeLime/predictions
   * @secure
   */
  static getplantsFreeLimePredictions = (
    plantId: string,
    query?: {
      /**
       * The production line
       * @example "3"
       */
      productionLineId?: string
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      startTime?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      endTime?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<FreelimeOutput[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/freeLime/predictions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags FreelimePredictions
   * @name GetplantsFreeLimeLabdata
   * @summary Get lab data for freelime
   * @request GET:/predict/plants/{plantId}/freeLime/labdata
   * @secure
   */
  static getplantsFreeLimeLabdata = (
    plantId: string,
    query?: {
      materialType?: string
      /**
       * The production line
       * @example "3"
       */
      productionLineId?: string
      tags?: string[]
      /** @format date-time */
      from?: string
      /** @format date-time */
      until?: string
      resolution?: TimeSpan
      /** @format int32 */
      maxPoints?: number
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<LabMetric[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/freeLime/labdata`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags FreelimePredictions
   * @name GetplantsFreeLimeLabmetadata
   * @summary Get lab metadata for freelime
   * @request GET:/predict/plants/{plantId}/freeLime/labmetadata
   * @secure
   */
  static getplantsFreeLimeLabmetadata = (
    plantId: string,
    query?: {
      tags?: string[]
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<LabMetricsMetadata[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/freeLime/labmetadata`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
