import React from 'react'
import {useTranslation} from 'react-i18next'

import {UserManualHook} from '../declarations/UserManualHook'

import {UserManualImage} from './UserManualImage'

import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'
import {MainLoader} from '@predict/WebUILib/molecules/Loader/MainLoader'
import {MarkdownRenderer} from '@predict/WebUILib/organisms/MarkdownRenderer/MarkdownRenderer'

interface MarkdownPageProps {
  hook: UserManualHook
}

export function UserManualMarkdownPage({hook: useHook}: MarkdownPageProps) {
  const {t} = useTranslation()
  const {manual, componentsOverrides} = useHook()
  const {data: markdown, isLoading} = manual

  if (isLoading) return <MainLoader />

  if (!markdown) return <ErrorMessage>{t('errors.noPageFoundDesc')}</ErrorMessage>

  return (
    <DefaultBox maxWidth={960} data-test-id="user-manual-container">
      <MarkdownRenderer
        markdown={markdown}
        componentsOverrides={{
          img: {
            component: UserManualImage
          },
          ...componentsOverrides
        }}
      />
    </DefaultBox>
  )
}
