import {maxBy, minBy} from 'lodash'

import {DEFAULT_TIME_MARGIN_FACTOR} from '../constants'
import {
  DateTimeRecord,
  NullableDateTimeRecord,
  TimeRange,
  WithDateTime
} from '../declarations/dateTimeTypes'

import {mapTimeToIntervalIndex} from './timeGrid'
import {inTimeRange, padTimeRange} from './timeRange'

export const filterSamplesByTimeRange = <T extends WithDateTime>(
  samples: T[],
  timeRange: TimeRange
): T[] => {
  return samples.filter((d) => inTimeRange(timeRange, d.datetime))
}

export const filterSamplesByTimeRangeWithMargin = <T extends WithDateTime>(
  samples: T[],
  timeRange: TimeRange,
  timeMarginFactor = DEFAULT_TIME_MARGIN_FACTOR
): T[] => filterSamplesByTimeRange(samples, padTimeRange(timeRange, timeMarginFactor))
const findMinValRecord = <R extends NullableDateTimeRecord>(records: R[]) =>
  minBy(records, (r) => r.value) ?? records[0]
const findMaxValRecord = <R extends NullableDateTimeRecord>(records: R[]) =>
  maxBy(records, (r) => r.value) ?? records[0]

export const reduceDataPoints = <R extends NullableDateTimeRecord>(
  records: R[],
  maxCount: number
): R[] => {
  if (maxCount < 2) {
    throw new Error('Illegal argument maxCount < 2')
  }

  if (records.length <= maxCount) {
    return records
  }
  // The idea is to map multiple records to the same index
  // then choose both the lowest and highest values of these records
  const min = records[0].datetime
  const max = records[records.length - 1].datetime
  if (min === max) {
    throw new Error('Illegal argument records must have disjunct timestamps')
  }
  const intervalCount = Math.floor(0.5 * maxCount)
  const splitRecords: R[][] = []
  records.forEach((record) => {
    const idx = mapTimeToIntervalIndex(record.datetime, min, max, intervalCount)
    splitRecords[idx] = [...(splitRecords[idx] ?? []), record]
  })

  return splitRecords.filter(Boolean).flatMap((splitted) => {
    if (splitted.length === 1) return splitted
    const minValueRecord = findMinValRecord(splitted)
    const maxValueRecord = findMaxValRecord(splitted.filter((record) => record !== minValueRecord))
    return [minValueRecord, maxValueRecord].sort((a, b) => {
      return (a as DateTimeRecord).datetime - (b as DateTimeRecord).datetime
    })
  })
}
