import React, {PropsWithChildren} from 'react'

interface ClippedWrapperProps extends PropsWithChildren<unknown> {
  clipPathId: string
}

/**
 * Clips its children using a clip path by passing its id
 * @param  - PropsWithChildren<{clipPathId: string}>
 */
export function ClippedWrapper({clipPathId, children}: ClippedWrapperProps) {
  return <g clipPath={`url(#${clipPathId})`}>{children}</g>
}
