import {isNumber} from 'lodash'
import {useMemo} from 'react'
import {useQuery, useQueryClient} from 'react-query'

import {CementStrengthSampleDetails} from '../../declarations/CementStrengthSampleDetails'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'
import {findNextSample, findPrevSample} from '../../utils/cementStrengthSampleUtils'

import {useAggregatedCemStrengthData} from './useAggregatedCemStrengthData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {CementQualityDetailOutput} from '@predict/DomainsLib/gen-api/data-contracts'
import {QualityDataApi} from '@predict/DomainsLib/gen-api/QualityData'
import {usePlantId} from '@predict/DomainsLib/plants'
import {parseDate} from '@predict/UtilsLib/dateTime'
import {stripObjectNulls} from '@predict/UtilsLib/general'

function convertCementStrengthDetailsDto({
  sampleIdPlant,
  sampleDate,
  ...rest
}: CementQualityDetailOutput): CementStrengthSampleDetails {
  return {
    ...stripObjectNulls(rest),
    id: sampleIdPlant,
    datetime: parseDate(sampleDate)
  }
}

export async function getSampleDetails(
  plantId: string,
  materialId: number,
  sampleId: string
): Promise<CementStrengthSampleDetails | undefined> {
  const response = await QualityDataApi.getplantsCementQualitiesDetails(plantId, {
    unifiedMaterialId: materialId,
    sampleIdPlant: sampleId
  })

  if (response.data.length === 0) {
    return
  }
  const [dto] = response.data
  return convertCementStrengthDetailsDto(dto)
}

const queryFunction =
  (plantId: string, materialId?: number, sampleId?: string) =>
  (): Promise<CementStrengthSampleDetails | undefined> => {
    if (isNumber(materialId) && sampleId) {
      return getSampleDetails(plantId, materialId, sampleId)
    }
    return Promise.resolve(undefined)
  }

function usePrefetch(plantId: string, materialId?: number, sampleId?: string) {
  const {data} = useAggregatedCemStrengthData(materialId)
  const queryClient = useQueryClient()
  const prev = useMemo(
    () => findPrevSample(data?.samples ?? [], sampleId)?.id,
    [sampleId, data?.samples]
  )
  const next = useMemo(
    () => findNextSample(data?.samples ?? [], sampleId)?.id,
    [sampleId, data?.samples]
  )
  void queryClient.prefetchQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, prev],
    queryFunction(plantId, materialId, prev)
  )
  void queryClient.prefetchQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, next],
    queryFunction(plantId, materialId, next)
  )
}

const useSampleDetailsSingle = (plantId: string, materialId?: number, sampleId?: string) => {
  return useQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, sampleId],
    queryFunction(plantId, materialId, sampleId)
  )
}

export function useCementStrengthSampleDetails(sampleId?: string) {
  const plantId = usePlantId()
  const {materialId} = useCementStrengthDomainContext()
  const query = useSampleDetailsSingle(plantId, materialId, sampleId)

  // pre-fetch predecessor and successor
  usePrefetch(plantId, materialId, sampleId)

  return query
}
