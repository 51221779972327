import * as d3 from 'd3'
import {isNull} from 'lodash'
import React, {useLayoutEffect, useRef} from 'react'

import type {AxisFormatFn, ScaleFn} from '@predict/WebUILib/Charts/helpers'

type AxisPosition = 'bottom' | 'left' | 'top' | 'right'

interface AxisProps {
  scale: ScaleFn
  position: AxisPosition
  posX?: number
  posY?: number
  color?: string
  textColor?: string
  tickSize?: number
  tickSizeInnerValue?: number
  tickSizeOuterValue?: number
  padding?: number
  format?: AxisFormatFn
  extraFormat?: AxisFormatFn
  tickValues?: readonly number[]
  translateX?: number
  'data-test-id'?: string
}

const axisFunction = <Domain extends number>(
  position: AxisPosition
): ((scale: ScaleFn<Domain>) => d3.Axis<Domain>) => {
  return {bottom: d3.axisBottom, left: d3.axisLeft, top: d3.axisTop, right: d3.axisRight}[
    position
  ] as (scale: ScaleFn<Domain>) => d3.Axis<Domain>
}

/**
 * It renders a D3 axis
 * @returns A g element that D3 uses to render its axis
 */
export function Axis({
  position,
  scale,
  posX = 0,
  posY = 0,
  color = 'black',
  textColor = color,
  tickSize = 6,
  tickSizeInnerValue = tickSize,
  tickSizeOuterValue = tickSize,
  padding = 3,
  format,
  extraFormat,
  tickValues,
  translateX = 0,
  'data-test-id': dataTestId
}: AxisProps) {
  const ref = useRef<SVGGElement>(null)

  useLayoutEffect(() => {
    if (isNull(ref.current)) return

    const root = d3
      .select(ref.current)
      .attr('transform', `translate(${posX}, ${posY})`)
      .attr('color', color)

    root.selectAll('*').remove()

    const axis: d3.Axis<number> = axisFunction(position)(scale)
      // tickFormat either takes a null or a method
      // Here we pass the two overloads together which confuses typescript
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .tickFormat((format ?? null)!)
      .tickSize(tickSize)
      .tickPadding(padding)
      // tickValues either takes a null or an iterable
      // Here we pass the two overloads together which confuses typescript
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .tickValues((tickValues ?? null)!)
      .tickSizeInner(tickSizeInnerValue)
      .tickSizeOuter(tickSizeOuterValue)

    axis(root)

    root
      .selectAll('.tick text')
      .attr('color', textColor)
      .attr('font-size', '12px')
      .style('transform', `translateX(${translateX}px)`)

    if (extraFormat) {
      root
        .selectAll<SVGGElement, number>('.tick text')
        .clone()
        .attr('dy', '1.8em')
        .text(extraFormat)
    }
  })

  return <g data-test-id={dataTestId} ref={ref} />
}
