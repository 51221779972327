import {isEqual} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  UnifiedSensorMetaData,
  useAddUnifiedSensorMetaDataMutation,
  useUpdateUnifiedSensorMetaDataMutation,
  isUnifiedSensorMetaDataDescriptionValid,
  isUnifiedSensorMetaDataUniformTagValid,
  isUnifiedSensorMetaDataValid
} from '@predict/DomainsLib/sensorData'
import {InfoOutlined} from '@predict/WebUILib/helpers/icons'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'
import {TextField} from '@predict/WebUILib/molecules/TextField/TextField'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

interface UnifiedSensorMetaDataDialogProps {
  open?: boolean
  item?: UnifiedSensorMetaData
  title?: string
  onClose?: () => void
}

const EMPTY_UNIFIED_SENSOR_META_DATA: UnifiedSensorMetaData = {
  uniformTag: '',
  unit: '',
  displayName: '',
  description: ''
} as const

const showError = (
  currentValue: string,
  originalValue: string,
  isValid: (value: string) => boolean
): boolean => currentValue !== originalValue && !isValid(currentValue)

export function UnifiedSensorMetaDataDialog({
  item: optionalItem,
  open = false,
  title = '',
  onClose
}: UnifiedSensorMetaDataDialogProps) {
  const shouldAdd = !optionalItem
  const item: UnifiedSensorMetaData = optionalItem ?? EMPTY_UNIFIED_SENSOR_META_DATA
  const {t} = useTranslation()
  const {unit, uniformTag, description} = item
  const [unitValue, setUnitValue] = useState(unit)
  const [uniformTagValue, setUniformTagValue] = useState(uniformTag)
  const [descriptionValue, setDescriptionValue] = useState(description)
  const {mutate: addMutate, isLoading: isAddMutateLoading} = useAddUnifiedSensorMetaDataMutation(
    useSnackbarMutationInjector(
      t('sensorMetaDataDialog.addSuccessful'),
      t('sensorMetaDataDialog.addFailed')
    )
  )
  const {mutate: updateMutate, isLoading: isUpdateMutateLoading} =
    useUpdateUnifiedSensorMetaDataMutation(
      useSnackbarMutationInjector(
        t('sensorMetaDataDialog.updateSuccessful'),
        t('sensorMetaDataDialog.updateFailed')
      )
    )

  const mutate = shouldAdd ? addMutate : updateMutate
  const isLoading = shouldAdd ? isAddMutateLoading : isUpdateMutateLoading

  const itemToSave: UnifiedSensorMetaData = {
    ...item,
    unit: unitValue,
    uniformTag: uniformTagValue,
    description: descriptionValue
  }
  const isPristine = isEqual(item, itemToSave)

  const onSave = () => {
    mutate(itemToSave, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  const onEnter = () => {
    setUnitValue(unit)
    setUniformTagValue(uniformTag)
    setDescriptionValue(description)
  }

  return (
    <GeneralDialog
      title={title}
      isOpen={open}
      onClose={onClose}
      data-test-id={`sensor-meta-data-dialog-${uniformTag}`}
      onEnter={onEnter}
      actions={
        <DialogBasicActions
          onCancel={onCancel}
          onSave={onSave}
          saveBtnLabel={t('button.save')}
          saveDisabled={!isUnifiedSensorMetaDataValid(itemToSave) || isPristine}
          isLoading={isLoading}
        />
      }
    >
      <GridContainer spacing={3}>
        <GridItem xs={12}>
          <TextField
            fullWidth
            data-test-id="sensor-meta-data-dialog-uniform-tag-field"
            placeholder={t('processMetaData.uniformTag')}
            label={t('processMetaData.uniformTag')}
            value={uniformTagValue}
            onChange={(event) => {
              setUniformTagValue(event.target.value)
            }}
            helperText={
              <span>
                <Box
                  component="span"
                  display="inline-block"
                  mr={1}
                  style={{verticalAlign: 'middle'}}
                >
                  <InfoOutlined />
                </Box>
                {t('sensorMetaDataDialog.uniformTagHelper')}
              </span>
            }
            required
            error={showError(uniformTagValue, uniformTag, isUnifiedSensorMetaDataUniformTagValid)}
            disabled={!shouldAdd}
          />
        </GridItem>
        <GridItem sm={6} xs={12}>
          <TextField
            data-test-id="sensor-meta-data-dialog-unit-field"
            placeholder={t('processMetaData.unit')}
            label={t('processMetaData.unit')}
            value={unitValue}
            onChange={(event) => {
              setUnitValue(event.target.value)
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            data-test-id="sensor-meta-data-dialog-description-field"
            placeholder={t('processMetaData.description')}
            label={t('processMetaData.description')}
            value={descriptionValue}
            onChange={(event) => {
              setDescriptionValue(event.target.value)
            }}
            error={showError(
              descriptionValue,
              description,
              isUnifiedSensorMetaDataDescriptionValid
            )}
            helperText={
              showError(descriptionValue, description, isUnifiedSensorMetaDataDescriptionValid)
                ? t('form.required')
                : undefined
            }
            required
          />
        </GridItem>
      </GridContainer>
    </GeneralDialog>
  )
}
