import {AxiosError} from 'axios'
import {useMutation} from 'react-query'

import {UnifiedSensorMetaData} from '../../declarations/SensorMetaData'
import {trimUnifiedSensorMetaDataFields} from '../../utils/sensorMetaDataUtils'
import {useInvalidateSensorData} from '../useInvalidateSensorData'

import {UniformProcessMetric} from '@predict/DomainsLib/gen-api/data-contracts'
import {UniformProcessMetricsApi} from '@predict/DomainsLib/gen-api/UniformProcessMetrics'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export async function addUnifiedSensorMetaData(
  metaData: UnifiedSensorMetaData
): Promise<UniformProcessMetric> {
  const log = logger.context('addUnifiedSensorMetaData', JSON.stringify(metaData))
  try {
    const {data} = await UniformProcessMetricsApi.processMetricsUniformCreate({
      uniformTag: metaData.uniformTag,
      unit: metaData.unit,
      description: metaData.description,
      displayName: metaData.displayName || metaData.description
    })
    return data
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export function useAddUnifiedSensorMetaDataMutation(optionsInjector = dummyMutationInjector) {
  const invalidateSensorData = useInvalidateSensorData()

  return useMutation(
    optionsInjector({
      mutationFn: async (metaData: UnifiedSensorMetaData) => {
        const dataToSave = trimUnifiedSensorMetaDataFields(metaData)
        await addUnifiedSensorMetaData(dataToSave)
      },
      onSuccess: invalidateSensorData
    })
  )
}
