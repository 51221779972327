import {uniq} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ClinkerActionBar} from './components/ClinkerActionBar'
import {ClinkerSamplesTable} from './components/ClinkerSamplesTable'
import {KilnChartsContainer} from './components/KilnChartsContainer'
import {PREDEFINED_CHART_TAGS} from './constants'

import {FreeLimeDomainProvider} from '@predict/DomainsLib/freeLime'
import {FreeLimeDomainConsumer} from '@predict/DomainsLib/freeLime/providers/FreeLimeDomainProvider'
import {LabDataDomainProvider} from '@predict/DomainsLib/labData'
import {SensorDataDomainProvider} from '@predict/DomainsLib/sensorData'
import {
  usePredictionViewSearchParam,
  useSelectedTagIdsSearchParam,
  useTimeRangeSearchParams
} from '@predict/WebApp/modules/navigation'
import {PlantPageShell} from '@predict/WebApp/modules/plants'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'

export function ClinkerPage() {
  const {t} = useTranslation()

  const {start, end} = useTimeRangeSearchParams()
  const {selectedTagIds} = useSelectedTagIdsSearchParam()

  const tagIds = useMemo(
    () => uniq([...PREDEFINED_CHART_TAGS, ...(selectedTagIds ?? [])]),
    [selectedTagIds]
  )

  const {predictionView} = usePredictionViewSearchParam()

  return (
    <FreeLimeDomainProvider start={start} end={end}>
      <FreeLimeDomainConsumer>
        {(flContextValue) =>
          flContextValue ? (
            <LabDataDomainProvider
              start={flContextValue.start}
              end={flContextValue.end}
              selectedTagIds={tagIds}
            >
              <SensorDataDomainProvider
                start={flContextValue.start}
                end={flContextValue.end}
                selectedTagIds={tagIds}
              >
                <PlantPageShell>
                  <ClinkerActionBar title={t('clinkerPage.title')} />
                  {predictionView === 'table' ? (
                    <DefaultBox width="100%" m={1} flex={1}>
                      <ClinkerSamplesTable />
                    </DefaultBox>
                  ) : (
                    <KilnChartsContainer />
                  )}
                </PlantPageShell>
              </SensorDataDomainProvider>
            </LabDataDomainProvider>
          ) : (
            <Loader />
          )
        }
      </FreeLimeDomainConsumer>
    </FreeLimeDomainProvider>
  )
}
