import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@mui/material'
import {groupBy, isUndefined} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {TABLE_ACTIONS_COLUMN_NAME} from './helpers/constants'
import {
  CellBuilders,
  ColumnConfiguration,
  DataKeyBuilder,
  DataValueKeyBuilder,
  DataValuePresenter,
  GroupedData,
  ManipulatorCriteria,
  SortType
} from './helpers/types'
import {useSelectedItems} from './helpers/useSelectedItems'
import {GeneralTableBodyCollapsibleRows} from './subComponents/GeneralTableBodyCollapsibleRows'
import {GeneralTableHeader} from './subComponents/GeneralTableHeader'
import {PaginationActions} from './subComponents/PaginationActions'

export interface GeneralTableProps<D> {
  tableId: string
  columnsConfiguration: ColumnConfiguration<D>[]
  data: D[]
  cellBuilders: CellBuilders<D>
  rowKey?: DataKeyBuilder<D>
  rowsGroupBy?: keyof D
  rowsGroupByBuilder?: DataValuePresenter<D>
  rowsGroupByKey?: DataValueKeyBuilder<D>
  sortCriteria?: ManipulatorCriteria<D, SortType>[]
  onSort?: (criteria: ManipulatorCriteria<D, SortType>[]) => void
  filterCriteria?: ManipulatorCriteria<D>[]
  onFilter?: (criteria: ManipulatorCriteria<D>[]) => void
  selectedItems?: D[]
  onSelectItems?: (selectedItems: D[]) => void
  onRowClick?: (item: D) => void
  selectedRowKey?: string
  hasColumnBorders?: boolean
  rowsPerPage?: number
  page?: number
  count?: number
  onPageChange?: (pageNumber: number) => void
  onRowsPerPageChange?: (rowsNumber: number) => void
  noHeader?: boolean
  isCompact?: boolean
}

function onCriteriaChange<D, T extends string = string>(
  criteria: ManipulatorCriteria<D, T>[],
  newCriterion
) {
  // Clean criteria of the old criterion of the same field
  let newCriteria = criteria.filter((filter) => filter.field !== newCriterion.field)
  const trimmedExpression = newCriterion.expression.trim().toLowerCase()
  if (trimmedExpression) {
    newCriteria = [...newCriteria, {...newCriterion, expression: trimmedExpression}]
  }
  return newCriteria
}

export function GeneralTable<D>({
  tableId,
  columnsConfiguration,
  rowKey,
  data,
  cellBuilders,
  rowsGroupBy,
  rowsGroupByBuilder,
  rowsGroupByKey,
  sortCriteria = [],
  onSort,
  filterCriteria = [],
  onFilter,
  selectedItems = [],
  onSelectItems,
  onRowClick,
  selectedRowKey,
  hasColumnBorders = false,
  count,
  rowsPerPage,
  page = 0,
  onRowsPerPageChange,
  onPageChange,
  noHeader = false,
  isCompact = false
}: GeneralTableProps<D>) {
  // Calculate flags
  const hasMultiSelect = !isUndefined(onSelectItems)
  const hasSorting = !isUndefined(onSort)
  const hasFiltering = !isUndefined(onFilter)
  const hasRowGroupings = !isUndefined(rowsGroupBy) && !isUndefined(rowsGroupByBuilder)
  const hasPagination = !!(
    rowsPerPage &&
    count &&
    !isUndefined(onPageChange) &&
    !isUndefined(onRowsPerPageChange)
  )

  // Get Styles
  const {t} = useTranslation()

  // Group Data
  const groupedData: GroupedData<D>[] = useMemo(() => {
    if (!rowsGroupBy) {
      return [{data: data, selectedItems}]
    }
    return Object.entries(groupBy(data, rowsGroupBy)).map<GroupedData<D>>(
      ([groupValue, groupData]) => ({
        group: groupValue as unknown as D[keyof D],
        data: groupData,
        selectedItems: selectedItems.filter((item) => groupData.includes(item))
      })
    )
  }, [rowsGroupBy, data, selectedItems])

  // On data manipulation
  const onFilterChange = useCallback(
    (criterion: ManipulatorCriteria<D>) =>
      hasFiltering && onFilter(onCriteriaChange(filterCriteria, criterion)),
    [filterCriteria, hasFiltering, onFilter]
  )
  const onSortChange = useCallback(
    (criterion: ManipulatorCriteria<D, SortType>) =>
      hasSorting && onSort(onCriteriaChange(sortCriteria, criterion)),
    [hasSorting, onSort, sortCriteria]
  )

  // Rows Selection
  const {selectAllState, onItemsSelect, onSelectAll} = useSelectedItems(
    data,
    selectedItems,
    onSelectItems
  )

  // Render UI
  return (
    <TableContainer data-test-id={tableId}>
      <Table size="small">
        {!noHeader && (
          <GeneralTableHeader
            tableId={tableId}
            columnsConfiguration={columnsConfiguration}
            sortCriteria={sortCriteria}
            onSort={hasSorting ? onSortChange : undefined}
            filterCriteria={filterCriteria}
            onFilter={hasFiltering ? onFilterChange : undefined}
            hasActions={Object.prototype.hasOwnProperty.call(
              cellBuilders,
              TABLE_ACTIONS_COLUMN_NAME
            )}
            hasRowGroupings={hasRowGroupings}
            hasColumnBorders={hasColumnBorders}
            selectAllState={selectAllState}
            onSelectAll={hasMultiSelect ? onSelectAll : undefined}
            isCompact={isCompact}
          />
        )}

        <TableBody>
          {groupedData.map((dataGroup, index) => {
            const groupKey =
              dataGroup.group && rowsGroupByKey ? rowsGroupByKey(dataGroup.group) : String(index)
            return (
              <GeneralTableBodyCollapsibleRows
                key={groupKey}
                tableId={tableId}
                columnsConfiguration={columnsConfiguration}
                rowKey={rowKey}
                dataGroup={dataGroup}
                cellBuilders={cellBuilders}
                rowsGroupByBuilder={rowsGroupByBuilder}
                rowsGroupByKey={groupKey}
                hasColumnBorders={hasColumnBorders}
                onItemSelect={hasMultiSelect ? onItemsSelect : undefined}
                onRowClick={onRowClick}
                selectedRowKey={selectedRowKey}
                isCompact={isCompact}
              />
            )
          })}
        </TableBody>
        {hasPagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{
                  '.MuiTablePagination-spacer': {
                    flex: '0.5 !important'
                  }
                }}
                count={count}
                page={page}
                onPageChange={(event, pageNumber) => onPageChange(pageNumber)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => onRowsPerPageChange(+event.target.value)}
                labelDisplayedRows={() => ''}
                labelRowsPerPage={t('UILibrary.GeneralTable.pagination.labelRowsPerPage')}
                ActionsComponent={PaginationActions}
                SelectProps={{
                  SelectDisplayProps: {
                    id: `${tableId}-rows-per-page-select`
                  },
                  MenuProps: {
                    MenuListProps: {
                      id: `${tableId}-rows-per-page-select-list`
                    }
                  }
                }}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
