import React from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'

import {HelmetTitle} from '../modules/general'
import {PlantPageShell} from '../modules/plants'
import {
  UserManualMarkdownPage,
  useCementUserManual,
  useClinkerUserManual,
  useIntroductionUserManual
} from '../modules/userManuals'

import {usePlantId} from '@predict/DomainsLib/plants'
import {ReverseStringsRecord} from '@predict/UtilsLib/general'
import {
  ROOT_PATH,
  USER_MANUAL_CEMENT_PATH,
  USER_MANUAL_FREE_LIME_PATH,
  USER_MANUAL_INTRODUCTION_PATH,
  getPageUrl,
  PredictPageId,
  useExactMatchedRoute,
  usePlantNavigation,
  USER_MANUAL_CLINKER_PATH,
  USER_MANUAL_CEMENT_STRENGTH_PATH
} from '@predict/WebApp/modules/navigation'
import {HeaderWithTabs} from '@predict/WebUILib/molecules/HeaderWithTabs/HeaderWithTabs'

export type UserManualView = 'introduction' | 'clinker' | 'cement'
const USER_MANUAL_VIEWS: UserManualView[] = ['introduction', 'clinker', 'cement']
const DEFAULT_USER_MANUAL_VIEW: UserManualView = 'introduction'

const UserManualViewToPredictPageId: Record<UserManualView, PredictPageId> = {
  introduction: 'userManualIntroduction',
  clinker: 'userManualClinker',
  cement: 'userManualCement'
}
const PredictPageIdToUserManualView = ReverseStringsRecord(UserManualViewToPredictPageId)

export function UserManualPage() {
  const {t} = useTranslation()
  const navigation = usePlantNavigation()
  const plantId = usePlantId()
  const matchedRoute = useExactMatchedRoute()
  const selectedView = matchedRoute
    ? PredictPageIdToUserManualView[matchedRoute.pageId]
    : DEFAULT_USER_MANUAL_VIEW

  const onViewChange = (newView: UserManualView) => {
    navigation.navigate({
      plantPage: UserManualViewToPredictPageId[newView],
      pathParams: {plant: plantId},
      resetQueryParams: true
    })
  }

  const getManualPageTitle = (view: UserManualView) =>
    `${t(`userManual.subPages.${view}`)} ${t('navigation.userManual')}`

  return (
    <PlantPageShell>
      <HeaderWithTabs
        title={t('userManualPage.title')}
        views={USER_MANUAL_VIEWS}
        selectedView={selectedView}
        defaultView={DEFAULT_USER_MANUAL_VIEW}
        onChange={onViewChange}
        tabLabel={(view) => t(`userManual.subPages.${view}`)}
        tabTestId={(view) => `user-manual-container-tab-${view}`}
        tabsTestId="settings-container-tabs"
      />
      <Switch>
        <Route path={USER_MANUAL_INTRODUCTION_PATH} exact>
          <HelmetTitle title={getManualPageTitle('introduction')} />
          <UserManualMarkdownPage hook={useIntroductionUserManual} />
        </Route>

        <Route path={USER_MANUAL_CLINKER_PATH} exact>
          <HelmetTitle title={getManualPageTitle('clinker')} />
          <UserManualMarkdownPage hook={useClinkerUserManual} />
        </Route>
        <Redirect from={USER_MANUAL_FREE_LIME_PATH} to={USER_MANUAL_CLINKER_PATH} exact />

        <Route path={USER_MANUAL_CEMENT_PATH} exact>
          <HelmetTitle title={getManualPageTitle('cement')} />
          <UserManualMarkdownPage hook={useCementUserManual} />
        </Route>
        <Redirect from={USER_MANUAL_CEMENT_STRENGTH_PATH} to={USER_MANUAL_CEMENT_PATH} exact />

        <Redirect
          to={
            plantId
              ? getPageUrl(UserManualViewToPredictPageId[DEFAULT_USER_MANUAL_VIEW], {
                  plant: plantId
                })
              : ROOT_PATH
          }
        />
      </Switch>
    </PlantPageShell>
  )
}
