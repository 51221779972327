import type {Plant} from '../declarations/Plant'

import {PlantConfigurationDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {DEFAULT_TIME_ZONE} from '@predict/UtilsLib/dateTime/constants'

export function plantConfigDtoToPlant(plants: PlantConfigurationDto[]): Plant[] {
  return plants
    .map((p) => ({
      id: p.plantId ?? '',
      name: p.plantName ?? '',
      fullName: p.plantFullName ?? '',
      country: p.country ?? '',
      timeZone: p.timezone ?? DEFAULT_TIME_ZONE,
      productionLines:
        p.productionLines?.map(({id, name}) => ({
          id,
          name: name ?? undefined
        })) ?? []
    }))
    .filter((p) => Boolean(p.id))
}
