/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {ProblemDetails, UniformProcessMetric} from './data-contracts'
import {ContentType, genApiClient, RequestParams} from './http-client'

export class UniformProcessMetricsApi {
  /**
   * No description
   *
   * @tags UniformProcessMetrics
   * @name GetprocessMetricsUniformList
   * @request GET:/predict/processMetrics/uniform
   * @secure
   */
  static getprocessMetricsUniformList = (params: RequestParams = {}) =>
    genApiClient.request<UniformProcessMetric[], ProblemDetails | void>({
      path: `/predict/processMetrics/uniform`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags UniformProcessMetrics
   * @name ProcessMetricsUniformCreate
   * @request POST:/predict/processMetrics/uniform
   * @secure
   */
  static processMetricsUniformCreate = (data: UniformProcessMetric, params: RequestParams = {}) =>
    genApiClient.request<UniformProcessMetric, ProblemDetails | void>({
      path: `/predict/processMetrics/uniform`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags UniformProcessMetrics
   * @name GetprocessMetricsUniform
   * @request GET:/predict/processMetrics/uniform/{uniformTag}
   * @secure
   */
  static getprocessMetricsUniform = (uniformTag: string, params: RequestParams = {}) =>
    genApiClient.request<UniformProcessMetric, ProblemDetails | void>({
      path: `/predict/processMetrics/uniform/${uniformTag}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags UniformProcessMetrics
   * @name ProcessMetricsUniformUpdate
   * @request PUT:/predict/processMetrics/uniform/{uniformTag}
   * @secure
   */
  static processMetricsUniformUpdate = (
    uniformTag: string,
    data: UniformProcessMetric,
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/processMetrics/uniform/${uniformTag}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags UniformProcessMetrics
   * @name ProcessMetricsUniformDelete
   * @request DELETE:/predict/processMetrics/uniform/{uniformTag}
   * @secure
   */
  static processMetricsUniformDelete = (uniformTag: string, params: RequestParams = {}) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/processMetrics/uniform/${uniformTag}`,
      method: 'DELETE',
      secure: true,
      ...params
    })
}
