/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {PerformanceDto, ProblemDetails, Resolution} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class FreelimePerformanceApi {
  /**
   * No description
   *
   * @tags FreelimePerformance
   * @name GetplantsFreeLimePerformance
   * @summary Returns uptime and error data of the predictions
   * @request GET:/predict/plants/{plantId}/freeLime/performance
   * @secure
   */
  static getplantsFreeLimePerformance = (
    plantId: string,
    query?: {
      /**
       * The production line
       * @example "1"
       */
      productionLineId?: string
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2022-01-17T05:45:18.070Z"
       */
      from?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2022-06-29T07:20:20.070Z"
       */
      until?: string
      /**
       * If omitted daily data will be returned.
       * @default 3
       * @example "Daily"
       */
      resolution?: Resolution
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<PerformanceDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/freeLime/performance`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
