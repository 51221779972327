import {InfoOutlined} from '@mui/icons-material'
import React, {PropsWithChildren} from 'react'

import {Text} from '../Text/Text'

import {PopOver} from '@predict/WebUILib/layouts/PopOver/PopOver'
import {usePopoverAnchor} from '@predict/WebUILib/layouts/PopOver/usePopoverAnchor'

interface InfoPopOverButtonProps extends PropsWithChildren<unknown> {
  'data-test-id'?: string
}

export function InfoPopOverButton({
  children,
  'data-test-id': testId = 'info-pop-over-icon'
}: InfoPopOverButtonProps) {
  const {anchorEl, openPopover, closePopover, isOpen, id} = usePopoverAnchor('info-popover')

  return (
    <Text
      component="span"
      sx={{
        position: 'relative'
      }}
    >
      <InfoOutlined
        aria-describedby={id}
        fontSize="small"
        color="primary"
        onClick={openPopover}
        sx={{
          cursor: 'pointer',
          verticalAlign: 'middle'
        }}
        data-test-id={testId}
      />
      <PopOver id={id} isOpen={isOpen} anchorEl={anchorEl} onClose={closePopover}>
        {children}
      </PopOver>
    </Text>
  )
}
