import React from 'react'
import {useTranslation} from 'react-i18next'

import {SensorMetaData, useDeleteMappedSensorMetaDataMutation} from '@predict/DomainsLib/sensorData'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

interface MappedSensorMetaDataDeleteDialogProps {
  open?: boolean
  item: SensorMetaData
  title?: string
  onClose?: () => void
}

export function MappedSensorMetaDataDeleteDialog({
  item,
  open = false,
  title = '',
  onClose
}: MappedSensorMetaDataDeleteDialogProps) {
  const {t} = useTranslation()
  const {mutate, isLoading} = useDeleteMappedSensorMetaDataMutation(
    useSnackbarMutationInjector(
      t('mappedSensorMetaDataDeleteDialog.deletionSucceeded'),
      t('mappedSensorMetaDataDeleteDialog.deletionFailed')
    )
  )

  const onDelete = () => {
    mutate(item, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  return (
    <GeneralDialog
      title={title}
      isOpen={open}
      onClose={onClose}
      data-test-id="mapped-sensor-meta-data-delete-dialog"
      actions={
        <DialogBasicActions
          onCancel={onCancel}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteTestId="mapped-sensor-meta-data-delete-dialog-delete-btn"
        />
      }
    >
      <Text>
        {t('mappedSensorMetaDataDeleteDialog.deleteQuestion')}
        <br />
        <b>{item.plantSpecificTag}</b>: {item.uniformTag}
      </Text>
    </GeneralDialog>
  )
}
