import {Shell} from '@hconnect/uikit/src/lib2'
import React, {PropsWithChildren} from 'react'

import {ShellFooter} from './ShellFooter'
import {ShellHeader, ShellHeaderProps} from './ShellHeader'

export type {NavItem} from '@hconnect/uikit/src/lib2'

type PredictShellProps = PropsWithChildren<ShellHeaderProps> &
  (
    | {
        isFooterInHeader: true
        footerCopyrightNote: string
        footerLabel?: never
      }
    | {
        isFooterInHeader?: false
        footerCopyrightNote?: string
        footerLabel: string
      }
  )

export function PredictShell({
  isFooterInHeader = false,
  footerLabel,
  footerCopyrightNote,
  plantId,
  plantName,
  permissions,
  env,
  user,
  onLogout,
  supportedLanguages,
  navItems,
  additionalActions,
  children
}: PredictShellProps) {
  return (
    <Shell
      isResponsive
      boxed={false}
      header={
        <ShellHeader
          plantName={plantName}
          plantId={plantId}
          user={user}
          permissions={permissions}
          onLogout={onLogout}
          env={env}
          supportedLanguages={supportedLanguages}
          navItems={navItems}
          footerCopyrightNote={isFooterInHeader ? footerCopyrightNote : undefined}
          additionalActions={additionalActions}
        />
      }
      footer={
        !isFooterInHeader ? (
          <ShellFooter label={String(footerLabel)} copyrightNote={footerCopyrightNote} />
        ) : undefined
      }
    >
      {children}
    </Shell>
  )
}
