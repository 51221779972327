import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, {useCallback, useMemo} from 'react'

import {replaceNonWordCharacters} from '@predict/UtilsLib/general'

export interface MultiSelectorItem<T> {
  label: string
  value?: T
}

interface MultiSelectorProps<T> {
  inputLabel: string
  labeledItems: MultiSelectorItem<T>[]
  selectedItems: T[]
  onSelect: (value: T[]) => void
  id?: string
  disabled?: boolean
}

export function MultiSelector<T>({
  inputLabel,
  labeledItems,
  selectedItems,
  onSelect,
  disabled,
  id = 'multi-select'
}: MultiSelectorProps<T>) {
  const selectedLabeledItems = useMemo(
    () => labeledItems.filter(({value}) => value && selectedItems.includes(value)),
    [labeledItems, selectedItems]
  )
  const selectedLabels: string[] = selectedLabeledItems.map((item) => item.label)

  const onSelectionChanged = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const newSelectedLabels = event.target.value
      const newSelectedItems = labeledItems
        .filter(({label, value}) => value && newSelectedLabels.includes(label))
        .map(({value}) => value) as T[]
      onSelect(newSelectedItems)
    },
    [labeledItems, onSelect]
  )

  return (
    <FormControl
      id={id}
      sx={{
        marginTop: 0,
        marginBottom: 1,
        width: '160px',
        maxWidth: '100%'
      }}
      variant="standard"
    >
      <InputLabel id={`${id}-label`}>{inputLabel}</InputLabel>
      <Select
        multiple
        variant="standard"
        value={selectedLabels}
        onChange={onSelectionChanged}
        renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
        MenuProps={{
          PaperProps: {
            id: `${id}-paper-id`
          },
          MenuListProps: {
            disablePadding: true
          }
        }}
        disabled={disabled}
        id={`${id}-id`}
        labelId={`${id}-label`}
        data-test-id={`${id}-test-id`}
      >
        {labeledItems.map(({label, value}) =>
          value ? (
            <MenuItem key={label} value={label}>
              <Checkbox
                color="secondary"
                data-test-id={`${id}-cb-${replaceNonWordCharacters(label)}`}
                checked={selectedLabels.includes(label)}
              />
              <ListItemText primary={label} />
            </MenuItem>
          ) : (
            <ListSubheader
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.background.paper
                    : theme.palette.grey[50],
                color: theme.palette.text.primary
              })}
              key={label}
            >
              {label}
            </ListSubheader>
          )
        )}
      </Select>
    </FormControl>
  )
}
