import {getPermissionsByUserId, User} from '@hconnect/apiclient'

import {PredictUser} from '../declarations/PredictUser'

import {client} from '@predict/DomainsLib/backend'

export const initUser = async (): Promise<PredictUser | undefined> => {
  const {dfApi, dfLoginFlow} = client
  const loginResponse = await dfLoginFlow.getLoginState()

  if (!loginResponse.loggedIn) {
    return undefined
  }

  const {user_id: userId, email} = loginResponse.decodedToken
  if (!userId || !email) {
    return undefined
  }

  const response = await Promise.all([
    dfApi.get<User>(`/users/${userId}`),
    getPermissionsByUserId(dfApi)(userId)
  ])

  const [userData, permissions] = response
  const pUser: User | undefined = userData?.data
  if (!pUser?.name) {
    return undefined
  }

  return {userId, email, name: pUser.name, roles: pUser.hasRoles ?? [], permissions}
}
