import {render} from '@testing-library/react'
import * as d3 from 'd3'
import React from 'react'

import {DateTimeRecord} from '@predict/UtilsLib/dateTime'

export function renderSvg(ui: React.ReactNode) {
  const result = render(<svg>{ui}</svg>)
  return {...result, container: result.container.firstElementChild as HTMLElement}
}

export const IS_TEST_ENV = process.env.NODE_ENV === 'test'

export const SIMPLE_TICKS = [1, 2, 3, 4, 5]

export const DEFAULT_RANGE = {
  min: -5,
  max: 5
}
export const DEFAULT_RANGE_ARRAY: [number, number] = [DEFAULT_RANGE.min, DEFAULT_RANGE.max]

export function generateMonthlyDates(years = 1): Date[] {
  const dates: Date[] = []
  for (let year = 0; year <= years; year++) {
    for (let month = 1; month <= 12; month++) {
      dates.push(new Date(`202${year}-${month}-1`))
    }
  }
  return dates
}

export function generateMonthlyTimestamps(years = 1): number[] {
  return generateMonthlyDates(years).map((d) => d.getTime())
}

export function generateDateTimeRecords(
  timestamps: number[] | Date[],
  minValue = DEFAULT_RANGE.min,
  maxValue = DEFAULT_RANGE.max
): DateTimeRecord[] {
  const padding = Math.round((maxValue - minValue) / 5)
  return timestamps.map((timestamp: number | Date) => ({
    datetime: +timestamp,
    value: Math.round(Math.random() * (maxValue - minValue - 2 * padding)) + minValue + padding
  }))
}

export function generateTimeScale(domain: number[], dimension: number, isX = true) {
  return d3
    .scaleUtc<number>()
    .domain(domain)
    .range([isX ? 0 : dimension, isX ? dimension : 0])
}

export function generateBandScale(domain: number[], dimension: number, isX = true) {
  return d3
    .scaleBand<number>()
    .domain(domain)
    .range([isX ? 0 : dimension, isX ? dimension : 0])
}

export function generateLinearScale(range: [number, number], dimension: number, isX = true) {
  return d3
    .scaleLinear()
    .domain(range)
    .range([isX ? 0 : dimension, isX ? dimension : 0])
}
