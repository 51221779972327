/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {CementQualityDetailOutput, CementQualityDto, ProblemDetails} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class QualityDataApi {
  /**
   * No description
   *
   * @tags QualityData
   * @name GetplantsCementQualities
   * @summary Get quality overview data
   * @request GET:/predict/plants/{plantId}/cementQualities
   * @secure
   */
  static getplantsCementQualities = (
    plantId: string,
    query?: {
      /**
       * unified material id
       * @format int32
       * @example 3
       */
      unifiedMaterialId?: number
      sampleIdPlant?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<CementQualityDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementQualities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags QualityData
   * @name GetplantsCementQualitiesDetails
   * @summary Get detailed quality data
   * @request GET:/predict/plants/{plantId}/cementQualities/details
   * @secure
   */
  static getplantsCementQualitiesDetails = (
    plantId: string,
    query?: {
      /**
       * unified material id
       * @format int32
       * @example 3
       */
      unifiedMaterialId?: number
      sampleIdPlant?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<CementQualityDetailOutput[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementQualities/details`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
