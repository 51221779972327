import {intersection, isNumber, sortBy} from 'lodash'

import {levelPredictionMap, levelStrengthMap} from '../constants'
import {
  AggregatedMaterialData,
  CsMaterialSample,
  MaterialData,
  StrengthLevel
} from '../declarations/MaterialData'

function strengthValueFilter(strengthLevel?: StrengthLevel): (CementStrengthSample) => boolean {
  if (strengthLevel) {
    return (sample: CsMaterialSample) =>
      isNumber(sample[levelPredictionMap[strengthLevel]]) ||
      isNumber(sample[levelStrengthMap[strengthLevel]])
  }
  return () => true
}

function aggregateSamples(
  materialData: MaterialData,
  sourcesToAggregate: string[] = []
): AggregatedMaterialData {
  const {materialId, materialName, sampleSources} = materialData

  const sourceNames = sampleSources.map((source) => source.name)
  const aggregatedSourceNames = intersection(sourceNames, sourcesToAggregate)
  const merged = sampleSources
    .filter((sourceData) => aggregatedSourceNames.includes(sourceData.name))
    .flatMap((md) => md.samples)

  const samples = sortBy(merged, 'datetime')

  return {
    materialId,
    materialName,
    samples
  }
}

export function aggregateMaterialData(
  materialData: MaterialData,
  options: {sourcesToAggregate?: string[]; strengthLevel?: StrengthLevel}
): AggregatedMaterialData {
  const aggregated = aggregateSamples(materialData, options.sourcesToAggregate)
  return {
    ...aggregated,
    samples: aggregated.samples.filter(strengthValueFilter(options.strengthLevel))
  }
}
