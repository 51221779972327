import {useMemo} from 'react'

import {FreeLimeSettings} from '../../declarations/FreeLimeSettings'

import {usePlantFreeLimeConfig} from './usePlantFreeLimeConfig'

export function usePlantFreeLimeSettings(): FreeLimeSettings {
  const {defaultTimeDurationFreeLime, axisMin, axisMax, min, max, target} = usePlantFreeLimeConfig()
  return useMemo(
    () => ({
      axisRange: {min: axisMin, max: axisMax},
      targetRange: {min, max, target},
      defaultTimeDurationFreeLime
    }),
    [axisMax, axisMin, defaultTimeDurationFreeLime, max, min, target]
  )
}
