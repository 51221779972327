import {isUndefined} from 'lodash'
import {useCallback, useMemo} from 'react'

import {CheckboxState} from './types'

import {addOrRemove, includesAll} from '@predict/UtilsLib/general'

export function useSelectedItems<D>(
  allItems: D[] = [],
  selectedItems: D[] = [],
  onSelectItems?: (selectedItems: D[]) => void
) {
  const selectAllState: CheckboxState = useMemo(() => {
    const numSelected = selectedItems.length
    const rowCount = allItems.length
    if (numSelected > 0 && numSelected < rowCount) {
      return 'indeterminate'
    }
    if (rowCount > 0 && numSelected === rowCount) {
      return 'checked'
    }
    return 'unchecked'
  }, [allItems.length, selectedItems.length])

  const onItemsSelect = useCallback(
    (selections: {item: D; isSelected: boolean}[]) => {
      if (!isUndefined(onSelectItems)) {
        let newSelectedItems = selectedItems
        selections.forEach(({item, isSelected}) => {
          newSelectedItems = addOrRemove(newSelectedItems, item, isSelected)
        })
        onSelectItems(newSelectedItems)
      }
    },
    [onSelectItems, selectedItems]
  )

  const onSelectAll = useCallback(() => {
    onSelectItems?.(includesAll(selectedItems, allItems) ? [] : allItems)
  }, [allItems, onSelectItems, selectedItems])

  return {
    selectAllState,
    onItemsSelect,
    onSelectAll
  }
}
