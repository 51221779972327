import {AppLogo} from '@hconnect/uikit/src/common'
import {styled} from '@mui/material'
import React from 'react'

interface Props {
  maxSize?: number // will be used as pixel for width and hight,
}

const Logo = styled(AppLogo)({
  height: '100%',
  maxWidth: '100%'
})

export function PredictLogo({maxSize}: Props) {
  return <Logo name="Predict" sx={{width: maxSize}} size={maxSize} />
}
