export const FREELIME_TAG = 'clinker:FreeLime'

export const PREDEFINED_CHART_TAGS = [
  'clinker:LSF',
  FREELIME_TAG,
  'clinker:Alite',
  'clinker:Belite'
]

export const PREDEFINED_TABLE_TAGS = [
  'clinker:LSF',
  'clinker:SR',
  'clinker:AR',
  FREELIME_TAG,
  'clinker:Alite',
  'clinker:Belite',
  'clinker:Aluminate',
  'clinker:Ferrite'
]
