import {isEqual} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {usePrevious} from 'react-use'

import type {HasDisabled, TargetRangeSettingsEntry} from '../declarations/helperTypes'

import {useFreeLimeSettingsMutation, usePlantFreeLimeConfig} from '@predict/DomainsLib/freeLime'
import {isValidTargetRange} from '@predict/UtilsLib/general'
import type {TargetRange} from '@predict/UtilsLib/general'
import {FormBox} from '@predict/WebUILib/layouts/FormBox/FormBox'
import {NumberField} from '@predict/WebUILib/molecules/NumberField/NumberField'
import {GeneralTable} from '@predict/WebUILib/organisms/GeneralTable/GeneralTable'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

export function FreeLimeTargetSettings({disabled}: HasDisabled) {
  const {t} = useTranslation()
  const {min, max, target} = usePlantFreeLimeConfig()
  const backendEntry = useMemo<TargetRangeSettingsEntry>(
    () => ({
      id: 'freeLimeTargetRange',
      name: t('freeLimeTargetSettings.freeLimeLabel'),
      min,
      max,
      target
    }),
    [max, min, t, target]
  )
  const prevBeEntry = usePrevious(backendEntry)
  const [targetRangeEntry, setTargetRangeEntry] = useState<TargetRangeSettingsEntry>(backendEntry)
  const isValid = isValidTargetRange(targetRangeEntry)
  const isPristine = isEqual(backendEntry, targetRangeEntry)
  const {isLoading, mutate} = useFreeLimeSettingsMutation(
    useSnackbarMutationInjector(t('settings.settingsSaved'), t('settings.settingsNotSavedError'))
  )

  useEffect(() => {
    if (!isEqual(prevBeEntry, backendEntry)) {
      setTargetRangeEntry(backendEntry)
    }
  }, [backendEntry, prevBeEntry])

  const onNumberChanged = useCallback(
    (entry: TargetRangeSettingsEntry, value: number | undefined, field: keyof TargetRange) => {
      const newEntries = {
        ...entry,
        [field]: value
      }
      setTargetRangeEntry(newEntries)
    },
    []
  )

  const unit = t('freeLimeTargetSettings.tableHeaderUnit')

  const onSave = () => {
    if (isLoading || isPristine || !isValid) return

    mutate({targetRange: targetRangeEntry})
  }

  return (
    <FormBox
      title={t('freeLimeTargetSettings.title')}
      onRevert={() => {
        setTargetRangeEntry(backendEntry)
      }}
      onSave={onSave}
      disabled={isPristine}
      isFormInvalid={!isValid}
      isSaving={isLoading}
      data-test-id="free-lime-target-settings-form-box"
    >
      <GeneralTable
        tableId="free-lime-target-settings-table"
        columnsConfiguration={[
          {
            name: 'name',
            label: t('freeLimeTargetSettings.tableHeaderLabel')
          },
          {
            name: 'min',
            label: t('targetRangeTableForm.minHeader', {unit}),
            alignment: 'right'
          },
          {
            name: 'target',
            label: t('targetRangeTableForm.targetHeader', {unit}),
            alignment: 'right'
          },
          {
            name: 'max',
            label: t('targetRangeTableForm.maxHeader', {unit}),
            alignment: 'right'
          }
        ]}
        data={[targetRangeEntry]}
        cellBuilders={{
          name: ({name}) => name,
          min: (entry) => (
            <NumberField
              value={entry.min}
              error={!isValid}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'min')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-min-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-min-input-${entry.id}`
              }}
            />
          ),
          target: (entry) => (
            <NumberField
              value={entry.target}
              error={!isValid}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'target')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-target-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-target-input-${entry.id}`
              }}
            />
          ),
          max: (entry) => (
            <NumberField
              value={entry.max}
              error={!isValid}
              onNumberChange={(value) => {
                onNumberChanged(entry, value, 'max')
              }}
              disabled={disabled}
              data-test-id={`target-range-row-max-field-${entry.id}`}
              inputProps={{
                'data-test-id': `target-range-row-max-input-${entry.id}`
              }}
            />
          )
        }}
      />
    </FormBox>
  )
}
