import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'

import {App} from './App'

import {initLocalization} from '@predict/localization'
import {AppInsightsAdapter} from '@predict/UtilsLib/logger'
import {MainLoader} from '@predict/WebUILib/molecules/Loader/MainLoader'

initLocalization()
AppInsightsAdapter.init()
// Tracking information and logs will be sent to azure
AppInsightsAdapter.allowTracking()

ReactDOM.render(
  <Suspense fallback={<MainLoader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
)
