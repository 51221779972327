import {isEqual} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  SensorMetaData,
  isSensorMetaDataPlantSpecificTagValid,
  isSensorMetaDataValid,
  useUpdateSensorMetaDataMutation
} from '@predict/DomainsLib/sensorData'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {GeneralDialog} from '@predict/WebUILib/layouts/GeneralDialog/GeneralDialog'
import {DialogBasicActions} from '@predict/WebUILib/molecules/DialogBasicActions/DialogBasicActions'
import {TextField} from '@predict/WebUILib/molecules/TextField/TextField'
import {SingleValueTable} from '@predict/WebUILib/organisms/SingleValueTable/SingleValueTable'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

interface SensorMetaDataDialogProps {
  open?: boolean
  item: SensorMetaData
  title?: string
  onClose?: () => void
}

const showError = (
  currentValue: string,
  originalValue: string,
  isValid: (value: string) => boolean
): boolean => currentValue !== originalValue && !isValid(currentValue)

export function MappedSensorMetaDataDialog({
  item,
  open = false,
  title = '',
  onClose
}: SensorMetaDataDialogProps) {
  // Visuals
  const {t} = useTranslation()
  // State
  const [plantTag, setPlantTag] = useState(item.plantSpecificTag)
  const itemToSave: SensorMetaData = {
    ...item,
    plantSpecificTag: plantTag
  }
  const isPristine = isEqual(item, itemToSave)
  // Call backs
  const {mutate, isLoading} = useUpdateSensorMetaDataMutation(
    useSnackbarMutationInjector(
      t('sensorMetaDataDialog.updateSuccessful'),
      t('sensorMetaDataDialog.updateFailed')
    )
  )
  const onSave = () => {
    mutate(itemToSave, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  const onEnter = () => {
    setPlantTag(item.plantSpecificTag)
  }

  return (
    <GeneralDialog
      title={title}
      isOpen={open}
      onClose={onClose}
      data-test-id={`process-meta-data-dialog-${item.uniformTag}`}
      onEnter={onEnter}
      actions={
        <DialogBasicActions
          onCancel={onCancel}
          onSave={onSave}
          saveBtnLabel={t('button.save')}
          saveDisabled={!isSensorMetaDataValid(itemToSave) || isPristine}
          isLoading={isLoading}
        />
      }
    >
      <TextField
        fullWidth
        data-test-id="process-meta-data-dialog-plant-tag-field"
        placeholder={t('processMetaData.plantSpecificTag')}
        label={t('processMetaData.plantSpecificTag')}
        value={plantTag}
        onChange={(event) => {
          setPlantTag(event.target.value)
        }}
        required
        error={showError(plantTag, item.plantSpecificTag, isSensorMetaDataPlantSpecificTagValid)}
        helperText={
          showError(plantTag, item.plantSpecificTag, isSensorMetaDataPlantSpecificTagValid)
            ? t('form.required')
            : undefined
        }
      />
      <Spacing height={3} />
      <SingleValueTable
        tableId="data-table"
        data={[
          {
            value: item.uniformTag,
            label: t('processMetaData.uniformTag')
          },
          {
            value: item.unit,
            label: t('processMetaData.unit')
          },
          {
            value: item.description,
            label: t('processMetaData.description')
          }
        ]}
        cellBuilders={{
          value: ({value}) => value || t('common.na'),
          label: ({label}) => label
        }}
      />
    </GeneralDialog>
  )
}
