import {AxiosInstance} from 'axios'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {WhiteList} from '@predict/UtilsLib/antiCorruptionLayers/authenticationAcl'
import {
  Translations,
  Notifications as CommonNotifications,
  TranslationKey,
  TranslationActionKey,
  NotificationsOptions,
  generateNotificationChannels,
  ApiProvider
} from '@predict/UtilsLib/antiCorruptionLayers/notificationsAcl'

const NOTIFICATIONS_TRANSLATION_KEYS: Translations = {
  label: {
    [TranslationKey.TODAY]: 'notifications.label.today',
    [TranslationKey.YESTERDAY]: 'notifications.label.yesterday',
    [TranslationKey.NOTIFICATIONS]: 'notifications.label.notifications',
    [TranslationKey.EMPTY]: 'notifications.label.empty',
    [TranslationKey.ERROR]: 'notifications.error.notificationsNotFetched',
    [TranslationKey.SHOULD_DELETE]: 'notifications.label.shouldDeleteNotification'
  },
  action: {
    [TranslationActionKey.DELETE]: 'notifications.action.delete',
    [TranslationActionKey.CONFIRM_DELETE]: 'notifications.action.confirmDelete',
    [TranslationActionKey.CANCEL]: 'notifications.action.cancel'
  }
}

interface NotificationsProps {
  whiteList: WhiteList
  timeZone?: string
  apiClient: AxiosInstance
  secureApiClient?: AxiosInstance
}

export function Notifications({
  whiteList,
  timeZone,
  apiClient,
  secureApiClient
}: NotificationsProps): JSX.Element {
  const {
    i18n: {language}
  } = useTranslation()

  const options = useMemo<NotificationsOptions>(() => {
    const channels = generateNotificationChannels(whiteList)
    return {
      channels,
      countryCode: language.toUpperCase().split('-')[0]
    }
  }, [language, whiteList])

  return (
    <ApiProvider secureApi={secureApiClient ?? null} publicApi={apiClient}>
      <CommonNotifications
        key="notification-provider"
        options={options}
        translations={NOTIFICATIONS_TRANSLATION_KEYS}
        timezone={timeZone}
      />
    </ApiProvider>
  )
}
