import type {TFunction} from 'i18next'

import {
  getActualValues,
  getPredictedValues,
  CsMaterialSample,
  StrengthLevel
} from '@predict/DomainsLib/cementStrength'
import type {LabData} from '@predict/DomainsLib/labData'
import {COLOR_ACTUAL_VALUES, COLOR_PREDICTIONS} from '@predict/UtilsLib/general/constants'
import {ChartData} from '@predict/WebUILib/Charts/helpers'

export function createChartDataFromCementStrengthSamples(
  samples: CsMaterialSample[],
  strengthLevel: StrengthLevel,
  t: TFunction
): ChartData {
  return {
    id: strengthLevel,
    name: t(`chart.selectableCsFields.${strengthLevel}.label`),
    color: COLOR_ACTUAL_VALUES,
    predictionColor: COLOR_PREDICTIONS,
    unit: t(`chart.selectableCsFields.${strengthLevel}.unit`),
    actualValues: getActualValues(samples, strengthLevel),
    predictions: getPredictedValues(samples, strengthLevel)
  }
}

export function createChartDataFromLabMatData(labData: LabData[]): ChartData[] {
  return labData.map(({uniformTag, unit, displayName, records}) => ({
    id: uniformTag,
    name: displayName,
    color: COLOR_ACTUAL_VALUES,
    unit,
    actualValues: records
  }))
}
