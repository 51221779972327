import Color from 'color'
import str from 'string-to-color'

import {COLOR_ACTUAL_VALUES} from '../../constants'

export enum ContrastLevels {
  none = 0,
  AA = 4.5,
  AAA = 7
}

const FIXED_COLORS = {
  strength1: COLOR_ACTUAL_VALUES,
  strength2: COLOR_ACTUAL_VALUES,
  strength3: COLOR_ACTUAL_VALUES,
  strength7: COLOR_ACTUAL_VALUES,
  strength28: COLOR_ACTUAL_VALUES
}

// We should aim for at least WCAG-AA contrast ratio to ensure readability
// Read more: https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#contrast-minimum
export function toColor(
  input: unknown,
  contrastLevel: ContrastLevels = ContrastLevels.AA,
  contrastColor = 'white'
) {
  if (typeof input === 'string' && Object.prototype.hasOwnProperty.call(FIXED_COLORS, input)) {
    return FIXED_COLORS[input]
  }
  const inputColor = Color(str(input))

  let outputColor = inputColor
  // TODO: @predict please fix the following eslint issue
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  while (outputColor.contrast(Color(contrastColor)) < contrastLevel) {
    outputColor = outputColor.darken(0.1)
  }

  return outputColor.hex()
}
