import {Theme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import Color from 'color'

function calcColorPair(
  color: string,
  lightTextColor: string,
  darkTextColor: string
): {textColor: string; backgroundColor: string} {
  if (!color) {
    throw new Error('color may not be empty!')
  }
  const baseColor = Color(color)
  const textColor = baseColor.isLight() ? lightTextColor : darkTextColor
  return {textColor, backgroundColor: color}
}

export function calcButtonColorStyle(
  color: string,
  lightTextColor: string,
  darkTextColor: string,
  colorizeWhenDisabled = false
): SystemStyleObject<Theme> {
  if (color) {
    const {textColor, backgroundColor} = calcColorPair(color, lightTextColor, darkTextColor)
    const bgColor = Color(backgroundColor)
    return {
      backgroundColor,
      color: textColor,
      '&.Mui-disabled': colorizeWhenDisabled
        ? {
            color: textColor
          }
        : {
            color: Color(textColor).mix(Color('#808080'), 0.25).string(),
            backgroundColor: bgColor.grayscale().mix(Color('#808080'), 0.25).string()
          },
      '&:hover': {
        backgroundColor
      }
    }
  }
  return {}
}
