export const createFilterTerms = (search: string): string[] =>
  search
    .split(/\s+/)
    .map((str) => str.toLowerCase())
    .filter((str) => str.length > 1)

export const matchesSome = (text: string, filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const preProcessedText = text.toLowerCase()
  return filter.some((filterTerm) => preProcessedText.includes(filterTerm))
}

export const matchesAll = (text: string, filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const preProcessedText = text.toLowerCase()
  return filter.every((filterTerm) => preProcessedText.includes(filterTerm))
}

export const someMatchAll = (texts: string[], filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const textsLower = texts.map((text) => text.toLowerCase())
  return filter.every((filterTerm) => textsLower.some((text) => text.includes(filterTerm)))
}
