import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {DefaultOptions, QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'

import {Main} from './Main'
import {HelmetHead} from './modules/general'

import {AuthProvider} from '@predict/DomainsLib/users'
import {
  CssBaseline,
  PredictThemeProvider
} from '@predict/WebUILib/helpers/providers/PredictThemeProvider'
import {PromptProvider} from '@predict/WebUILib/organisms/PromptProvider'
import {SnackbarProvider} from '@predict/WebUILib/organisms/SnackbarProvider'

const DEFAULT_QUERY_STALE_TIME_MS = 300_000
const defaultOptions: DefaultOptions = {
  queries: {
    staleTime: DEFAULT_QUERY_STALE_TIME_MS,
    keepPreviousData: true
  }
}
const queryClient = new QueryClient({defaultOptions})

export function App() {
  const {t} = useTranslation()

  return (
    <HelmetProvider>
      <HelmetHead title={t('appName')} />
      <PredictThemeProvider>
        <CssBaseline />
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <PromptProvider>
                <AuthProvider>
                  <Main />
                </AuthProvider>
              </PromptProvider>
            </Router>
          </QueryClientProvider>
        </SnackbarProvider>
      </PredictThemeProvider>
    </HelmetProvider>
  )
}
