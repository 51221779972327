import i18next from 'i18next'
import {isNumber} from 'lodash'

import {levelPredictionMap, levelStrengthMap} from '../constants'
import {CsMaterialSample, StrengthLevel} from '../declarations/MaterialData'

import {DateTimeRecord} from '@predict/UtilsLib/dateTime'
import {formatFloat} from '@predict/UtilsLib/general'

export function getActualValues(
  samples: CsMaterialSample[],
  level: StrengthLevel
): DateTimeRecord[] {
  return samples
    .map((s) => ({
      datetime: s.datetime,
      value: s[levelStrengthMap[level]]
    }))
    .filter((s) => isNumber(s.value)) as DateTimeRecord[]
}

export function getPredictedValues(
  samples: CsMaterialSample[],
  level: StrengthLevel
): DateTimeRecord[] {
  return samples
    .map((s) => ({
      datetime: s.datetime,
      value: s[levelPredictionMap[level]]
    }))
    .filter((s) => isNumber(s.value)) as DateTimeRecord[]
}

// Sample finders
export function findPrevSample(
  samples: CsMaterialSample[],
  sampleId?: string
): CsMaterialSample | undefined {
  if (!sampleId || samples.length === 0) {
    return undefined
  }
  const idx = samples.findIndex((s) => s.id === sampleId)
  return idx === -1 ? undefined : samples[idx - 1]
}

export function findNextSample(
  samples: CsMaterialSample[],
  sampleId?: string
): CsMaterialSample | undefined {
  if (!sampleId || samples.length === 0) {
    return undefined
  }
  const idx = samples.findIndex((s) => s.id === sampleId)
  return idx === -1 ? undefined : samples[idx + 1]
}

export function getFormattedPredictedValue(
  sample: CsMaterialSample | undefined,
  level: StrengthLevel,
  fallback: string,
  locale: string = i18next.language
): string {
  const value = sample?.[levelPredictionMap[level]]
  return isNumber(value) ? formatFloat(value, {locale}) : fallback
}

export function getFormattedActualValue(
  sample: CsMaterialSample | undefined,
  level: StrengthLevel,
  fallback: string,
  locale: string = i18next.language
): string {
  const value = sample?.[levelStrengthMap[level]]
  return isNumber(value) ? formatFloat(value, {locale}) : fallback
}
