import {isString, orderBy} from 'lodash'

import {KilnLabData} from '../declarations/labData'

import {dtoToKilnLabMetaData} from './dtoToKilnLabMetaData'

import {LabMetric} from '@predict/DomainsLib/gen-api/data-contracts'
import {TimeRange} from '@predict/UtilsLib/dateTime'

export const dtoToKilnLabData = (
  plantId: string,
  productionLineId: string,
  materialType: string,
  metricsDto: LabMetric[],
  range: TimeRange
): KilnLabData => {
  return {
    range,
    plantId,
    productionLineId,
    materialType,
    data:
      metricsDto
        ?.filter((dto) => isString(dto.plantSpecificTag))
        .map((metric) => ({
          ...dtoToKilnLabMetaData(metric),
          materialType: metric.materialType?.trim() || materialType,
          // Make sure records are ordered by datetime
          // this fix weird chart behavior
          records: orderBy(
            metric.timeseries.map(({value, timestamp, sampleId}) => ({
              value: Number(value),
              // convert to milliseconds
              datetime: +new Date(timestamp),
              sampleId
            })) ?? [],
            ['datetime']
          )
        })) ?? []
  }
}
