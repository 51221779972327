import {Lock} from '@mui/icons-material'
import React from 'react'

import {ColorButton, ColorButtonProps} from '../ColorButton/ColorButton'
import {LoaderIcon} from '../LoaderIcon/LoaderIcon'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface LoadingButtonProps extends ColorButtonProps {
  loading?: boolean
  btnClassName?: string
  progressClassName?: string
}

export function LoadingButton({
  loading,
  children,
  btnClassName,
  progressClassName,
  startIcon,
  disabled,
  ...otherProps
}: LoadingButtonProps) {
  return (
    <Box display="inline-block" position="relative">
      <ColorButton
        disabled={loading || disabled}
        startIcon={loading || disabled ? <Lock /> : startIcon}
        {...otherProps}
        className={btnClassName}
      >
        {children}
      </ColorButton>
      {loading && (
        <LoaderIcon
          size={24}
          className={progressClassName}
          sx={{
            position: 'absolute',
            bottom: '9px',
            left: '50%',
            marginLeft: -1.5
          }}
        />
      )}
    </Box>
  )
}
