import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PredictPageId} from '../definitions/routeTypes'
import {ADMIN_PATH} from '../routes'
import {getPageUrl} from '../utils/getPageUrl'

import {usePlantParameter} from './routeParams/usePlantParameter'

import {NavItem} from '@predict/WebUILib/layouts/PredictShell/PredictShell'

interface NestedPageIds {
  page: PredictPageId
  activateOnlyIfExact?: boolean
  subPages?: NestedPageIds[]
}
const PAGES_TREE: NestedPageIds[] = [
  {page: 'clinker'},
  {page: 'cement'},
  {page: 'userManual'},
  {page: 'settings'}
]
export function useNavItems(showAdminPages = false): NavItem[] {
  const {t} = useTranslation()
  const plantParam = usePlantParameter()

  const mapper = useCallback(
    (item: NestedPageIds): NavItem => ({
      label: t(`navigation.${item.page}`),
      url: getPageUrl(item.page, {plant: plantParam}),
      dataTestId: `nav-link-${item.page}`,
      subItems: item.subPages?.map(mapper),
      activateOnlyIfExact: item.activateOnlyIfExact ?? false
    }),
    [plantParam, t]
  )

  return useMemo(() => {
    const adminNavItems = showAdminPages
      ? [
          {
            label: t('navigation.admin'),
            url: ADMIN_PATH,
            dataTestId: 'nav-link-admin'
          }
        ]
      : []
    const plantItems = plantParam ? PAGES_TREE.map(mapper) : []
    const navItems = [...plantItems, ...adminNavItems]
    return navItems
  }, [mapper, plantParam, showAdminPages, t])
}
