import React from 'react'

import {COLOR_ACCURACY_DIAGONAL, COLOR_TARGET} from '@predict/WebUILib/Charts/helpers'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'

interface AccuracyGraphBackgroundProps {
  height: number
  width: number
  targetPos: {x: number; y: number}
  rangeOffset: number
}

export function AccuracyTargetLines({
  height,
  width,
  targetPos,
  rangeOffset
}: AccuracyGraphBackgroundProps) {
  const palette = useThemePalette()
  return (
    <>
      <circle cx={targetPos.x} cy={targetPos.y} r={16} fill={COLOR_TARGET} />
      <line
        x1={0}
        x2={width}
        y1={height + rangeOffset}
        y2={rangeOffset}
        strokeWidth={1}
        stroke={palette.error.main}
        fill="none"
        data-test-id="accuracy-target-lines-lower"
      />
      <line
        x1={0}
        x2={width}
        y1={height - rangeOffset}
        y2={-rangeOffset}
        strokeWidth={1}
        stroke={palette.error.main}
        fill="none"
        data-test-id="accuracy-target-lines-upper"
      />
      <line
        x1={0}
        x2={width}
        y1={height}
        y2={0}
        strokeWidth={2}
        stroke={COLOR_ACCURACY_DIAGONAL}
        fill="none"
        data-test-id="accuracy-target-lines-target"
      />
    </>
  )
}
