import type {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'

import {CementStrengthTargetLevelUpdateSettings} from '../../declarations/CementStrengthUpdateSettings'
import {StrengthLevel} from '../../declarations/MaterialData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {MaterialTargetLevelsApi} from '@predict/DomainsLib/gen-api/MaterialTargetLevels'
import {usePlantId} from '@predict/DomainsLib/plants'
import {dummyMutationInjector, TargetRange} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export const updateCementStrengthTargetLevel = async (
  plantId: string,
  materialId: number,
  strengthLevel: StrengthLevel,
  targetRange: TargetRange
): Promise<void> => {
  const log = logger.context(
    'updateCementStrengthTargetLevel',
    plantId,
    materialId.toString(),
    strengthLevel,
    JSON.stringify(targetRange)
  )
  try {
    await MaterialTargetLevelsApi.plantsSettingsCementStrengthMaterialTargetLevelsUpdate(
      plantId,
      materialId,
      strengthLevel,
      {
        plantId,
        unifiedMaterialId: materialId,
        name: strengthLevel,
        targetValue: targetRange.target,
        lowerBound: targetRange.min,
        upperBound: targetRange.max
      }
    )
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export function useCementStrengthTargetLevelMutation(optionsInjector = dummyMutationInjector) {
  const client = useQueryClient()
  const plantId = usePlantId()

  return useMutation(
    optionsInjector({
      mutationFn: async (mutationArgs: CementStrengthTargetLevelUpdateSettings[]) => {
        for (const {materialId, strengthLevel, targetRange} of mutationArgs) {
          await updateCementStrengthTargetLevel(plantId, materialId, strengthLevel, targetRange)
        }
      },
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetMaterials)
        void client.invalidateQueries(QueryKeyName.GetPlants)
      }
    })
  )
}
