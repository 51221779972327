import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'

import {CementStrengthViewUpdateSettings} from '../../declarations/CementStrengthUpdateSettings'
import {CsSampleType} from '../../declarations/CsSamples'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {
  CementStrengthSettings,
  CementStrengthView,
  SampleTypeSource
} from '@predict/DomainsLib/gen-api/data-contracts'
import {PlantSettingsApi} from '@predict/DomainsLib/gen-api/PlantSettings'
import {usePlantId} from '@predict/DomainsLib/plants'
import {getTimeHorizonIdAsDurationIsoString} from '@predict/UtilsLib/dateTime'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export const CS_SAMPLE_TYPE_TO_SAMPLE_TYPE_SOURCE_MAP: Record<CsSampleType, SampleTypeSource> = {
  mill: SampleTypeSource.Mill,
  silo: SampleTypeSource.Silo,
  dailyAverage: SampleTypeSource.DailyAverage,
  hourlyProduction: SampleTypeSource.HourlyProduction
}

export async function updateCementStrengthSettings(
  plantId: string,
  settings: CementStrengthViewUpdateSettings
): Promise<void> {
  const log = logger.context('updateCementStrengthSettings', plantId, JSON.stringify(settings))
  try {
    const backendSettings: CementStrengthSettings = {
      defaultView: CementStrengthView.TimeSelection,
      defaultTimeHorizon: settings.defaultTimeHorizon,
      defaultTimeDurationCementStrength: getTimeHorizonIdAsDurationIsoString(
        settings.defaultTimeDurationCementStrength
      ),
      defaultSampleTypeSources: settings.defaultSampleTypeSources.map(
        (csSampleType) => CS_SAMPLE_TYPE_TO_SAMPLE_TYPE_SOURCE_MAP[csSampleType]
      )
    }
    await PlantSettingsApi.plantsSettingsCementStrengthUpdate(plantId, backendSettings)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export function useCementStrengthSettingsMutation(optionsInjector = dummyMutationInjector) {
  const client = useQueryClient()
  const plantId = usePlantId()

  return useMutation(
    optionsInjector({
      mutationFn: (mutationArgs: CementStrengthViewUpdateSettings) => {
        return updateCementStrengthSettings(plantId, mutationArgs)
      },
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetPlants)
      }
    })
  )
}
