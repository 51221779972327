import React from 'react'

import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {Tabs, TabsProps} from '@predict/WebUILib/atoms/Tabs/Tabs'
import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface HeaderWithTabsProps<T = string> extends TabsProps<T> {
  title?: string
  'data-test-id'?: string
  titleTestId?: string
  tabsTestId?: string
}

export function HeaderWithTabs<T extends string>({
  title,
  views,
  defaultView,
  selectedView,
  onChange,
  tabLabel,
  'data-test-id': testId,
  titleTestId,
  tabsTestId,
  tabTestId
}: HeaderWithTabsProps<T>) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'flex-start'
        }
      })}
      data-test-id={testId}
    >
      {title && (
        <PageTitle
          data-test-id={titleTestId}
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              marginRight: 3
            }
          })}
        >
          {title}
        </PageTitle>
      )}
      <Tabs
        views={views}
        defaultView={defaultView}
        selectedView={selectedView}
        tabLabel={tabLabel}
        onChange={onChange}
        data-test-id={tabsTestId}
        tabTestId={tabTestId}
        sx={{
          marginTop: -1,
          marginBottom: 1
        }}
      />
    </Box>
  )
}
