import {SensorMetaData} from '../declarations/SensorMetaData'

import {ProcessMetricsMetadataOutput} from '@predict/DomainsLib/gen-api/data-contracts'

export function dtoToSensorMetaData({
  plantSpecificTag,
  uniformTag,
  unit,
  description,
  displayName
}: ProcessMetricsMetadataOutput): SensorMetaData {
  return {
    plantSpecificTag: plantSpecificTag,
    uniformTag: uniformTag ?? '',
    unit: unit ?? '',
    description: (description ?? '').trim(),
    displayName: (displayName || description || '').trim()
  }
}
