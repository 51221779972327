import {HubConnection, ILogger, LogLevel} from '@microsoft/signalr'

import {logger} from '@predict/UtilsLib/logger'

export function startConnection(connection?: HubConnection) {
  return new Promise<void>((resolve, reject) => {
    if (!connection) {
      reject()
    } else {
      void connection
        .start()
        .then(() => resolve())
        .catch((err: Error) => {
          logger.error(err)
          reject(err)
        })
    }
  })
}

export function createSignalRLogger(): ILogger {
  return {
    log(logLevel: LogLevel, message: string) {
      switch (logLevel) {
        case LogLevel.Trace:
          return logger.log(message)
        case LogLevel.Debug:
          return logger.debug(message)
        case LogLevel.Information:
          return logger.info(message)
        case LogLevel.Warning:
          return logger.warning(message)
        case LogLevel.Error:
          return logger.error(message)
        case LogLevel.Critical:
          return logger.critical(message)
        default:
          return
      }
    }
  }
}
