import {isNumber, round} from 'lodash'
import React, {useEffect, useState} from 'react'
import {usePrevious} from 'react-use'

import {TextField, TextFieldProps} from '../TextField/TextField'

import {formatNumber, parseFormattedFloat} from '@predict/UtilsLib/general'

type NumberFieldProps = Omit<TextFieldProps, 'value' | 'defaultValue' | 'onBlur'> & {
  defaultValue?: number
  value?: number
  formatter?: (v?: number) => string
  onNumberChange?: (v?: number) => void
  precision?: number
}

const DEFAULT_PRECISION = 8

const defaultFormatter = (precision: number) => (v?: number) =>
  isNumber(v) ? formatNumber(v, {minimumFractionDigits: 2, maximumFractionDigits: precision}) : ''

const roundValue = (val: number | undefined, precision: number) =>
  isNumber(val) ? round(val, precision) : val

export function NumberField({
  value: unrounded,
  precision = DEFAULT_PRECISION,
  formatter = defaultFormatter(precision),
  onNumberChange,
  inputProps,
  ...remainingProps
}: NumberFieldProps) {
  const value = roundValue(unrounded, precision)
  const previousValue = usePrevious(value)
  const [numValue, setNumValue] = useState(formatter(value))

  useEffect(() => {
    if (previousValue !== value) {
      setNumValue(formatter(value))
    }
  }, [formatter, previousValue, value])

  return (
    <TextField
      value={numValue}
      inputProps={{
        ...inputProps,
        sx: {
          textAlign: 'right'
        }
      }}
      onChange={(event) => {
        setNumValue(event.target.value)
      }}
      onBlur={(event) => {
        const selectedNumber = roundValue(parseFormattedFloat(event.target.value), precision)
        setNumValue(formatter(selectedNumber))
        onNumberChange?.(selectedNumber)
      }}
      {...remainingProps}
    />
  )
}
