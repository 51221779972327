import Color from 'color'

export function generateColors(
  baseColor: string,
  numberOfColors: number,
  darken = true,
  level = 0.2
) {
  if (numberOfColors < 1) {
    throw new Error('Must generate more that 0 colors')
  }

  const colors = [Color(baseColor)]

  for (let index = 1; index < numberOfColors; index++) {
    if (darken) colors.push(colors[index - 1].darken(level))
    else colors.push(colors[index - 1].lighten(level))
  }

  return colors.map((color) => color.hex())
}
