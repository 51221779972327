import {isNil} from 'lodash'
import {useCallback, useMemo} from 'react'

import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

import {DateTimeParam, isValidDateTimeParamsTimeRange} from '@predict/UtilsLib/dateTime'

export function useTimeRangeSearchParams() {
  const [start, setStart] = useTypedURLSearchParam('start', ParamTypes.dateTime)
  const [end, setEnd] = useTypedURLSearchParam('end', ParamTypes.dateTime)

  const isValid = useMemo<boolean>(
    () => !isNil(start) && !isNil(end) && isValidDateTimeParamsTimeRange({start, end}),
    [end, start]
  )

  const setTimeRange = useCallback(
    (start: DateTimeParam, end: DateTimeParam) => {
      setStart(start)
      setEnd(end)
    },
    [setEnd, setStart]
  )
  return {
    start: isValid ? start : undefined,
    end: isValid ? end : undefined,
    setTimeRange
  }
}
