import {Theme} from '@mui/material'

export const MAX_CHART_DATA_POINTS = 400

export const CEMENT_STRENGTH_DOMAIN_MARGIN = 4
export const CEMENT_STRENGTH_OVERVIEW_STEP_SIZE = 2

export const SUGGESTED_TRENDS_TICK_COUNT = 10

export const CEMENT_STRENGTH_ACCURACY_OFFSET = 3

export const PREDICTION_DASHARRAY_SMALL = '4 2'

export const COLOR_TARGET = '#7fd493'
export const COLOR_LIMITS = '#ff4f00'
export const COLOR_TREND_AXIS = '#002e4d'
export const COLOR_TREND_BORDER = '#d4dbe0'
export const COLOR_OVERVIEW_BORDER = '#5e7a8c'
export const COLOR_ACCURACY_DIAGONAL = '#5e7a8c'
export const COLOR_GRID_LINES = '#f5f6f8'

export const hoveredColor = (theme: Theme) => theme.palette.grey[500]
