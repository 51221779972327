import {Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps} from '@mui/material'
import {isNumber} from 'lodash'
import React from 'react'

import {DefaultBox} from '../../layouts/DefaultBox/DefaultBox'

export interface SkeletonProps {
  variant?: NonNullable<MuiSkeletonProps['variant']> | 'table'
  count?: number
  height?: number | string
  minWidth?: number | string
  margin?: number | string
  marginBottom?: number | string
  animation?: NonNullable<MuiSkeletonProps['animation']>
}

const MINIMUM_SKELETON_HEIGHT = 15 / 0.6 // MUI skeleton is scaling height down to 0.6

export function Skeleton({
  variant = 'rectangular',
  count,
  height,
  minWidth,
  margin = 3,
  marginBottom,
  animation = 'wave'
}: SkeletonProps) {
  const nativeVariant: SkeletonProps['variant'] = variant === 'table' ? 'text' : variant
  const totalSkeletonNumber = count ?? (variant === 'table' ? 4 : nativeVariant === 'text' ? 3 : 1)
  const subSkeletonNumber = variant === 'table' ? totalSkeletonNumber - 1 : totalSkeletonNumber
  const totalSkeletonHeight = height ?? MINIMUM_SKELETON_HEIGHT * totalSkeletonNumber
  const skeletonWidth = nativeVariant === 'circular' ? totalSkeletonHeight : '100%'

  // Calculate a single skeleton height
  let skeletonNumberHeight: number
  let skeletonHeight: number | string
  let tableHeaderHeight: number | string
  if (isNumber(totalSkeletonHeight)) {
    skeletonNumberHeight = totalSkeletonHeight / totalSkeletonNumber
    tableHeaderHeight = skeletonNumberHeight * 2
    skeletonHeight = skeletonNumberHeight
  } else {
    const result = /(\d+)(.*)/i.exec(totalSkeletonHeight)
    skeletonNumberHeight = result ? Number(result[1]) / totalSkeletonNumber : 0
    skeletonHeight = result ? `${skeletonNumberHeight}${result[2]}` : skeletonNumberHeight
    tableHeaderHeight = result
      ? `${skeletonNumberHeight * 2}${result[2]}`
      : skeletonNumberHeight * 2
  }

  return (
    <DefaultBox
      display="flex"
      p={2}
      m={margin}
      mb={marginBottom}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minWidth={minWidth}
    >
      {variant === 'table' && (
        <MuiSkeleton animation={animation} variant="text" width="100%" height={tableHeaderHeight} />
      )}
      {Array(subSkeletonNumber)
        .fill('')
        .map((_, index) => (
          <MuiSkeleton
            key={index}
            animation={animation}
            variant={nativeVariant}
            height={skeletonHeight}
            width={skeletonWidth}
          />
        ))}
    </DefaultBox>
  )
}
