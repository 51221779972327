import {clamp, first, isUndefined} from 'lodash'
import moment from 'moment-timezone'

import {Resolution} from '../../general'
import {WithDateTime} from '../declarations/dateTimeTypes'

import {resolutionToIsoMomentUnit, resolutionToMomentUnit} from './resolutionUtils'

export function mapTimeToIntervalIndex(
  time: number,
  start: number,
  end: number,
  intervalCount: number
): number {
  if (intervalCount < 1) {
    throw new Error('Illegal argument interval < 1')
  }
  if (start >= end) {
    throw new Error('Illegal argument start >= end')
  }
  const timePos = (time - start) / (end - start)
  return clamp(Math.floor(timePos * intervalCount), 0, intervalCount - 1)
}

export function addCalendarInterval(
  dateTime: number,
  resolution: Resolution,
  timeZone: string
): number {
  return +moment
    .tz(dateTime, timeZone)
    .add(1, resolutionToMomentUnit(resolution))
    .startOf(resolutionToIsoMomentUnit(resolution))
    .toDate()
}

export function createTimeGrid(
  startTime: number,
  endTime: number,
  resolution: Resolution,
  timeZone: string,
  maxCount?: number
): number[] {
  const timeGrid: number[] = []
  const timeRangeMoment = {
    start: moment.tz(startTime, timeZone).startOf('day'),
    end: moment.tz(endTime, timeZone).endOf('day')
  }
  const timeRange = {
    start: +timeRangeMoment.start.toDate(),
    end: +timeRangeMoment.end.toDate()
  }

  let nextTime = timeRange.start

  // reasons we +2:
  // +1 to make up for shorter durations due to calender grid
  // +1 as moment floor the result to closest resolution
  const maxNumberOfPoints =
    timeRangeMoment.end.diff(timeRangeMoment.start, resolutionToMomentUnit(resolution)) + 2

  const shouldTrim = maxCount && maxNumberOfPoints > maxCount

  while (nextTime <= timeRange.end) {
    if (shouldTrim) {
      const idx = mapTimeToIntervalIndex(nextTime, timeRange.start, timeRange.end, maxCount)
      if (!timeGrid[idx]) timeGrid[idx] = nextTime
    } else {
      timeGrid.push(nextTime)
    }
    nextTime = addCalendarInterval(nextTime, resolution, timeZone)
  }

  return shouldTrim ? timeGrid.filter(Boolean) : timeGrid
}

export function applyRecordsToTimeGrid<N extends WithDateTime>(
  timeGrid: number[],
  records: N[],
  emptyRecord: Omit<N, 'datetime'>,
  aggregationFunction: (records: N[]) => N | undefined = first
): N[] {
  // sort records for efficiency
  const sortedRecords = [...records].sort((a, b) => {
    return a.datetime - b.datetime
  })

  let recordIndex = 0

  // new records should exactly map to the timeGrid passed
  return timeGrid.map<N>((timestamp, gridIndex) => {
    const nextTimestamp: number | undefined = timeGrid[gridIndex + 1]

    // we might have multiple records in the same time span
    // these records are added here and aggregated later to a single record
    const mappedRecords: N[] = []

    // We pick a record only if:
    // - the record exist in the current time span
    // - if the current time span is the last, we pick all remaining records
    // - record is within records length boundaries
    while (
      recordIndex < sortedRecords.length &&
      sortedRecords[recordIndex].datetime >= timestamp &&
      (isUndefined(nextTimestamp) || sortedRecords[recordIndex].datetime < nextTimestamp)
    ) {
      mappedRecords.push(sortedRecords[recordIndex])
      recordIndex++
    }

    // Pick the record closest to the current timestamp
    const selectedRecord = aggregationFunction(mappedRecords)

    if (!selectedRecord) {
      return {
        ...emptyRecord,
        datetime: timestamp
      } as N
    }

    // create nullable record
    return {
      ...selectedRecord,
      datetime: timestamp
    }
  })
}
