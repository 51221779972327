import {hpDarkTheme, hpTheme} from '@hconnect/uikit/src/lib2'
import {ThemeProvider} from '@mui/material'
import React, {PropsWithChildren} from 'react'

export {CssBaseline} from '@mui/material'

export function PredictThemeProvider({children}: PropsWithChildren<unknown>) {
  return <ThemeProvider theme={hpTheme}>{children}</ThemeProvider>
}

export function PredictDarkThemeProvider({children}: PropsWithChildren<unknown>) {
  return <ThemeProvider theme={hpDarkTheme}>{children}</ThemeProvider>
}
