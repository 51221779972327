import {useMemo} from 'react'

import {ProductionLine} from '../declarations/Plant'

import {usePlant} from './usePlant'

export const usePlantProductionLines: () => ProductionLine[] = () => {
  const plant = usePlant()
  return useMemo(() => plant?.productionLines ?? [], [plant?.productionLines])
}
