import * as d3 from 'd3'
import moment from 'moment'

import {TimeScaleFn} from '../declarations/scaleFn'

import {TimeRange} from '@predict/UtilsLib/dateTime'

export function calcTimeScale({
  timeRange,
  timeZone,
  chartWidth,
  tickWidth = 32
}: {
  timeRange: TimeRange
  timeZone: string
  chartWidth: number
  tickWidth?: number
}): {x: TimeScaleFn; ticks: number[]} {
  // The x-domain is in local time.
  // For example start is Apr 1 2021 0:00:00 GMT+0200 (Central European summer time) which is 2021-03-31T22:00:00.000Z in UTC
  // D3 auto-generates the ticks based on the UTC value,
  // making the first tick at 2021-04-01T00:00:00.000Z which is 2 o'clock
  // at local time. So we need shift back the tick by 2 hours to have the tick
  // at 12am local time with is 10pm UTC.
  const domain = [timeRange.start, timeRange.end]
  const shiftedTimeRange = addTimeZoneOffsetOfUtcTicks(domain, timeZone)
  const xShifted = d3.scaleUtc().domain(shiftedTimeRange).range([0, chartWidth])
  const numTicks = Math.floor(chartWidth / tickWidth)
  const ticks = subtractTimeZoneOffsetOfUtcTicks(xShifted.ticks(numTicks), timeZone)

  return {
    x: d3.scaleUtc().domain(domain).range([0, chartWidth]),
    ticks
  }
}

export function addTimeZoneOffsetOfUtcTicks(utcTimes: Date[], timeZone: string): number[] {
  return calcTimeOffsetsInMillisecondsOfUtcTimes(utcTimes, timeZone).map(
    (offset, idx) => utcTimes[idx].getTime() + offset
  )
}

export function subtractTimeZoneOffsetOfUtcTicks(utcTimes: Date[], timeZone: string): number[] {
  return calcTimeOffsetsInMillisecondsOfUtcTimes(utcTimes, timeZone).map(
    (offset, idx) => utcTimes[idx].getTime() - offset
  )
}

export function calcTimeOffsetsInMillisecondsOfUtcTimes(
  utcTimes: Date[],
  timeZone: string
): number[] {
  const zone = moment.tz.zone(timeZone)
  if (!zone) {
    throw new Error('Invalid time zone')
  }

  return utcTimes
    .map((utcTime) => utcTime.getTime())
    .map((utcTime) => -zone.utcOffset(utcTime) * 60 * 1000)
}
