import React, {PropsWithChildren} from 'react'

import {Text} from '../Text/Text'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface ColoredDataProps extends PropsWithChildren<unknown> {
  color: string
  'data-test-id'?: string
}
export function ColoredData({color, 'data-test-id': testId, children}: ColoredDataProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
      data-test-id={testId}
    >
      <Box
        sx={{
          backgroundColor: color,
          borderRadius: '100%',
          height: '0.75em',
          width: '0.75em',
          marginRight: 1
        }}
      />
      <Text variant="body2">{children}</Text>
    </Box>
  )
}
