import {intersection} from 'lodash'
import {useMemo} from 'react'

import {useLabDataDomainContext} from '../providers/LabDataDomainProvider'

import {useKilnLabMetaData} from './useKilnLabMetaData'

import {uniqAdd} from '@predict/UtilsLib/general'

interface MaterialUniformTags {
  [k: string]: string[]
}
export function useKilnSelectedUniformTags(tagIds?: string[]): MaterialUniformTags {
  const {selectedTagIds} = useLabDataDomainContext()
  const metaData = useKilnLabMetaData()

  const finalTagIds = tagIds ?? selectedTagIds

  return useMemo(() => {
    // Get unique ids
    const ids = intersection(
      metaData.map(({materialType, uniformTag}) => `${materialType}:${uniformTag}`),
      finalTagIds.filter((tagId) => tagId.includes(':'))
    )
    // Get material/ tag pairs
    const materialTags: MaterialUniformTags = {}
    ids
      .map((id) => id.split(':'))
      .forEach(([materialType, uniformTag]) => {
        if (!Object.prototype.hasOwnProperty.call(materialTags, materialType)) {
          materialTags[materialType] = []
        }
        materialTags[materialType] = uniqAdd(materialTags[materialType], uniformTag)
      })

    return materialTags
  }, [metaData, finalTagIds])
}
