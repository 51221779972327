import {DEFAULT_CEMENT_STRENGTH_CONFIG} from '../../constants'
import {CementStrengthConfig} from '../../declarations/CementStrengthConfig'
import {plantConfigDtoToCementStrengthConfig} from '../../utils/plantConfigDtoToCementStrengthConfig'

import {useBasePlantsList, usePlant} from '@predict/DomainsLib/plants'

export function usePlantCementStrengthConfig(): CementStrengthConfig {
  const basePlants = useBasePlantsList().data
  const csPlantsConfig = plantConfigDtoToCementStrengthConfig(basePlants ?? [])
  const plant = usePlant()

  return csPlantsConfig?.find((p) => p.id === plant?.id) ?? DEFAULT_CEMENT_STRENGTH_CONFIG
}
