import React from 'react'
import {useTranslation} from 'react-i18next'

import {useCementStrengthDomainContext, useMaterials} from '@predict/DomainsLib/cementStrength'
import {timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'
import {CementStrengthSamplesTable} from '@predict/WebApp/modules/cementStrength'
import {usePredictionViewSearchParam} from '@predict/WebApp/modules/navigation'
import {MaterialChartBox} from '@predict/WebApp/pages/CementStrengthPage/components/MaterialChartBox'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'

export function MaterialChartsContainer() {
  const {t} = useTranslation()
  const {timeRange: csTimeRange} = useCementStrengthDomainContext()
  const timeRange = timeRangeFromDateTimeParams(csTimeRange)
  const {data: materials, isLoading, isRefetching, error} = useMaterials()
  const {predictionView} = usePredictionViewSearchParam()

  if (isLoading || isRefetching) {
    return <Loader />
  }

  if (error) {
    return <ErrorMessage>{t('errors.cementStrengthData')}</ErrorMessage>
  }

  if (!materials || materials.length === 0) {
    return <ErrorMessage>{t('errors.noMaterials')}</ErrorMessage>
  }

  return (
    <GridContainer spacing={2} justifyContent="flex-start">
      {predictionView === 'table' ? (
        <DefaultBox width="100%" m={1} flex={1}>
          <CementStrengthSamplesTable />
        </DefaultBox>
      ) : (
        materials.map((mat) => (
          <GridItem key={mat.materialId} xs={12} sm={6} lg={4} xl={3}>
            <MaterialChartBox
              material={mat}
              timeRange={timeRange}
              predictionView={predictionView}
            />
          </GridItem>
        ))
      )}
    </GridContainer>
  )
}
