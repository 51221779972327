import {InfoOutlined} from '@mui/icons-material'
import React, {PropsWithChildren} from 'react'

import {DefaultBox} from '../../layouts/DefaultBox/DefaultBox'

import {Text} from '@predict/WebUILib/atoms/Text/Text'

interface ErrorMessageProps extends PropsWithChildren<unknown> {
  height?: string | number
}

export function ErrorMessage({height, children}: ErrorMessageProps) {
  return (
    <DefaultBox
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mb={2}
    >
      <Text variant="body1" align="center" color="#5e7a8c">
        <Text
          component="span"
          sx={{
            display: 'inline-block',
            position: 'relative',
            width: 20,
            height: 16,
            marginRight: 1
          }}
        >
          <InfoOutlined
            fontSize="small"
            sx={{
              position: 'absolute',
              left: 0
            }}
          />
        </Text>
        {children}
      </Text>
    </DefaultBox>
  )
}
