import {isEmpty} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  createChartDataFromCementStrengthSamples,
  createChartDataFromLabMatData
} from '../utils/createChartData'

import {
  AggregatedMaterialData,
  CsMaterialSample,
  MaterialLevel,
  STRENGTH_LEVELS,
  useCementStrengthDomainContext
} from '@predict/DomainsLib/cementStrength'
import {useLabDataDomainContext, useMaterialLabData} from '@predict/DomainsLib/labData'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {TimeRange, timeRangeFromDateTimeParams} from '@predict/UtilsLib/dateTime'
import {replaceNonWordCharacters} from '@predict/UtilsLib/general'
import {CHART_MARGIN} from '@predict/WebApp/modules/general/utils/chartHelpers'
import {useSelectedMills} from '@predict/WebApp/modules/labData/hooks/useSelectedMills'
import {useSelectedSilos} from '@predict/WebApp/modules/labData/hooks/useSelectedSilos'
import {
  useTimeRangeSearchParams,
  useSelectedTagIdsSearchParam
} from '@predict/WebApp/modules/navigation'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Headline} from '@predict/WebUILib/atoms/Headline/Headline'
import {LoaderIcon} from '@predict/WebUILib/atoms/LoaderIcon/LoaderIcon'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {ChartData} from '@predict/WebUILib/Charts/helpers'
import {StepChart} from '@predict/WebUILib/Charts/organisms/StepChart/StepChart'
import {Close} from '@predict/WebUILib/helpers/icons'
import {AsyncContainer} from '@predict/WebUILib/layouts/AsyncContainer/AsyncContainer'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {HorizontalScrollableWrapper} from '@predict/WebUILib/layouts/HorizontalScrollableWrapper/HorizontalScrollableWrapper'

interface LabChartsProps {
  materialLevel: MaterialLevel
  materialData?: AggregatedMaterialData
  selectedSample?: CsMaterialSample
  onSelectSample: (selectedId?: string) => void
}

const GRAPH_HEIGHT = 250 as const

export function LabCharts({
  materialLevel,
  materialData,
  selectedSample,
  onSelectSample
}: LabChartsProps) {
  const {t} = useTranslation()
  const {setTimeRange: onTimeRangeChanged} = useTimeRangeSearchParams()

  const selectedMills = useSelectedMills()
  const selectedSilos = useSelectedSilos()

  const samples = useMemo<CsMaterialSample[]>(() => materialData?.samples ?? [], [materialData])
  const timeZone = usePlantTimeZone()
  const strengthLevel = materialLevel.name
  const {materialId, timeRange} = useCementStrengthDomainContext()
  const {selectedTagIds} = useLabDataDomainContext()
  const {
    data: labData,
    isLoading: labIsLoading,
    isRefetching: labIsRefetching,
    isSuccess: labIsSuccess,
    isError: labIsError
  } = useMaterialLabData(materialId, selectedMills, selectedSilos)

  const optionalStrengthLevels = useMemo(
    () =>
      STRENGTH_LEVELS.filter(
        (strength) => selectedTagIds.includes(strength) && strength !== strengthLevel
      ),
    [selectedTagIds, strengthLevel]
  )

  const optionalStrengthLevelsGraphData = useMemo<ChartData[]>(
    () =>
      optionalStrengthLevels.map((strength) =>
        createChartDataFromCementStrengthSamples(samples, strength, t)
      ),
    [optionalStrengthLevels, samples, t]
  )

  const optionalLabGraphData = useMemo<ChartData[]>(
    () =>
      labData
        ? createChartDataFromLabMatData(
            labData.data.filter(({uniformTag}) => selectedTagIds.includes(uniformTag))
          )
        : [],
    [labData, selectedTagIds]
  )

  const optionalGraphData = useMemo<ChartData[]>(
    () => [...optionalStrengthLevelsGraphData, ...optionalLabGraphData],
    [optionalStrengthLevelsGraphData, optionalLabGraphData]
  )

  const {setSelectedTagIds: onTagIdsChanged} = useSelectedTagIdsSearchParam()
  const onClose = useCallback(
    (chartId: string) => {
      onTagIdsChanged(selectedTagIds.filter((tag) => tag !== chartId))
    },
    [selectedTagIds, onTagIdsChanged]
  )

  const onSelected = useCallback(
    (datetime: number) => {
      onSelectSample(samples.find((sample) => sample.datetime === datetime)?.id)
    },
    [onSelectSample, samples]
  )

  return (
    <AsyncContainer
      isLoading={
        labIsLoading ||
        (isEmpty(optionalLabGraphData) && labIsRefetching) ||
        (!materialData && !isEmpty(optionalStrengthLevels))
      }
      isSuccess={labIsSuccess && (isEmpty(optionalStrengthLevels) || !!materialData)}
      isError={labIsError || !labData}
      errorMessage={t('errors.labAndSensorData')}
      loaderType="rectangular"
      loaderHeight={300}
      loaderMargin={0}
    >
      {optionalGraphData.map((chartData) => {
        const key = replaceNonWordCharacters(chartData.id)
        return (
          <DefaultBox
            mb={2}
            key={key}
            data-test-id={`optional-data-chart-box-${key}`}
            minHeight={GRAPH_HEIGHT}
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="baseline">
                <Headline tag="h4" bottomSpacing={1} mr={1}>
                  {chartData.name}
                </Headline>
                {labIsRefetching && <LoaderIcon size="1rem" />}
              </Box>
              <ColorButton
                onClick={() => onClose(chartData.id)}
                startIcon={<Close />}
                data-test-id={`optional-data-chart-${key}-close-button`}
              >
                {t('button.close')}
              </ColorButton>
            </Box>

            <Spacing height={1} />
            <HorizontalScrollableWrapper>
              <StepChart
                chartId={key}
                height={GRAPH_HEIGHT}
                margin={CHART_MARGIN}
                timeZone={timeZone}
                data-test-id={`optional-data-chart-${key}`}
                timeRange={timeRangeFromDateTimeParams(timeRange)}
                mainChartData={chartData}
                selectedDateTime={selectedSample?.datetime}
                onSelected={onSelected}
                onTimeRangeChanged={(timeRange: TimeRange<number>) => {
                  onTimeRangeChanged(timeRange.start, timeRange.end)
                }}
              />
            </HorizontalScrollableWrapper>
          </DefaultBox>
        )
      })}
    </AsyncContainer>
  )
}
