import {Popover} from '@mui/material'
import React, {PropsWithChildren, useLayoutEffect, useState} from 'react'

import {Box} from '../Box/Box'

interface PopOverProps extends PropsWithChildren<unknown> {
  anchorEl: Element | null
  isOpen?: boolean
  onClose?: () => void
  id?: string
  width?: number
  marginThreshold?: number
}

function calcPopoverOffset(anchorEl: Element, popoverMargin: number, popoverWidth: number) {
  const headerCellRect = anchorEl.getBoundingClientRect()
  const windowWidth = window.innerWidth
  const cellCenterPosX = headerCellRect.x + 0.5 * headerCellRect.width
  const distanceToRight = windowWidth - cellCenterPosX
  const minDistPopover = popoverMargin + 0.5 * popoverWidth
  if (cellCenterPosX < minDistPopover) {
    return minDistPopover - cellCenterPosX
  }
  if (distanceToRight < minDistPopover) {
    return windowWidth - minDistPopover - cellCenterPosX
  }
  return 0
}

const DEFAULT_POPOVER_MARGIN = 16
const DEFAULT_POPOVER_WIDTH = 256

export function PopOver({
  anchorEl,
  isOpen = false,
  id,
  onClose,
  marginThreshold = DEFAULT_POPOVER_MARGIN,
  width = DEFAULT_POPOVER_WIDTH,
  children
}: PopOverProps) {
  const [popoverOffset, setPopoverOffset] = useState(0)

  useLayoutEffect(() => {
    if (anchorEl) {
      setPopoverOffset(calcPopoverOffset(anchorEl, marginThreshold, width))
    }
  }, [anchorEl, marginThreshold, width])

  return (
    <Popover
      id={id}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={{
        sx: (theme) => ({
          borderRadius: 1,
          marginTop: 1,
          overflow: 'visible',

          // Arrow
          '&:before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: -11,
            left: `calc(50% - ${popoverOffset}px)`,
            borderWidth: 8,
            borderColor: 'white',
            borderStyle: 'solid',
            transformOrigin: '0 0',
            transform: 'rotate(45deg)',
            clipPath: 'polygon(-100% -100%, -100% 200%, 200% -100%)',
            boxShadow: theme.shadows[8]
          }
        })
      }}
      marginThreshold={marginThreshold}
    >
      <Box m={3} maxWidth={width}>
        {children}
      </Box>
    </Popover>
  )
}
