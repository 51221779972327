import {AxiosError} from 'axios'
import {useMutation} from 'react-query'

import {UnifiedSensorMetaData} from '../../declarations/SensorMetaData'
import {useInvalidateSensorData} from '../useInvalidateSensorData'

import {UniformProcessMetricsApi} from '@predict/DomainsLib/gen-api/UniformProcessMetrics'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export async function deleteUnifiedSensorMetaData(uniformTag: string): Promise<void> {
  const log = logger.context('deleteUnifiedSensorMetaData', uniformTag)
  try {
    await UniformProcessMetricsApi.processMetricsUniformDelete(uniformTag)
    log.info(`UniformTag ${uniformTag} deleted.`)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export async function deleteUnifiedSensorMetaDataList(uniformTags: string[]): Promise<void> {
  await Promise.all(uniformTags.map((tag) => deleteUnifiedSensorMetaData(tag)))
}

export function useDeleteUnifiedSensorMetaDataMutation(optionsInjector = dummyMutationInjector) {
  const invalidateSensorData = useInvalidateSensorData()
  return useMutation(
    optionsInjector({
      mutationFn: (metaData: UnifiedSensorMetaData[]) => {
        return deleteUnifiedSensorMetaDataList(metaData.map(({uniformTag}) => uniformTag))
      },
      onSuccess: invalidateSensorData
    })
  )
}
