/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Area {
  /** @format uuid */
  areaId: string
  sapProcessStages: SapProcessStage[]
  type: AreaType
  /** @maxLength 2 */
  areaTypeCode: string
  plant: Plant
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
}

export interface AreaType {
  /** @maxLength 2 */
  areaTypeCode: string
  areaTypeName: string
}

export interface AvailabilityDto {
  /**
   * Between 0 and 1
   * @format double
   */
  noPredictionGenerated: number
  /**
   * Between 0 and 1
   * @format double
   */
  noLabData: number
  /**
   * Between 0 and 1
   * @format double
   */
  noSensorData: number
  /**
   * Between 0 and 1
   * @format double
   */
  noDataArrived: number
  /**
   * Between 0 and 1
   * @format double
   */
  noProduction: number
  /**
   * Between 0 and 1
   * @format double
   */
  backfilled: number
  /**
   * Between 0 and 1
   * @format double
   */
  running: number
}

export interface AxisRange {
  /** @format double */
  lowerBound: number
  /** @format double */
  upperBound: number
}

export interface CementQualityDetailOutput {
  /**
   * Plant Code
   * @example "0014"
   */
  plantId: string
  /**
   * Plant specfic id of the sample
   * always use in conjunction with plant id to uniquely identify sample
   * @example "1146810"
   */
  sampleIdPlant: string
  /**
   * @format date-time
   * @example "2019-08-30T07:45:00+02:00"
   */
  sampleDate: string
  unifiedMaterialName: string
  /** @example "0" */
  millName?: string | null
  /**
   * Silo name or number
   * @example "0, s1"
   */
  silo?: string | null
  /**
   * @format float
   * @example 47.5
   */
  alite?: number | null
  /**
   * @format float
   * @example 7
   */
  belite?: number | null
  /**
   * @format float
   * @example 4103
   */
  blaine?: number | null
  /**
   * @format float
   * @example 25.6
   */
  strength2d?: number | null
  /**
   * @format float
   * @example 55.2
   */
  strength28d?: number | null
  /** @format date-time */
  updateDate?: string | null
  /** @format float */
  siO2?: number | null
  /** @format float */
  al2O3?: number | null
  /** @format float */
  fe2O3?: number | null
  /** @format float */
  caO?: number | null
  /** @format float */
  mgO?: number | null
  /** @format float */
  k2O?: number | null
  /** @format float */
  na2O?: number | null
  /** @format float */
  sO3?: number | null
  /** @format float */
  insolubleResidue?: number | null
  /** @format float */
  aliteM1?: number | null
  /** @format float */
  aliteM3?: number | null
  /** @format float */
  beliteAlpha?: number | null
  /** @format float */
  beliteBeta?: number | null
  /** @format float */
  aluminate?: number | null
  /** @format float */
  aluminateCubic?: number | null
  /** @format float */
  aluminateOrtho?: number | null
  /** @format float */
  ferrite?: number | null
  /** @format float */
  gypsum?: number | null
  /** @format float */
  hemihydrate?: number | null
  /** @format float */
  anhydrite?: number | null
  /** @format float */
  calcite?: number | null
  /** @format float */
  dolomite?: number | null
  /** @format float */
  periclase?: number | null
  /** @format float */
  quartz?: number | null
  /** @format float */
  arcanite?: number | null
  /** @format float */
  aphthitalite?: number | null
  /** @format float */
  syngenite?: number | null
  /** @format float */
  langbeinite?: number | null
  /** @format float */
  portlandite?: number | null
  /** @format float */
  freeLime?: number | null
  /** @format float */
  loi?: number | null
  /** @format float */
  waterDemand?: number | null
  /** @format float */
  settingInitial?: number | null
  /** @format float */
  settingFinal?: number | null
  /** @format float */
  expansion?: number | null
  /** @format float */
  falseSet?: number | null
  /** @format float */
  airContent?: number | null
  /** @format float */
  naEquiv?: number | null
  /** @format float */
  strength1d?: number | null
  /** @format float */
  strength3d?: number | null
  /** @format float */
  strength7d?: number | null
  /** @format float */
  psdRrLocation?: number | null
  /** @format float */
  psdRrSlope?: number | null
  /** @format float */
  psdSieve32um?: number | null
  /** @format float */
  psdSieve45um?: number | null
  rawMaterialCode: string
  rawMaterialName: string
  productionLine?: string | null
  /** @format float */
  cl?: number | null
  /** @format float */
  slag?: number | null
  /** @format float */
  flyash?: number | null
  /** @format double */
  predictedStrength1d?: number | null
  /** @format double */
  predictedStrength2d?: number | null
  /** @format double */
  predictedStrength3d?: number | null
  /** @format double */
  predictedStrength7d?: number | null
  /** @format double */
  predictedStrength28d?: number | null
  /** @format float */
  colorA?: number | null
  /** @format float */
  colorB?: number | null
  /** @format float */
  colorL?: number | null
  /** @format float */
  psdX10?: number | null
  /** @format float */
  psdX50?: number | null
  /** @format float */
  psdX90?: number | null
  /** @format float */
  tiO2?: number | null
  /** @format float */
  clinker?: number | null
  /** @format float */
  psdBlaine?: number | null
}

export interface CementQualityDto {
  /**
   * Plant Code
   * @example "0014"
   */
  plantId: string
  /**
   * Plant specfic id of the sample
   * always use in conjunction with plant id to uniquely identify sample
   * @example "1146810"
   */
  sampleIdPlant: string
  /**
   * @format date-time
   * @example "2019-08-30T07:45:00+02:00"
   */
  sampleDate: string
  unifiedMaterialName: string
  /** @example "0" */
  millName?: string | null
  /**
   * Silo name or number
   * @example "0, s1"
   */
  silo?: string | null
  /**
   * @format float
   * @example 47.5
   */
  alite?: number | null
  /**
   * @format float
   * @example 7
   */
  belite?: number | null
  /**
   * @format float
   * @example 4103
   */
  blaine?: number | null
  /**
   * @format float
   * @example 25.6
   */
  strength2d?: number | null
  /**
   * @format float
   * @example 55.2
   */
  strength28d?: number | null
}

export interface CementStrengthDataDto {
  /**
   * Name of the Material
   * @example "CEM I 42,5 N"
   */
  materialName: string
  /**
   * Material Id generated from data mart
   * @format int32
   * @example 3
   */
  materialId?: number | null
  mills: MillDto[]
}

export interface CementStrengthPredictionDataDto {
  /**
   * Name of the Material
   * @example "CEM I 42,5 N"
   */
  materialName: string
  /**
   * Material Id generated from data mart
   * @format int32
   * @example 3
   */
  materialId?: number | null
  mills?: MillPredictionDto[] | null
  silos?: DatedCementStrengthPredictionDto[] | null
}

export interface CementStrengthPredictionDto {
  /**
   * Lab analysis sample identifier
   * @minLength 1
   * @example "42"
   */
  sampleIdPlant: string
  /**
   * SAP plant code (e.g. "0014" for Burglengenfeld)
   * @minLength 4
   * @maxLength 4
   * @example "0014"
   */
  plantId: string
  /**
   * The predicted cement stength after 1 day
   * @format double
   * @example 42.3
   */
  strength1d?: number | null
  /**
   * The predicted cement stength after 2 days
   * @format double
   * @example 43.3
   */
  strength2d?: number | null
  /**
   * The predicted cement stength after 3 days
   * @format double
   * @example 44.3
   */
  strength3d?: number | null
  /**
   * The predicted cement stength after 7 days
   * @format double
   * @example 45.3
   */
  strength7d?: number | null
  /**
   * The predicted cement stength after 28 days
   * @format double
   * @example 46.3
   */
  strength28d?: number | null
}

export interface CementStrengthSettings {
  defaultView: CementStrengthView
  defaultTimeHorizon: string
  defaultTimeDurationCementStrength: string
  defaultSampleTypeSources: SampleTypeSource[]
}

export enum CementStrengthView {
  SampleSelection = 'SampleSelection',
  TimeSelection = 'TimeSelection'
}

export interface DataPoint {
  sampleId: string
  /** @format date-time */
  timestamp: string
  /** @format float */
  value: number
}

export interface DatedCementStrengthDto {
  /**
   * Lab analysis sample identifier
   * The id of the sample taken
   * @example "43"
   */
  sampleIdPlant: string
  /**
   * The cement stength after 1 day
   * @format float
   * @example 42.3
   */
  strength1d?: number | null
  /**
   * The cement stength after 2 days
   * @format float
   * @example 43.3
   */
  strength2d?: number | null
  /**
   * The cement stength after 3 days
   * @format float
   * @example 44.3
   */
  strength3d?: number | null
  /**
   * The cement stength after 7 days
   * @format float
   * @example 45.3
   */
  strength7d?: number | null
  /**
   * The stength after 28 days
   * @format float
   * @example 46.3
   */
  strength28d?: number | null
  /**
   * The Date and Time the sample was taken
   * @format date-time
   * @example "2021-03-29T17:30:20.070Z"
   */
  datetime: string
}

export interface DatedCementStrengthPredictionDto {
  /**
   * Lab analysis sample identifier
   * The id of the sample taken
   * @example "43"
   */
  sampleIdPlant: string
  /**
   * The cement stength after 1 day
   * @format float
   * @example 42.3
   */
  strength1d?: number | null
  /**
   * The cement stength after 2 days
   * @format float
   * @example 43.3
   */
  strength2d?: number | null
  /**
   * The cement stength after 3 days
   * @format float
   * @example 44.3
   */
  strength3d?: number | null
  /**
   * The cement stength after 7 days
   * @format float
   * @example 45.3
   */
  strength7d?: number | null
  /**
   * The stength after 28 days
   * @format float
   * @example 46.3
   */
  strength28d?: number | null
  /**
   * The Date and Time the sample was taken
   * @format date-time
   * @example "2021-03-29T17:30:20.070Z"
   */
  datetime: string
  /**
   * The predicted cement stength after 1 day
   * @format double
   * @example 42.3
   */
  predictedStrength1d?: number | null
  /**
   * The predicted cement stength after 2 days
   * @format double
   * @example 43.3
   */
  predictedStrength2d?: number | null
  /**
   * The predicted cement stength after 3 days
   * @format double
   * @example 44.3
   */
  predictedStrength3d?: number | null
  /**
   * The predicted cement stength after 7 days
   * @format double
   * @example 45.3
   */
  predictedStrength7d?: number | null
  /**
   * The predicted cement stength after 28 days
   * @format double
   * @example 46.3
   */
  predictedStrength28d?: number | null
  /** @example "0" */
  millName?: string | null
  /** @example "0" */
  silo?: string | null
  /**
   * @format float
   * @example 47.5
   */
  alite?: number | null
  /**
   * @format float
   * @example 7
   */
  belite?: number | null
  /**
   * @format float
   * @example 4103
   */
  blaine?: number | null
  /** @format float */
  sO3?: number | null
  /** @format float */
  sieve45um?: number | null
  /** @format float */
  psdRrLocation?: number | null
}

export interface Department {
  /** @format uuid */
  departmentId: string
  sapProcessStages: SapProcessStage[]
  type: DepartmentType
  /** @maxLength 1 */
  departmentTypeCode: string
  /** @maxLength 2 */
  areaTypeCode: string
  area: Area
  /** @format uuid */
  areaId: string
  lineNumber?: string | null
  name?: string | null
}

export interface DepartmentType {
  /** @maxLength 1 */
  departmentTypeCode: string
  departmentTypeName: string
  areaType: AreaType
  /** @maxLength 2 */
  areaTypeCode: string
}

export interface DeviationDto {
  /** @format double */
  highStandardDeviation?: number | null
  /** @format double */
  lowStandardDeviation?: number | null
  /** @format double */
  mean?: number | null
}

export interface Equipment {
  /** @format uuid */
  equipmentId: string
  sapEquipment: SapEquipment[]
  type: EquipmentType
  /** @maxLength 2 */
  equipmentTypeCode: string
  department: Department
  /** @format uuid */
  departmentId: string
  subEquipment: Equipment[]
  parentEquipment?: Equipment | null
  /** @format uuid */
  parentEquipmentId?: string | null
  sensor: Sensor[]
  isSubEquipment: boolean
  equipmentCode: string
}

export interface EquipmentType {
  /** @maxLength 2 */
  equipmentTypeCode: string
  equipmentTypeName: string
  canBeEquipment?: boolean | null
  canBeSubEquipment?: boolean | null
}

export interface FreelimeActual {
  /** @format date-time */
  time: string
  /** @format double */
  value: number
  /** @format float */
  alite?: number | null
  /** @format float */
  belite?: number | null
  /** @format float */
  silicaRatio?: number | null
  /** @format float */
  aluminaRatio?: number | null
  /** @format float */
  limeSaturationFactor?: number | null
}

export interface FreelimeConfigurationDto {
  /**
   * The target of the free lime value
   * @format double
   * @example 1
   */
  target: number
  /**
   * The lower bound of what is an acceptable free lime value
   * @format double
   * @example 0.5
   */
  min: number
  /**
   * The upper bound of what is an acceptable free lime value
   * @format double
   * @example 1.5
   */
  max: number
  /**
   * The lower bound of the free lime axis
   * @format double
   * @example 0
   */
  axisMin: number
  /**
   * The upper bound of the free lime axis
   * @format double
   * @example 3
   */
  axisMax: number
}

export interface FreelimeModelDto {
  /** @format int32 */
  modelId: number
  plantId: string
  productionLineId: string
  /** @format double */
  qualityDataLookBackInMS?: number | null
  /** @format double */
  processDataLookBackInMS?: number | null
  features: string[]
  signals: SignalDto[]
}

export interface FreelimeOutput {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  productionLineId: string
  actuals: FreelimeActual[]
  predictions: FreelimePrediction[]
  range: Range
}

export interface FreelimePrediction {
  /** @format date-time */
  time: string
  /** @format double */
  value: number
}

export interface FreelimeSettings {
  axisRange: AxisRange
  targetRange: TargetRange
  defaultTimeDurationFreelime: string
}

export interface LabMaterialMetrics {
  /**
   * Material Id generated from data mart
   * @format int32
   * @example 3
   */
  materialId?: number | null
  /**
   * Name of the Material
   * @example "CEM I 42,5 N"
   */
  materialName: string
  /**
   * Plant Code
   * @example "0014"
   */
  plantId: string
  metrics: LabMetric[]
}

export interface LabMetric {
  /**
   * Name of the Sensor or uniform tag, the same across plants
   * @example "preheater.cyclone.north.pressure"
   */
  uniformTag: string
  plantSpecificTag: string
  /**
   * Unit of the sensor value
   * @example "mBar"
   */
  unit: string
  /**
   * A human friendly Name
   * @example "Fuel rate"
   */
  displayName: string
  /**
   * Description
   * @example "Bottom cyclone outlet static pressure - North"
   */
  description: string
  category: string
  materialType: string
  timeseries: DataPoint[]
}

export interface LabMetricsMetadata {
  /**
   * Name of the Sensor or uniform tag, the same across plants
   * @example "preheater.cyclone.north.pressure"
   */
  uniformTag: string
  plantSpecificTag: string
  /**
   * Unit of the sensor value
   * @example "mBar"
   */
  unit: string
  /**
   * A human friendly Name
   * @example "Fuel rate"
   */
  displayName: string
  /**
   * Description
   * @example "Bottom cyclone outlet static pressure - North"
   */
  description: string
  category: string
  materialType: string
}

export interface LevelDto {
  /** @example "strength28" */
  name: string
  /**
   * @format double
   * @example 42.1
   */
  target: number
  /**
   * @format double
   * @example 40.5
   */
  min: number
  /**
   * @format double
   * @example 43.7
   */
  max: number
}

export interface MaterialDto {
  /**
   * Name of the cement type material
   * @example "CEM I 42,5 N"
   */
  name: string
  /**
   * Material Id generated from data mart
   * @format int32
   * @example 3
   */
  materialId: number
  levels: LevelDto[]
}

export interface Mill {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  /** @format int32 */
  number: number
  name: string
  plantConfiguration: Plant
}

export interface MillDto {
  /**
   * The name of the cement mill
   * @example "ZM1"
   */
  millName: string
  predictions?: DatedCementStrengthDto[] | null
}

export interface MillPredictionDto {
  /**
   * The name of the cement mill
   * @example "ZM1"
   */
  millName: string
  predictions?: DatedCementStrengthPredictionDto[] | null
}

export interface PerformanceDto {
  /** @format double */
  meanAbsoluteError?: number | null
  availability: AvailabilityDto
  /** @format double */
  meanPredictionError?: number | null
  /** @format date-time */
  time: string
  actuals?: DeviationDto | null
  predictions?: DeviationDto | null
}

export interface Plant {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  sapPlant: SapPlant[]
  siteId: string
  managementId?: string | null
  company?: string | null
  predictOnMillSamples: boolean
  predictOnSiloSamples: boolean
  plantName: string
  plantFullName: string
  country: string
  region?: string | null
  timezone: string
  defaultTimeHorizon: string
  supportedTimeHorizons: string[]
  productionLines: ProductionLine[]
  silos: string[]
  mills: Mill[]
  freeLimeTargetRange: TargetRange
  defaultView: string
  defaultSampleTypeSources: SampleTypeSource[]
  defaultTimeDurationCementStrength: string
  defaultTimeDurationFreelime: string
  /** @format double */
  freeLimeAxisLowerBound: number
  /** @format double */
  freeLimeAxisUpperBound: number
}

export interface PlantConfigurationDto {
  /** @example "0014" */
  plantId: string
  /** @example "burglengenfeld" */
  plantName: string
  /** @example "Burglengenfeld" */
  plantFullName: string
  /** @example "DE" */
  country: string
  /** @example "DE-BY" */
  region: string
  /** @example "Europe/Berlin" */
  timezone: string
  /** @example "strength28" */
  defaultTimeHorizon: string
  supportedTimeHorizons: string[]
  /**
   * Returns Mills or Silos for the plant or specfic sources for some plants such as "Daily average" or "Hourly
   * production"
   */
  supportedSampleTypeSources: Record<string, string[]>
  productionLines: ProductionLineOutput[]
  /** The configuration of the plant's free lime view */
  freelimeConfig: FreelimeConfigurationDto
  /**
   * The sample type source to select by default.
   * @example ["dailyAverage","hourlyProduction"]
   */
  defaultSampleTypeSources: string[]
  /**
   * The default time range to display for cement strength predictions in ISO 8601 format.
   * @example "P1M"
   */
  defaultTimeDurationCementStrength: string
  defaultTimeDurationFreelime: string
}

export interface PlantSpecificProcessMetricInput {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  plantTag: string
  /**
   * @minLength 1
   * @maxLength 40
   */
  uniformTag?: string | null
  /** @format uuid */
  equipmentId?: string | null
  productionLineId?: string | null
}

export interface ProblemDetails {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
  [key: string]: any
}

export enum ProcessDataStorageProvider {
  Auto = 'Auto',
  ADX = 'ADX'
}

export interface ProcessMetric {
  /**
   * Name of the Sensor or uniform tag, the same across plants
   * @example "preheater.cyclone.north.pressure"
   */
  uniformTag: string
  plantSpecificTag: string
  productionLine: string
  /**
   * Unit of the sensor value
   * @example "mBar"
   */
  unit: string
  /**
   * A human friendly Name
   * @example "Fuel rate"
   */
  displayName: string
  /**
   * Description
   * @example "Bottom cyclone outlet static pressure - North"
   */
  description: string
  category: string
  /**
   * 2d array of 32-bit floating point numbers and unix timestamps (seconds since January 1st 1970)
   * @example [[1,1311836008],[2,1311836009],[3,1311836010],[5,1311836011],[6,1311836012]]
   */
  datapoints: any[][]
}

export interface ProcessMetricsDumpDto {
  /** List of all unform process metrics in all plants */
  uniformData: UniformProcessMetricDto[]
  /** List of all plant specific process metrics in all plants */
  plantSpecificData: SensorDto[]
}

export interface ProcessMetricsMetadataOutput {
  /**
   * Plant specfic sensor tag
   * @example "00OA826"
   */
  plantSpecificTag: string
  /**
   * Name of the Sensor or uniform tag, the same accross plants
   * @example "preheater.cyclone.north.pressure"
   */
  uniformTag: string
  /**
   * A human friendly Name
   * @example "Fuel rate"
   */
  displayName: string
  /**
   * Unit of the sensor value
   * @example "mBar"
   */
  unit: string
  /**
   * Description
   * @example "Bottom cyclone outlet static pressure - North"
   */
  description: string
  category: string
  /** @format uuid */
  equipmentId?: string | null
  equipmentCode?: string | null
  equipmentName?: string | null
  productionLine: ProductionLineOutput
}

export interface ProcessMetricsMetadataOutputWithPlantId {
  /**
   * Plant specfic sensor tag
   * @example "00OA826"
   */
  plantSpecificTag: string
  /**
   * Name of the Sensor or uniform tag, the same accross plants
   * @example "preheater.cyclone.north.pressure"
   */
  uniformTag: string
  /**
   * A human friendly Name
   * @example "Fuel rate"
   */
  displayName: string
  /**
   * Unit of the sensor value
   * @example "mBar"
   */
  unit: string
  /**
   * Description
   * @example "Bottom cyclone outlet static pressure - North"
   */
  description: string
  category: string
  /** @format uuid */
  equipmentId?: string | null
  equipmentCode?: string | null
  equipmentName?: string | null
  productionLine: ProductionLineOutput
  plantId: string
}

export interface ProductionLine {
  /**
   * @minLength 1
   * @maxLength 20
   */
  id: string
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  name?: string | null
  plant: Plant
}

export interface ProductionLineOutput {
  id: string
  name?: string | null
}

export interface Range {
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
}

export interface RawLabdataDto {
  sampleId: string
  plantId: string
  /** @format date-time */
  sampleDate: string
  millName?: string | null
  siloName?: string | null
  lineNumber?: string | null
  materialCode: string
  materialName: string
  sampleType: string
  materialType: string
  /** @format int32 */
  unifiedMaterialId: number
  unifiedMaterialName: string
  /** @format float */
  caO?: number | null
  /** @format float */
  siO2?: number | null
  /** @format float */
  al2O3?: number | null
  /** @format float */
  fe2O3?: number | null
  /** @format float */
  mgO?: number | null
  /** @format float */
  sO3?: number | null
  /** @format float */
  k2O?: number | null
  /** @format float */
  na2O?: number | null
  /** @format float */
  cl?: number | null
  /** @format float */
  tiO2?: number | null
  /** @format float */
  insolubleResidue?: number | null
  /** @format float */
  naEquiv?: number | null
  /** @format float */
  alite?: number | null
  /** @format float */
  aliteM1?: number | null
  /** @format float */
  aliteM3?: number | null
  /** @format float */
  belite?: number | null
  /** @format float */
  beliteAlpha?: number | null
  /** @format float */
  beliteBeta?: number | null
  /** @format float */
  aluminate?: number | null
  /** @format float */
  aluminateCubic?: number | null
  /** @format float */
  aluminateOrtho?: number | null
  /** @format float */
  ferrite?: number | null
  /** @format float */
  clinker?: number | null
  /** @format float */
  gypsum?: number | null
  /** @format float */
  quartz?: number | null
  /** @format float */
  calcite?: number | null
  /** @format float */
  slag?: number | null
  /** @format float */
  flyash?: number | null
  /** @format float */
  freeLime?: number | null
  /** @format float */
  freeLimeTitration?: number | null
  /** @format float */
  portlandite?: number | null
  /** @format float */
  hemihydrate?: number | null
  /** @format float */
  anhydrite?: number | null
  /** @format float */
  dolomite?: number | null
  /** @format float */
  periclase?: number | null
  /** @format float */
  arcanite?: number | null
  /** @format float */
  aphthitalite?: number | null
  /** @format float */
  syngenite?: number | null
  /** @format float */
  langbeinite?: number | null
  /** @format float */
  colorA?: number | null
  /** @format float */
  colorB?: number | null
  /** @format float */
  colorL?: number | null
  /** @format float */
  loi?: number | null
  /** @format float */
  waterDemand?: number | null
  /** @format float */
  settingInitial?: number | null
  /** @format float */
  settingFinal?: number | null
  /** @format float */
  expansion?: number | null
  /** @format float */
  falseSet?: number | null
  /** @format float */
  airContent?: number | null
  /** @format float */
  strength1d?: number | null
  /** @format float */
  strength2d?: number | null
  /** @format float */
  strength3d?: number | null
  /** @format float */
  strength7d?: number | null
  /** @format float */
  strength28d?: number | null
  /** @format float */
  blaine?: number | null
  /** @format float */
  psdRrLocation?: number | null
  /** @format float */
  psdRrSlope?: number | null
  /** @format float */
  psdBlaine?: number | null
  /** @format float */
  psdSieve32um?: number | null
  /** @format float */
  psdSieve45um?: number | null
  /** @format float */
  sieve32um?: number | null
  /** @format float */
  sieve45um?: number | null
  /** @format float */
  sieve90um?: number | null
  /** @format float */
  sieve200um?: number | null
  /** @format float */
  psdX10?: number | null
  /** @format float */
  psdX50?: number | null
  /** @format float */
  psdX90?: number | null
  /** @format double */
  predictedStrength1d?: number | null
  /** @format double */
  predictedStrength2d?: number | null
  /** @format double */
  predictedStrength3d?: number | null
  /** @format double */
  predictedStrength7d?: number | null
  /** @format double */
  predictedStrength28d?: number | null
}

export enum Resolution {
  Second = 'Second',
  Minute = 'Minute',
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}

export enum SampleTypeSource {
  Mill = 'Mill',
  Silo = 'Silo',
  DailyAverage = 'DailyAverage',
  HourlyProduction = 'HourlyProduction'
}

export interface SapEquipment {
  /**
   * @minLength 1
   * @maxLength 36
   */
  sapEquipmentId: string
}

export interface SapPlant {
  /** @format uuid */
  sapPlantId: string
  sapId: string
  plantId: string
}

export interface SapProcessStage {
  /**
   * @minLength 1
   * @maxLength 36
   */
  processStageId: string
}

export interface Sensor {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  /**
   * @minLength 1
   * @maxLength 40
   */
  plantTag: string
  /**
   * @minLength 1
   * @maxLength 40
   */
  uniformTag?: string | null
  uniformProcessMetric?: UniformProcessMetric | null
  /** @format uuid */
  equipmentId?: string | null
  equipment?: Equipment | null
  productionLine?: ProductionLine | null
  plant: Plant
  productionLineId?: string | null
  unit?: Unit | null
  unitId?: string | null
  /** @maxLength 2 */
  sensorTypeCode?: string | null
  type?: SensorType | null
  sensorSequenceNumber?: string | null
  descriptionEnglish?: string | null
  descriptionLocal?: string | null
  /** @maxLength 40 */
  globalTagName?: string | null
}

export interface SensorDto {
  plantId: string
  plantTag: string
  uniformTag?: string | null
  /** @format uuid */
  equipmentId?: string | null
  productionLineId?: string | null
  unitId?: string | null
  sensorTypeCode?: string | null
  sensorSequenceNumber?: string | null
  descriptionEnglish?: string | null
  descriptionLocal?: string | null
  globalTagName?: string | null
}

export interface SensorType {
  /**
   * @minLength 1
   * @maxLength 2
   */
  sensorTypeCode: string
  sensorTypeName: string
}

export interface SignalDto {
  /** The uniform tag of the input signal */
  inputSignalName: string
  /** The material type for the sample, if any */
  inputSignalMaterial?: string | null
}

export interface Target {
  /**
   * @minLength 4
   * @maxLength 4
   */
  plantId: string
  /** @format int32 */
  unifiedMaterialId: number
  /** @maxLength 20 */
  name: string
  /** @format double */
  targetValue: number
  /** @format double */
  lowerBound: number
  /** @format double */
  upperBound: number
}

export interface TargetRange {
  /** @format double */
  targetValue: number
  /** @format double */
  lowerBound: number
  /** @format double */
  upperBound: number
}

export interface TimeSpan {
  /** @format int64 */
  ticks: number
  /** @format int32 */
  days: number
  /** @format int32 */
  hours: number
  /** @format int32 */
  milliseconds: number
  /** @format int32 */
  minutes: number
  /** @format int32 */
  seconds: number
  /** @format double */
  totalDays: number
  /** @format double */
  totalHours: number
  /** @format double */
  totalMilliseconds: number
  /** @format double */
  totalMinutes: number
  /** @format double */
  totalSeconds: number
}

export interface UniformProcessMetric {
  /**
   * @minLength 1
   * @maxLength 40
   */
  uniformTag: string
  /** @maxLength 20 */
  unit?: string | null
  description?: string | null
  displayName?: string | null
  /** @format double */
  min?: number | null
  /** @format double */
  max?: number | null
  plantSpecificProcessMetrics?: Sensor[] | null
}

export interface UniformProcessMetricDto {
  uniformTag: string
  unit?: string | null
  description?: string | null
  displayName?: string | null
  /** @format double */
  min?: number | null
  /** @format double */
  max?: number | null
}

export interface Unit {
  /**
   * @minLength 1
   * @maxLength 36
   */
  unitId: string
  unitName: string
  timeContext?: string | null
}
