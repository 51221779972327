import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'

import {FreeLimeSettings} from '../../declarations/FreeLimeSettings'
import {usePlantFreeLimeSettings} from '../queries/usePlantFreeLimeSettings'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {FreelimeSettings} from '@predict/DomainsLib/gen-api/data-contracts'
import {PlantSettingsApi} from '@predict/DomainsLib/gen-api/PlantSettings'
import {usePlantId} from '@predict/DomainsLib/plants'
import {getTimeHorizonIdAsDurationIsoString} from '@predict/UtilsLib/dateTime'
import {dummyMutationInjector} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'

export async function updateFreelimeSettings(
  plantId: string,
  settings: FreeLimeSettings
): Promise<void> {
  const log = logger.context('updateFreelimeSettings', plantId, JSON.stringify(settings))
  try {
    const {axisRange, targetRange, defaultTimeDurationFreeLime} = settings
    const backendSettings: FreelimeSettings = {
      axisRange: {lowerBound: axisRange.min, upperBound: axisRange.max},
      targetRange: {
        lowerBound: targetRange.min,
        targetValue: targetRange.target,
        upperBound: targetRange.max
      },
      defaultTimeDurationFreelime: getTimeHorizonIdAsDurationIsoString(defaultTimeDurationFreeLime)
    }
    await PlantSettingsApi.plantsSettingsFreelimeUpdate(plantId, backendSettings)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export function useFreeLimeSettingsMutation(optionsInjector = dummyMutationInjector) {
  const client = useQueryClient()
  const plantId = usePlantId()
  const {
    axisRange: currentAxisRange,
    defaultTimeDurationFreeLime: currentTimeDuration,
    targetRange: currentTargetRange
  } = usePlantFreeLimeSettings()

  return useMutation(
    optionsInjector({
      mutationFn: ({
        axisRange,
        targetRange,
        defaultTimeDurationFreeLime
      }: Partial<FreeLimeSettings>) =>
        updateFreelimeSettings(plantId, {
          axisRange: axisRange ?? currentAxisRange,
          defaultTimeDurationFreeLime: defaultTimeDurationFreeLime ?? currentTimeDuration,
          targetRange: targetRange ?? currentTargetRange
        }),
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetPlants)
      }
    })
  )
}
