import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {ArrowDownward, ArrowUpward, FilterList} from '@mui/icons-material'
import {TableCell, TableCellProps} from '@mui/material'
import React from 'react'

import {SortType} from '../helpers/types'
import {useTableStyles} from '../helpers/useTableStyles'

import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Box} from '@predict/WebUILib/layouts/Box/Box'

interface GeneralTableHeaderCellProps extends TableCellProps {
  isFiltered?: boolean
  sortType?: SortType
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void
  alwaysCellBorder?: boolean
  isCompact?: boolean
}

export function GeneralTableHeaderCell({
  onClick,
  isFiltered,
  sortType,
  alwaysCellBorder,
  children,
  sx,
  isCompact = false,
  ...rest
}: GeneralTableHeaderCellProps) {
  const tableSx = useTableStyles(isCompact)

  return (
    <TableCell
      onClick={onClick}
      sx={mergeSxs(
        [
          tableSx.tableHeaderCell,
          tableSx.cell,
          alwaysCellBorder ? tableSx.alwaysCellBorder : tableSx.cellBorder,
          !!onClick && {
            cursor: 'pointer',
            position: 'relative'
          }
        ],
        sx
      )}
      {...rest}
    >
      {children}
      {(sortType || isFiltered) && (
        <Text
          component="span"
          sx={{
            position: 'absolute',
            top: 0,
            right: 2,
            height: '100%'
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            {sortType === 'asc' && (
              <ArrowUpward
                data-test-id="general-table-header-cell-sort-asc-icon"
                sx={{fontSize: '0.75rem'}}
              />
            )}
            {sortType === 'desc' && (
              <ArrowDownward
                data-test-id="general-table-header-cell-sort-desc-icon"
                sx={{fontSize: '0.75rem'}}
              />
            )}
            {isFiltered && (
              <FilterList
                data-test-id="general-table-header-cell-filter-icon"
                sx={{fontSize: '0.75rem'}}
              />
            )}
          </Box>
        </Text>
      )}
    </TableCell>
  )
}
