import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react'

import {StrengthLevel} from '../declarations/MaterialData'
import {usePlantCementStrengthConfig} from '../hooks/queries/usePlantCementStrengthConfig'

import {usePlantsDomainContext} from '@predict/DomainsLib/plants'
import {DateTimeParam, dateTimeParamsFromHorizon, TimeRange} from '@predict/UtilsLib/dateTime'
import {NOW_PARAM} from '@predict/UtilsLib/dateTime/constants'

interface CSDContext {
  materialId?: number
  sampleId?: string
  strength: StrengthLevel
  selectedSources: string[]
  start: DateTimeParam
  end: DateTimeParam
  timeRange: TimeRange<DateTimeParam>
}

const CementStrengthDomainContext = createContext<CSDContext | undefined>(undefined)

export function useCementStrengthDomainContext(): CSDContext {
  const context = useContext(CementStrengthDomainContext)
  if (!context) {
    throw new Error('useCementStrengthDomainContext used outside of CementStrengthDomainProvider')
  }

  return context
}

export const CementStrengthDomainConsumer = CementStrengthDomainContext.Consumer

export function CementStrengthDomainProvider({
  materialId,
  sampleId,
  strength,
  selectedSources,
  start,
  end,
  timeRange,
  children
}: PropsWithChildren<Partial<CSDContext>>) {
  // Ensure upstream domains
  usePlantsDomainContext()

  const {
    defaultSampleTypeSources,
    defaultStrengthLevel,
    defaultTimeDurationCementStrength,
    sampleSources: sources
  } = usePlantCementStrengthConfig()

  const startTime = useMemo(
    () =>
      start ??
      timeRange?.start ??
      dateTimeParamsFromHorizon(defaultTimeDurationCementStrength).start,
    [defaultTimeDurationCementStrength, start, timeRange?.start]
  )
  const endTime = useMemo(() => end ?? timeRange?.end ?? NOW_PARAM, [end, timeRange?.end])
  const range = useMemo(
    () => timeRange ?? {start: startTime, end: endTime},
    [endTime, startTime, timeRange]
  )

  const defaultSampleSources = useMemo(
    () =>
      sources
        .filter(({sampleType}) => defaultSampleTypeSources.includes(sampleType))
        .flatMap(({names}) => names),
    [defaultSampleTypeSources, sources]
  )

  return (
    <CementStrengthDomainContext.Provider
      value={{
        materialId,
        sampleId,
        strength: strength ?? defaultStrengthLevel,
        selectedSources: selectedSources ?? defaultSampleSources,
        start: startTime,
        end: endTime,
        timeRange: range
      }}
    >
      {children}
    </CementStrengthDomainContext.Provider>
  )
}
