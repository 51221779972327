import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

export function useSelectedTagIdsSearchParam() {
  const [selectedTagIds, setSelectedTagIds] = useTypedURLSearchParam(
    'selectedTagIds',
    ParamTypes.array
  )
  return {
    selectedTagIds,
    setSelectedTagIds
  }
}
