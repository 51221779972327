import {styled} from '@mui/material'
import {isNumber} from 'lodash'
import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {useKey} from 'react-use'

import {DateTimeParam, TimeRange, zoom} from '@predict/UtilsLib/dateTime'
import {DEFAULT_ZOOM_OUT_FACTOR} from '@predict/UtilsLib/general/constants'
import {hoveredColor, TimeScaleFn} from '@predict/WebUILib/Charts/helpers'

interface TimeZoomWrapperProps extends PropsWithChildren<unknown> {
  x: TimeScaleFn
  chartHeight: number
  chartWidth: number
  onTimeRangeChanged: (timeRange: TimeRange<DateTimeParam>) => void
  zoomOutFactor?: number
}

const BoundingRect = styled('rect')(({theme}) => ({
  fill: hoveredColor(theme),
  fillOpacity: 0.25,
  stroke: hoveredColor(theme),
  strokeOpacity: 0.5
}))

const BUTTON_LEFT = 0
const BUTTONS_LEFT = 1

function isLeftButtonDown(event: React.MouseEvent): boolean {
  return Boolean(event.buttons & BUTTONS_LEFT)
}

export function getRelativePosition(event: React.MouseEvent): {x: number; y: number} {
  const rect = event.currentTarget.getBoundingClientRect()
  return {x: event.clientX - rect.x, y: event.clientY - rect.y}
}

export function TimeZoomWrapper({
  x,
  chartHeight,
  chartWidth,
  onTimeRangeChanged,
  zoomOutFactor = DEFAULT_ZOOM_OUT_FACTOR,
  children
}: TimeZoomWrapperProps) {
  const [startX, setStartX] = useState<number>()
  const [endX, setEndX] = useState<number>()
  const onMouseDown = useCallback((event: React.MouseEvent<SVGRectElement>) => {
    if (event.button === BUTTON_LEFT) {
      const pos = getRelativePosition(event)
      setStartX(pos.x)

      event.preventDefault()
    }
  }, [])

  const onMouseLeave = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (isNumber(startX)) {
        const pos = getRelativePosition(event)
        setEndX(pos.x > startX ? chartWidth : 0)

        event.preventDefault()
      }
    },
    [chartWidth, startX]
  )

  const onZoomOutKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        const start = x.invert(0).getTime()
        const end = x.invert(chartWidth).getTime()
        const zoomed = zoom(
          {
            start,
            end
          },
          zoomOutFactor,
          Date.now()
        )
        onTimeRangeChanged(zoomed)
        event.preventDefault()
      }
    },
    [chartWidth, onTimeRangeChanged, x, zoomOutFactor]
  )

  useKey('z', onZoomOutKey, undefined, [onZoomOutKey])
  useKey('Z', onZoomOutKey, undefined, [onZoomOutKey])

  useEffect(() => {
    const onMouseUp = (event) => {
      if (event.button === BUTTON_LEFT) {
        setStartX(undefined)
        setEndX(undefined)

        if (isNumber(startX) && isNumber(endX)) {
          const startTime = x.invert(startX).getTime()
          const endTime = x.invert(endX).getTime()

          if (startTime < endTime) {
            onTimeRangeChanged({start: startTime, end: endTime})
          } else if (startTime > endTime) {
            onTimeRangeChanged({start: endTime, end: startTime})
          }
        }

        event.preventDefault()
      }
    }

    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [endX, onTimeRangeChanged, startX, x])

  const onMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (!isNumber(startX)) {
        return
      }
      if (!isLeftButtonDown(event)) {
        setStartX(undefined)
        setEndX(undefined)
      }
      const pos = getRelativePosition(event)
      setEndX(pos.x)

      event.preventDefault()
    },
    [startX]
  )

  const showSelection = isNumber(startX) && isNumber(endX)

  return (
    <g onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
      {showSelection ? (
        <BoundingRect
          x={Math.min(startX, endX)}
          y={0}
          height={chartHeight}
          width={Math.abs(endX - startX)}
        />
      ) : null}
      <rect fill="transparent" x={0} y={0} height={chartHeight} width={chartWidth} />
      {children}
    </g>
  )
}
