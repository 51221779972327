import type {TFunction} from 'i18next'
import {isNumber} from 'lodash'

import {SelectedPoint} from './SelectedPoint'

import {formatFloat} from '@predict/UtilsLib/general'
import {VerticalMarkerPoint} from '@predict/WebUILib/Charts/atoms/VerticalMarker/VerticalMarker'
import {ChartData, PREDICTION_DASHARRAY_SMALL} from '@predict/WebUILib/Charts/helpers'
import {InfoItemData} from '@predict/WebUILib/Charts/molecules/InfoItem/InfoItem'

export function calcSelectedPoints(chartData: ChartData[], timestamp: number): SelectedPoint[] {
  const selectedPoints: SelectedPoint[] = []
  chartData.forEach((data) => {
    const actualValue = data.actualValues.find(({datetime}) => datetime === timestamp)?.value
    const predictedValue = data.predictions?.find(({datetime}) => datetime === timestamp)?.value
    if (isNumber(actualValue) || isNumber(predictedValue)) {
      selectedPoints.push({
        datetime: timestamp,
        data,
        actualValue,
        predictedValue
      })
    }
  })
  return selectedPoints
}

export function calcVerticalMarkerPoint(
  points: SelectedPoint[],
  yScaleFnMap
): VerticalMarkerPoint[] {
  return points.flatMap<VerticalMarkerPoint>(({data, actualValue, predictedValue}) => {
    const verticalMarkerPoints: VerticalMarkerPoint[] = []
    if (isNumber(actualValue)) {
      verticalMarkerPoints.push({
        id: data.id,
        posY: yScaleFnMap[data.id](actualValue),
        color: data.color
      })
    }
    if (isNumber(predictedValue)) {
      verticalMarkerPoints.push({
        id: `${data.id}Predicted`,
        posY: yScaleFnMap[data.id](predictedValue),
        color: data.predictionColor ?? data.color
      })
    }
    return verticalMarkerPoints
  })
}

function valueWithUnit(value: number, unit?: string): string {
  const options = {fractionDigits: value < 10 ? 2 : 1}
  return unit ? `${formatFloat(value, options)} ${unit}` : formatFloat(value, options)
}

export function calcInfoBoxItems(points: SelectedPoint[], t: TFunction): InfoItemData[] {
  return points.flatMap<InfoItemData>(({data, actualValue, predictedValue}) => {
    const infoItemData: InfoItemData[] = []
    if (isNumber(actualValue)) {
      infoItemData.push({
        color: data.color,
        label: data.name,
        value: valueWithUnit(actualValue, data.unit)
      })
    }
    if (isNumber(predictedValue)) {
      infoItemData.push({
        color: data.predictionColor ?? data.color,
        label: `${data.name} ${t('chart.predicted')}`,
        value: valueWithUnit(predictedValue, data.unit),
        strokeDashArray: PREDICTION_DASHARRAY_SMALL
      })
    }
    return infoItemData
  })
}
