import React from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'

import {CementStrengthTargetSettings} from './components/CementStrengthTargetSettings'
import {CementStrengthViewSettings} from './components/CementStrengthViewSettings'
import {FreeLimeTargetSettings} from './components/FreeLimeTargetSettings'
import {FreeLimeViewSettings} from './components/FreeLimeViewSettings'
import {SensorMetaDataSettings} from './components/SensorMetaDataSettings'
import {HasDisabled} from './declarations/helperTypes'

import {usePlantId, usePlantProductionLineIds} from '@predict/DomainsLib/plants'
import {userMayWriteSettings, useUser} from '@predict/DomainsLib/users'
import {ReverseStringsRecord} from '@predict/UtilsLib/general'
import {HelmetTitle} from '@predict/WebApp/modules/general'
import {
  ROOT_PATH,
  SETTINGS_CEMENT_PATH,
  useExactMatchedRoute,
  getPageUrl,
  PredictPageId,
  usePlantNavigation,
  SETTINGS_CLINKER_PATH,
  SETTINGS_CEMENT_STRENGTH_PATH
} from '@predict/WebApp/modules/navigation'
import {PlantPageShell, ProductionLineSelect} from '@predict/WebApp/modules/plants'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {ActionBar} from '@predict/WebUILib/layouts/ActionBar/ActionBar'
import {HeaderWithTabs} from '@predict/WebUILib/molecules/HeaderWithTabs/HeaderWithTabs'

// Sub Pages
export type SettingsView = 'clinker' | 'cement'
export const SETTINGS_VIEWS: SettingsView[] = ['clinker', 'cement']
export const DEFAULT_SETTINGS_VIEW: SettingsView = 'clinker'

const SettingsViewToPredictPageId: Record<SettingsView, PredictPageId> = {
  clinker: 'settingsClinker',
  cement: 'settingsCementStrength'
}

const PredictPageIdToSettingsView = ReverseStringsRecord(SettingsViewToPredictPageId)

// Tabs

function ClinkerSettings({disabled}: HasDisabled) {
  return (
    <div data-test-id="settings-container-panel-clinker">
      <FreeLimeTargetSettings disabled={disabled} />
      <Spacing height={2} />
      <FreeLimeViewSettings disabled={disabled} />
      <Spacing height={2} />
      <SensorMetaDataSettings disabled={disabled} />
    </div>
  )
}

function CementStrengthSettings({disabled}: HasDisabled) {
  return (
    <div data-test-id="settings-container-panel-cement-strength">
      <CementStrengthTargetSettings disabled={disabled} />
      <Spacing height={2} />
      <CementStrengthViewSettings disabled={disabled} />
    </div>
  )
}

// Page
export function SettingsPage() {
  const {t} = useTranslation()
  const navigation = usePlantNavigation()
  const plantId = usePlantId()
  const user = useUser()
  const isReadOnly = !userMayWriteSettings(user)
  const matchedRoute = useExactMatchedRoute()
  const productionLineNumbers = usePlantProductionLineIds()
  const selectedView = matchedRoute
    ? PredictPageIdToSettingsView[matchedRoute.pageId]
    : DEFAULT_SETTINGS_VIEW

  const onViewChange = (newView: SettingsView) => {
    navigation.navigate({
      plantPage: SettingsViewToPredictPageId[newView],
      pathParams: {plant: plantId},
      resetQueryParams: true
    })
  }

  const getSettingsPageTitle = (view: SettingsView) =>
    `${t(`settingsContainer.tab.${view}`)} ${t('settingsPage.title')}`

  return (
    <PlantPageShell>
      <ActionBar
        title={
          <HeaderWithTabs
            title={t('settingsPage.title')}
            views={SETTINGS_VIEWS}
            selectedView={selectedView}
            defaultView={DEFAULT_SETTINGS_VIEW}
            onChange={onViewChange}
            tabLabel={(view) => t(`settingsContainer.tab.${view}`)}
            tabTestId={(view) => `settings-container-tab-${view}`}
            tabsTestId="settings-container-tabs"
          />
        }
      >
        {productionLineNumbers.length > 1 && selectedView === 'clinker' && <ProductionLineSelect />}
      </ActionBar>
      <div role="tabpanel" data-test-id="settings-container-tab-panel">
        <Switch>
          <Route path={SETTINGS_CLINKER_PATH} exact>
            <HelmetTitle title={getSettingsPageTitle('clinker')} />
            <ClinkerSettings disabled={isReadOnly} />
          </Route>
          <Route path={SETTINGS_CEMENT_PATH} exact>
            <HelmetTitle title={getSettingsPageTitle('cement')} />
            <CementStrengthSettings disabled={isReadOnly} />
          </Route>
          <Redirect from={SETTINGS_CEMENT_STRENGTH_PATH} to={SETTINGS_CEMENT_PATH} />
          <Redirect
            to={
              plantId
                ? getPageUrl(SettingsViewToPredictPageId[DEFAULT_SETTINGS_VIEW], {
                    plant: plantId
                  })
                : ROOT_PATH
            }
          />
        </Switch>
      </div>
    </PlantPageShell>
  )
}
