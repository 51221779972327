import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  getCementStrengthSample,
  getChemicalData,
  getGeneralData,
  getMineralogicalData,
  getParticleSizes,
  getPhysicalData
} from '../utils/cementStrengthSampleDetails'

import {SampleDataBox} from './SampleDataBox'

import {CementStrengthSampleDetails, useMaterial} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {CementStrengthTable} from '@predict/WebApp/modules/cementStrength/components/CementStrengthTable'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {useBreakPoints} from '@predict/WebUILib/helpers/hooks/useBreakPoints'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'

interface SampleDataContainerProps {
  sample?: CementStrengthSampleDetails
}

interface DataBoxProps {
  sample?: CementStrengthSampleDetails
}

function GeneralDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()

  return (
    <Box paddingBottom={3}>
      <SampleDataBox
        qualityParameters={getGeneralData(sample, timeZone, t('sampleDetails.dateFormat'))}
        caption={t('sampleData.generalCaption')}
        data-test-id={'sample-data-container-general-box'}
      />
    </Box>
  )
}

function CementStrengthDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()
  const material = useMaterial()
  const levels = material?.levels ?? []

  return (
    <Box paddingBottom={3}>
      <DefaultBox data-test-id="sample-data-container-strength-box">
        <Text variant="h6" gutterBottom>
          {t('sampleData.strengthCaption')}
        </Text>
        <CementStrengthTable
          levels={levels}
          sample={getCementStrengthSample(sample)}
          valueRowsFontSize={16}
          isValuesBold
        />
      </DefaultBox>
    </Box>
  )
}

function PhysicalDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={3}>
      <SampleDataBox
        qualityParameters={getPhysicalData(sample)}
        caption={t('sampleData.physicalCaption')}
        data-test-id={'sample-data-container-physical-box'}
      />
    </Box>
  )
}

function ChemicalDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={3}>
      <SampleDataBox
        qualityParameters={getChemicalData(sample)}
        caption={t('sampleData.chemicalCaption')}
        data-test-id={'sample-data-container-chemical-box'}
      />
    </Box>
  )
}

function MineralogicalDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={3}>
      <SampleDataBox
        qualityParameters={getMineralogicalData(sample)}
        caption={t('sampleData.mineralogicalCaption')}
        data-test-id={'sample-data-container-mineralogical-box'}
      />
    </Box>
  )
}

function ParticleDataBox({sample}: DataBoxProps) {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={3}>
      <SampleDataBox
        qualityParameters={getParticleSizes(sample)}
        caption={t('sampleData.particleSizeCaption')}
        data-test-id={'sample-data-container-particle-box'}
      />
    </Box>
  )
}

export function SampleDataContainer({sample}: SampleDataContainerProps) {
  const breakpoint = useBreakPoints()
  const isMedium = breakpoint === 'md'
  const isMobile = breakpoint === 'sm' || breakpoint === 'xs'
  return (
    <GridContainer>
      <GridItem xs={12} md={6} lg={4}>
        <Box padding={0} paddingRight={isMobile ? 0 : 1.5}>
          <GeneralDataBox sample={sample} />
          <CementStrengthDataBox sample={sample} />
          <PhysicalDataBox sample={sample} />
          <ChemicalDataBox sample={sample} />
        </Box>
      </GridItem>
      <GridItem xs={12} md={6} lg={8}>
        <GridContainer>
          <GridItem xs={12} md={12} lg={6}>
            <Box
              padding={0}
              paddingRight={isMedium || isMobile ? 0 : 1.5}
              paddingLeft={isMobile ? 0 : 1.5}
            >
              <MineralogicalDataBox sample={sample} />
            </Box>
          </GridItem>
          <GridItem xs={12} md={12} lg={6}>
            <Box padding={0} paddingLeft={isMobile ? 0 : 1.5}>
              <ParticleDataBox sample={sample} />
            </Box>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}
