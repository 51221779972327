import {styled} from '@mui/material'
import {isUndefined} from 'lodash'
import React from 'react'

type AxisPosition = 'bottom' | 'left' | 'top' | 'right'

interface AxisLabelProps {
  position: AxisPosition
  height: number
  width: number
  label: string
  color: string
  offset?: number
  className?: string
  'data-test-id'?: string
}

const DEFAULT_VERTICAL_OFFSET = 48
const DEFAULT_HORIZONTAL_OFFSET = 32

const LabelText = styled('text')(({theme}) => ({
  ...theme.typography.caption
}))

/**
 * It renders a label for an axis
 * @returns A React component that renders a text element.
 */
export function AxisLabel({
  position,
  height,
  width,
  label,
  color,
  offset,
  className,
  'data-test-id': dataTestId
}: AxisLabelProps) {
  // Calculate position
  let xTranslation = 0
  let yTranslation = 0
  let rotation = 0
  let safeOffset = offset
  if (position === 'bottom') {
    // Set default offset
    if (isUndefined(safeOffset)) safeOffset = DEFAULT_VERTICAL_OFFSET
    // Center horizontally
    xTranslation = 0.5 * width
    // Move bottom and add offset
    yTranslation = height + safeOffset
  } else if (position === 'top') {
    // Set default offset
    if (isUndefined(safeOffset)) safeOffset = -DEFAULT_VERTICAL_OFFSET
    // Center horizontally
    xTranslation = 0.5 * width
    // Add offset
    yTranslation = safeOffset
  } else if (position === 'left') {
    // Set default offset
    if (isUndefined(safeOffset)) safeOffset = -DEFAULT_HORIZONTAL_OFFSET
    // Center vertically
    yTranslation = 0.5 * height
    // Add offset
    xTranslation = safeOffset
    // Rotate counter clockwise
    rotation = -90
  } else if (position === 'right') {
    // Set default offset
    if (isUndefined(safeOffset)) safeOffset = DEFAULT_HORIZONTAL_OFFSET
    // Center vertically
    yTranslation = 0.5 * height
    // Move bottom and add offset
    xTranslation = width + safeOffset
    // Rotate clockwise
    rotation = 90
  }

  return (
    <LabelText
      fill={color}
      textAnchor="middle"
      transform={`translate(${xTranslation}, ${yTranslation}) rotate(${rotation})`}
      className={className}
      data-test-id={dataTestId}
    >
      {label}
    </LabelText>
  )
}
