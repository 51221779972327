import React, {PropsWithChildren} from 'react'

import {GridContainer, GridItem} from '../Grid/Grid'
import {PageLayout} from '../PageLayout/PageLayout'

export function NarrowCenteredLayout({children}: PropsWithChildren<unknown>) {
  return (
    <PageLayout>
      <GridContainer spacing={2} justifyContent="center">
        <GridItem xs={12} sm={6} lg={4} xl={3}>
          {children}
        </GridItem>
      </GridContainer>
    </PageLayout>
  )
}
