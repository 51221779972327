import {localTimeToTimeZoneTime, timeZoneTimeToLocalTime} from '@hconnect/common/dates/timeZone'
import i18next, {TFunction} from 'i18next'
import {isNumber, isString} from 'lodash'
import moment from 'moment-timezone'

import {isEqualCaseInsensitive} from '../../general/utils/helpers'
import {DEFAULT_TIME_ZONE, DURATION_PREFIX, NOW_PARAM} from '../constants'
import {DateTimeParam, TimeHorizonId, TimeRange} from '../declarations/dateTimeTypes'

import {getTimeHorizonIdAsDurationIsoString, TIME_HORIZONS} from './timeHorizon'

export const parseDate = (dateString: string): number => +moment.utc(dateString)

export const absoluteTimeToLocalTime = (
  timeZoneDate: Date | string | number,
  timeZone: string
): Date => timeZoneTimeToLocalTime(timeZoneDate, timeZone)

export const localTimeToAbsoluteTime = (localTime: Date, timeZone: string): Date =>
  localTimeToTimeZoneTime(localTime, timeZone)

export const formatTimeZoneDate = (
  date: Date | string | number,
  timeZone: string,
  format: string,
  locale = i18next.language
): string => moment.utc(date).locale(locale).tz(timeZone).format(format)

export const startOf = (
  date: Date | string | number,
  unit: moment.unitOfTime.StartOf,
  timeZone = DEFAULT_TIME_ZONE
): Date => {
  return moment
    .utc(date)
    .tz(timeZone)
    .startOf(unit === 'week' ? 'isoWeek' : unit)
    .toDate()
}

export const endOf = (
  date: Date | string | number,
  unit: moment.unitOfTime.Base,
  timeZone = DEFAULT_TIME_ZONE
): Date => {
  return moment
    .utc(date)
    .tz(timeZone)
    .add(1, unit)
    .startOf(unit === 'week' ? 'isoWeek' : unit)
    .toDate()
}

export const addTimeUnit = (
  date: Date | string | number,
  amount: number,
  unit: moment.unitOfTime.Base,
  timeZone: string
): Date => {
  return moment.utc(date).tz(timeZone).add(amount, unit).toDate()
}

export const dateTimeMinusTimeHorizon = (
  dateTime: Date | string | number,
  horizon: TimeHorizonId,
  timeZone: string
): Date => {
  const th = TIME_HORIZONS[horizon]
  return addTimeUnit(dateTime, -th.amount, th.unit, timeZone)
}

export const timeHorizonToTimeRangeRounded = (
  horizon: TimeHorizonId,
  timeZone: string,
  currentDate: Date | string | number
): TimeRange => {
  const th = TIME_HORIZONS[horizon]

  return {
    start: startOf(addTimeUnit(currentDate, -th.amount, th.unit, timeZone), 'day', timeZone),
    end: startOf(addTimeUnit(currentDate, 1, 'day', timeZone), 'day', timeZone)
  }
}

export const parseTimeRange = (range: TimeRange<string>): TimeRange => ({
  start: moment.utc(range.start).toDate(),
  end: moment.utc(range.end).toDate()
})

export const extractDurationFromRelativeDateTimeParam = (param: string): moment.Duration => {
  const dur = param.trim().substring(DURATION_PREFIX.length).toUpperCase()
  return moment.duration(dur.startsWith('P') ? dur : `P${dur}`)
}

const parseRelativeDateTimeParam = (param: string): string => {
  if (isEqualCaseInsensitive(param, NOW_PARAM)) {
    return NOW_PARAM
  }

  const prefix = param.substring(0, DURATION_PREFIX.length).toLowerCase()
  return `${prefix}${extractDurationFromRelativeDateTimeParam(param).toJSON()}`
}

const relativeDateTimeParamToDate = (param: string, now = new Date()): Date =>
  moment.utc(now.getTime()).subtract(extractDurationFromRelativeDateTimeParam(param)).toDate()

export const dateToArray = (
  date: Date
): [year: number, month: number, date: number, hours: number, minutes: number] => [
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  date.getHours(),
  date.getMinutes()
]

export const dateTimeParamToLocalDate = (
  param: DateTimeParam,
  timeZone: string,
  now = new Date()
): Date => {
  if (isNumber(param)) {
    const date = new Date(param)
    return absoluteTimeToLocalTime(date, timeZone)
  }

  return absoluteTimeToLocalTime(relativeDateTimeParamToDate(param, now), timeZone)
}

export const dateTimeParamToAbsDate = (param: DateTimeParam, now = new Date()): Date => {
  if (isNumber(param)) {
    return new Date(param)
  }

  if (param === NOW_PARAM) {
    return now
  }

  return relativeDateTimeParamToDate(param, now)
}

export const formatDateTimeParam = (
  date: DateTimeParam,
  timeZone: string,
  format: string,
  locale = i18next.language
): string => (isString(date) ? date : formatTimeZoneDate(date, timeZone, format, locale))

const humanizeRelativeDateTimeParam = (param: string, t: TFunction): string =>
  param === NOW_PARAM ? t('dateFormat.now') : param.toLowerCase().replace('p', '')

const humanizeDateTimeParam = (
  param: DateTimeParam,
  dateFormat: string,
  timeZone: string,
  t: TFunction,
  locale: string
): string =>
  isString(param)
    ? humanizeRelativeDateTimeParam(param, t)
    : formatDateTimeParam(param, timeZone, dateFormat, locale)

export const toLastDurationString = (startParam: string, t: TFunction): string => {
  const duration = extractDurationFromRelativeDateTimeParam(startParam)
  const timeUnits: {unit: moment.unitOfTime.Base; i18nKey: string}[] = [
    {unit: 'years', i18nKey: 'dateFormat.lastYears'},
    {unit: 'months', i18nKey: 'dateFormat.lastMonths'},
    {unit: 'weeks', i18nKey: 'dateFormat.lastWeeks'},
    {unit: 'days', i18nKey: 'dateFormat.lastDays'},
    {unit: 'hours', i18nKey: 'dateFormat.lastHours'},
    {unit: 'minutes', i18nKey: 'dateFormat.lastMinutes'}
  ]
  const i18nTexts = timeUnits
    .map(({unit, i18nKey}) => {
      const durInUnit = duration.as(unit)
      const count = durInUnit >= 1 ? Math.round(durInUnit) : 0
      return {
        unit,
        count,
        i18nText: t(i18nKey, {count})
      }
    })
    .filter(({count}) => count > 0)

  return i18nTexts[0]?.i18nText ?? t('dateFormat.invalidDuration')
}

export const formatDateTimeParamRange = (
  timeRange: TimeRange<DateTimeParam>,
  timeZone: string,
  dateFormat: string,
  t: TFunction,
  locale = i18next.language
): string => {
  if (timeRange.end === NOW_PARAM) {
    return isString(timeRange.start)
      ? toLastDurationString(timeRange.start, t)
      : t('dateFormat.since', {start: formatDateTimeParam(timeRange.start, timeZone, dateFormat)})
  }

  return t('dateFormat.range', {
    start: humanizeDateTimeParam(timeRange.start, dateFormat, timeZone, t, locale),
    end: humanizeDateTimeParam(timeRange.end, dateFormat, timeZone, t, locale)
  })
}

export const parseFormattedDateTimeParam = (
  date: string,
  timeZone: string,
  format: string
): DateTimeParam => {
  const trimmed = date.trim()
  if (isEqualCaseInsensitive(trimmed.substring(0, NOW_PARAM.length), NOW_PARAM)) {
    return parseRelativeDateTimeParam(trimmed)
  }
  const timeStamp = +moment.tz(trimmed, format, timeZone)
  return isNaN(timeStamp) ? NOW_PARAM : timeStamp
}

export const dateTimeParamsFromHorizon = (horizon: TimeHorizonId): TimeRange<DateTimeParam> => ({
  start: `${DURATION_PREFIX}${getTimeHorizonIdAsDurationIsoString(horizon)}`,
  end: NOW_PARAM
})

export const isRelativeDateTimeParam = (param: unknown): param is string => isString(param)

export const isDateTimeParam = (param: unknown): param is DateTimeParam =>
  isNumber(param) || isRelativeDateTimeParam(param)
