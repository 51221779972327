import {styled} from '@mui/material'
import React, {PropsWithChildren, useLayoutEffect, useMemo, useRef, useState} from 'react'

import {ClippedWrapper} from '@predict/WebUILib/Charts/layouts/ClippedWrapper/ClippedWrapper'

interface InfoBoxProps extends PropsWithChildren<unknown> {
  title: string
  chartWidth: number
  chartHeight: number
  xPos: number
  boxWidth?: number
  xRightOffset?: number
  xLeftOffset?: number
}

const Container = styled('rect')(({theme}) => ({
  fill: theme.palette.background.paper,
  stroke: theme.palette.grey[700]
}))

const Title = styled('text')(({theme}) => ({
  ...theme.typography.body1,
  fontWeight: 'bold'
}))

export const DEFAULT_BOX_WIDTH = 320
export const MARGIN_X = 8
export const MARGIN_Y = 24
export const PADDING_X = 16
export const PADDING_Y = 16
// titleHeight = title line height + title bottom margin
const TITLE_HEIGHT = 17 + PADDING_Y
const BASE_BOX_HEIGHT = TITLE_HEIGHT + PADDING_Y * 2
// Shift between content <g> and <rect>
const ERROR_CORRECTION = 15

export function InfoBox({
  title,
  chartWidth,
  chartHeight,
  xPos,
  children,
  boxWidth = DEFAULT_BOX_WIDTH,
  xRightOffset = 0,
  xLeftOffset = 0
}: InfoBoxProps) {
  const childrenRef = useRef<SVGGElement>(null)
  const [childrenHeight, setChildrenHeight] = useState(0)

  useLayoutEffect(() => {
    setChildrenHeight(childrenRef.current?.getBoundingClientRect().height ?? 0)
  }, [children])

  const boxHeight = useMemo(() => BASE_BOX_HEIGHT + childrenHeight, [childrenHeight])
  const isLeft = useMemo(() => xPos > 0.5 * chartWidth, [chartWidth, xPos])
  const rectX = useMemo(
    () => (isLeft ? xPos - (boxWidth + MARGIN_X + xLeftOffset) : xPos + MARGIN_X + xRightOffset),
    [boxWidth, isLeft, xLeftOffset, xPos, xRightOffset]
  )
  const rectY = useMemo(() => chartHeight - (boxHeight + MARGIN_Y), [boxHeight, chartHeight])
  const entryWidth = useMemo(() => boxWidth - 2 * PADDING_X, [boxWidth])
  const clipPathId = 'chart-info-box-clip-path-id'

  return (
    <g data-role="info-box">
      <Container x={rectX} y={rectY} height={boxHeight} width={boxWidth} rx={8} />
      <g
        data-role="info-box-content"
        transform={`translate(${rectX + PADDING_X}, ${rectY + PADDING_Y + ERROR_CORRECTION})`}
      >
        <defs>
          <clipPath id={clipPathId}>
            <rect x={0} y={-PADDING_Y - ERROR_CORRECTION} height={boxHeight} width={entryWidth} />
          </clipPath>
        </defs>
        <ClippedWrapper clipPathId={clipPathId}>
          <Title>{title}</Title>
          <g ref={childrenRef} transform={`translate(0, ${TITLE_HEIGHT})`}>
            {children}
          </g>
        </ClippedWrapper>
      </g>
    </g>
  )
}
