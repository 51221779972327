export interface SelectableOption {
  id: string
  label: string
  selectable: boolean
}

export interface SelectableOptionCategory {
  categoryId: string
  categoryLabel: string
  options: SelectableOption[]
}

export interface SelectableOptionView {
  viewId: string
  viewLabel: string
  options: SelectableOption[] | SelectableOptionCategory[]
}
