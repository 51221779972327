import {isString} from 'lodash'
import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {KilnLabMetaData} from '../declarations/labMetaData'
import {dtoToKilnLabMetaData} from '../utils/dtoToKilnLabMetaData'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {FreelimePredictionsApi} from '@predict/DomainsLib/gen-api/FreelimePredictions'
import {usePlantId} from '@predict/DomainsLib/plants'

export function useKilnLabMetaData(): KilnLabMetaData[] {
  const plantId = usePlantId()
  const {data} = useQuery([QueryKeyName.GetFreeLimeLabMetaData], async () => {
    const response = await FreelimePredictionsApi.getplantsFreeLimeLabmetadata(plantId)

    const metaData = response.data
      .filter((dto) => isString(dto.plantSpecificTag))
      .map(dtoToKilnLabMetaData)
    return {metaData}
  })
  return useMemo(() => data?.metaData ?? [], [data])
}
