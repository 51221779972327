import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getCsSelectableOptionCategories} from '../utils/getCsSelectableOptionCategories'

import {
  getMaterialLevel,
  useCementStrengthDomainContext,
  useMaterial
} from '@predict/DomainsLib/cementStrength'
import {useLabDataDomainContext, useMaterialLabMetaData} from '@predict/DomainsLib/labData'
import {useSelectedTagIdsSearchParam} from '@predict/WebApp/modules/navigation'
import {ActionBarButton} from '@predict/WebUILib/atoms/ActionBarButton/ActionBarButton'
import {Add} from '@predict/WebUILib/helpers/icons'
import {SelectableOptionCategory} from '@predict/WebUILib/molecules/MultiSelectDataDialog/helpers'
import {MultiSelectDataDialog} from '@predict/WebUILib/molecules/MultiSelectDataDialog/MultiSelectDataDialog'

export function CementStrengthDataSelector() {
  const {t} = useTranslation()
  const [isDataDialogOpen, setIsDataDialogOpen] = useState(false)

  const {setSelectedTagIds: onTagIdsChanged} = useSelectedTagIdsSearchParam()

  const onTagsSelected = useCallback(
    (selected: string[]) => {
      onTagIdsChanged(selected)
    },
    [onTagIdsChanged]
  )

  const handleDataDialogApply = (selected: string[]) => {
    onTagsSelected(selected)
    setIsDataDialogOpen(false)
  }

  const {selectedTagIds} = useLabDataDomainContext()

  const metaData = useMaterialLabMetaData()
  const material = useMaterial()
  const {strength: level} = useCementStrengthDomainContext()
  const materialLevel = getMaterialLevel(material, level)
  const options: SelectableOptionCategory[] = useMemo(
    () =>
      material && materialLevel
        ? getCsSelectableOptionCategories(materialLevel, material.levels, metaData, t)
        : [],
    [material, metaData, materialLevel, t]
  )

  return (
    <>
      <ActionBarButton
        onClick={() => setIsDataDialogOpen(true)}
        data-test-id="chart-add-data-action-bar-btn"
        startIcon={<Add />}
      >
        {t('chartData.addData')}
      </ActionBarButton>
      <MultiSelectDataDialog
        isOpen={isDataDialogOpen}
        title={t('chartData.addData')}
        onApply={handleDataDialogApply}
        onClose={() => setIsDataDialogOpen(false)}
        options={options}
        selectedOptions={selectedTagIds}
      />
    </>
  )
}
