import {FESampleSource, BESampleSource} from './declarations/SampleSource'

export const SILO_SAMPLE_SOURCE: BESampleSource = 'silo'

export const SAMPLE_SOURCES: FESampleSource[] = ['mill', 'silo', 'hourlyProduction', 'dailyAverage']

export const SAMPLE_SOURCES_MAP: Record<FESampleSource, BESampleSource> = {
  mill: 'mill',
  silo: 'silo',
  hourlyProduction: 'Hourly production',
  dailyAverage: 'Daily average'
}
