import {CommonHeader} from '@hconnect/uikit/src/lib2'
import {CommonHeaderProps} from '@hconnect/uikit/src/lib2/shell/header/CommonHeader'
import React from 'react'
import {useHistory, useLocation} from 'react-router'

import {Env, Permission} from '@predict/UtilsLib/general'

export type ShellHeaderProps = Pick<
  CommonHeaderProps,
  'user' | 'onLogout' | 'supportedLanguages' | 'navItems' | 'additionalActions'
> & {
  plantName?: string
  plantId?: string
  permissions: Permission[]
  env: Env
  footerCopyrightNote?: string
}

export function ShellHeader({
  plantId,
  plantName,
  permissions,
  env,
  user,
  onLogout,
  supportedLanguages,
  navItems,
  footerCopyrightNote,
  additionalActions
}: ShellHeaderProps) {
  const history = useHistory()
  const location = useLocation()
  const plant = plantId && plantName ? {plantName, plantId} : undefined

  return (
    <CommonHeader
      appName="Predict"
      plant={plant}
      user={user}
      permissions={permissions}
      onLogout={onLogout}
      env={env}
      supportedLanguages={supportedLanguages}
      navItems={navItems}
      navigate={(url) => history.push(url)}
      locationPath={location.pathname}
      footer={footerCopyrightNote ? {copyrightNote: footerCopyrightNote} : undefined}
      additionalActions={additionalActions}
    />
  )
}
