import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import type {CsMaterialSample, Material} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate} from '@predict/UtilsLib/dateTime'
import {formatFloat, useKeyEventUpOrHoldTriggered} from '@predict/UtilsLib/general'
import {CementStrengthTable} from '@predict/WebApp/modules/cementStrength/components/CementStrengthTable'
import {usePlantNavigation} from '@predict/WebApp/modules/navigation'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {InAppLink} from '@predict/WebUILib/atoms/InAppLink/InAppLink'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {NavigateBefore, NavigateNext} from '@predict/WebUILib/helpers/icons'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {SingleValueTable} from '@predict/WebUILib/organisms/SingleValueTable/SingleValueTable'

interface CementStrengthSampleDetailsProps {
  sample: CsMaterialSample
  material: Material
  onPrev: (currentSampleId: string) => void
  onNext: (currentSampleId: string) => void
  isFirstSample: boolean
  isLastSample: boolean
}

interface QualityParameter {
  key: string
  value?: number
}

const getQualityParameters = (sample: CsMaterialSample): QualityParameter[] => [
  {key: 'alite', value: sample.alite},
  {key: 'belite', value: sample.belite},
  {key: 'blaine', value: sample.blaine}
]

export function CementStrengthSampleDetails({
  sample,
  material,
  onPrev,
  onNext,
  isFirstSample,
  isLastSample
}: CementStrengthSampleDetailsProps) {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const palette = useThemePalette()
  const {levels} = material
  const onPrevSample = useCallback(() => onPrev(sample.id), [onPrev, sample.id])
  const onNextSample = useCallback(() => onNext(sample.id), [onNext, sample.id])

  useKeyEventUpOrHoldTriggered(onPrevSample, 'ArrowLeft')
  useKeyEventUpOrHoldTriggered(onNextSample, 'ArrowRight')

  const qualityParameters = useMemo(() => getQualityParameters(sample), [sample])
  const navigation = usePlantNavigation()

  return (
    <Box display="flex" flexDirection="column">
      {/* Sample navigation */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ColorButton
          variant="outlined"
          color="secondary"
          textColor={palette.primary.main}
          squareDimension={40}
          onClick={onPrevSample}
          data-test-id="sample-details-prev-button"
          disabled={isFirstSample}
        >
          <NavigateBefore />
        </ColorButton>
        <Box data-test-id="cement-strength-sample-details-caption-box">
          <Text variant="subtitle1" align="center" isBold gutterBottom>
            {formatTimeZoneDate(sample.datetime, timeZone, t('sampleDetails.dateFormat'))}
          </Text>
          <Text component="div" variant="caption" align="center" color="secondary">
            {t('sampleDetails.sampleId', {id: sample.id})}
          </Text>
        </Box>
        <ColorButton
          variant="outlined"
          color="secondary"
          textColor={palette.primary.main}
          squareDimension={40}
          onClick={onNextSample}
          data-test-id="sample-details-next-button"
          disabled={isLastSample}
        >
          <NavigateNext />
        </ColorButton>
      </Box>
      <Spacing height={2} />
      {/* silo info table */}
      <SingleValueTable
        tableId="sample-details-table"
        data={[
          {
            label: t('sampleData.millName.label'),
            value: sample.millName
          },
          {
            label: t('sampleData.silo.label'),
            value: sample.silo
          }
        ]}
        cellBuilders={{
          label: ({label}) => label,
          value: ({value}) => value || t('common.na')
        }}
        valueCellTextId={({rowIndex}) =>
          rowIndex === 0 ? 'sample-details-millName' : 'sample-details-silo'
        }
      />
      <Spacing height={2} />

      {/* CS Predictions table */}
      <div>
        <Text
          variant="subtitle1"
          isBold
          isUnderlined
          data-test-id="sample-details-strength-section-header"
        >
          {t('sampleDetails.cementStrengthTitle')}
        </Text>
      </div>
      <Spacing height={1} />
      <CementStrengthTable levels={levels} sample={sample} />

      {/* Quality parameters table */}
      {qualityParameters.length > 0 && (
        <>
          <Spacing height={2} />

          <div>
            <Text
              variant="subtitle1"
              isBold
              isUnderlined
              data-test-id="sample-details-quality-parameters-section-header"
            >
              {t('sampleDetails.qualityParameters')}
            </Text>
          </div>
          <Spacing height={1} />

          <SingleValueTable
            tableId="sample-details-table"
            data={qualityParameters.map((qp) => ({
              label: qp.key,
              value: String(qp.value)
            }))}
            cellBuilders={{
              label: ({label}) => t(`sampleData.${label}.label`),
              value: ({label, value}) =>
                Number(value)
                  ? t(`sampleData.${label}.value`, {value: formatFloat(Number(value))})
                  : t('common.na')
            }}
            valueCellTextId={({rowData}) => `sample-details-${rowData.label}`}
          />
        </>
      )}
      <Spacing height={2} />

      <InAppLink
        to={navigation.getPlantUrl({
          plantPage: 'cementSample',
          pathParams: {
            materialId: String(material.materialId),
            sampleId: String(sample.id)
          }
        })}
      >
        <ColorButton
          variant="outlined"
          color="secondary"
          textColor={palette.primary.main}
          data-test-id="sample-details-view-details-button"
          fullWidth
        >
          {t('sampleDetails.viewDetailsBtnCaption')}
        </ColorButton>
      </InAppLink>
    </Box>
  )
}
