import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  useAggregatedCemStrengthData,
  findNextSample,
  findPrevSample
} from '@predict/DomainsLib/cementStrength'
import {usePlantNavigation} from '@predict/WebApp/modules/navigation'
import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {InAppLink} from '@predict/WebUILib/atoms/InAppLink/InAppLink'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {useBreakPoints} from '@predict/WebUILib/helpers/hooks/useBreakPoints'
import {NavigateBefore, NavigateNext} from '@predict/WebUILib/helpers/icons'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {Loader} from '@predict/WebUILib/molecules/Loader/Loader'

interface SampleNavigationControlsProps {
  currentSampleId?: string
  materialId?: number
}

export function SampleNavigationControls({
  currentSampleId,
  materialId
}: SampleNavigationControlsProps) {
  const {t} = useTranslation()
  const navigation = usePlantNavigation()
  const breakpoint = useBreakPoints()
  const isMobile = breakpoint === 'xs'

  // prev/next behavior
  const {data, isLoading} = useAggregatedCemStrengthData(materialId)
  const prevSampleId = useMemo(
    () => findPrevSample(data?.samples ?? [], currentSampleId)?.id,
    [currentSampleId, data?.samples]
  )
  const nextSampleId = useMemo(
    () => findNextSample(data?.samples ?? [], currentSampleId)?.id,
    [currentSampleId, data?.samples]
  )

  const getSampleUrl = useCallback(
    (sampleId): string =>
      sampleId
        ? navigation.getPlantUrl({
            plantPage: 'cementSample',
            pathParams: {
              materialId: materialId ? String(materialId) : undefined,
              sampleId
            },
            queryParams: {sampleId: sampleId}
          })
        : '',
    [materialId, navigation]
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      marginBottom={1}
      // to be responsive on mobile
      width="calc(100vw - 64px)"
      maxWidth={376}
    >
      <Box width="50%">
        <InAppLink to={getSampleUrl(prevSampleId)}>
          <ColorButton
            disabled={!prevSampleId}
            fullWidth
            startIcon={<NavigateBefore />}
            data-test-id="sample-nav-prev-button"
          >
            {!isMobile ? t('pageActionBar.actionPrevious') : ''}
          </ColorButton>
        </InAppLink>
      </Box>
      <Spacing width={2} />
      <Box width="50%">
        <InAppLink to={getSampleUrl(nextSampleId)}>
          <ColorButton
            disabled={!nextSampleId}
            fullWidth
            endIcon={<NavigateNext />}
            data-test-id="sample-nav-next-button"
          >
            {!isMobile ? t('pageActionBar.actionNext') : ''}
          </ColorButton>
        </InAppLink>
      </Box>
    </Box>
  )
}
