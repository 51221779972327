import {DEFAULT_FREE_LIME_CONFIG} from '../constants'
import {FreeLimeConfig} from '../declarations/FreeLimeConfig'

import {PlantConfigurationDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {timeHorizonFromDuration} from '@predict/UtilsLib/dateTime'

export function plantConfigDtoToFreeLimeConfig(plants: PlantConfigurationDto[]): FreeLimeConfig[] {
  return plants
    .map((p) => ({
      ...DEFAULT_FREE_LIME_CONFIG,
      ...p.freelimeConfig,
      id: p.plantId ?? '',
      defaultTimeDurationFreeLime: timeHorizonFromDuration(
        p.defaultTimeDurationFreelime,
        DEFAULT_FREE_LIME_CONFIG.defaultTimeDurationFreeLime
      )
    }))
    .filter((p) => Boolean(p.id))
}
