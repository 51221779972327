import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  CsMaterialSample,
  getStrengthLevelInDays,
  MaterialLevel,
  getFormattedActualValue,
  getFormattedPredictedValue
} from '@predict/DomainsLib/cementStrength'
import {formatFloat} from '@predict/UtilsLib/general'
import {GeneralTable} from '@predict/WebUILib/organisms/GeneralTable/GeneralTable'

interface CementStrengthTableProps {
  levels: MaterialLevel[]
  sample?: CsMaterialSample
  valueRowsFontSize?: number
  isValuesBold?: boolean
}

export function CementStrengthTable({
  levels,
  sample,
  valueRowsFontSize,
  isValuesBold
}: CementStrengthTableProps) {
  const {t} = useTranslation()

  return (
    <GeneralTable
      tableId="sample-details-table"
      columnsConfiguration={[
        {
          name: 'strength',
          label: t('sampleDetails.strengthColumnTitle'),
          dataCellStyles: () => ({fontSize: valueRowsFontSize})
        },
        {
          name: 'target',
          label: t('sampleDetails.targetColumnTitle'),
          alignment: 'right',
          dataCellTestId: ({rowData}) => `sample-details-${rowData.strength}-target`,
          dataCellStyles: () => ({
            fontSize: valueRowsFontSize,
            fontWeight: isValuesBold ? 'bold' : undefined
          })
        },
        {
          name: 'predicted',
          label: t('sampleDetails.predictionColumnTitle'),
          alignment: 'right',
          dataCellTestId: ({rowData}) => `sample-details-${rowData.strength}-prediction`,
          dataCellStyles: () => ({
            fontSize: valueRowsFontSize,
            fontWeight: isValuesBold ? 'bold' : undefined
          })
        },
        {
          name: 'actual',
          label: t('sampleDetails.actualValueColumnTitle'),
          alignment: 'right',
          dataCellTestId: ({rowData}) => `sample-details-${rowData.strength}-actual-value`,
          dataCellStyles: () => ({
            fontSize: valueRowsFontSize,
            fontWeight: isValuesBold ? 'bold' : undefined
          })
        }
      ]}
      data={levels.map((l) => ({
        strength: l.name,
        target: l.target,
        predicted: getFormattedPredictedValue(sample, l.name, t('common.na')),
        actual: getFormattedActualValue(sample, l.name, t('common.na'))
      }))}
      cellBuilders={{
        strength: ({strength}) =>
          t('strengthLevel.strength', {count: getStrengthLevelInDays(strength)}),
        target: ({target}) => formatFloat(target),
        predicted: ({predicted}) => predicted,
        actual: ({actual}) => actual
      }}
    />
  )
}
