import React from 'react'

import type {ScaleFn} from '@predict/WebUILib/Charts/helpers'

type ChartGridProps = {
  color: string
  strokeWidth?: number
} & (
  | {
      type: 'vertical'
      xScale: ScaleFn
      xTickValues: number[]
      height: number
      yScale?: never
      yTickValues?: never
      width?: never
    }
  | {
      type: 'horizontal'
      xScale?: never
      xTickValues?: never
      height?: never
      yScale: ScaleFn
      yTickValues: number[]
      width: number
    }
  | {
      type?: 'full'
      xScale: ScaleFn
      xTickValues: number[]
      height: number
      yScale: ScaleFn
      yTickValues: number[]
      width: number
    }
)

/**
 * Generates a grid using a D3 scale and scale values.
 * it can show vertical or horizontal grid only, or both together
 */
export function ChartGrid({
  color,
  strokeWidth = 1,
  type = 'full',
  xScale,
  xTickValues,
  height,
  yScale,
  yTickValues,
  width
}: ChartGridProps) {
  return (
    <>
      {['full', 'vertical'].includes(type) && xTickValues && xScale && (
        <g data-role="vertical-grid">
          {xTickValues.map((tick) => {
            const x = xScale(tick)
            return (
              <line
                key={tick}
                stroke={color}
                strokeWidth={strokeWidth}
                x1={x}
                x2={x}
                y1={0}
                y2={height}
              />
            )
          })}
        </g>
      )}
      {['full', 'horizontal'].includes(type) && yTickValues && yScale && (
        <g data-role="horizontal-grid">
          {yTickValues.map((tick) => {
            const y = yScale(tick)

            return (
              <line
                key={tick}
                stroke={color}
                strokeWidth={strokeWidth}
                x1={0}
                x2={width}
                y1={y}
                y2={y}
              />
            )
          })}
        </g>
      )}
    </>
  )
}
