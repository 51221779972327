import React from 'react'

interface ChartProps {
  chartHeight: number
  chartWidth: number
  color: string
}

/**
 * It returns a hollow rectangle that is the size of the chart
 * @param {ChartProps}  - ChartProps
 */
export function ChartBorder({chartWidth, chartHeight, color}: ChartProps) {
  return <rect x={0} y={0} height={chartHeight} width={chartWidth} fill="none" stroke={color} />
}
