import React from 'react'
import {useTranslation} from 'react-i18next'

import {PredictPageId} from '../definitions/routeTypes'
import {usePlantNavigation} from '../hooks/usePlantNavigation'

import {InAppLink} from '@predict/WebUILib/atoms/InAppLink/InAppLink'

export type UserManualPages = Extract<
  PredictPageId,
  'userManual' | 'userManualIntroduction' | 'userManualClinker' | 'userManualCement'
>

interface UserManualLinkProps {
  page?: UserManualPages
  hash?: string
  'data-test-id'?: string
}

export function UserManualLink({
  page = 'userManualIntroduction',
  hash,
  'data-test-id': testId
}: UserManualLinkProps) {
  const {t} = useTranslation()
  const {getPlantUrl} = usePlantNavigation()

  return (
    <InAppLink
      data-test-id={testId}
      to={{
        hash,
        pathname: getPlantUrl({
          plantPage: page,
          resetQueryParams: true
        })
      }}
    >
      {t('userManualPage.title')}
    </InAppLink>
  )
}
