import React, {PropsWithChildren} from 'react'

import {ErrorMessage} from '../../molecules/ErrorMessage/ErrorMessage'
import {Loader} from '../../molecules/Loader/Loader'

import {Skeleton, SkeletonProps} from '@predict/WebUILib/molecules/Skeleton/Skeleton'

export type LoaderType = NonNullable<SkeletonProps['variant']> | 'loader'

interface AsyncContainerProps extends PropsWithChildren<unknown> {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  errorMessage?: React.ReactNode
  loaderType?: LoaderType
  loaderHeight?: number | string
  loaderMinWidth?: number | string
  loaderMargin?: number | string
  loaderMarginBottom?: number | string
}

export function AsyncContainer({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  loaderType = 'loader',
  loaderHeight,
  loaderMinWidth,
  loaderMargin,
  loaderMarginBottom,
  children
}: AsyncContainerProps) {
  if (isLoading) {
    if (loaderType === 'loader') {
      return (
        <Loader
          height={loaderHeight}
          minWidth={loaderMinWidth}
          margin={loaderMargin}
          marginBottom={loaderMarginBottom}
        />
      )
    }
    return (
      <Skeleton
        variant={loaderType}
        height={loaderHeight}
        minWidth={loaderMinWidth}
        margin={loaderMargin}
        marginBottom={loaderMarginBottom}
      />
    )
  }

  if (isError) {
    return typeof errorMessage === 'string' ? (
      <ErrorMessage>{errorMessage ?? ''}</ErrorMessage>
    ) : (
      <>{errorMessage}</>
    )
  }

  if (isSuccess) {
    return <>{children}</>
  }

  return <></>
}
