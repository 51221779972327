import {isEqual} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {usePrevious} from 'react-use'

import type {HasDisabled} from '../declarations/helperTypes'

import {
  useCementStrengthSettingsMutation,
  usePlantCementStrengthConfig,
  useSampleTypes
} from '@predict/DomainsLib/cementStrength'
import {CS_TIME_HORIZON_IDS, TimeHorizonId} from '@predict/UtilsLib/dateTime'
import {PredictionStrengthSelect} from '@predict/WebApp/modules/cementStrength'
import {TimeHorizonSelect} from '@predict/WebApp/modules/dateTime'
import {MultiSelector} from '@predict/WebUILib/atoms/MultiSelector/MultiSelector'
import {FormBox} from '@predict/WebUILib/layouts/FormBox/FormBox'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

export function CementStrengthViewSettings({disabled}: HasDisabled) {
  const {t} = useTranslation()
  const availableSampleTypes = useSampleTypes()
  const {defaultSampleTypeSources, defaultStrengthLevel, defaultTimeDurationCementStrength} =
    usePlantCementStrengthConfig()
  const prevSampleTypes = usePrevious(defaultSampleTypeSources)
  const [selectedSampleTypes, setSelectedSampleTypes] = useState(defaultSampleTypeSources)

  const [selectedTimeHorizon, setSelectedTimeHorizon] = useState<TimeHorizonId>(
    defaultTimeDurationCementStrength
  )

  const [selectedStrengthLevel, setSelectedStrengthLevel] = useState(defaultStrengthLevel)

  const {isLoading, mutate} = useCementStrengthSettingsMutation(
    useSnackbarMutationInjector(t('settings.settingsSaved'), t('settings.settingsNotSavedError'))
  )

  useEffect(() => {
    if (!isEqual(prevSampleTypes, defaultSampleTypeSources)) {
      setSelectedSampleTypes(defaultSampleTypeSources)
    }
  }, [defaultSampleTypeSources, prevSampleTypes])

  useEffect(() => {
    setSelectedTimeHorizon(defaultTimeDurationCementStrength)
  }, [defaultTimeDurationCementStrength])

  useEffect(() => {
    setSelectedStrengthLevel(defaultStrengthLevel)
  }, [defaultStrengthLevel])

  const onRevert = () => {
    setSelectedSampleTypes(defaultSampleTypeSources)
    setSelectedTimeHorizon(defaultTimeDurationCementStrength)
    setSelectedStrengthLevel(defaultStrengthLevel)
  }

  const isPristine =
    selectedStrengthLevel === defaultStrengthLevel &&
    selectedTimeHorizon === defaultTimeDurationCementStrength &&
    isEqual(selectedSampleTypes, defaultSampleTypeSources)

  const onSave = () => {
    if (isLoading || isPristine) return

    mutate({
      defaultTimeHorizon: selectedStrengthLevel,
      defaultTimeDurationCementStrength: selectedTimeHorizon,
      defaultSampleTypeSources: selectedSampleTypes
    })
  }

  return (
    <FormBox
      title={t('cementStrengthViewSettings.title')}
      onSave={onSave}
      onRevert={onRevert}
      disabled={isPristine}
      isSaving={isLoading}
      data-test-id="cement-strength-view-settings-form-box"
    >
      <GridContainer spacing={2} alignItems="flex-end">
        <GridItem xs={12}>
          {/* CsSampleTypesSelect */}
          <MultiSelector
            id="cs-sample-type-source-select-id"
            inputLabel={t('csSampleTypeSourcesSelect.label')}
            labeledItems={availableSampleTypes.map((s) => ({
              label: t(`sampleType.${s}`),
              value: s
            }))}
            selectedItems={selectedSampleTypes}
            onSelect={setSelectedSampleTypes}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12}>
          <TimeHorizonSelect
            selectable={CS_TIME_HORIZON_IDS}
            value={selectedTimeHorizon}
            onSelected={setSelectedTimeHorizon}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12}>
          <PredictionStrengthSelect
            strengthLevel={selectedStrengthLevel}
            onStrengthLevelChanged={setSelectedStrengthLevel}
            disabled={disabled}
          />
        </GridItem>
      </GridContainer>
    </FormBox>
  )
}
