import {isNumber} from 'lodash'

import {
  CsMaterialSample,
  StrengthLevel,
  levelPredictionMap,
  levelStrengthMap
} from '@predict/DomainsLib/cementStrength'
import {TimeRange, filterSamplesByTimeRange} from '@predict/UtilsLib/dateTime'
import {AccuracyPointRecord} from '@predict/WebUILib/Charts/organisms/AccuracyChart/helpers/AccuracyChartTypes'

export function csMaterialSampleToStatsRecord(
  sample: CsMaterialSample,
  strengthLevel: StrengthLevel
) {
  const actual = sample[levelStrengthMap[strengthLevel]]
  const predicted = sample[levelPredictionMap[strengthLevel]]
  if (isNumber(actual) && isNumber(predicted)) {
    return {
      id: sample.id,
      actual,
      predicted
    }
  }

  return undefined
}
export const csMaterialSampleToStatsRecords = (
  samples: CsMaterialSample[],
  strengthLevel: StrengthLevel
): AccuracyPointRecord[] =>
  samples
    .map((sample) => csMaterialSampleToStatsRecord(sample, strengthLevel))
    .filter(
      (record): record is AccuracyPointRecord =>
        Boolean(record) &&
        isNumber((record as AccuracyPointRecord).actual) &&
        isNumber((record as AccuracyPointRecord).predicted)
    )

export function csMaterialSampleToStatsRecordsByTimeRange(
  samples: CsMaterialSample[],
  strengthLevel: StrengthLevel,
  timeRange: TimeRange
): AccuracyPointRecord[] {
  return csMaterialSampleToStatsRecords(filterSamplesByTimeRange(samples, timeRange), strengthLevel)
}
