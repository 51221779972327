import {DEFAULT_LAB_DATA_CONFIG} from '../constants'
import {LabDataConfig} from '../declarations/LabDataConfig'
import {plantConfigDtoToLabDataConfig} from '../utils/plantConfigDtoToLabDataConfig'

import {useBasePlantsList, usePlantId} from '@predict/DomainsLib/plants'

export function usePlantLabDataConfig(): LabDataConfig {
  const basePlants = useBasePlantsList().data
  const plantId = usePlantId()
  const basePlant = basePlants?.find((p) => p.plantId === plantId)

  if (!basePlant) return DEFAULT_LAB_DATA_CONFIG
  return plantConfigDtoToLabDataConfig(basePlant)
}
