import {isNumber} from 'lodash'

import {AGGREGATED_SILOS_NAME, levelPredictionMap, levelStrengthMap} from '../constants'
import {CementStrengthSample} from '../declarations/CsSamples'
import {StrengthLevel} from '../declarations/MaterialData'

import {RawLabdataDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {parseDate} from '@predict/UtilsLib/dateTime'
import {stripNull} from '@predict/UtilsLib/general'

export const rawDataDtoToCsSample = (
  rawData: RawLabdataDto[],
  strength: StrengthLevel
): CementStrengthSample[] =>
  rawData.map((record) => ({
    id: record.sampleId,
    datetime: parseDate(record.sampleDate),
    strength: stripNull(record[levelStrengthMap[strength]]),
    predictedStrength: stripNull(record[levelPredictionMap[strength]]),
    location: stripNull(record.millName || (record.siloName ? AGGREGATED_SILOS_NAME : undefined)),
    materialName: record.unifiedMaterialName,
    materialId: record.unifiedMaterialId,
    alite: stripNull(record.alite),
    belite: stripNull(record.belite),
    blaine: stripNull(record.blaine),
    sO3: stripNull(record.sO3)
  }))

export const filterCsSamplesByMaterialAndSource = (
  tableSamples: CementStrengthSample[],
  selectedSources: string[],
  selectedMaterialId?: number
): CementStrengthSample[] => {
  return tableSamples
    .filter(({materialId}) => !isNumber(selectedMaterialId) || selectedMaterialId === materialId)
    .filter(({location}) => !location || selectedSources.includes(location))
}
