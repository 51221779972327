import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  useCementStrengthDomainContext,
  CsMaterialSample,
  getStrengthLevelInDays,
  MaterialLevel
} from '@predict/DomainsLib/cementStrength'
import {COLOR_ACTUAL_VALUES} from '@predict/UtilsLib/general/constants'
import {getStrengthScaleRange} from '@predict/WebApp/modules/cementStrength'
import {csMaterialSampleToStatsRecords} from '@predict/WebApp/modules/cementStrength/utils/csMaterialSampleToStatsRecords'
import {CEMENT_STRENGTH_DOMAIN_MARGIN} from '@predict/WebUILib/Charts/helpers'
import {AccuracyChart} from '@predict/WebUILib/Charts/organisms/AccuracyChart/AccuracyChart'
import {HorizontalScrollableWrapper} from '@predict/WebUILib/layouts/HorizontalScrollableWrapper/HorizontalScrollableWrapper'

interface CementStrengthAccuracyChartProps {
  samples: CsMaterialSample[]
  materialId: number
  materialLevel: MaterialLevel
  selectedSample?: CsMaterialSample
  onSelectSample: (selectedId?: string) => void
}

export function CementStrengthAccuracyChart({
  samples: visibleSamples,
  materialId,
  materialLevel,
  selectedSample,
  onSelectSample
}: CementStrengthAccuracyChartProps) {
  const {t} = useTranslation()

  const {strength: level} = useCementStrengthDomainContext()

  return (
    <HorizontalScrollableWrapper squareDimension={576}>
      <AccuracyChart
        chartId={String(materialId)}
        pointsColor={COLOR_ACTUAL_VALUES}
        chartData={csMaterialSampleToStatsRecords(visibleSamples, level)}
        targetRange={{
          ...getStrengthScaleRange(
            materialLevel.min,
            materialLevel.max,
            CEMENT_STRENGTH_DOMAIN_MARGIN
          ),
          target: materialLevel.target
        }}
        chartLabels={{
          x: t('chart.axisCementStrengthLabelActual', {
            count: getStrengthLevelInDays(materialLevel.name)
          }),
          y: t('chart.axisCementStrengthLabelPredicted', {
            count: getStrengthLevelInDays(materialLevel.name)
          })
        }}
        selectedRecordId={selectedSample?.id}
        onSelectSample={onSelectSample}
        margin={{left: 56, top: 32, bottom: 64, right: 32}}
        data-test-id={`cement-strength-line-chart-${materialId}-accuracy`}
      />
    </HorizontalScrollableWrapper>
  )
}
