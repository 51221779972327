import * as d3 from 'd3'
import {isNil, isUndefined, sortedUniq} from 'lodash'

import {MAX_CHART_DATA_POINTS, SUGGESTED_TRENDS_TICK_COUNT} from '../chartConfigurations'
import {ChartData, NullableChartData} from '../declarations/ChartData'
import {ScaleFn, LinearScaleFn} from '../declarations/scaleFn'

import {inTimeRange, reduceDataPoints, TimeRange, WithDateTime} from '@predict/UtilsLib/dateTime'
import {NumberRange, WithNullableValue} from '@predict/UtilsLib/general'

export function labelWithUnit(label: string, unit?: string): string {
  return unit ? `${label} (${unit})` : label
}

export function safeExtent(extent: [number, number] | [undefined, undefined]): [number, number] {
  if (isUndefined(extent[0])) return [0, 0]
  return extent as [number, number]
}

export function calcYScaleFunc(
  actualValues: WithNullableValue[],
  chartHeight: number,
  suggestedTickCount: number,
  predictions?: WithNullableValue[]
) {
  const data = predictions ? [...actualValues, ...predictions] : actualValues
  const domain = safeExtent(
    d3.extent(
      data.filter((v) => !isNil(v)),
      (sample) => sample.value
    )
  )
  return d3.scaleLinear().domain(domain).nice(suggestedTickCount).range([chartHeight, 0])
}

export function getScaleRange(scale: ScaleFn): NumberRange {
  const domain = scale.domain()
  return {
    min: Number(domain[0]),
    max: Number(domain[1])
  }
}

export function calcYFuncScaleMap(
  optionalChartData: ChartData[],
  chartHeight: number
): Record<string, LinearScaleFn> {
  const result: Record<string, LinearScaleFn> = {}
  optionalChartData.forEach(({id, actualValues, predictions}) => {
    result[id] = calcYScaleFunc(actualValues, chartHeight, SUGGESTED_TRENDS_TICK_COUNT, predictions)
  })
  return result
}

export function reduceChartDataPoints<D extends NullableChartData>(chartData: D): D {
  return {
    ...chartData,
    actualValues: reduceDataPoints(chartData.actualValues, MAX_CHART_DATA_POINTS),
    predictions: reduceDataPoints(chartData.predictions ?? [], MAX_CHART_DATA_POINTS)
  }
}

export function getTimestampsOfData(data: WithDateTime[][], timeRange: TimeRange): number[] {
  return sortedUniq(
    data
      .flat()
      .map(({datetime}) => datetime)
      .filter((timestamp) => inTimeRange(timeRange, timestamp))
      .sort()
  )
}
