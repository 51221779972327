import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

export function useProductionLineSearchParam() {
  const [productionLineId, setProductionLineId] = useTypedURLSearchParam(
    'productionLine',
    ParamTypes.string
  )
  return {
    productionLineId,
    setProductionLineId
  }
}
