import {useMemo} from 'react'

import {CsSampleType} from '../../declarations/CsSamples'

import {usePlantCementStrengthConfig} from './usePlantCementStrengthConfig'

export function useSampleTypes(): CsSampleType[] {
  const {sampleSources} = usePlantCementStrengthConfig()
  return useMemo(
    () =>
      sampleSources.filter((source) => source.names.length > 0).map((source) => source.sampleType),
    [sampleSources]
  )
}
