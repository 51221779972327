import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {Checkbox, keyframes, TableRow, TableRowProps, useTheme} from '@mui/material'
import {isUndefined} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TABLE_ACTIONS_COLUMN_NAME} from '../helpers/constants'
import {
  CellBuilders,
  ColumnCalculationProps,
  ColumnConfiguration,
  DataKeyBuilder,
  DataValuePresenter,
  GroupedData
} from '../helpers/types'
import {useSelectedItems} from '../helpers/useSelectedItems'
import {useTableStyles} from '../helpers/useTableStyles'

import {GeneralTableBodyCell} from './GeneralTableBodyCell'

import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'

export interface GeneralTableBodyCollapsibleRowsProps<D> extends Omit<TableRowProps, 'children'> {
  tableId: string
  columnsConfiguration: ColumnConfiguration<D>[]
  dataGroup: GroupedData<D>
  cellBuilders: CellBuilders<D>
  rowKey?: DataKeyBuilder<D>
  rowsGroupByBuilder?: DataValuePresenter<D>
  rowsGroupByKey?: string
  hasColumnBorders?: boolean
  onItemSelect?: (selections: {item: D; isSelected: boolean}[]) => void
  onRowClick?: (item: D) => void
  selectedRowKey?: string
  isCompact?: boolean
}

export function GeneralTableBodyCollapsibleRows<D>({
  tableId,
  columnsConfiguration,
  dataGroup,
  cellBuilders,
  rowKey,
  rowsGroupByBuilder,
  rowsGroupByKey,
  hasColumnBorders,
  onItemSelect,
  onRowClick,
  selectedRowKey,
  isCompact = false,
  ...rest
}: GeneralTableBodyCollapsibleRowsProps<D>) {
  const {group, data, selectedItems} = dataGroup
  const isCollapsible = !isUndefined(rowsGroupByBuilder) && !isUndefined(group)
  const isClickable = !isUndefined(onRowClick)

  const groupKey = rowsGroupByKey ?? ''

  const [isCollapsed, setIsCollapsed] = useState(isCollapsible)
  const tableSx = useTableStyles(isCompact)
  const theme = useTheme()
  const {t} = useTranslation()

  const hasMultiSelect = !isUndefined(onItemSelect)
  const hasActions = Object.prototype.hasOwnProperty.call(cellBuilders, TABLE_ACTIONS_COLUMN_NAME)

  const tableColumnsNumber = useMemo(() => {
    let colSpan = columnsConfiguration.length
    if (isCollapsible) {
      colSpan += 1
    }
    if (hasActions) {
      colSpan += 1
    }
    return colSpan
  }, [columnsConfiguration.length, hasActions, isCollapsible])

  // Rows Selection
  const onSelectItems = useCallback(
    (Items: D[]) => onItemSelect?.(data.map((d) => ({item: d, isSelected: Items.includes(d)}))),
    [data, onItemSelect]
  )
  const {selectAllState, onItemsSelect, onSelectAll} = useSelectedItems(
    data,
    selectedItems,
    onSelectItems
  )

  const rowAnim = keyframes`
    0% {
      background-color: ${theme.palette.primary.main}
    }
  `

  const cellSx = !hasColumnBorders ? tableSx.noBorderCell : undefined
  return (
    <>
      {isCollapsible && (
        <TableRow data-test-id={`${tableId}-group-${groupKey}`}>
          {hasMultiSelect && (
            <GeneralTableBodyCell
              align="center"
              sx={cellSx}
              padding="checkbox"
              isCompact={isCompact}
            >
              <Checkbox
                color="secondary"
                indeterminate={selectAllState === 'indeterminate'}
                checked={selectAllState === 'checked'}
                onChange={onSelectAll}
                inputProps={{'aria-label': t('UILibrary.GeneralTable.selectAll')}}
                data-test-id={`${tableId}-group-${groupKey}-select-all-checkbox`}
              />
            </GeneralTableBodyCell>
          )}
          <GeneralTableBodyCell colSpan={tableColumnsNumber} isCompact={isCompact}>
            <ColorButton
              variant="text"
              color="secondary"
              startIcon={isCollapsed ? <ExpandMore /> : <ExpandLess />}
              onClick={() => setIsCollapsed((o) => !o)}
              sx={[
                {
                  padding: 1,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    fontWeight: 'bold'
                  }
                },
                !isCollapsed && {
                  color: 'primary.main'
                }
              ]}
              data-test-id={`${tableId}-group-${groupKey}-expand-button`}
              data-collapsed={String(isCollapsed)}
            >
              {rowsGroupByBuilder(group)}
            </ColorButton>
          </GeneralTableBodyCell>
        </TableRow>
      )}
      {data.map((datum, index) => {
        const key = (rowKey && rowKey(datum)) || String(index)
        return (
          <TableRow
            key={key}
            data-test-id={`${tableId}-row-${key}`}
            onClick={() => onRowClick?.(datum)}
            sx={[
              isClickable && {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#018ae60a'
                }
              },
              selectedRowKey === key && {
                animation: `${rowAnim} 1500ms ${theme.transitions.easing.sharp}`
              }
            ]}
            {...rest}
          >
            {isCollapsible && (
              <GeneralTableBodyCell sx={cellSx} isCollapsed={isCollapsed} isCompact={isCompact} />
            )}
            {hasMultiSelect && (
              <GeneralTableBodyCell
                isCollapsed={isCollapsed}
                isCompact={isCompact}
                sx={cellSx}
                padding="checkbox"
                align="center"
              >
                <Checkbox
                  color="secondary"
                  onChange={(event) =>
                    onItemsSelect([{item: datum, isSelected: event.target.checked}])
                  }
                  checked={selectedItems.includes(datum)}
                  inputProps={{
                    'aria-label': t('UILibrary.GeneralTable.selectRow')
                  }}
                  data-test-id={`${tableId}-row-${key}-checkbox`}
                />
              </GeneralTableBodyCell>
            )}
            {columnsConfiguration.map(
              ({name, alignment, dataCellTestId, dataCellClassName, dataCellStyles}) => {
                const columnKey = String(name)
                const calcProps: ColumnCalculationProps<D> = {
                  rowData: datum,
                  rowIndex: index,
                  theme
                }
                return (
                  <GeneralTableBodyCell
                    key={columnKey}
                    isCollapsed={isCollapsed}
                    isCompact={isCompact}
                    sx={cellSx}
                    className={dataCellClassName && dataCellClassName(calcProps)}
                    align={alignment}
                    style={dataCellStyles && dataCellStyles(calcProps)}
                    data-test-id={
                      dataCellTestId
                        ? dataCellTestId(calcProps)
                        : `${tableId}-row-${key}-cell-${columnKey}`
                    }
                  >
                    {cellBuilders[name]?.(datum)}
                  </GeneralTableBodyCell>
                )
              }
            )}
            {hasActions && (
              <GeneralTableBodyCell
                isCollapsed={isCollapsed}
                isCompact={isCompact}
                sx={cellSx}
                align="right"
                data-test-id={`${tableId}-row-${key}-cell-actions`}
              >
                {cellBuilders[TABLE_ACTIONS_COLUMN_NAME]?.(datum)}
              </GeneralTableBodyCell>
            )}
          </TableRow>
        )
      })}
    </>
  )
}
