import {DEFAULT_FREE_LIME_CONFIG} from '../../constants'
import {FreeLimeConfig} from '../../declarations/FreeLimeConfig'
import {plantConfigDtoToFreeLimeConfig} from '../../utils/plantConfigDtoToFreeLimeConfig'

import {useBasePlantsList, usePlant} from '@predict/DomainsLib/plants'

export function usePlantFreeLimeConfig(): FreeLimeConfig {
  const basePlants = useBasePlantsList().data
  const flPlantsConfig = plantConfigDtoToFreeLimeConfig(basePlants ?? [])
  const plant = usePlant()

  return flPlantsConfig?.find((p) => p.id === plant?.id) ?? DEFAULT_FREE_LIME_CONFIG
}
