import {isUndefined} from 'lodash'
import React, {useMemo} from 'react'

import {useTypedURLSearchParam, ParamTypes} from '@predict/WebApp/modules/navigation'
import {
  GeneralTable,
  GeneralTableProps
} from '@predict/WebUILib/organisms/GeneralTable/GeneralTable'
import {
  isManipulatorCriteria,
  isSortCriteria
} from '@predict/WebUILib/organisms/GeneralTable/helpers/tableHelpers'
import {
  DataManipulator,
  ManipulatorCriteria,
  SortType
} from '@predict/WebUILib/organisms/GeneralTable/helpers/types'

export interface StatefulGeneralTableProps<D>
  extends Omit<
    GeneralTableProps<D>,
    | 'sortCriteria'
    | 'onSort'
    | 'filterCriteria'
    | 'onFilter'
    | 'rowsPerPage'
    | 'page'
    | 'count'
    | 'onPageChange'
    | 'onRowsPerPageChange'
  > {
  sortMethod?: DataManipulator<D, SortType>
  filterMethod?: DataManipulator<D>
  defaultRowsPerPage?: number
}

export function StatefulGeneralTable<D>({
  tableId,
  columnsConfiguration,
  data,
  cellBuilders,
  rowKey,
  rowsGroupBy,
  rowsGroupByBuilder,
  rowsGroupByKey,
  selectedItems,
  onSelectItems,
  onRowClick,
  noHeader,
  selectedRowKey,
  hasColumnBorders,
  sortMethod,
  filterMethod,
  defaultRowsPerPage,
  isCompact
}: StatefulGeneralTableProps<D>) {
  // Filtering
  const hasFiltering = !isUndefined(filterMethod)
  const [filtersParam, setFilterCriteria] = useTypedURLSearchParam<
    ParamTypes.object,
    ManipulatorCriteria<D>[]
  >(`${tableId}-filters`, ParamTypes.object, isManipulatorCriteria)

  const filterCriteria = useMemo(() => filtersParam ?? [], [filtersParam])

  const filteredData: D[] = useMemo(() => {
    if (!hasFiltering || filterCriteria.length < 1) return data
    return filterMethod(data, filterCriteria)
  }, [data, filterCriteria, filterMethod, hasFiltering])

  // Sorting
  const hasSorting = !isUndefined(sortMethod)
  const [sortParam, setSortCriteria] = useTypedURLSearchParam<
    ParamTypes.object,
    ManipulatorCriteria<D, SortType>[]
  >(`${tableId}-sort`, ParamTypes.object, isSortCriteria)

  const sortCriteria: ManipulatorCriteria<D, SortType>[] = useMemo(
    () => sortParam ?? [],
    [sortParam]
  )

  const sortedData: D[] = useMemo(() => {
    if (!hasSorting || sortCriteria.length < 1) return filteredData
    return sortMethod(filteredData, sortCriteria)
  }, [filteredData, hasSorting, sortCriteria, sortMethod])

  // Pagination
  const [pageNumberParam, setPageNumber] = useTypedURLSearchParam(
    `${tableId}-page-no`,
    ParamTypes.int
  )
  const pageNumber: number = useMemo(() => pageNumberParam ?? 0, [pageNumberParam])

  const [rowsPerPageParam, setRowsPerPage] = useTypedURLSearchParam(
    `${tableId}-rows-per-page`,
    ParamTypes.int
  )
  const rowsPerPage: number | undefined = useMemo(
    () => rowsPerPageParam ?? defaultRowsPerPage,
    [defaultRowsPerPage, rowsPerPageParam]
  )

  const paginatedData: D[] = useMemo(() => {
    if (!rowsPerPage) return sortedData
    return sortedData.slice(pageNumber * rowsPerPage, (pageNumber + 1) * rowsPerPage)
  }, [pageNumber, rowsPerPage, sortedData])

  // Render UI
  return (
    <GeneralTable
      tableId={tableId}
      columnsConfiguration={columnsConfiguration}
      data={paginatedData}
      cellBuilders={cellBuilders}
      rowKey={rowKey}
      rowsGroupBy={rowsGroupBy}
      rowsGroupByBuilder={rowsGroupByBuilder}
      rowsGroupByKey={rowsGroupByKey}
      filterCriteria={hasFiltering ? filterCriteria : undefined}
      onFilter={hasFiltering ? setFilterCriteria : undefined}
      sortCriteria={sortCriteria}
      onSort={hasSorting ? setSortCriteria : undefined}
      selectedItems={selectedItems}
      onSelectItems={onSelectItems}
      onRowClick={onRowClick}
      selectedRowKey={selectedRowKey}
      hasColumnBorders={hasColumnBorders}
      count={filteredData.length}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={setRowsPerPage}
      page={pageNumber}
      onPageChange={setPageNumber}
      noHeader={noHeader}
      isCompact={isCompact}
    />
  )
}
