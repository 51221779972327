import {isNumber} from 'lodash'

import type {NumberRange, TargetRange} from '../../declarations/NumberRange'

export const inNumberRange = (range: NumberRange, value: number): boolean =>
  range.min <= value && value <= range.max

export const splitIntegerRange = (integerRange: NumberRange, partsCount: number): NumberRange[] => {
  if (partsCount < 1) {
    throw new Error('partsCount must be > 1')
  }
  if (partsCount < 2) {
    return [integerRange]
  }
  const {min, max} = integerRange
  if (partsCount > max - min + 1) {
    throw new Error('the range is to small to split into disjunct parts')
  }

  const stepSize = (max - min) / partsCount
  const results: NumberRange[] = []
  for (let i = 0; i < partsCount; ++i) {
    const lower = i === 0 ? min : results[i - 1].max + 1
    const upper =
      i === partsCount - 1 ? max : Math.max(lower, Math.round(min + stepSize * (i + 1)) - 1)
    results.push({min: lower, max: upper})
  }
  return results
}

export const isNumberRange = (value: unknown): value is NumberRange =>
  Boolean(value) && isNumber((value as NumberRange).min) && isNumber((value as NumberRange).max)

export const isValidNumberRange = (range: unknown): boolean =>
  isNumberRange(range) && range.min <= range.max

export const isTargetRange = (value: unknown): value is TargetRange =>
  isNumberRange(value) && isNumber((value as TargetRange).target)

export const isValidTargetRange = (value: unknown): boolean =>
  isTargetRange(value) && inNumberRange(value, value.target)
