import {useCallback} from 'react'
import {UseMutationOptions} from 'react-query'

import {useSnackbar} from './useSnackbar'

export function useSnackbarMutationInjector(successMessage: string, errorMessage: string) {
  const {showSuccess, showError} = useSnackbar()

  return useCallback(
    <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
      options: UseMutationOptions<TData, TError, TVariables, TContext>
    ) => {
      const optionsWithMessageHooks: UseMutationOptions<TData, TError, TVariables, TContext> = {
        ...options,
        onSuccess: (data, variables, context) => {
          showSuccess(successMessage)
          return options.onSuccess?.(data, variables, context)
        },
        onError: (error, variables, context) => {
          showError(errorMessage)
          return options.onError?.(error, variables, context)
        }
      }
      return optionsWithMessageHooks
    },
    [errorMessage, showError, showSuccess, successMessage]
  )
}
