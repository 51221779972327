export const PREDICT_PAGES_PARAMS = {
  plant: 'plant',
  materialId: 'materialId',
  sampleId: 'sampleId'
} as const

export const ROOT_PATH = '/' as const

export const PLANTS_PATH = '/plants' as const
export const PLANTS_HOME_PATH = `${PLANTS_PATH}/:${PREDICT_PAGES_PARAMS.plant}` as const

export const CLINKER_PATH = `${PLANTS_HOME_PATH}/clinker` as const
export const FREE_LIME_PATH = `${PLANTS_HOME_PATH}/free-lime` as const // Used in redirects

export const CEMENT_PATH = `${PLANTS_HOME_PATH}/cement` as const
export const CEMENT_STRENGTH_PATH = `${PLANTS_HOME_PATH}/cement-strength` as const // Used in redirects

export const CEMENT_TRENDS_PATH = `${CEMENT_PATH}/:${PREDICT_PAGES_PARAMS.materialId}` as const
export const CEMENT_SAMPLE_PATH = `${CEMENT_TRENDS_PATH}/:${PREDICT_PAGES_PARAMS.sampleId}` as const

export const CEMENT_STRENGTH_TRENDS_PATH =
  `${CEMENT_STRENGTH_PATH}/:${PREDICT_PAGES_PARAMS.materialId}` as const // Used in redirects
export const CEMENT_STRENGTH_SAMPLE_PATH =
  `${CEMENT_STRENGTH_TRENDS_PATH}/:${PREDICT_PAGES_PARAMS.sampleId}` as const // Used in redirects

export const USER_MANUAL_PATH = `${PLANTS_HOME_PATH}/user-manual` as const
export const USER_MANUAL_INTRODUCTION_PATH = `${USER_MANUAL_PATH}/introduction` as const
export const USER_MANUAL_CEMENT_PATH = `${USER_MANUAL_PATH}/cement` as const
export const USER_MANUAL_CEMENT_STRENGTH_PATH = `${USER_MANUAL_PATH}/cement-strength` as const // Used in redirects
export const USER_MANUAL_CLINKER_PATH = `${USER_MANUAL_PATH}/clinker` as const
export const USER_MANUAL_FREE_LIME_PATH = `${USER_MANUAL_PATH}/free-lime` as const // Used in redirects

export const SETTINGS_PATH = `${PLANTS_HOME_PATH}/settings` as const
export const SETTINGS_CEMENT_PATH = `${SETTINGS_PATH}/cement` as const
export const SETTINGS_CEMENT_STRENGTH_PATH = `${SETTINGS_PATH}/cement-strength` as const
export const SETTINGS_CLINKER_PATH = `${SETTINGS_PATH}/clinker` as const

export const ADMIN_PATH = '/admin' as const

export const PREDICT_PAGES = {
  root: ROOT_PATH,
  plants: PLANTS_PATH,
  plantsHome: PLANTS_HOME_PATH,
  clinker: CLINKER_PATH,
  cement: CEMENT_PATH,
  cementTrends: CEMENT_TRENDS_PATH,
  cementSample: CEMENT_SAMPLE_PATH,
  userManual: USER_MANUAL_PATH,
  userManualIntroduction: USER_MANUAL_INTRODUCTION_PATH,
  userManualClinker: USER_MANUAL_CLINKER_PATH,
  userManualCement: USER_MANUAL_CEMENT_PATH,
  settings: SETTINGS_PATH,
  settingsCementStrength: SETTINGS_CEMENT_PATH,
  settingsClinker: SETTINGS_CLINKER_PATH,
  admin: ADMIN_PATH
} as const
