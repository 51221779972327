import {StrengthLevel} from '../declarations/MaterialData'

import {logger} from '@predict/UtilsLib/logger'

const strengthLevelMap: Record<StrengthLevel, number> = {
  strength1: 1,
  strength2: 2,
  strength3: 3,
  strength7: 7,
  strength28: 28
} as const

export const STRENGTH_LEVELS = Object.keys(strengthLevelMap) as StrengthLevel[]

export const compareStrengthLevels = (a: StrengthLevel, b: StrengthLevel): number =>
  strengthLevelMap[a] - strengthLevelMap[b]

export const isStrengthLevel = (str: unknown): str is StrengthLevel =>
  STRENGTH_LEVELS.includes(str as StrengthLevel)

export const getStrengthLevelFromString = (
  str: string | null | undefined
): StrengthLevel | undefined => {
  if (!str) {
    return undefined
  }

  return STRENGTH_LEVELS.find((sl) => sl.toLowerCase() === str.toLowerCase()) ?? undefined
}

export const getStrengthLevelInDays = (level: StrengthLevel): number => {
  return strengthLevelMap[level]
}

export const calcDefaultStrengthLevel = (
  levels: StrengthLevel[],
  defaultTimeHorizon?: string | null
): StrengthLevel => {
  const log = logger.context(
    'calculate default strength level from plant config',
    'calcDefaultStrengthLevel'
  )

  let defaultStrengthLevel = getStrengthLevelFromString(defaultTimeHorizon)
  if (!defaultStrengthLevel) {
    log.info(
      `The default strength level is undefined, the last supported strength level ${
        levels[levels.length - 1]
      } is used`
    )
    defaultStrengthLevel = levels[levels.length - 1]
  } else if (!levels.includes(defaultStrengthLevel)) {
    log.info(
      `The default strength level ${defaultStrengthLevel} is not supported. The last supported strength level ${
        levels[levels.length - 1]
      } is used`
    )
    defaultStrengthLevel = levels[levels.length - 1]
  }

  return defaultStrengthLevel
}
