import {useCallback} from 'react'
import {useQueryClient} from 'react-query'

import {QueryKeyName} from '@predict/DomainsLib/constants'

interface SensorDataInvalidationOptions {
  PlantSpecificSensorMetaData: boolean
  unifiedSensorMetaData: boolean
  sensorMetaData: boolean
  sensorData: boolean
}

const DEFAULT_SENSOR_DATA_INVALIDATION_OPTIONS: SensorDataInvalidationOptions = {
  PlantSpecificSensorMetaData: true,
  unifiedSensorMetaData: true,
  sensorMetaData: true,
  sensorData: true
}

export function useInvalidateSensorData(
  invalidationOptions: Partial<SensorDataInvalidationOptions> = {}
) {
  const client = useQueryClient()

  const options: SensorDataInvalidationOptions = {
    ...DEFAULT_SENSOR_DATA_INVALIDATION_OPTIONS,
    ...invalidationOptions
  }

  return useCallback(() => {
    if (options.sensorMetaData) {
      void client.invalidateQueries(QueryKeyName.GetSensorMetaData)
    }
    if (options.unifiedSensorMetaData) {
      void client.invalidateQueries(QueryKeyName.GetUnifiedSensorMetaData)
    }
    if (options.sensorData) {
      void client.invalidateQueries(QueryKeyName.GetSensorData)
    }
    if (options.PlantSpecificSensorMetaData) {
      void client.invalidateQueries(QueryKeyName.GetPlantSpecificSensorMetaData)
    }
  }, [
    client,
    options.PlantSpecificSensorMetaData,
    options.sensorData,
    options.sensorMetaData,
    options.unifiedSensorMetaData
  ])
}
