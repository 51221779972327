export {levelPredictionMap, levelStrengthMap, AGGREGATED_SILOS_NAME} from './constants'
// Declarations
export type {CsSampleType, CsSampleSource, CementStrengthSample} from './declarations/CsSamples'
export type {Material, MaterialLevel} from './declarations/Material'
export type {
  StrengthField,
  StrengthLevel,
  StrengthPredictionField,
  WithStrengthPredictions,
  WithStrengthValues,
  CsMaterialSample,
  SampleSourceData,
  MaterialData,
  AggregatedMaterialData
} from './declarations/MaterialData'
export type {
  CementStrengthSampleDetails,
  CsSampleChemicalDetails,
  CsSampleGeneralDetails,
  CsSampleMineralogicalDetails,
  CsSampleParticleSizes,
  CsSamplePhysicalDetails,
  CsSampleStrengthDetails
} from './declarations/CementStrengthSampleDetails'
export type {
  CementStrengthViewUpdateSettings,
  CementStrengthTargetLevelUpdateSettings
} from './declarations/CementStrengthUpdateSettings'

// Providers
export {
  CementStrengthDomainProvider,
  useCementStrengthDomainContext
} from './providers/CementStrengthDomainProvider'

// Hooks

// Queries
export {useMaterials} from './hooks/queries/useMaterials'
export {useMaterial} from './hooks/queries/useMaterial'
export {useMaterialLevel} from './hooks/queries/useMaterialLevel'
export {usePlantCementStrengthConfig} from './hooks/queries/usePlantCementStrengthConfig'
export {useSampleTypes} from './hooks/queries/useSampleTypes'
export {useAggregatedCemStrengthData} from './hooks/queries/useAggregatedCemStrengthData'
export {useMaterialSamplesInTimeRange} from './hooks/queries/useMaterialSamplesInTimeRange'
export {useAllCementStrengthSamples} from './hooks/queries/useAllCementStrengthSamples'
export {useCementStrengthSampleDetails} from './hooks/queries/useCementStrengthSampleDetails'

// Mutations
export {useCementStrengthSettingsMutation} from './hooks/mutations/useCementStrengthSettingsMutation'
export {useCementStrengthTargetLevelMutation} from './hooks/mutations/useCementStrengthTargetLevelMutation'

// Utils
export {convertSampleSourceDto, convertToSampleType} from './utils/convertSampleSourceDto'
export {
  compareStrengthLevels,
  getStrengthLevelFromString,
  getStrengthLevelInDays,
  isStrengthLevel,
  calcDefaultStrengthLevel,
  STRENGTH_LEVELS
} from './utils/strengthLevel'
export {calcCementStrengthStats} from './utils/calcCementStrengthStats'
export {getMaterialLevel, isValueOutOfMaterialRange} from './utils/materialUtils'
export {filterCsSamplesByMaterialAndSource} from './utils/rawDataDtoToCSSample'
export {
  findNextSample,
  findPrevSample,
  getActualValues,
  getFormattedActualValue,
  getFormattedPredictedValue,
  getPredictedValues
} from './utils/cementStrengthSampleUtils'
