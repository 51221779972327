/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {ProblemDetails, Target} from './data-contracts'
import {ContentType, genApiClient, RequestParams} from './http-client'

export class MaterialTargetLevelsApi {
  /**
   * No description
   *
   * @tags MaterialTargetLevels
   * @name PlantsSettingsCementStrengthMaterialTargetLevelsUpdate
   * @request PUT:/predict/plants/{plantId}/settings/cementStrength/materialTargetLevels/{materialId}/{horizon}
   * @secure
   */
  static plantsSettingsCementStrengthMaterialTargetLevelsUpdate = (
    plantId: string,
    materialId: number,
    horizon: string,
    data: Target,
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/plants/${plantId}/settings/cementStrength/materialTargetLevels/${materialId}/${horizon}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
}
