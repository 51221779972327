import {flatten, isEmpty, orderBy} from 'lodash'
import {useMemo} from 'react'

import {PREDEFINED_TABLE_TAGS} from '../constants'

import {useKilnLabData} from '@predict/DomainsLib/labData'
import {WithDateTime} from '@predict/UtilsLib/dateTime'

export interface ClinkerSample extends WithDateTime {
  id: string
  lsf?: number
  sr?: number
  ar?: number
  freelime?: number
  alite?: number
  belite?: number
  aluminate?: number
  ferrite?: number
}

function getEmptyClinkerSample(sampleId: string, datetime: number): ClinkerSample {
  return {
    id: sampleId,
    datetime: datetime,
    lsf: undefined,
    sr: undefined,
    ar: undefined,
    freelime: undefined,
    alite: undefined,
    belite: undefined,
    aluminate: undefined,
    ferrite: undefined
  }
}

export function useLabClinkerSamples(): {
  data: ClinkerSample[]
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  refetch: () => void
} {
  const {data, isLoading, isRefetching, isSuccess, isError, refetch} = useKilnLabData({
    tagIds: PREDEFINED_TABLE_TAGS,
    shouldPadTimeRange: false
  })

  const tableData: ClinkerSample[] = useMemo(() => {
    if (!data) return []

    const samples: Record<string, ClinkerSample> = {}

    flatten(data.map((labData) => labData.data ?? [])).forEach(({uniformTag, records}) => {
      const sampleKey = uniformTag.toLowerCase()
      records.forEach(({sampleId, datetime, value}) => {
        if (!samples[sampleId]) {
          samples[sampleId] = getEmptyClinkerSample(sampleId, datetime)
        }
        samples[sampleId][sampleKey] = value
      })
    })

    return orderBy(Object.values(samples), ['datetime', 'id'], ['desc', 'asc'])
  }, [data])

  return {
    isLoading: isLoading || (isEmpty(tableData) && isRefetching),
    isSuccess: isSuccess,
    isError: isError || !tableData,
    data: tableData,
    refetch: () => void refetch()
  }
}
