import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {StatefulGeneralTable} from '../../general/components/StatefulGeneralTable'

import {
  filterCsSamplesByMaterialAndSource,
  useAllCementStrengthSamples,
  getStrengthLevelInDays,
  isValueOutOfMaterialRange,
  useCementStrengthDomainContext,
  useMaterials,
  CementStrengthSample
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate} from '@predict/UtilsLib/dateTime'
import {formatFloatOrNa} from '@predict/UtilsLib/general'
import {logger} from '@predict/UtilsLib/logger'
import {usePlantNavigation, useSampleIdSearchParam} from '@predict/WebApp/modules/navigation'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {ErrorMessage} from '@predict/WebUILib/molecules/ErrorMessage/ErrorMessage'
import {Skeleton} from '@predict/WebUILib/molecules/Skeleton/Skeleton'
import {
  lowerCaseStringFilter,
  lowerCaseStringSort,
  TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE
} from '@predict/WebUILib/organisms/GeneralTable/helpers'
import {ColumnCalculationProps} from '@predict/WebUILib/organisms/GeneralTable/helpers/types'

interface CementStrengthSamplesTableProps {
  materialId?: number
}

const COLUMN_KEYS: (keyof CementStrengthSample)[] = [
  'datetime',
  'id',
  'location',
  'materialName',
  'strength',
  'predictedStrength',
  'blaine',
  'sO3',
  'alite',
  'belite'
]

export function CementStrengthSamplesTable({materialId}: CementStrengthSamplesTableProps) {
  const {data, isLoading, isError} = useAllCementStrengthSamples()
  const {strength: strengthLevel, selectedSources} = useCementStrengthDomainContext()
  const palette = useThemePalette()
  const {t} = useTranslation()
  const {data: materialsData} = useMaterials()
  const materials = materialsData ?? []
  const timeZone = usePlantTimeZone()
  const {navigate} = usePlantNavigation()
  const {sampleId: selectedSampleId} = useCementStrengthDomainContext()
  const {setSampleId} = useSampleIdSearchParam()

  const tableData = filterCsSamplesByMaterialAndSource(data ?? [], selectedSources, materialId)

  if (isLoading) {
    return <Skeleton variant="table" height={400} count={10} />
  }

  if (!data || isError) {
    return (
      <DefaultBox width="100%" height={560}>
        <ErrorMessage height="65%">{t('errors.noDataAvailable')}</ErrorMessage>
      </DefaultBox>
    )
  }

  return (
    <StatefulGeneralTable
      tableId="cement-strength-samples-table"
      data={tableData}
      rowKey={({id}) => String(id)}
      selectedRowKey={String(selectedSampleId)}
      columnsConfiguration={COLUMN_KEYS.map((key, index) => {
        const isStrength = key === 'strength' || key === 'predictedStrength'
        return {
          name: key,
          label: t(`cementStrengthSamplesTable.header.${key}`),
          groupLabel: isStrength
            ? t('cementStrengthSamplesTable.header.cementStrength', {
                count: getStrengthLevelInDays(strengthLevel)
              })
            : undefined,
          alignment: index >= 4 ? 'right' : 'left',
          dataCellStyles: ({rowData: sample}: ColumnCalculationProps<CementStrengthSample>) =>
            isStrength &&
            isValueOutOfMaterialRange(materials, sample.materialId, strengthLevel, sample[key])
              ? {
                  color: palette.error.main
                }
              : undefined
        }
      })}
      cellBuilders={{
        datetime: ({datetime}) =>
          formatTimeZoneDate(datetime, timeZone, t('cementStrengthSamplesTable.datetime')),
        id: ({id}) => id,
        location: ({location}) => location,
        materialName: ({materialName}) => materialName,
        strength: ({strength}) => formatFloatOrNa(strength, t('common.na')),
        predictedStrength: ({predictedStrength}) =>
          formatFloatOrNa(predictedStrength, t('common.na')),
        blaine: ({blaine}) => formatFloatOrNa(blaine, t('common.na')),
        sO3: ({sO3}) => formatFloatOrNa(sO3, t('common.na')),
        alite: ({alite}) => formatFloatOrNa(alite, t('common.na')),
        belite: ({belite}) => formatFloatOrNa(belite, t('common.na'))
      }}
      sortMethod={lowerCaseStringSort}
      filterMethod={lowerCaseStringFilter}
      onRowClick={(sample) => {
        setSampleId(sample.id)
        if (isNumber(sample.materialId)) {
          navigate({
            plantPage: 'cementSample',
            pathParams: {
              materialId: String(sample.materialId),
              sampleId: String(sample.id)
            },
            queryParams: {
              sampleId: String(sample.id)
            }
          })
        } else {
          logger.error('Cannot link a sample that has no material ID.')
        }
      }}
      defaultRowsPerPage={TABLE_RECOMMENDED_DEFAULT_ROWS_PER_PAGE}
      hasColumnBorders
    />
  )
}
