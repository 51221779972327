import {KilnLabMetaData} from '../declarations/labMetaData'

import {LabMetricsMetadata} from '@predict/DomainsLib/gen-api/data-contracts'

export const dtoToKilnLabMetaData = ({
  plantSpecificTag = '',
  uniformTag = '',
  unit = '',
  description = '',
  displayName = '',
  category = '',
  materialType = ''
}: LabMetricsMetadata): KilnLabMetaData => ({
  plantSpecificTag,
  uniformTag,
  unit,
  description: description.trim(),
  displayName: (displayName || description).trim(),
  category: category.trim(),
  materialType: materialType.trim()
})
