import {Theme, useTheme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'

export function useTableStyles(isCompact = false): Record<string, SystemStyleObject<Theme>> {
  const theme = useTheme()

  const cellPadding = isCompact ? 1 : 2

  const cellPaddingAdjustStyle: SystemStyleObject<Theme> = {
    paddingRight: cellPadding,
    paddingLeft: cellPadding,
    '&:last-child': {
      paddingRight: cellPadding
    }
  }

  const borderColor = theme.palette.grey[300]
  return {
    tableHeader: {
      backgroundColor: theme.palette.grey[50]
    },
    tableHeaderCell: {
      ...theme.typography.caption,
      ...cellPaddingAdjustStyle,
      color: theme.palette.grey[700],
      borderBottomColor: borderColor,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    },
    tableRow: {
      '&:last-child > td': {
        borderBottom: 0
      }
    },
    cell: {
      backgroundColor: 'transparent',
      transition: 'all 500ms',
      borderBottomColor: borderColor,
      ...cellPaddingAdjustStyle
    },
    cellBorder: {
      borderRightColor: borderColor,
      borderRightStyle: 'solid',
      borderRightWidth: 1,
      '&:last-child': {
        borderRight: 0
      }
    },
    collapsedCell: {
      padding: 0,
      borderBottom: 0
    },
    alwaysCellBorder: {
      borderRightColor: borderColor,
      borderRightStyle: 'solid',
      borderRightWidth: 1
    },
    noBorderCell: {
      borderRight: 0
    }
  }
}
