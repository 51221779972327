/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PlantSpecificProcessMetricInput,
  ProcessMetricsMetadataOutput,
  Sensor
} from './data-contracts'
import {ContentType, genApiClient, RequestParams} from './http-client'

export class PlantSpecificProcessMetricsApi {
  /**
   * No description
   *
   * @tags PlantSpecificProcessMetrics
   * @name GetplantsProcessMetricsPlantSpecific
   * @request GET:/predict/plants/{plantId}/processMetrics/plantSpecific
   * @secure
   */
  static getplantsProcessMetricsPlantSpecific = (
    plantId: string,
    query?: {
      productionLineId?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<ProcessMetricsMetadataOutput[], any>({
      path: `/predict/plants/${plantId}/processMetrics/plantSpecific`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags PlantSpecificProcessMetrics
   * @name PlantsProcessMetricsPlantSpecificCreate
   * @request POST:/predict/plants/{plantId}/processMetrics/plantSpecific
   * @secure
   */
  static plantsProcessMetricsPlantSpecificCreate = (
    plantId: string,
    data: Sensor,
    params: RequestParams = {}
  ) =>
    genApiClient.request<Sensor, any>({
      path: `/predict/plants/${plantId}/processMetrics/plantSpecific`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags PlantSpecificProcessMetrics
   * @name GetplantsProcessMetricsPlantSpecific2
   * @request GET:/predict/plants/{plantId}/processMetrics/plantSpecific/{plantTag}
   * @originalName getplantsProcessMetricsPlantSpecific
   * @duplicate
   * @secure
   */
  static getplantsProcessMetricsPlantSpecific2 = (
    plantId: string,
    plantTag: string,
    query?: {
      productionLineId?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<Sensor, any>({
      path: `/predict/plants/${plantId}/processMetrics/plantSpecific/${plantTag}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags PlantSpecificProcessMetrics
   * @name PlantsProcessMetricsPlantSpecificUpdate
   * @request PUT:/predict/plants/{plantId}/processMetrics/plantSpecific/{plantTag}
   * @secure
   */
  static plantsProcessMetricsPlantSpecificUpdate = (
    plantId: string,
    plantTag: string,
    data: PlantSpecificProcessMetricInput,
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, any>({
      path: `/predict/plants/${plantId}/processMetrics/plantSpecific/${plantTag}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags PlantSpecificProcessMetrics
   * @name PlantsProcessMetricsPlantSpecificDelete
   * @request DELETE:/predict/plants/{plantId}/processMetrics/plantSpecific/{plantTag}
   * @secure
   */
  static plantsProcessMetricsPlantSpecificDelete = (
    plantId: string,
    plantTag: string,
    query?: {
      productionLineId?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, any>({
      path: `/predict/plants/${plantId}/processMetrics/plantSpecific/${plantTag}`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params
    })
}
