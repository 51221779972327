import React from 'react'

import {
  IntervalCalculationMethod,
  calculateIntervalAfter,
  calculateIntervalAround,
  inNumberRange
} from './touchAreaUtils'

import type {ScaleFn} from '@predict/WebUILib/Charts/helpers'

type IntervalCalculationType = 'around' | 'after'

const IntervalCalculationMethodMap: Record<IntervalCalculationType, IntervalCalculationMethod> = {
  around: calculateIntervalAround,
  after: calculateIntervalAfter
}

interface TouchAreasProps {
  timestamps: number[]
  x: ScaleFn
  chartHeight: number
  chartWidth: number
  intervalCalculationType?: IntervalCalculationType
  onSelect?: (datetime: number) => void
  onHover?: (datetime?: number) => void
}

/**
 * Generate transparent areas to catch user hover and clicks
 * @export
 * @param {TouchAreasProps} {
 *   timestamps,
 *   x,
 *   chartHeight,
 *   chartWidth,
 *   onSelect,
 *   onHover,
 *   intervalCalculationType = 'around'
 * }
 * @return {*}  {JSX.Element}
 */
export function TouchAreas({
  timestamps,
  x,
  chartHeight,
  chartWidth,
  onSelect,
  onHover,
  intervalCalculationType = 'around'
}: TouchAreasProps) {
  const filteredTimestamps = timestamps.filter((timestamp) =>
    inNumberRange(0, x(timestamp) ?? 0, chartWidth)
  )
  const positions = filteredTimestamps.map((timestamp) => x(timestamp) ?? 0)

  return (
    <g data-role="touch-areas">
      {filteredTimestamps.map((dt, idx) => {
        const [xPos, xNextPos] = IntervalCalculationMethodMap[intervalCalculationType](
          positions,
          idx,
          chartWidth
        )

        return (
          <rect
            key={dt}
            x={xPos}
            y={0}
            height={chartHeight}
            width={xNextPos - xPos}
            fill="transparent"
            onMouseOver={() => onHover?.(dt)}
            onMouseLeave={() => onHover?.()}
            onClick={() => onSelect?.(dt)}
          />
        )
      })}
    </g>
  )
}
