import moment from 'moment'

import {Resolution} from '../../general'

export function resolutionToMomentUnit(resolution: Resolution): moment.unitOfTime.Base {
  if (resolution === Resolution.Monthly) {
    return 'month'
  }
  if (resolution === Resolution.Weekly) {
    return 'week'
  }
  return 'day'
}

export function resolutionToIsoMomentUnit(
  resolution: Resolution
): moment.unitOfTime.Base | moment.unitOfTime._isoWeek {
  if (resolution === Resolution.Weekly) {
    return 'isoWeek'
  }
  return resolutionToMomentUnit(resolution)
}
