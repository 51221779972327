import {intersection, isEmpty} from 'lodash'
import {useMemo} from 'react'
import {useQuery, UseQueryResult} from 'react-query'

import {SensorData} from '../../declarations/SensorData'
import {useSensorDataDomainContext} from '../../providers/SensorDataDomainProvider'
import {dtoToSensorDataRecords} from '../../utils/dtoToSensorDataRecords'

import {useSensorMetaData} from './useSensorMetaData'

import {
  FL_FETCH_INTERVAL_MS,
  PROCESS_METRICS_MAX_POINTS_RESOLUTION,
  QueryKeyName
} from '@predict/DomainsLib/constants'
import {ProcessMetricsApi} from '@predict/DomainsLib/gen-api/ProcessMetrics'
import {usePlantId, usePlantTimeZone, useProductionLine} from '@predict/DomainsLib/plants'
import {
  isRelativeDateTimeParam,
  padTimeRange,
  timeRangeFromStartEnd
} from '@predict/UtilsLib/dateTime'
import {DEFAULT_TIME_MARGIN_FACTOR} from '@predict/UtilsLib/dateTime/constants'

export function useSensorData(
  refetchInterval: number = FL_FETCH_INTERVAL_MS
): UseQueryResult<SensorData> {
  const plantId = usePlantId()
  const {productionLineId} = useProductionLine()
  const timeZone = usePlantTimeZone()
  const {data: sensorMetaData} = useSensorMetaData()

  const {selectedTagIds, start, end} = useSensorDataDomainContext()
  const ids = useMemo(
    () => intersection(sensorMetaData?.map((data) => data['uniformTag']) ?? [], selectedTagIds),
    [sensorMetaData, selectedTagIds]
  )
  const range = padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR)

  return useQuery<SensorData>(
    [QueryKeyName.GetSensorData, plantId, productionLineId, ids, timeZone, start, end],
    async () => {
      if (isEmpty(ids)) {
        return {data: [], range}
      }
      const response = await ProcessMetricsApi.getplantsProcessMetricsData(plantId, {
        productionLine: productionLineId,
        from: range.start.toJSON(),
        until: range.end.toJSON(),
        maxPoints: PROCESS_METRICS_MAX_POINTS_RESOLUTION,
        tags: ids
      })
      return {data: dtoToSensorDataRecords(response.data ?? []), range}
    },
    {
      refetchInterval: isRelativeDateTimeParam(end) && refetchInterval,
      staleTime: 0
    }
  )
}
