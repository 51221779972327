import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  ClientConfig,
  createHttpClient,
  createPublicHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import type {LoginFlow, LoginStorage} from '@hconnect/apiclient'
import type {AxiosInstance} from 'axios'
import {stringify} from 'query-string'

export interface Client {
  dfLoginStorage: LoginStorage
  dfApi: AxiosInstance
  publicApi: AxiosInstance
  dfLoginFlow: LoginFlow
}

export function createClientConfig(): ClientConfig {
  const dfBackendSelector = new BrowserBackendSelector(getDefaultDFBackends())
  const dfSelectedBackend = dfBackendSelector.getSelectedBackend()

  const dfLoginStorage = new BrowserLoginStorage(
    `HC-${process.env.REACT_APP_CLIENT_NAME}-${dfSelectedBackend.NAME}`
  )

  const dfAuthRequestProvider = new EnvAuthRequestProvider(dfBackendSelector)
  const dfLoginFlow = new BrowserLoginFlow(dfLoginStorage, dfAuthRequestProvider, dfBackendSelector)

  return {
    backendSelector: dfBackendSelector,
    authRequestProvider: dfAuthRequestProvider,
    loginStorage: dfLoginStorage,
    loginFlow: dfLoginFlow
  }
}

const createClient = (): Client => {
  const clientConfig = createClientConfig()

  const dfApi = createHttpClient(clientConfig)
  const publicApi = createPublicHttpClient({
    backendSelector: clientConfig.backendSelector
  })

  // Send array parameters without brackets
  dfApi.interceptors.request.use((config) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    config.paramsSerializer = (params) => stringify(params, {arrayFormat: 'none'})
    return config
  })

  return {
    dfLoginStorage: clientConfig.loginStorage,
    dfApi,
    publicApi,
    dfLoginFlow: clientConfig.loginFlow
  }
}

export const client = createClient()
