import {DateTimeRecord, TimeRange} from '@predict/UtilsLib/dateTime'

export interface FreeLimeSample extends DateTimeRecord {
  alite?: number
  belite?: number
}

export interface KilnDataSet {
  productionLineId: string
  actualValues: FreeLimeSample[]
  predictions: DateTimeRecord[]
  range: TimeRange
}

export interface FreeLimeData {
  kilnDataSet: KilnDataSet[]
}
