import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePredictionViewSearchParam} from '../hooks/searchParams/usePredictionViewSearchParam'

import {GridOn, MyLocation, ShowChart} from '@predict/WebUILib/helpers/icons'
import {
  IconButtonGroup,
  IconButtonGroupItem
} from '@predict/WebUILib/molecules/IconButtonGroup/IconButtonGroup'

interface PredictionViewButtonGroupProps {
  showTimeView?: boolean
  showAccuracyView?: boolean
  showTableView?: boolean
  'data-test-id'?: string
}

export function PredictionViewButtonGroup({
  showTimeView = true,
  showAccuracyView = true,
  showTableView = true,
  'data-test-id': dataTestId = 'prediction-view-btn'
}: PredictionViewButtonGroupProps) {
  const {t} = useTranslation()
  const {predictionView: selectedView, setPredictionView: onPredictionViewSelected} =
    usePredictionViewSearchParam()

  const predictionViewBtnData: IconButtonGroupItem[] = useMemo(() => {
    const views: IconButtonGroupItem[] = []
    if (showTimeView) {
      views.push({
        label: t('pageActionBar.actionPredictionViewTime'),
        icon: <ShowChart />,
        testId: `${dataTestId}-time`,
        isSelected: selectedView === 'time',
        onClick: () => onPredictionViewSelected('time')
      })
    }
    if (showAccuracyView) {
      views.push({
        label: t('pageActionBar.actionPredictionViewAccuracy'),
        icon: <MyLocation />,
        testId: `${dataTestId}-accuracy`,
        isSelected: selectedView === 'accuracy',
        onClick: () => onPredictionViewSelected('accuracy')
      })
    }
    if (showTableView) {
      views.push({
        label: t('pageActionBar.actionPredictionViewTable'),
        icon: <GridOn />,
        testId: `${dataTestId}-table`,
        isSelected: selectedView === 'table',
        onClick: () => onPredictionViewSelected('table')
      })
    }
    return views
  }, [
    dataTestId,
    onPredictionViewSelected,
    selectedView,
    showAccuracyView,
    showTableView,
    showTimeView,
    t
  ])

  return <IconButtonGroup items={predictionViewBtnData} data-test-id={dataTestId} />
}
