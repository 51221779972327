import {Checkbox, TableHead, TableRow} from '@mui/material'
import {isUndefined} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CheckboxState,
  ColumnConfiguration,
  HeaderConfiguration,
  ManipulatorCriteria,
  SortType
} from '../helpers/types'
import {useTableStyles} from '../helpers/useTableStyles'

import {ColumnOptions} from './ColumnOptions'
import {GeneralTableHeaderCell} from './GeneralTableHeaderCell'

import {PopOver} from '@predict/WebUILib/layouts/PopOver/PopOver'
import {usePopoverAnchor} from '@predict/WebUILib/layouts/PopOver/usePopoverAnchor'

interface GeneralTableHeaderProps<D> {
  tableId: string
  columnsConfiguration: ColumnConfiguration<D>[]
  sortCriteria?: ManipulatorCriteria<D, SortType>[]
  onSort?: (criterion: ManipulatorCriteria<D, SortType>) => void
  filterCriteria?: ManipulatorCriteria<D>[]
  onFilter?: (criterion: ManipulatorCriteria<D>) => void
  hasActions?: boolean
  hasRowGroupings?: boolean
  hasColumnBorders?: boolean
  selectAllState?: CheckboxState
  onSelectAll?: () => void
  isCompact?: boolean
}

const POPOVER_WIDTH = 308

export function GeneralTableHeader<D>({
  tableId,
  columnsConfiguration,
  sortCriteria = [],
  onSort,
  filterCriteria = [],
  onFilter,
  hasActions = false,
  hasRowGroupings = false,
  hasColumnBorders = false,
  isCompact = false,
  selectAllState,
  onSelectAll
}: GeneralTableHeaderProps<D>) {
  // Calculate flags
  const hasMultiSelect = !isUndefined(onSelectAll)
  const isClickable = !isUndefined(onSort) || !isUndefined(onFilter)

  // Get Styles
  const tableSx = useTableStyles(isCompact)
  const {t} = useTranslation()

  // Calculate Headers
  const hasHeaderGroups = columnsConfiguration.findIndex(({groupLabel}) => !!groupLabel) !== -1
  const headerRows: HeaderConfiguration<D>[][] = useMemo(() => {
    // Set output initial value
    const rows: HeaderConfiguration<D>[][] = [[], []]

    // Calculate header config for each column config
    columnsConfiguration.map((config) => {
      if (!config.groupLabel) {
        // Insert in first row if no grouping
        rows[0].push({
          ...config,
          isGroup: false,
          colSpan: 1,
          rowSpan: hasHeaderGroups ? 2 : 1
        })
      } else {
        // Insert in second row if has grouping
        rows[1].push({
          ...config,
          isGroup: false,
          colSpan: 1,
          rowSpan: 1
        })
        // Insert Grouping
        const lastFirstRowItem = rows[0][rows[0].length - 1]
        if (lastFirstRowItem.groupLabel === config.groupLabel) {
          // Increase group colspan if it's the same grouping as the last one
          lastFirstRowItem.colSpan += 1
          lastFirstRowItem.alignment = 'center'
        } else {
          // Insert new grouping
          rows[0].push({
            ...config,
            isGroup: true,
            colSpan: 1,
            rowSpan: 1
          })
        }
      }
    })
    return rows
  }, [columnsConfiguration, hasHeaderGroups])

  const [popoverColumn, setPopoverColumn] = useState<ColumnConfiguration<D>>()
  const {anchorEl, openPopover, closePopover, isOpen, id} = usePopoverAnchor(
    'table-header-sensor-settings-popover'
  )

  const onHeaderClick = useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>, columnConfig: ColumnConfiguration<D>) => {
      setPopoverColumn(columnConfig)
      openPopover(event)
    },
    [openPopover]
  )

  // Render UI
  const cellSx = !hasColumnBorders ? tableSx.noBorderCell : undefined

  return (
    <TableHead sx={tableSx.tableHeader}>
      {headerRows.map((row, rowIndex) => {
        if (row.length < 1) return null
        const isFirstRow = rowIndex === 0
        return (
          <TableRow key={rowIndex} data-test-id={`${tableId}-header-row`}>
            {isFirstRow && hasMultiSelect && (
              <GeneralTableHeaderCell
                sx={cellSx}
                padding="checkbox"
                rowSpan={hasHeaderGroups ? 2 : 1}
                align="center"
                isCompact={isCompact}
              >
                <Checkbox
                  color="secondary"
                  indeterminate={selectAllState === 'indeterminate'}
                  checked={selectAllState === 'checked'}
                  onChange={onSelectAll}
                  inputProps={{'aria-label': t('UILibrary.GeneralTable.selectAll')}}
                  data-test-id={`${tableId}-select-all-checkbox`}
                />
              </GeneralTableHeaderCell>
            )}
            {isFirstRow && hasRowGroupings && (
              <GeneralTableHeaderCell
                data-test-id={`${tableId}-header-rows-grouping-cell`}
                sx={cellSx}
                rowSpan={hasHeaderGroups ? 2 : 1}
                isCompact={isCompact}
              />
            )}
            {row.map((config) => {
              const {name, label, groupLabel, alignment, isGroup, rowSpan, colSpan} = config
              const isLastColumn =
                !hasActions && columnsConfiguration[columnsConfiguration.length - 1].name === name
              return (
                <GeneralTableHeaderCell
                  data-test-id={`${tableId}-header-row-${String(name)}`}
                  key={String(name)}
                  sortType={
                    !isGroup
                      ? sortCriteria.find(({field}) => field === name)?.expression
                      : undefined
                  }
                  isFiltered={
                    !isGroup && filterCriteria.findIndex(({field}) => field === name) !== -1
                  }
                  onClick={
                    !isGroup && isClickable ? (event) => onHeaderClick(event, config) : undefined
                  }
                  align={alignment}
                  rowSpan={rowSpan}
                  colSpan={colSpan}
                  sx={cellSx}
                  alwaysCellBorder={hasColumnBorders && !isLastColumn}
                  isCompact={isCompact}
                >
                  {isFirstRow ? groupLabel ?? label : label}
                </GeneralTableHeaderCell>
              )
            })}
            {isFirstRow && hasActions && (
              <GeneralTableHeaderCell
                data-test-id={`${tableId}-header-actions-cell`}
                sx={cellSx}
                rowSpan={hasHeaderGroups ? 2 : 1}
                isCompact={isCompact}
              />
            )}
          </TableRow>
        )
      })}
      {isClickable && (
        <PopOver
          id={id}
          isOpen={isOpen}
          onClose={closePopover}
          anchorEl={anchorEl}
          width={POPOVER_WIDTH}
        >
          {popoverColumn && (
            <ColumnOptions
              tableId={tableId}
              columnName={popoverColumn.name}
              onFilter={onFilter}
              onSort={onSort}
              onClose={closePopover}
              sortExpression={
                sortCriteria.find((sc) => sc.field === popoverColumn.name)?.expression
              }
              sortButtonLabel={t('UILibrary.GeneralTable.sortBy', {
                field: popoverColumn?.label
              })}
              filterExpression={
                filterCriteria.find((fc) => fc.field === popoverColumn.name)?.expression
              }
              filterPlaceholder={t('UILibrary.GeneralTable.filterBy', {
                field: popoverColumn.label
              })}
            />
          )}
        </PopOver>
      )}
    </TableHead>
  )
}
