import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {createChartDataFromCementStrengthSamples} from '../utils/createChartData'

import {
  AggregatedMaterialData,
  CsMaterialSample,
  MaterialLevel
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import type {TimeRange} from '@predict/UtilsLib/dateTime'
import type {NumberRange} from '@predict/UtilsLib/general'
import {getStrengthScaleRange} from '@predict/WebApp/modules/cementStrength/utils/getStrengthScaleRange'
import {CHART_MARGIN} from '@predict/WebApp/modules/general/utils/chartHelpers'
import {useTimeRangeSearchParams} from '@predict/WebApp/modules/navigation'
import {CEMENT_STRENGTH_DOMAIN_MARGIN, ChartData} from '@predict/WebUILib/Charts/helpers'
import {StepChart} from '@predict/WebUILib/Charts/organisms/StepChart/StepChart'
import {HorizontalScrollableWrapper} from '@predict/WebUILib/layouts/HorizontalScrollableWrapper/HorizontalScrollableWrapper'

interface CementStrengthMaterialChartProps {
  materialData: AggregatedMaterialData
  materialLevel: MaterialLevel
  timeRange: TimeRange
  selectedSample?: CsMaterialSample
  onSelectSample: (selectedId?: string) => void
  height?: number
}

export function CementStrengthMaterialChart({
  materialData,
  materialLevel,
  timeRange,
  selectedSample,
  onSelectSample,
  height = 480
}: CementStrengthMaterialChartProps) {
  const timeZone = usePlantTimeZone()
  const {setTimeRange: onTimeRangeChanged} = useTimeRangeSearchParams()
  const {t} = useTranslation()
  const strengthLevel = materialLevel.name
  const {samples} = materialData
  const mainGraphData: ChartData = useMemo(
    () => createChartDataFromCementStrengthSamples(samples, strengthLevel, t),
    [samples, strengthLevel, t]
  )

  const mainAxisRange = useMemo((): NumberRange => {
    return getStrengthScaleRange(
      materialLevel.min,
      materialLevel.max,
      CEMENT_STRENGTH_DOMAIN_MARGIN
    )
  }, [materialLevel.max, materialLevel.min])

  const onSelected = useCallback(
    (datetime: number) => {
      onSelectSample(samples.find((sample) => sample.datetime === datetime)?.id)
    },
    [onSelectSample, samples]
  )

  return (
    <HorizontalScrollableWrapper>
      <StepChart
        chartId={`cement-strength-line-chart-${materialData.materialId}`}
        height={height}
        margin={CHART_MARGIN}
        timeRange={timeRange}
        timeZone={timeZone}
        mainTargetRange={materialLevel}
        mainAxisRange={mainAxisRange}
        mainChartData={mainGraphData}
        selectedDateTime={selectedSample?.datetime}
        onSelected={onSelected}
        data-test-id={`cement-strength-line-chart-${materialData.materialId}`}
        onTimeRangeChanged={(timeRange: TimeRange<number>) => {
          onTimeRangeChanged(timeRange.start, timeRange.end)
        }}
      />
    </HorizontalScrollableWrapper>
  )
}
