import {isBoolean, isString} from 'lodash'

import {SelectableOptionCategory, SelectableOption, SelectableOptionView} from './SelectableOption'

import {someMatchAll} from '@predict/UtilsLib/general'

export const isSelectableOption = (option: unknown): option is SelectableOption => {
  if (option) {
    const {id, label, selectable} = option as SelectableOption
    return isString(id) && isString(label) && isBoolean(selectable)
  }
  return false
}

export const isOptionSelected = (option: SelectableOption, selectedOptions: string[]): boolean =>
  selectedOptions.includes(option.id)

export const isSelectableOptionCategory = (
  options: unknown[]
): options is SelectableOptionCategory[] =>
  options.length === 0 || isString((options[0] as SelectableOptionCategory).categoryId)

const DEFAULT_CATEGORY: SelectableOptionCategory = {
  categoryId: '',
  categoryLabel: '',
  options: []
}

export const toSelectableOptionCategories = (
  options: SelectableOption[] | SelectableOptionCategory[]
): SelectableOptionCategory[] =>
  isSelectableOptionCategory(options) ? options : [{...DEFAULT_CATEGORY, options}]

export const isSelectableOptionView = (options: unknown[]): options is SelectableOptionView[] =>
  options.length === 0 || isString((options[0] as SelectableOptionView).viewId)

const DEFAULT_VIEW: SelectableOptionView = {
  viewId: '',
  viewLabel: '',
  options: []
}

export const toSelectableOptionViews = (
  options: SelectableOption[] | SelectableOptionCategory[] | SelectableOptionView[]
): SelectableOptionView[] =>
  isSelectableOptionView(options)
    ? options
    : [{...DEFAULT_VIEW, options: toSelectableOptionCategories(options)}]

export const filterSelectableOptionCategories = (
  categories: SelectableOptionCategory[],
  filter: string[]
) =>
  categories
    .map((category) => ({
      ...category,
      options: category.options.filter(
        (option) => Boolean(option.id) && someMatchAll([option.id, option.label], filter)
      )
    }))
    .filter(({options}) => options.length > 0)
