import {isEmpty} from 'lodash'
import {useMemo} from 'react'

import {LabAndSensorChartData} from '../components/LabAndSensorCharts'
import {PREDEFINED_CHART_TAGS} from '../constants'

import {SensorData, useSensorData, useSensorDataDomainContext} from '@predict/DomainsLib/sensorData'
import {COLOR_ACTUAL_VALUES} from '@predict/UtilsLib/general/constants'
import {ChartData} from '@predict/WebUILib/Charts/helpers'

const calcSensorChartData = (sensorData: SensorData | undefined): ChartData[] => {
  return (
    sensorData?.data.map(({displayName, unit, records, uniformTag}) => ({
      id: uniformTag,
      actualValues: records,
      name: displayName,
      unit,
      color: COLOR_ACTUAL_VALUES
    })) ?? []
  )
}

export function useSensorChartData(): {
  data: LabAndSensorChartData[]
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  refetch: () => void
} {
  const {data, isLoading, isRefetching, isSuccess, isError, refetch} = useSensorData()

  const {selectedTagIds} = useSensorDataDomainContext()

  const chartData: ChartData[] = useMemo(
    () =>
      !data
        ? []
        : calcSensorChartData(data)
            .filter(({id}) => selectedTagIds.includes(id))
            .map<LabAndSensorChartData>((d) => ({
              ...d,
              canBeClosed: !PREDEFINED_CHART_TAGS.includes(d.id),
              isReloading: isRefetching
            })),
    [data, selectedTagIds, isRefetching]
  )

  return {
    isLoading: isLoading || (isEmpty(chartData) && isRefetching),
    isSuccess: isSuccess,
    isError: isError || !chartData,
    data: chartData,
    refetch: () => void refetch()
  }
}
