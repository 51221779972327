import {useCallback, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import type {PathParams, PredictPageId} from '../definitions/routeTypes'
import {getPageUrl} from '../utils/getPageUrl'
import {queryParamsToString} from '../utils/queryParamsToString'

import {usePlantParameter} from './routeParams/usePlantParameter'

// Types
export interface NavigatePlantTarget {
  plantPage: PredictPageId
  pathParams?: PathParams
  queryParams?: Record<string, string | undefined>
  resetQueryParams?: boolean
}

export interface PlantNavigation {
  navigate: (target: NavigatePlantTarget) => void
  getPlantUrl: (target: NavigatePlantTarget) => string
}

// Functions
const mergePathParamsWithDefaultPlant = (
  defaultPlant: string,
  pathParams?: PathParams
): PathParams => {
  return {...pathParams, plant: pathParams?.plant ?? defaultPlant}
}

const mergeQueryParams = (
  currentQueryParams: Record<string, string | undefined>,
  queryParams: Record<string, string | undefined> | undefined,
  reset: boolean
): Record<string, string | undefined> | undefined =>
  reset ? queryParams : {...currentQueryParams, ...queryParams}

export function usePlantNavigation(): PlantNavigation {
  const {search} = useLocation()
  const history = useHistory()
  const currentPlantParam = usePlantParameter()
  const currentQueryParams = Object.fromEntries(new URLSearchParams(search))

  const navigate = useCallback(
    ({plantPage, pathParams, queryParams, resetQueryParams = false}: NavigatePlantTarget) => {
      const search = queryParamsToString(
        mergeQueryParams(currentQueryParams, queryParams, resetQueryParams)
      )
      history.push({
        pathname: getPageUrl(
          plantPage,
          mergePathParamsWithDefaultPlant(currentPlantParam, pathParams)
        ),
        search
      })
    },
    [currentPlantParam, currentQueryParams, history]
  )
  const getPlantUrl = useCallback(
    ({plantPage, pathParams, queryParams, resetQueryParams = false}: NavigatePlantTarget) =>
      getPageUrl(
        plantPage,
        mergePathParamsWithDefaultPlant(currentPlantParam, pathParams),
        mergeQueryParams(currentQueryParams, queryParams, resetQueryParams)
      ),
    [currentPlantParam, currentQueryParams]
  )

  return useMemo(() => ({navigate, getPlantUrl}), [getPlantUrl, navigate])
}
