import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TimeRangePickerDialog} from './TimeRangePickerDialog'

import type {DateTimeParam, TimeHorizonId, TimeRange} from '@predict/UtilsLib/dateTime'
import {formatDateTimeParamRange} from '@predict/UtilsLib/dateTime'
import {ActionBarButton} from '@predict/WebUILib/atoms/ActionBarButton/ActionBarButton'
import {DateRange} from '@predict/WebUILib/helpers/icons'

interface TimeRangePickerButtonProps {
  start: DateTimeParam
  end: DateTimeParam
  timeZone: string
  selectableTimeHorizons: TimeHorizonId[]
  onTimeRangeChanged: (string: DateTimeParam, end: DateTimeParam) => void
}

export function TimeRangePickerButton({
  start,
  end,
  timeZone,
  selectableTimeHorizons,
  onTimeRangeChanged
}: TimeRangePickerButtonProps) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(
    (selectedRange?: TimeRange<DateTimeParam>) => {
      if (selectedRange) {
        onTimeRangeChanged(selectedRange.start, selectedRange.end)
      }
      setOpen(false)
    },
    [onTimeRangeChanged]
  )

  return (
    <>
      <ActionBarButton
        onClick={handleClick}
        data-test-id="time-range-picker-button"
        startIcon={<DateRange />}
        width={345}
      >
        {formatDateTimeParamRange({start, end}, timeZone, t('dateFormat.datetime'), t)}
      </ActionBarButton>
      <TimeRangePickerDialog
        open={open}
        onClose={handleClose}
        start={start}
        end={end}
        selectableTimeHorizons={selectableTimeHorizons}
        timeZone={timeZone}
      />
    </>
  )
}
