import React, {createContext, PropsWithChildren, useCallback, useMemo, useState} from 'react'
import type {ReactNode} from 'react'

import {CustomSnackbar, SnackbarConfig} from './CustomSnackbar'

export interface SnackbarContextType {
  showSnackbar: (message: ReactNode, config?: SnackbarConfig) => void
  showSuccess: (message: ReactNode) => void
  showError: (message: ReactNode) => void
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

export function SnackbarProvider({children}: PropsWithChildren<unknown>) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<React.ReactNode>('')
  const [config, setConfig] = useState<SnackbarConfig>({})

  const onClose = useCallback(() => {
    config.onClose?.()
    setOpen(false)
  }, [config])

  const value: SnackbarContextType = useMemo(() => {
    const showSnackbar = (message: React.ReactNode, config?: SnackbarConfig): void => {
      setMessage(message)
      setConfig(config ?? {})
      setOpen(true)
    }
    const showSuccess = (message: React.ReactNode): void =>
      showSnackbar(message, {
        autoHideDuration: 2000,
        severity: 'success'
      })

    const showError = (message: React.ReactNode): void => showSnackbar(message)
    return {
      showSnackbar,
      showSuccess,
      showError
    }
  }, [])

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <CustomSnackbar
        severity={config.severity}
        autoHideDuration={config.autoHideDuration}
        show={open}
        onClose={onClose}
      >
        {message}
      </CustomSnackbar>
    </SnackbarContext.Provider>
  )
}
