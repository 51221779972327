import React, {PropsWithChildren, useLayoutEffect} from 'react'
import {useLocation} from 'react-router-dom'

const HEADER_HEIGHT = 65 // in pixels

function scrollToTop(top = 0): void {
  // Run the code after react-router animation in callback queue
  setTimeout(() => window.scrollTo({top, left: 0, behavior: 'smooth'}), 15)
}

export function NavigationPositionHandler({children}: PropsWithChildren<unknown>) {
  const {hash, pathname} = useLocation()

  useLayoutEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1))
      if (element) {
        const elementPositions = element?.getBoundingClientRect()
        // element screen position + window top offset - header height
        scrollToTop(elementPositions.top + window.scrollY - HEADER_HEIGHT)
      }
    } else {
      scrollToTop()
    }
  }, [hash, pathname]) // Scroll on hash or pathname change, not search query

  return <>{children}</>
}
