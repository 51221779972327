import {useQuery} from 'react-query'

import {materialDtoToMaterial} from '../../utils/materialDtoToMaterial'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {MaterialsApi} from '@predict/DomainsLib/gen-api/Materials'
import {usePlantId} from '@predict/DomainsLib/plants'

export function useMaterials() {
  const plantId = usePlantId()
  return useQuery(
    [QueryKeyName.GetMaterials, plantId],
    async () => {
      const response = await MaterialsApi.getplantsCementStrengthsMaterials(plantId)
      return response.data.map((matDto) => materialDtoToMaterial(matDto))
    },
    {enabled: Boolean(plantId)}
  )
}
