import React, {PropsWithChildren} from 'react'

import {Text} from '../Text/Text'
interface SideInfoProps {
  'data-test-id'?: string
}

export function SideInfo({children, 'data-test-id': dataTestId}: PropsWithChildren<SideInfoProps>) {
  return (
    <Text component="p" variant="caption" align="right" data-test-id={dataTestId}>
      {children}
    </Text>
  )
}
