import {styled} from '@mui/material'
import React, {PropsWithChildren} from 'react'

import {GridContainer, GridItem} from '../Grid/Grid'

import {PredictDarkThemeProvider} from '@predict/WebUILib/helpers/providers/PredictThemeProvider'

const ActionBarContainer = styled(GridContainer)(({theme}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  }
}))

const ActionBarTitle = styled(GridItem)(({theme}) => ({
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
    width: 'auto'
  }
}))

const ActionBarItemsGird = styled(GridItem)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto'
  }
}))

const ActionItemsContainer = styled(GridContainer)(({theme}) => ({
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
}))

const ActionItem = styled(GridItem)({
  maxWidth: '100%'
})

interface ActionBarProps extends PropsWithChildren<unknown> {
  title: React.ReactNode
  'data-test-id'?: string
}

export function ActionBar({title, children, 'data-test-id': dataTestId}: ActionBarProps) {
  return (
    <ActionBarContainer data-test-id={dataTestId}>
      <ActionBarTitle>{title}</ActionBarTitle>
      <ActionBarItemsGird>
        <PredictDarkThemeProvider>
          <ActionItemsContainer spacing={2}>
            {React.Children.map(children, (child) => child && <ActionItem>{child}</ActionItem>)}
          </ActionItemsContainer>
        </PredictDarkThemeProvider>
      </ActionBarItemsGird>
    </ActionBarContainer>
  )
}
