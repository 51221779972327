import {styled} from '@mui/material'
import {isUndefined} from 'lodash'
import React from 'react'

import {inNumberRange} from '@predict/UtilsLib/general'

export interface VerticalMarkerPoint {
  id: string
  posY: number
  color?: string
}

export interface VerticalMarkerProps {
  height: number
  color: string
  posX?: number
  label?: string
  'data-test-id'?: string
  points?: VerticalMarkerPoint[]
}

const labelPadding = 48

const LatestLabel = styled('text')(({theme}) => ({
  ...theme.typography.caption,
  fontWeight: 'bold'
}))

export function VerticalMarker({
  height,
  posX,
  color,
  label,
  'data-test-id': dataTestId,
  points = []
}: VerticalMarkerProps) {
  if (isUndefined(posX)) return <></>

  return (
    <g data-test-id={dataTestId}>
      <line
        x1={posX}
        x2={posX}
        y1={0}
        y2={height}
        strokeWidth={2}
        stroke={color}
        fill="none"
        data-test-id={`${dataTestId}-line`}
      />
      {label && (
        <LatestLabel
          fill={color}
          textAnchor="middle"
          transform={`translate(${posX}, ${height + labelPadding})`}
          data-test-id={`${dataTestId}-label`}
        >
          {label}
        </LatestLabel>
      )}
      <circle r={6} cx={posX} cy={height} fill={color} data-test-id={`${dataTestId}-dot`} />
      {points
        .filter(({posY}) => inNumberRange({min: 0, max: height}, posY))
        .map((point) => (
          <circle
            key={point.id}
            cx={posX}
            cy={point.posY}
            r={4}
            fill="#ffffff"
            stroke={point.color ?? color}
            strokeWidth={2}
            data-test-id={`${dataTestId}-point-${point.id}`}
          />
        ))}
    </g>
  )
}
