export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U

export type NonNullableRecord<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type StrippedNullObject<T extends Object> = {
  [k in keyof T]: T[k] extends NonNullable<T[k]> ? T[k] : NonNullable<T[k]> | undefined
}
