import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {FormattedQualityParameter} from '../declarations/FormattedQualityParameter'

import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {DefaultBox} from '@predict/WebUILib/layouts/DefaultBox/DefaultBox'
import {SingleValueTable} from '@predict/WebUILib/organisms/SingleValueTable/SingleValueTable'

interface SampleDataBoxProps {
  caption?: string
  qualityParameters: FormattedQualityParameter[]
  'data-test-id'?: string
}

export function SampleDataBox({
  caption,
  qualityParameters,
  'data-test-id': dataTestId
}: SampleDataBoxProps) {
  const {t} = useTranslation()
  const data = useMemo(
    () =>
      qualityParameters.map((qp) => ({
        value: qp.value && t(`sampleData.${String(qp.key)}.value`, {value: qp.value}),
        label: t(`sampleData.${String(qp.key)}.label`)
      })),
    [qualityParameters, t]
  )

  const labelToKeyMap = useMemo(() => {
    const map = {}
    qualityParameters.forEach(
      (qp) => (map[t(`sampleData.${String(qp.key)}.label`)] = String(qp.key))
    )
    return map
  }, [qualityParameters, t])

  return (
    <DefaultBox data-test-id={dataTestId}>
      {caption && (
        <Text variant="h6" gutterBottom>
          {caption}
        </Text>
      )}

      <SingleValueTable
        tableId="data-table"
        data={data}
        cellBuilders={{
          value: ({value}) => value || t('common.na'),
          label: ({label}) => label
        }}
        fontSize={16}
        valueCellTextId={({rowData}) => `sample-data-box-field-${labelToKeyMap[rowData.label]}`}
      />
    </DefaultBox>
  )
}
