import {TFunction} from 'i18next'
import {sortBy, uniq, upperFirst} from 'lodash'

import {PREDEFINED_CHART_TAGS} from '../constants'

import {KilnLabMetaData} from '@predict/DomainsLib/labData'
import type {SensorMetaData} from '@predict/DomainsLib/sensorData'
import {
  SelectableOptionCategory,
  SelectableOptionView
} from '@predict/WebUILib/molecules/MultiSelectDataDialog/helpers'

function getKilnLabMetaDataCategories(
  labMetaData: KilnLabMetaData[],
  t: TFunction
): SelectableOptionCategory[] {
  const labCategoryNames: string[] = uniq(labMetaData.map(({category}) => category))
  return sortBy(
    labCategoryNames.map((cat) => {
      const categoryWordsArray = cat.split(' ')
      const category = categoryWordsArray.pop()?.toLowerCase()
      const material = categoryWordsArray.join(' ')
      return {
        categoryId: cat,
        categoryLabel: `${material} ${t(`chart.categories.${category}`, upperFirst(category))}`,
        options: sortBy(
          labMetaData
            .filter(({category}) => category === cat)
            .map(({materialType, uniformTag, displayName}) => {
              const id = `${materialType}:${uniformTag}`
              return {
                id,
                label: displayName,
                selectable: !PREDEFINED_CHART_TAGS.includes(id)
              }
            }),
          (o) => o.label
        )
      }
    }),
    (cat) => cat.categoryLabel
  )
}

function calcSensorDataCategory(
  metaData: SensorMetaData[],
  t: TFunction
): SelectableOptionCategory {
  return {
    categoryId: 'sensorData',
    categoryLabel: t('chart.sensorData'),
    options: metaData.map(({uniformTag, displayName, plantSpecificTag}) => ({
      id: uniformTag,
      label: `${displayName} (${plantSpecificTag})`,
      selectable: true
    }))
  }
}

function getFlSelectableOptionCategories(
  processMetaData: SensorMetaData[],
  labMetaData: KilnLabMetaData[],
  t: TFunction
): SelectableOptionCategory[] {
  return [
    ...getKilnLabMetaDataCategories(labMetaData, t),
    calcSensorDataCategory(processMetaData, t)
  ]
}

export function getFlSelectableOptionViews(
  processMetaData: SensorMetaData[],
  labMetaData: KilnLabMetaData[],
  t: TFunction
): SelectableOptionView[] {
  const rawCategories = getFlSelectableOptionCategories(processMetaData, labMetaData, t)

  if (rawCategories.length === 0) return []
  return [
    {
      viewId: 'rawData',
      viewLabel: t('chartData.rawData'),
      options: rawCategories
    }
  ]
}
