/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {LabMaterialMetrics, LabMetricsMetadata, ProblemDetails, TimeSpan} from './data-contracts'
import {genApiClient, RequestParams} from './http-client'

export class LabMetricsApi {
  /**
   * No description
   *
   * @tags LabMetrics
   * @name GetplantsLabMetrics
   * @summary Retrieves information about available metrics
   * @request GET:/predict/plants/{plantId}/labMetrics
   * @secure
   */
  static getplantsLabMetrics = (
    plantId: string,
    query?: {
      /**
       * The names of the metrics,
       * optional
       */
      tags?: string[]
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<LabMetricsMetadata[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/labMetrics`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags LabMetrics
   * @name GetplantsLabMetricsData
   * @summary Retrieves metrics data
   * @request GET:/predict/plants/{plantId}/labMetrics/data
   * @secure
   */
  static getplantsLabMetricsData = (
    plantId: string,
    query?: {
      /** The names of the metrics */
      tags?: string[]
      millNames?: string[]
      siloNames?: string[]
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      from?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      until?: string
      /**
       * unified material id
       * @format int32
       * @example 3
       */
      unifiedMaterialId?: number
      /** If omitted minute data will be returned. */
      resolution?: TimeSpan
      /**
       * Limits the number of data points returned by limiting the resolution
       * @format int32
       */
      maxPoints?: number
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<LabMaterialMetrics, ProblemDetails | void>({
      path: `/predict/plants/${plantId}/labMetrics/data`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
