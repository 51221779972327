import React, {createContext, PropsWithChildren, useContext} from 'react'

interface PDContext {
  selectedPlantId: string
  selectedProductionLineId?: string
}

const PlantsDomainContext = createContext<PDContext | undefined>(undefined)

export function usePlantsDomainContext(): PDContext {
  const context = useContext(PlantsDomainContext)
  if (!context) {
    throw new Error('usePlantsDomainContext used outside of PlantsDomainProvider')
  }

  return context
}

export const PlantsDomainConsumer = PlantsDomainContext.Consumer

export function PlantsDomainProvider({
  selectedPlantId,
  selectedProductionLineId,
  children
}: PropsWithChildren<PDContext>) {
  return (
    <PlantsDomainContext.Provider value={{selectedPlantId, selectedProductionLineId}}>
      {children}
    </PlantsDomainContext.Provider>
  )
}
