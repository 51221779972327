import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react'

import {usePlantFreeLimeConfig} from '../hooks/queries/usePlantFreeLimeConfig'

import {usePlantsDomainContext} from '@predict/DomainsLib/plants'
import {
  DateTimeParam,
  dateTimeParamsFromHorizon,
  FLP_DEFAULT_RESOLUTION,
  TimeRange
} from '@predict/UtilsLib/dateTime'
import {NOW_PARAM} from '@predict/UtilsLib/dateTime/constants'
import {Resolution} from '@predict/UtilsLib/general'

interface FLDContext {
  start: DateTimeParam
  end: DateTimeParam
  resolution: Resolution
  timeRange: TimeRange<DateTimeParam>
}

const FreeLimeDomainContext = createContext<FLDContext | undefined>(undefined)

export function useFreeLimeDomainContext(): FLDContext {
  const context = useContext(FreeLimeDomainContext)
  if (!context) {
    throw new Error('useFreeLimeDomainContext used outside of FreeLimeDomainProvider')
  }

  return context
}

export const FreeLimeDomainConsumer = FreeLimeDomainContext.Consumer

export function FreeLimeDomainProvider({
  start,
  end,
  resolution,
  timeRange,
  children
}: PropsWithChildren<Partial<FLDContext>>) {
  // Ensure upstream domains
  usePlantsDomainContext()

  const {defaultTimeDurationFreeLime} = usePlantFreeLimeConfig()
  const startTime = useMemo(
    () => start ?? timeRange?.start ?? dateTimeParamsFromHorizon(defaultTimeDurationFreeLime).start,
    [defaultTimeDurationFreeLime, start, timeRange?.start]
  )
  const endTime = useMemo(() => end ?? timeRange?.end ?? NOW_PARAM, [end, timeRange?.end])
  const range = useMemo(
    () => timeRange ?? {start: startTime, end: endTime},
    [endTime, startTime, timeRange]
  )

  return (
    <FreeLimeDomainContext.Provider
      value={{
        start: startTime,
        end: endTime,
        timeRange: range,
        resolution: resolution ?? FLP_DEFAULT_RESOLUTION
      }}
    >
      {children}
    </FreeLimeDomainContext.Provider>
  )
}
