import {calcOpacityColor} from '@predict/UtilsLib/general'

export function calcChartColors(actualColor: string, predictionColor?: string) {
  return {
    actualColor: actualColor,
    actualBackColor: calcOpacityColor(actualColor, 0.3) ?? actualColor,
    predictionColor: predictionColor ?? actualColor,
    predictionBackColor:
      calcOpacityColor(predictionColor ?? actualColor, 0.3) ??
      predictionColor ??
      calcOpacityColor(actualColor, 0.3) ??
      actualColor
  }
}
