import {TextField} from '@mui/material'
import React, {useCallback} from 'react'

export interface ActionBarSelectRenderProps<T> {
  itemValue: T
}

interface ActionBarSelectProps<T> {
  onSelected: (value: T) => void
  value: T
  values: T[]
  render: (props: ActionBarSelectRenderProps<T>) => React.ReactNode
  fieldId: string
  label?: string
  className?: string
  'data-test-id'?: string
  disabled?: boolean
}

export const ActionBarSelect = <T,>({
  onSelected,
  value,
  values,
  render,
  fieldId,
  label,
  className,
  'data-test-id': dataTestId,
  disabled
}: ActionBarSelectProps<T>) => {
  const onChange = useCallback(
    (event) => {
      onSelected(event.target.value as T)
    },
    [onSelected]
  )

  return (
    <TextField
      className={className}
      id={fieldId}
      data-test-id={dataTestId}
      select
      label={label}
      value={value}
      onChange={onChange}
      margin="normal"
      variant="standard"
      disabled={disabled}
      sx={{
        marginTop: 0,
        marginBottom: 1,
        width: 160,
        maxWidth: '100%'
      }}
    >
      {values.map((itemValue) => render({itemValue}))}
    </TextField>
  )
}
