import {DEFAULT_CEMENT_STRENGTH_CONFIG} from '../constants'
import {CementStrengthConfig} from '../declarations/CementStrengthConfig'
import {CsSampleType} from '../declarations/CsSamples'
import {StrengthLevel} from '../declarations/MaterialData'

import {convertSampleSourceDto, convertToSampleType} from './convertSampleSourceDto'
import {
  calcDefaultStrengthLevel,
  compareStrengthLevels,
  getStrengthLevelFromString,
  isStrengthLevel
} from './strengthLevel'

import {PlantConfigurationDto} from '@predict/DomainsLib/gen-api/data-contracts'
import {timeHorizonFromDuration} from '@predict/UtilsLib/dateTime'
import {logger} from '@predict/UtilsLib/logger'

export const extractPlantConfig = (plant: PlantConfigurationDto): CementStrengthConfig => {
  const log = logger.context('load plants', 'extractPlantConfig')
  const {defaultTimeHorizon, supportedTimeHorizons} = plant
  let levels: StrengthLevel[] =
    supportedTimeHorizons?.map((sth) => getStrengthLevelFromString(sth)).filter(isStrengthLevel) ??
    []

  if (levels.length === 0) {
    log
      .tags({plantDto: plant.plantFullName ?? ''})
      .info(`No valid strength horizons are provided for ${plant.plantFullName}, using default...`)
    levels = DEFAULT_CEMENT_STRENGTH_CONFIG.supportedStrengthLevels
  }
  levels.sort(compareStrengthLevels)

  return {
    id: plant.plantId ?? '',
    sampleSources: convertSampleSourceDto(plant.supportedSampleTypeSources ?? {}),
    defaultStrengthLevel: calcDefaultStrengthLevel(levels, defaultTimeHorizon),
    supportedStrengthLevels: levels,
    defaultSampleTypeSources: (plant.defaultSampleTypeSources
      ?.map(convertToSampleType)
      .filter(Boolean) ?? []) as CsSampleType[],
    defaultTimeDurationCementStrength: timeHorizonFromDuration(
      plant.defaultTimeDurationCementStrength,
      DEFAULT_CEMENT_STRENGTH_CONFIG.defaultTimeDurationCementStrength
    )
  }
}

export function plantConfigDtoToCementStrengthConfig(
  plants: PlantConfigurationDto[]
): CementStrengthConfig[] {
  return plants.map(extractPlantConfig).filter((p) => Boolean(p.id))
}
