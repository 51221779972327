export interface WithId<T = string> {
  id: T
}

export interface WithValue {
  value: number
}

export interface WithNullableValue {
  value?: number | null
}
