import {isNil} from 'lodash'

import {FreeLimeSample, KilnDataSet} from '../declarations/FreeLimeData'

import {
  FreelimeActual,
  FreelimeOutput,
  FreelimePrediction
} from '@predict/DomainsLib/gen-api/data-contracts'
import {parseTimeRange, parseDate, DateTimeRecord} from '@predict/UtilsLib/dateTime'

export function mapFreeLimeActuals(actuals: FreelimeActual[]): FreeLimeSample[] {
  return actuals.map(({time, value, alite, belite}) => ({
    datetime: parseDate(time),
    value: Number(value),
    alite: isNil(alite) ? undefined : Number(alite),
    belite: isNil(belite) ? undefined : Number(belite)
  }))
}

export function mapFreelimePrediction(records: FreelimePrediction[]): DateTimeRecord[] {
  return records.map(({time, value}) => ({datetime: parseDate(time), value: Number(value)}))
}

export function freelimeDtoToData(freelimeData: FreelimeOutput[]): KilnDataSet[] {
  return freelimeData.map((data) => ({
    productionLineId: data.productionLineId ?? '',
    actualValues: mapFreeLimeActuals(data.actuals),
    predictions: mapFreelimePrediction(data.predictions),
    range: parseTimeRange(data.range)
  }))
}
