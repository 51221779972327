import {useContext} from 'react'

import {SnackbarContext, SnackbarContextType} from '../SnackbarProvider'

export function useSnackbar(): SnackbarContextType {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbarContext used outside of SnackbarProvider')
  }

  return context
}
