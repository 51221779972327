import {styled} from '@mui/material'
import React, {SVGAttributes} from 'react'

import {DEFAULT_BOX_WIDTH, PADDING_X} from '@predict/WebUILib/Charts/layouts/InfoBox/InfoBox'

export interface InfoItemData {
  label: string
  color: string
  value: string
  secondaryColor?: string
  secondaryValue?: string
  isCircle?: boolean
  strokeDashArray?: string
}

export interface InfoItemProps extends InfoItemData {
  itemWidth?: number
  transform?: SVGAttributes<SVGGElement>['transform']
}

export const ITEM_HEIGHT = 24
const INDICATOR_Y_POSITION = -4

const InfoText = styled('text')(({theme}) => ({
  ...theme.typography.body2
}))

export function InfoItem({
  label,
  color,
  value,
  secondaryColor,
  secondaryValue,
  isCircle,
  strokeDashArray,
  transform,
  itemWidth = DEFAULT_BOX_WIDTH - 2 * PADDING_X
}: InfoItemProps) {
  return (
    <g data-role="info-item" transform={transform}>
      {isCircle ? (
        <circle cx={8} cy={INDICATOR_Y_POSITION} r={4} fill={color} />
      ) : (
        <line
          x1={0}
          x2={16}
          stroke={color}
          strokeWidth={2}
          strokeDasharray={strokeDashArray}
          y1={INDICATOR_Y_POSITION}
          y2={INDICATOR_Y_POSITION}
        />
      )}
      <InfoText dx={21} fill={color}>
        {label}
      </InfoText>
      <InfoText dx={itemWidth} textAnchor="end">
        <tspan>{value}</tspan>
        <tspan fill={secondaryColor ?? color}>&nbsp;{secondaryValue}</tspan>
      </InfoText>
    </g>
  )
}
