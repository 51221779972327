import {HubConnectionState} from '@microsoft/signalr'
import {useCallback, useEffect, useRef, useState} from 'react'

import {connection} from '../signalRConnection'
import {startConnection} from '../utils/signalRUtils'

export enum ConnectionStatus {
  Disconnected,
  Connecting,
  Connected
}

export function useSignalRConnection() {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.Disconnected
  )
  const isMounted = useRef(false)

  const setIsConnectionStatusIfMounted = (status: ConnectionStatus) =>
    isMounted.current && setConnectionStatus(status)

  const connect = useCallback(() => {
    // If disconnecting, try in 1 second
    if (connection.state === HubConnectionState.Disconnecting) {
      setTimeout(() => void connect(), 1000)
      return
    }

    // If connecting, or trying to connect, Do nothing
    if (
      [
        HubConnectionState.Connected,
        HubConnectionState.Connecting,
        HubConnectionState.Reconnecting
      ].includes(connection.state)
    ) {
      return
    }

    // Try to connect
    setIsConnectionStatusIfMounted(ConnectionStatus.Connecting)
    return startConnection(connection)
      .then(() => {
        setIsConnectionStatusIfMounted(ConnectionStatus.Connected)
      })
      .catch(() => {
        setIsConnectionStatusIfMounted(ConnectionStatus.Disconnected)
      })
  }, [])

  useEffect(() => {
    isMounted.current = true
    connection.onclose(() => {
      setIsConnectionStatusIfMounted(ConnectionStatus.Disconnected)
    })

    connection.onreconnecting(() => {
      setIsConnectionStatusIfMounted(ConnectionStatus.Connecting)
    })

    connection.onreconnected(() => {
      setIsConnectionStatusIfMounted(ConnectionStatus.Connected)
    })

    void connect()

    return () => {
      isMounted.current = false
      void connection.stop()
    }
  }, [connect])

  return {
    connection,
    connectionStatus
  }
}
