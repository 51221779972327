import {CementStrengthConfig} from './declarations/CementStrengthConfig'
import {StrengthField, StrengthLevel, StrengthPredictionField} from './declarations/MaterialData'

// Instead of making a distinction between the different silos,
// all silos are aggregated under this name.
export const AGGREGATED_SILOS_NAME = 'Silo'

export const DEFAULT_CEMENT_STRENGTH_CONFIG: CementStrengthConfig = {
  id: '',
  sampleSources: [],
  defaultStrengthLevel: 'strength28',
  supportedStrengthLevels: ['strength1', 'strength2', 'strength3', 'strength7', 'strength28'],
  defaultTimeDurationCementStrength: 'lastMonth',
  defaultSampleTypeSources: ['mill']
}

export const levelStrengthMap: Record<StrengthLevel, StrengthField> = {
  strength1: 'strength1d',
  strength2: 'strength2d',
  strength3: 'strength3d',
  strength7: 'strength7d',
  strength28: 'strength28d'
} as const

export const levelPredictionMap: Record<StrengthLevel, StrengthPredictionField> = {
  strength1: 'predictedStrength1d',
  strength2: 'predictedStrength2d',
  strength3: 'predictedStrength3d',
  strength7: 'predictedStrength7d',
  strength28: 'predictedStrength28d'
} as const
