/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ProblemDetails,
  ProcessDataStorageProvider,
  ProcessMetric,
  ProcessMetricsDumpDto,
  ProcessMetricsMetadataOutput,
  ProcessMetricsMetadataOutputWithPlantId,
  TimeSpan
} from './data-contracts'
import {ContentType, genApiClient, RequestParams} from './http-client'

export class ProcessMetricsApi {
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name GetplantsProcessMetricsViewFreelime
   * @summary Retrieves information about available metrics which should be displayed in the freelime view
   * @request GET:/predict/plants/{plantId}/processMetrics/view/freelime
   * @secure
   */
  static getplantsProcessMetricsViewFreelime = (
    plantId: string,
    query?: {
      productionLine?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<ProcessMetricsMetadataOutput[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/processMetrics/view/freelime`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name GetprocessMetricsList
   * @summary Retrieves information about all available metrics
   * @request GET:/predict/processMetrics
   * @secure
   */
  static getprocessMetricsList = (params: RequestParams = {}) =>
    genApiClient.request<ProcessMetricsMetadataOutputWithPlantId[], ProblemDetails | void>({
      path: `/predict/processMetrics`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name GetplantsProcessMetrics
   * @summary Retrieves information about available metrics
   * @request GET:/predict/plants/{plantId}/processMetrics
   * @secure
   */
  static getplantsProcessMetrics = (
    plantId: string,
    query?: {
      productionLine?: string
      /**
       * The names of the metrics,
       * optional
       */
      tags?: string[]
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<ProcessMetricsMetadataOutput[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/processMetrics`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name GetplantsProcessMetricsData
   * @summary Retrieves metrics data
   * @request GET:/predict/plants/{plantId}/processMetrics/data
   * @secure
   */
  static getplantsProcessMetricsData = (
    plantId: string,
    query?: {
      /** The names of the metrics */
      tags?: string[]
      productionLine?: string
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      from?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      until?: string
      /** If omitted minute data will be returned. */
      resolution?: TimeSpan
      /**
       * Limits the number of data points returned by limiting the resolution
       * @format int32
       * @example 1000
       */
      maxPoints?: number
      /** @default "Auto" */
      warmStorageProvider?: ProcessDataStorageProvider
      /** @default "Auto" */
      coldStorageProvider?: ProcessDataStorageProvider
      /** @default "Auto" */
      storageProvider?: ProcessDataStorageProvider
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<ProcessMetric[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/processMetrics/data`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name GetprocessMetricsDumpAllList
   * @summary Dump all metrics data from all plants
   * @request GET:/predict/processMetrics/dumpAll
   * @secure
   */
  static getprocessMetricsDumpAllList = (params: RequestParams = {}) =>
    genApiClient.request<ProcessMetricsDumpDto, ProblemDetails | void>({
      path: `/predict/processMetrics/dumpAll`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags ProcessMetrics
   * @name ProcessMetricsOverrideAllUpdate
   * @summary Clears all metrics data and replaces them with new data
   * @request PUT:/predict/processMetrics/overrideAll
   * @secure
   */
  static processMetricsOverrideAllUpdate = (
    data: ProcessMetricsDumpDto,
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/processMetrics/overrideAll`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
}
