import {camelCase} from 'lodash'
import {useCallback, useMemo} from 'react'

import {
  arrayFromParameter,
  getDateTimeParam,
  getValueFromArray,
  objFromString,
  toInt
} from '../utils/fromParameterUtils'
import {arrayToParameter, intToParameter} from '../utils/toParameterUtils'

import {useURLSearchParam} from './useURLSearchParam'

import {DateTimeParam} from '@predict/UtilsLib/dateTime'

export enum ParamTypes {
  string,
  int,
  array,
  dateTime,
  object,
  oneOf
}
interface ParamReturnType<T> {
  [ParamTypes.string]: string
  [ParamTypes.int]: number
  [ParamTypes.array]: string[]
  [ParamTypes.dateTime]: DateTimeParam
  [ParamTypes.object]: T
  [ParamTypes.oneOf]: T
}

const converters = {
  [ParamTypes.string]: {
    fromParam: (val?: string) => val,
    toParam: String
  },
  [ParamTypes.int]: {
    fromParam: toInt,
    toParam: intToParameter
  },
  [ParamTypes.array]: {
    fromParam: arrayFromParameter,
    toParam: arrayToParameter
  },
  [ParamTypes.dateTime]: {
    fromParam: getDateTimeParam,
    toParam: String
  },
  [ParamTypes.object]: {
    fromParam: objFromString,
    toParam: JSON.stringify
  },
  [ParamTypes.oneOf]: {
    fromParam: getValueFromArray,
    toParam: String
  }
}

// SIGNATURES
export function useTypedURLSearchParam<
  T extends Exclude<ParamTypes, ParamTypes.object | ParamTypes.oneOf>,
  A
>(
  paramName: string,
  type: T
): [ParamReturnType<A>[T] | undefined, (val?: ParamReturnType<A>[T] | undefined) => void]

export function useTypedURLSearchParam<T extends ParamTypes.object, A>(
  paramName: string,
  type: T,
  additional: (value: unknown) => value is A
): [ParamReturnType<A>[T] | undefined, (val?: ParamReturnType<A>[T] | undefined) => void]
export function useTypedURLSearchParam<T extends ParamTypes.oneOf, A>(
  paramName: string,
  type: T,
  values: A[]
): [ParamReturnType<A>[T] | undefined, (val?: ParamReturnType<A>[T] | undefined) => void]

// IMPLEMENTATION
export function useTypedURLSearchParam<T extends ParamTypes, A>(
  paramName: string,
  type: T,
  additional?: ((value: unknown) => value is A) | A[]
): [ParamReturnType<A>[T] | undefined, (val?: ParamReturnType<A>[T] | undefined) => void] {
  const [param, setParam] = useURLSearchParam(camelCase(paramName))
  const {toParam, fromParam} = converters[type]

  const typedParam = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return fromParam(param, additional as any) as ParamReturnType<A>[T] | undefined
  }, [additional, fromParam, param])

  const setTypedParam = useCallback(
    (value?: ParamReturnType<A>[T]) => {
      setParam(toParam(value))
    },
    [setParam, toParam]
  )
  // Get Param
  return [typedParam, setTypedParam]
}
