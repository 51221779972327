import {useTheme} from '@mui/material'
import * as d3 from 'd3'
import {isNumber, noop} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SelectedPoint} from './helpers/SelectedPoint'
import {
  calcInfoBoxItems,
  calcSelectedPoints,
  calcVerticalMarkerPoint
} from './helpers/selectedPointUtils'
import {StepChartCurve} from './subComponents/StepChartCurve'

import {formatTimeZoneDate, TimeRange} from '@predict/UtilsLib/dateTime'
import type {NumberRange, TargetRange} from '@predict/UtilsLib/general'
import {AxisLabel} from '@predict/WebUILib/Charts/atoms/AxisLabel/AxisLabel'
import {ChartBorder} from '@predict/WebUILib/Charts/atoms/ChartBorder/ChartBorder'
import {ChartGrid} from '@predict/WebUILib/Charts/atoms/ChartGrid/ChartGrid'
import {TargetLines} from '@predict/WebUILib/Charts/atoms/TargetLines/TargetLines'
import {TouchAreas} from '@predict/WebUILib/Charts/atoms/TouchArea/TouchAreas'
import {VerticalMarker} from '@predict/WebUILib/Charts/atoms/VerticalMarker/VerticalMarker'
import {
  calcTimeScale,
  timeAxisFormatter,
  labelWithUnit,
  COLOR_GRID_LINES,
  COLOR_TREND_AXIS,
  COLOR_TREND_BORDER,
  hoveredColor,
  SUGGESTED_TRENDS_TICK_COUNT,
  ChartData,
  LinearScaleFn,
  calcYScaleFunc,
  calcYFuncScaleMap,
  reduceChartDataPoints,
  getTimestampsOfData
} from '@predict/WebUILib/Charts/helpers'
import {InfoBox} from '@predict/WebUILib/Charts/layouts/InfoBox/InfoBox'
import {
  SvgWrapper,
  SvgWrapperRenderProps
} from '@predict/WebUILib/Charts/layouts/SvgWrapper/SvgWrapper'
import {Axis} from '@predict/WebUILib/Charts/molecules/Axis/Axis'
import {InfoItem, ITEM_HEIGHT} from '@predict/WebUILib/Charts/molecules/InfoItem/InfoItem'
import {TimeZoomWrapper} from '@predict/WebUILib/Charts/molecules/TimeZoomWrapper/TimeZoomWrapper'

export interface StepChartProps {
  timeRange: TimeRange
  chartId: string
  mainChartData: ChartData
  optionalChartData?: ChartData[]
  mainTargetRange?: TargetRange
  mainAxisRange?: NumberRange
  margin: {
    left: number
    top: number
    right: number
    bottom: number
  }
  timeZone: string
  selectedDateTime?: number
  onSelected?: (datetime: number) => void
  selectedLabel?: string
  onTimeRangeChanged?: (timeRange: TimeRange<number>) => void
  height?: number
  disableMouse?: boolean
  axisLabelBaseOffset?: number
  isSmallChart?: boolean
  'data-test-id'?: string
}
interface StepChartContentProps {
  chartWidth: number
  chartHeight: number
  Clipped: React.FC<{children: React.ReactNode}>
  timeRange: TimeRange
  timeZone: string
  mainTargetRange?: TargetRange
  mainAxisRange?: NumberRange
  mainChartData: ChartData
  optionalChartData: ChartData[]
  marginLeft: number
  selectedDateTime?: number
  onSelected?: (datetime: number) => void
  selectedLabel?: string
  onTimeRangeChanged?: (timeRange: TimeRange<number>) => void
  disableMouse?: boolean
  axisLabelBaseOffset?: number
  isSmallChart?: boolean
  'data-test-id'?: string
}

function StepChartContent({
  chartWidth,
  chartHeight,
  Clipped,
  timeRange,
  timeZone,
  mainTargetRange,
  mainAxisRange,
  mainChartData,
  optionalChartData,
  marginLeft,
  selectedDateTime,
  onSelected,
  selectedLabel,
  onTimeRangeChanged,
  disableMouse = false,
  axisLabelBaseOffset = -48,
  isSmallChart = false,
  'data-test-id': dataTestId
}: StepChartContentProps) {
  const {t} = useTranslation()
  const theme = useTheme()

  const allChartData = useMemo(
    () => [mainChartData, ...optionalChartData],
    [mainChartData, optionalChartData]
  )
  const selected: SelectedPoint[] = useMemo(
    () => (isNumber(selectedDateTime) ? calcSelectedPoints(allChartData, selectedDateTime) : []),
    [allChartData, selectedDateTime]
  )

  const [hoveredTimestamp, setHoveredTimestamp] = useState<number | undefined>()
  const hovered: SelectedPoint[] = useMemo(
    () => (isNumber(hoveredTimestamp) ? calcSelectedPoints(allChartData, hoveredTimestamp) : []),
    [allChartData, hoveredTimestamp]
  )
  const onHover = useCallback((timestamp?: number) => {
    setHoveredTimestamp(timestamp)
  }, [])

  const {x, ticks: xTimeTicks} = useMemo(
    () => calcTimeScale({timeRange, chartWidth, timeZone, tickWidth: 64}),
    [chartWidth, timeRange, timeZone]
  )
  const {format, extraFormat} = timeAxisFormatter(xTimeTicks, x, timeZone, t)
  const y = useMemo(() => {
    if (mainAxisRange)
      return d3.scaleLinear().domain([mainAxisRange.min, mainAxisRange.max]).range([chartHeight, 0])
    return calcYScaleFunc(
      mainChartData.actualValues,
      chartHeight,
      SUGGESTED_TRENDS_TICK_COUNT,
      mainChartData.predictions
    )
  }, [chartHeight, mainAxisRange, mainChartData.actualValues, mainChartData.predictions])

  const yScaleFnMap: Record<string, LinearScaleFn> = useMemo(() => {
    const result: Record<string, LinearScaleFn> = calcYFuncScaleMap(optionalChartData, chartHeight)
    result[mainChartData.id] = y
    return result
  }, [chartHeight, mainChartData.id, optionalChartData, y])

  const renderOptionalDataAxes = useCallback(
    () =>
      optionalChartData.map(({id, color, name, unit}, index) => {
        const yScaleFn = yScaleFnMap[id]
        const posOffset = (index + 1) * -marginLeft

        return (
          <g key={id}>
            <Axis
              data-test-id={`${dataTestId}-axis-${id}`}
              scale={yScaleFn}
              position="left"
              tickValues={yScaleFn.ticks()}
              posX={posOffset}
              color={color}
            />
            <AxisLabel
              position="left"
              height={chartHeight}
              width={chartWidth}
              label={labelWithUnit(name, unit)}
              color={color}
              offset={posOffset + axisLabelBaseOffset}
              data-test-id={`${dataTestId}-axis-label-${id}`}
            />
          </g>
        )
      }),
    [
      axisLabelBaseOffset,
      chartHeight,
      chartWidth,
      marginLeft,
      optionalChartData,
      yScaleFnMap,
      dataTestId
    ]
  )

  const timestamps = useMemo(
    () =>
      getTimestampsOfData(
        allChartData.map(({actualValues, predictions}) => [
          ...actualValues,
          ...(predictions ?? [])
        ]),
        timeRange
      ),
    [allChartData, timeRange]
  )

  return (
    <>
      <ChartGrid
        type="vertical"
        xScale={x}
        color={COLOR_GRID_LINES}
        height={chartHeight}
        xTickValues={xTimeTicks}
      />
      {mainTargetRange && (
        <TargetLines
          width={chartWidth}
          targetRange={{
            min: y(mainTargetRange.min),
            max: y(mainTargetRange.max),
            target: y(mainTargetRange.target)
          }}
        />
      )}
      <ChartBorder chartHeight={chartHeight} chartWidth={chartWidth} color={COLOR_TREND_BORDER} />
      <Axis
        scale={x}
        position="bottom"
        posY={chartHeight}
        format={format}
        extraFormat={extraFormat}
        tickValues={xTimeTicks}
        color={COLOR_TREND_AXIS}
      />
      <Axis
        scale={y}
        position="left"
        tickValues={y.ticks(SUGGESTED_TRENDS_TICK_COUNT)}
        color={COLOR_TREND_AXIS}
        textColor={COLOR_TREND_AXIS}
      />
      <AxisLabel
        position="left"
        height={chartHeight}
        width={chartWidth}
        label={labelWithUnit(mainChartData.name, mainChartData.unit)}
        color={COLOR_TREND_AXIS}
        offset={axisLabelBaseOffset}
        data-test-id={`${dataTestId}-axis-label-${mainChartData.id}`}
      />
      {renderOptionalDataAxes()}
      <Clipped>
        <StepChartCurve
          chartData={mainChartData}
          x={x}
          y={y}
          useSmallDots={isSmallChart}
          data-test-id={dataTestId}
        />
        {optionalChartData.map((chartData) => (
          <StepChartCurve
            key={chartData.id}
            chartData={chartData}
            x={x}
            y={yScaleFnMap[chartData.id]}
            useSmallDots={isSmallChart}
            data-test-id={dataTestId}
          />
        ))}
      </Clipped>
      {!disableMouse && hoveredTimestamp ? (
        <VerticalMarker
          height={chartHeight}
          color={hoveredColor(theme)}
          posX={x(hoveredTimestamp)}
          points={calcVerticalMarkerPoint(hovered, yScaleFnMap)}
        />
      ) : null}
      {!disableMouse && selectedDateTime && selected.length > 0 ? (
        <VerticalMarker
          height={chartHeight}
          color={theme.palette.primary.main}
          posX={x(selectedDateTime)}
          points={calcVerticalMarkerPoint(selected, yScaleFnMap)}
          label={selectedLabel}
          data-test-id={`${dataTestId}-selected-marker`}
        />
      ) : null}
      {!disableMouse && hoveredTimestamp ? (
        <InfoBox
          title={formatTimeZoneDate(hoveredTimestamp, timeZone, t('sampleDetails.dateFormat'))}
          xPos={x(hoveredTimestamp)}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
        >
          {calcInfoBoxItems(hovered, t).map(({label, color, value, strokeDashArray}, index) => (
            <InfoItem
              key={label}
              label={label}
              color={color}
              value={value}
              strokeDashArray={strokeDashArray}
              transform={`translate(0, ${index * ITEM_HEIGHT})`}
            />
          ))}
        </InfoBox>
      ) : null}
      {!disableMouse && (
        <TimeZoomWrapper
          x={x}
          chartHeight={chartHeight}
          chartWidth={chartWidth}
          onTimeRangeChanged={onTimeRangeChanged ?? noop}
        >
          <TouchAreas
            timestamps={timestamps}
            x={x}
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            onHover={onHover}
            onSelect={onSelected}
          />
        </TimeZoomWrapper>
      )}
    </>
  )
}

export function StepChart({
  timeRange,
  chartId,
  margin,
  mainTargetRange,
  mainAxisRange,
  mainChartData,
  optionalChartData = [],
  timeZone,
  selectedDateTime,
  onSelected,
  selectedLabel,
  height,
  onTimeRangeChanged,
  disableMouse = false,
  axisLabelBaseOffset = -48,
  isSmallChart = false,
  'data-test-id': dataTestId = 'step-chart'
}: StepChartProps) {
  const filteredMainChartData = useMemo(() => reduceChartDataPoints(mainChartData), [mainChartData])
  const filteredOptionalChartData = useMemo(
    () => optionalChartData.map((data) => reduceChartDataPoints(data)),
    [optionalChartData]
  )

  const render = useCallback(
    ({innerWidth, innerHeight, Clipped}: SvgWrapperRenderProps) => (
      <StepChartContent
        chartWidth={innerWidth}
        chartHeight={innerHeight}
        Clipped={Clipped}
        timeRange={timeRange}
        timeZone={timeZone}
        mainTargetRange={mainTargetRange}
        mainAxisRange={mainAxisRange}
        mainChartData={filteredMainChartData}
        optionalChartData={filteredOptionalChartData}
        marginLeft={margin.left}
        selectedDateTime={selectedDateTime}
        onSelected={onSelected}
        selectedLabel={selectedLabel}
        onTimeRangeChanged={onTimeRangeChanged}
        disableMouse={disableMouse}
        axisLabelBaseOffset={axisLabelBaseOffset}
        isSmallChart={isSmallChart}
        data-test-id={dataTestId}
      />
    ),
    [
      disableMouse,
      filteredMainChartData,
      filteredOptionalChartData,
      mainAxisRange,
      mainTargetRange,
      margin.left,
      onSelected,
      onTimeRangeChanged,
      selectedDateTime,
      selectedLabel,
      timeRange,
      timeZone,
      axisLabelBaseOffset,
      isSmallChart,
      dataTestId
    ]
  )

  return (
    <SvgWrapper
      height={height}
      margin={
        optionalChartData.length > 0
          ? {...margin, left: margin.left * (1 + optionalChartData.length)}
          : margin
      }
      render={render}
      svgId={chartId}
      data-test-id={dataTestId}
    />
  )
}
