import {ParamTypes, useTypedURLSearchParam} from '../useTypedURLSearchParam'

import {STRENGTH_LEVELS} from '@predict/DomainsLib/cementStrength'

export function useStrengthSearchParam() {
  const [strength, setStrength] = useTypedURLSearchParam(
    'strength',
    ParamTypes.oneOf,
    STRENGTH_LEVELS
  )
  return {
    strength,
    setStrength
  }
}
