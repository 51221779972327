import {useMutation} from 'react-query'

import {SensorMetaData} from '../../declarations/SensorMetaData'
import {useInvalidateSensorData} from '../useInvalidateSensorData'

import {PlantSpecificProcessMetricsApi} from '@predict/DomainsLib/gen-api/PlantSpecificProcessMetrics'
import {usePlantId, useProductionLine} from '@predict/DomainsLib/plants'
import {dummyMutationInjector} from '@predict/UtilsLib/general'

export function useDeleteMappedSensorMetaDataMutation(optionsInjector = dummyMutationInjector) {
  const plantId = usePlantId()
  const invalidateSensorData = useInvalidateSensorData()
  const {productionLineId} = useProductionLine()

  return useMutation(
    optionsInjector({
      mutationFn: async (metaData: SensorMetaData) => {
        await PlantSpecificProcessMetricsApi.plantsProcessMetricsPlantSpecificDelete(
          plantId,
          metaData.plantSpecificTag,
          {productionLineId}
        )
      },
      onSuccess: invalidateSensorData
    })
  )
}
