import * as d3 from 'd3'
import React from 'react'

import {DateTimeRecord} from '@predict/UtilsLib/dateTime'
import {
  ChartData,
  MAX_CHART_DATA_POINTS,
  PREDICTION_DASHARRAY_SMALL,
  ScaleFn
} from '@predict/WebUILib/Charts/helpers'

function calcDotSize(data: unknown[][], threshold: number, useSmallDots = false): number {
  const maxPoints = data.reduce<number>((val, array) => Math.max(val, array.length), 0)
  const percentage = maxPoints / threshold

  if (percentage < 0.4) {
    return useSmallDots ? 3 : 4
  }
  if (percentage < 0.8) {
    return useSmallDots ? 2 : 3
  }
  return 2
}

interface StepChartCurveProps {
  chartData: ChartData
  x: ScaleFn
  y: ScaleFn
  useSmallDots?: boolean
  'data-test-id'?: string
}
export function StepChartCurve({
  chartData,
  x,
  y,
  useSmallDots = false,
  'data-test-id': dataTestId = 'step-chart'
}: StepChartCurveProps) {
  const dotSize = calcDotSize(
    [chartData.actualValues, chartData.predictions ?? []],
    MAX_CHART_DATA_POINTS,
    useSmallDots
  )
  // Actuals should be on top of predictions
  return (
    <>
      {chartData.predictions ? (
        <StepCurve
          color={chartData.predictionColor ?? chartData.color}
          records={chartData.predictions}
          x={x}
          y={y}
          strokeDasharray={PREDICTION_DASHARRAY_SMALL}
          dotSize={dotSize}
          data-test-id={`${dataTestId}-curve-${chartData.id}-predicted`}
        />
      ) : null}
      <StepCurve
        color={chartData.color}
        records={chartData.actualValues}
        x={x}
        y={y}
        dotSize={dotSize}
        data-test-id={`${dataTestId}-curve-${chartData.id}`}
      />
    </>
  )
}

interface StepCurveProps {
  color: string
  records: DateTimeRecord[]
  x: ScaleFn
  y: ScaleFn
  dotSize?: number
  strokeDasharray?: string
  'data-test-id'?: string
}

export function StepCurve({
  color,
  records,
  x,
  y,
  strokeDasharray,
  dotSize = 3,
  'data-test-id': dataTestId
}: StepCurveProps) {
  const line = d3
    .line<DateTimeRecord>()
    .x((d: DateTimeRecord) => x(d.datetime) ?? 0)
    .y((d: DateTimeRecord) => y(d.value) ?? 0)

  const newRecords: DateTimeRecord[] = []
  records.forEach((currentRecord, index) => {
    // insert y for the current x point
    newRecords.push(currentRecord)

    if (index !== records.length - 1) {
      // insert y for the next x point
      newRecords.push({
        datetime: records[index + 1].datetime,
        value: currentRecord.value
      })
    }
  })

  return (
    <g data-test-id={dataTestId}>
      <path
        data-test-id={`${dataTestId}-path`}
        d={line(newRecords) ?? undefined}
        fill="none"
        stroke={color}
        strokeMiterlimit={1}
        strokeWidth={2}
        strokeDasharray={strokeDasharray}
      />
      <g data-test-id={`${dataTestId}-dots`}>
        {records.map(({datetime, value}, index) => (
          <circle key={index} cx={x(datetime) ?? 0} cy={y(value) ?? 0} r={dotSize} fill={color} />
        ))}
      </g>
    </g>
  )
}
