import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import type {CsSampleSource} from '@predict/DomainsLib/cementStrength'
import {MultiSelector, MultiSelectorItem} from '@predict/WebUILib/atoms/MultiSelector/MultiSelector'

interface SampleSourceSelectProps {
  sampleSources: CsSampleSource[]
  selectedSources: string[]
  onSourceSelected: (value: string[]) => void
  id?: string
  disabled?: boolean
}

export function SampleSourceSelect({
  sampleSources,
  selectedSources,
  onSourceSelected,
  disabled,
  id = 'sample-source-select'
}: SampleSourceSelectProps) {
  const {t} = useTranslation()
  const sourceNames = useMemo<MultiSelectorItem<string>[]>(
    () =>
      sampleSources
        .filter(({names}) => names.length > 0)
        .flatMap(({sampleType, names}) => [
          {label: t(`sampleType.${sampleType}`)},
          ...names.map((name) => ({label: name, value: name}))
        ]),
    [sampleSources, t]
  )

  return (
    <MultiSelector
      inputLabel={t('pageActionBar.actionSourcesLabel')}
      labeledItems={sourceNames}
      selectedItems={selectedSources}
      onSelect={onSourceSelected}
      disabled={disabled}
      id={id}
    />
  )
}
