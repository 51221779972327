import {LandingPage as SharedLandingPage} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {LogoPredictLanding} from '@predict/WebUILib/helpers/assets'
import {ShellFooter} from '@predict/WebUILib/layouts/PredictShell/ShellFooter'

interface LandingPageProps {
  buttonLabel: string
  onButtonClick: () => void
  footerLabel: string
  footerCopyrightNote?: string
}

export function LandingPage({
  buttonLabel,
  onButtonClick,
  footerLabel,
  footerCopyrightNote
}: LandingPageProps) {
  return (
    <SharedLandingPage
      onClickButton={onButtonClick}
      loginButtonLabel={buttonLabel}
      footer={<ShellFooter label={footerLabel} copyrightNote={footerCopyrightNote} />}
    >
      <img src={LogoPredictLanding} alt="Logo" data-test-id="landing-page-logo" />
    </SharedLandingPage>
  )
}
