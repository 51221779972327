import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'

import {DefaultShell, HelmetTitle} from './modules/general'
import {
  ADMIN_PATH,
  CEMENT_PATH,
  CEMENT_STRENGTH_PATH,
  CEMENT_SAMPLE_PATH,
  CEMENT_TRENDS_PATH,
  CLINKER_PATH,
  FREE_LIME_PATH,
  getPageUrl,
  PLANTS_HOME_PATH,
  PLANTS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  useExactMatchedRoute,
  usePlantParameter,
  useProductionLineSearchParam,
  USER_MANUAL_PATH,
  CEMENT_STRENGTH_SAMPLE_PATH,
  CEMENT_STRENGTH_TRENDS_PATH
} from './modules/navigation'
import {AdminPage} from './pages/AdminPage'
import {CementStrengthPage} from './pages/CementStrengthPage'
import {CementStrengthSamplePage} from './pages/CementStrengthSamplePage'
import {CementStrengthTrendsPage} from './pages/CementStrengthTrendsPage'
import {ClinkerPage} from './pages/ClinkerPage'
import {ErrorPage} from './pages/ErrorPage'
import {LoginPage} from './pages/LoginPage'
import {PlantHomePage} from './pages/PlantHomePage'
import {PlantsPage} from './pages/PlantsPage'
import {SettingsPage} from './pages/SettingsPage'
import {UserManualPage} from './pages/UserManualPage'

import {PlantsDomainProvider, usePlantsList} from '@predict/DomainsLib/plants'
import {useUser, useAuthContext, isUserAdmin} from '@predict/DomainsLib/users'
import {MainLoader} from '@predict/WebUILib/molecules/Loader/MainLoader'

function ErrorRoute() {
  const {t} = useTranslation()
  return (
    <Route>
      <HelmetTitle title={t('errors.error')} />
      <ErrorPage />
    </Route>
  )
}

function LoggedInMain() {
  const {t} = useTranslation()
  const {data: plants, isLoading} = usePlantsList()
  const plantParam = usePlantParameter()
  const {productionLineId: productionLineParam} = useProductionLineSearchParam()
  const matchedRoute = useExactMatchedRoute()
  const user = useUser()
  const isAdmin = isUserAdmin(user)
  const {search} = useLocation()

  // Show loading if plants are not loaded yet
  if (isLoading) {
    return <MainLoader />
  }

  // Redirect to plant id page if plant name is used
  const plantByName = plants?.find((p) => p.name === plantParam)
  if (matchedRoute && plantByName) {
    return (
      <Redirect
        to={getPageUrl(
          matchedRoute.pageId,
          {
            ...matchedRoute.params,
            plant: plantByName.id
          },
          Object.fromEntries(new URLSearchParams(search))
        )}
      />
    )
  }

  // Routes
  return (
    <PlantsDomainProvider
      selectedPlantId={plantParam}
      selectedProductionLineId={productionLineParam}
    >
      <DefaultShell>
        <Switch>
          <Route path={CEMENT_PATH}>
            <HelmetTitle title={t('navigation.cement')} />
            <Switch>
              <Route path={CEMENT_SAMPLE_PATH} exact>
                <CementStrengthSamplePage />
              </Route>
              <Route path={CEMENT_TRENDS_PATH} exact>
                <CementStrengthTrendsPage />
              </Route>
              <Route path={CEMENT_PATH} exact>
                <CementStrengthPage />
              </Route>
              <ErrorRoute />
            </Switch>
          </Route>
          <Redirect
            from={CEMENT_STRENGTH_SAMPLE_PATH}
            to={{pathname: CEMENT_SAMPLE_PATH, search}}
          />
          <Redirect
            from={CEMENT_STRENGTH_TRENDS_PATH}
            to={{pathname: CEMENT_TRENDS_PATH, search}}
          />
          <Redirect from={CEMENT_STRENGTH_PATH} to={{pathname: CEMENT_PATH, search}} />

          <Route path={CLINKER_PATH} exact>
            <HelmetTitle title={t('navigation.clinker')} />
            <ClinkerPage />
          </Route>
          <Redirect from={FREE_LIME_PATH} to={{pathname: CLINKER_PATH, search}} />

          <Route path={USER_MANUAL_PATH}>
            <HelmetTitle title={t('navigation.userManual')} />
            <UserManualPage />
          </Route>
          <Route path={SETTINGS_PATH}>
            <HelmetTitle title={t('navigation.settings')} />
            <SettingsPage />
          </Route>
          <Route path={PLANTS_HOME_PATH} exact>
            <HelmetTitle />
            <PlantHomePage />
          </Route>
          {isAdmin ? (
            <Route path={ADMIN_PATH} exact>
              <HelmetTitle title={t('navigation.admin')} />
              <AdminPage />
            </Route>
          ) : null}
          <Route path={PLANTS_PATH} exact>
            <PlantsPage />
          </Route>
          <Redirect from={ROOT_PATH} to={PLANTS_PATH} exact />
          <ErrorRoute />
        </Switch>
      </DefaultShell>
    </PlantsDomainProvider>
  )
}

export function Main() {
  const {isLoggedIn, isLoading} = useAuthContext()

  if (isLoading) {
    return <MainLoader />
  }

  return (
    <Switch>
      {/* Add routes accessible while logged out */}
      <Route>{isLoggedIn ? <LoggedInMain /> : <LoginPage />}</Route>
    </Switch>
  )
}
