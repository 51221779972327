import {useLayoutEffect, useState} from 'react'
import {useLocation, matchPath, match} from 'react-router-dom'

import {PredictPageId} from '../definitions/routeTypes'
import {PREDICT_PAGES} from '../routes'

export interface PredictMatchedRoute extends match {
  pageId: PredictPageId
}

/**
 * It returns an array of all the routes that match the current location
 * Similar to useRouteMatch but gets all matches
 * even if the hook call is outside the route
 * @returns An array of matched routes.
 */
export function useMatchedRoutes() {
  const location = useLocation()
  const [matchedRoutes, setMatchedRoutes] = useState<PredictMatchedRoute[]>([])

  useLayoutEffect(() => {
    setMatchedRoutes([])
    Object.entries(PREDICT_PAGES).forEach(([pageId, path]) => {
      const newRoute = matchPath(location.pathname, path)
      if (newRoute)
        setMatchedRoutes((oldRoutes) => [
          ...oldRoutes,
          {...newRoute, pageId: pageId as PredictPageId}
        ])
    })
  }, [location])

  return matchedRoutes
}

export function useExactMatchedRoute() {
  const matchedRoutes = useMatchedRoutes()
  return matchedRoutes.find((route) => route.isExact)
}
