import {ClientConfig, createTokenHandlerRequestInterceptor} from '@hconnect/apiclient'
import {backEndServiceOverrides} from '@hconnect/apiclient/src/localBackendOverrides'
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr'

import {createClientConfig} from './client'
import {createSignalRLogger} from './utils/signalRUtils'

// For cypress tests only
declare global {
  interface Window {
    signalrMock: HubConnection | undefined
  }
}

async function getAuthToken(clientConfig: ClientConfig): Promise<string> {
  const callback = createTokenHandlerRequestInterceptor(clientConfig)
  const {headers} = await callback({})
  const authorizationHeader = String(headers?.Authorization ?? '')
  return authorizationHeader.split(' ').pop() || ''
}

function getApiUrl(clientConfig: ClientConfig): string {
  const selectedBackend = clientConfig.backendSelector.getSelectedBackend()
  const localBackends = process.env.REACT_APP_LOCAL_BACKENDS?.split(',')

  return localBackends && localBackends.includes('predict')
    ? backEndServiceOverrides.predict.baseUrl
    : selectedBackend.API_URL
}

function createConnection(hubName = 'predict/hubs/statusHub'): HubConnection {
  const clientConfig = createClientConfig()
  const apiUrl = getApiUrl(clientConfig)

  return (
    window.signalrMock ||
    new HubConnectionBuilder()
      .withUrl(`${apiUrl}/${hubName}`, {
        accessTokenFactory: () => getAuthToken(clientConfig),
        withCredentials: false
      })
      .withAutomaticReconnect()
      .configureLogging(createSignalRLogger())
      .build()
  )
}

export const connection = createConnection()
