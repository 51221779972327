import {MouseEvent, useMemo, useState} from 'react'

interface PopoverAnchorData {
  anchorEl: Element | null
  openPopover: (event: MouseEvent) => void
  closePopover: () => void
  isOpen: boolean
  id?: string
}

export function usePopoverAnchor(popoverId: string): PopoverAnchorData {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  return useMemo(() => {
    const openPopover = (event: MouseEvent) => {
      setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
      setAnchorEl(null)
    }

    const isOpen = Boolean(anchorEl)
    const id = isOpen ? popoverId : undefined
    return {anchorEl, openPopover, closePopover, isOpen, id}
  }, [anchorEl, popoverId])
}
