import Markdown, {MarkdownToJSX} from 'markdown-to-jsx'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {ZoomableImage} from '@predict/WebUILib/atoms/ZoomableImage/ZoomableImage'
import {ClickableHeadline} from '@predict/WebUILib/molecules/ClickableHeadline/ClickableHeadline'

const SCROLL_TO_DELAY_MS = 10

export interface MarkdownRendererProps {
  markdown: string
  componentsOverrides?: MarkdownToJSX.Overrides
}

export function MarkdownRenderer({markdown, componentsOverrides = {}}: MarkdownRendererProps) {
  const {hash} = useLocation()

  useEffect(() => {
    const anchorId = hash.replace('#', '')
    if (anchorId) {
      const getElemAndScroll = () => {
        document.getElementById(anchorId)?.scrollIntoView({behavior: 'smooth'})
      }

      const handle = setTimeout(getElemAndScroll, SCROLL_TO_DELAY_MS)
      return () => clearTimeout(handle)
    }
  }, [hash])

  return (
    <Markdown
      options={{
        overrides: {
          a: {
            props: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          },
          h1: {
            component: ClickableHeadline,
            props: {
              tag: 'h1'
            }
          },
          h2: {
            component: ClickableHeadline,
            props: {
              tag: 'h2'
            }
          },
          h3: {
            component: ClickableHeadline,
            props: {
              tag: 'h3'
            }
          },
          h4: {
            component: ClickableHeadline,
            props: {
              tag: 'h4'
            }
          },
          h5: {
            component: ClickableHeadline,
            props: {
              tag: 'h5'
            }
          },
          h6: {
            component: ClickableHeadline,
            props: {
              tag: 'h6'
            }
          },
          img: {
            component: ZoomableImage
          },
          ...componentsOverrides
        }
      }}
    >
      {markdown}
    </Markdown>
  )
}
