import {Grid, GridProps as MuiGridProps} from '@mui/material'
import React from 'react'

type GridProps = Omit<MuiGridProps, 'container' | 'item'>

export function GridContainer({...other}: GridProps) {
  return <Grid container {...other} />
}

export function GridItem({...other}: GridProps) {
  return <Grid item {...other} />
}
