import {MenuItem, TextField} from '@mui/material'
import React from 'react'

import {Text} from '../Text/Text'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

export interface SelectorItem {
  name: string
  value: string
}

export interface SelectorStylesProps {
  width?: string | number
  margin?: number | 'actionBar'
}

export interface SelectorProps extends SelectorStylesProps {
  items: SelectorItem[]
  onSelect: (value: string) => void
  label: string
  caption?: string
  id?: string
}

export function Selector({items, onSelect, label, caption, id, width, margin}: SelectorProps) {
  return (
    <Box>
      {caption && <Text>{caption}</Text>}
      <TextField
        id={id}
        select
        label={label}
        value=""
        fullWidth
        onChange={(event) => {
          onSelect(event.target.value)
        }}
        margin="normal"
        variant="standard"
        sx={{
          maxWidth: '100%',
          width: width,
          marginTop: margin === 'actionBar' ? 0 : margin,
          marginBottom: margin === 'actionBar' ? 8 : margin
        }}
      >
        {items.map((p) => (
          <MenuItem key={p.value} value={p.value}>
            {p.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}
