import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {plantConfigDtoToPlant} from '../utils/plantConfigDtoToPlant'

import {QueryKeyName} from '@predict/DomainsLib/constants'
import {PlantApi} from '@predict/DomainsLib/gen-api/Plant'

export function useBasePlantsList() {
  return useQuery([QueryKeyName.GetPlants], async () => {
    const response = await PlantApi.getplantsList()
    return response.data
  })
}

export function usePlantsList() {
  const baseQueryResult = useBasePlantsList()
  return useMemo(
    () => ({
      ...baseQueryResult,
      data: baseQueryResult.data ? plantConfigDtoToPlant(baseQueryResult.data) : undefined
    }),
    [baseQueryResult]
  )
}
