/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CementStrengthPredictionDataDto,
  CementStrengthPredictionDto,
  ProblemDetails
} from './data-contracts'
import {ContentType, genApiClient, RequestParams} from './http-client'

export class CementStrengthPredictionsApi {
  /**
 * No description
 * 
 * @tags CementStrengthPredictions
 * @name CementStrengthsPredictionsUpdate
 * @summary Stores predictions in a DB table. Subsequent requests with the same key override previous values if a value is
supplied, otherwise the old value stays in place (upsert semantic)
 * @request PUT:/predict/cementStrengths/predictions
 * @secure
 */
  static cementStrengthsPredictionsUpdate = (
    data: CementStrengthPredictionDto[],
    params: RequestParams = {}
  ) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/cementStrengths/predictions`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    }) /**
 * No description
 * 
 * @tags CementStrengthPredictions
 * @name GetplantsCementStrengthsPredictions
 * @summary Retrieves the cement strength predictions by certain parameters
To get the predictions one has to give a plant and material type, and optionally a start and end date and a mill
 * @request GET:/predict/plants/{plantId}/cementStrengths/predictions
 * @secure
 */
  static getplantsCementStrengthsPredictions = (
    plantId: string,
    query?: {
      /**
       * unified material id
       * @format int32
       * @example 3
       */
      unifiedMaterialId?: number
      /**
       * Date and time from which to include sample data
       * @format date-time
       * @example "2021-01-17T05:45:18.070Z"
       */
      startTime?: string
      /**
       * Date and time up to which to include sample data
       * @format date-time
       * @example "2021-03-29T07:20:20.070Z"
       */
      endTime?: string
    },
    params: RequestParams = {}
  ) =>
    genApiClient.request<CementStrengthPredictionDataDto[], ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementStrengths/predictions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags CementStrengthPredictions
   * @name PlantsCementStrengthsPredictionsDelete
   * @summary Removes predictions for a specific plant
   * @request DELETE:/predict/plants/{plantId}/cementStrengths/predictions
   * @secure
   */
  static plantsCementStrengthsPredictionsDelete = (plantId: string, params: RequestParams = {}) =>
    genApiClient.request<void, ProblemDetails | void>({
      path: `/predict/plants/${plantId}/cementStrengths/predictions`,
      method: 'DELETE',
      secure: true,
      ...params
    })
}
