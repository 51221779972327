import {intersection} from 'lodash'

import {useSelectedSourcesSearchParam} from '../../navigation'

import {usePlantLabDataConfig} from '@predict/DomainsLib/labData'

export function useSelectedMills(): string[] {
  const {selectedSources} = useSelectedSourcesSearchParam()
  const plantConfig = usePlantLabDataConfig()

  if (!selectedSources) return plantConfig.defaultMills

  return intersection(selectedSources, plantConfig.millNames)
}
