/* eslint-disable jsx-a11y/no-autofocus */
import {Clear, Sort} from '@mui/icons-material'
import {IconButton, InputAdornment} from '@mui/material'
import {isUndefined} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ManipulatorCriteria, SortType} from '../helpers/types'

import {ColorButton} from '@predict/WebUILib/atoms/ColorButton/ColorButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {TextField} from '@predict/WebUILib/molecules/TextField/TextField'

interface ColumnOptionsProps<D> {
  tableId: string
  columnName: keyof D
  onClose: () => void
  onFilter?: (criterion: ManipulatorCriteria<D>) => void
  onSort?: (criterion: ManipulatorCriteria<D, SortType>) => void
  sortExpression?: SortType
  filterExpression?: string
  sortButtonLabel?: string
  filterPlaceholder?: string
}

export function ColumnOptions<D>({
  tableId,
  columnName,
  onClose,
  onFilter,
  onSort,
  sortExpression,
  filterExpression = '',
  sortButtonLabel,
  filterPlaceholder
}: ColumnOptionsProps<D>) {
  const {t} = useTranslation()
  const [newFilterExpression, setNewFilterExpression] = useState(filterExpression)

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (columnName && event.key === 'Enter') {
        onFilter?.({field: columnName, expression: newFilterExpression.trim()})
        onClose()
      }
    },
    [columnName, newFilterExpression, onClose, onFilter]
  )

  if (isUndefined(onSort) && isUndefined(onFilter)) return <></>

  return (
    <Box display="flex" flexDirection="column">
      {!isUndefined(onSort) && (
        <ColorButton
          data-test-id={`${tableId}-column-options-sort-button`}
          startIcon={<Sort />}
          onClick={() => {
            onSort({field: columnName, expression: sortExpression === 'asc' ? 'desc' : 'asc'})
            onClose()
          }}
        >
          {sortButtonLabel ?? t('UILibrary.GeneralTable.sort')}
        </ColorButton>
      )}
      {!isUndefined(onSort) && !isUndefined(onFilter) && <Spacing height={2} />}
      {!isUndefined(onFilter) && (
        <TextField
          data-test-id={`${tableId}-column-options-filter-text-field`}
          fullWidth
          label={filterPlaceholder ?? t('UILibrary.GeneralTable.filter')}
          value={newFilterExpression}
          onChange={(event) => {
            setNewFilterExpression(event.target.value)
          }}
          autoFocus
          onBlur={() => {
            onFilter({field: columnName, expression: newFilterExpression.trim()})
          }}
          onKeyUp={onKeyUp}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={(theme) => ({
                    border: 0,
                    backgroundColor: 'transparent',
                    color: theme.palette.grey[700],
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none'
                    }
                  })}
                  data-test-id={`${tableId}-column-options-filter-clear-button`}
                  aria-label={t('UILibrary.GeneralTable.clearFilter')}
                  onClick={() => {
                    setNewFilterExpression('')
                    onFilter({field: columnName, expression: ''})
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    </Box>
  )
}
