import {SensorDataRecords} from '../declarations/SensorData'

import {ProcessMetric} from '@predict/DomainsLib/gen-api/data-contracts'

export function dtoToSensorDataRecords(processMetrics: ProcessMetric[]): SensorDataRecords[] {
  return processMetrics.map((dto) => ({
    plantSpecificTag: dto.plantSpecificTag,
    uniformTag: dto.uniformTag,
    unit: dto.unit,
    description: dto.description.trim(),
    displayName: (dto.displayName || dto.description).trim(),
    records:
      dto.datapoints?.map(([value, datetime]) => ({
        // convert to milliseconds
        datetime: Number(datetime) * 1000,
        value: Number(value)
      })) ?? []
  }))
}
