import {isNumber} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  AggregatedMaterialData,
  calcCementStrengthStats,
  getMaterialLevel,
  getStrengthLevelInDays,
  Material,
  StrengthLevel
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {formatTimeZoneDate, TimeRange} from '@predict/UtilsLib/dateTime'
import {calcColorFromDeviation, formatFloat} from '@predict/UtilsLib/general'
import {UserManualLink, PredictionView} from '@predict/WebApp/modules/navigation'
import {InfoPopOverButton} from '@predict/WebUILib/atoms/InfoPopOverButton/InfoPopOverButton'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {StatsItem, StatsItemProps} from '@predict/WebUILib/molecules/StatsItem/StatsItem'

interface MaterialStatsProps {
  predictionView: Extract<PredictionView, 'time' | 'accuracy'>
  data: AggregatedMaterialData
  material: Material
  strengthLevel: StrengthLevel
  timeRange: TimeRange
}

export function MaterialStats({
  predictionView,
  data,
  material,
  strengthLevel,
  timeRange
}: MaterialStatsProps) {
  const {t, i18n} = useTranslation()

  const palette = useThemePalette()
  const timeZone = usePlantTimeZone()
  const materialLevel = getMaterialLevel(material, strengthLevel)
  const {samples} = data

  const stats = useMemo(
    () => calcCementStrengthStats(samples, timeRange, strengthLevel, materialLevel?.target),
    [materialLevel?.target, samples, strengthLevel, timeRange]
  )

  const averageDeviation = stats.deviationFromTarget
  const deviationString = isNumber(averageDeviation)
    ? t('millStats.strengthMPa', {
        strength: formatFloat(averageDeviation ?? 0, {locale: i18n.language, displayPlus: true})
      })
    : t('common.na')

  const testId = predictionView === 'time' ? 'mill-stats-container' : 'accuracy-stats'

  const statsItems = useMemo<StatsItemProps[]>(
    () =>
      predictionView === 'time'
        ? [
            {
              headerLabel: t('millStats.averagePredictedStrength'),
              value: stats.average,
              valueUnit: t('unit.MPa'),
              hasSeparateUnit: true,
              'data-test-id': `${testId}-${material.materialId}-predicted-strength`
            },
            {
              headerLabel: (
                <>
                  {t('millStats.fromTarget')}
                  <Spacing isInline width={1} />
                  <InfoPopOverButton>
                    <Text>
                      {t('millStats.fromTargetInfo', {
                        start: formatTimeZoneDate(
                          timeRange.start,
                          timeZone,
                          t('millStats.datetime')
                        ),
                        end: formatTimeZoneDate(timeRange.end, timeZone, t('millStats.datetime')),
                        strength: t('strengthLevel.strength', {
                          count: getStrengthLevelInDays(strengthLevel)
                        }),
                        materialName: material?.name,
                        value: deviationString
                      })}
                    </Text>
                  </InfoPopOverButton>
                </>
              ),
              value: stats.deviationFromTarget,
              valueColor: calcColorFromDeviation(
                {
                  normal: palette.grey[700],
                  success: palette.success.main,
                  warning: palette.warning.main,
                  error: palette.error.main
                },
                averageDeviation,
                materialLevel
              ),
              valueUnit: t('unit.MPa'),
              hasSeparateUnit: true,
              showPlusSign: true,
              'data-test-id': `${testId}-${material.materialId}-deviation`
            }
          ]
        : [
            {
              headerLabel: t('accuracyStats.meanAbsoluteError'),
              value: stats.meanAbsoluteError,
              valueUnit: t('unit.MPa'),
              hasSeparateUnit: true,
              'data-test-id': `${testId}-${material.materialId}-mae`
            },
            {
              headerLabel: (
                <>
                  {t('accuracyStats.r2')}
                  <Spacing isInline width={1} />
                  <InfoPopOverButton>
                    <Text>
                      {t('accuracyStats.r2Info')} (
                      <UserManualLink
                        hash={t('userManual.anchors.metrics')}
                        data-test-id={`${testId}-link-to-metrics`}
                      />
                      )
                    </Text>
                  </InfoPopOverButton>
                </>
              ),
              value: stats.r2,
              valueDigits: 2,
              'data-test-id': `${testId}-${material.materialId}-r2`
            }
          ],
    [
      averageDeviation,
      deviationString,
      material.materialId,
      material?.name,
      materialLevel,
      predictionView,
      stats.average,
      stats.deviationFromTarget,
      stats.meanAbsoluteError,
      stats.r2,
      strengthLevel,
      t,
      testId,
      palette,
      timeRange.end,
      timeRange.start,
      timeZone
    ]
  )

  return (
    <Box display="flex" data-test-id={`${testId}-${material.materialId}`}>
      {statsItems.map((props, index) => (
        <React.Fragment key={index}>
          <StatsItem {...props} />
          {index !== statsItems.length - 1 && <Spacing width={3} />}
        </React.Fragment>
      ))}
    </Box>
  )
}
