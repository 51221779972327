import React from 'react'

import {TimeRangePickerButton, ZoomButtonGroup} from '../../dateTime'
import {PredictionViewButtonGroup} from '../../navigation/components/PredictionViewButtonGroup'

import {CementStrengthDataSelector} from './CementStrengthDataSelector'
import {PredictionStrengthSelect} from './PredictionStrengthSelect'
import {SampleSourceSelect} from './SampleSourceSelect'

import {
  useCementStrengthDomainContext,
  usePlantCementStrengthConfig
} from '@predict/DomainsLib/cementStrength'
import {usePlantTimeZone} from '@predict/DomainsLib/plants'
import {CS_TIME_HORIZON_IDS, DateTimeParam} from '@predict/UtilsLib/dateTime'
import {NOW_PARAM} from '@predict/UtilsLib/dateTime/constants'
import {
  useSelectedSourcesSearchParam,
  useStrengthSearchParam,
  useTimeRangeSearchParams
} from '@predict/WebApp/modules/navigation'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {ActionBar} from '@predict/WebUILib/layouts/ActionBar/ActionBar'

interface CementStrengthActionBarProps {
  title: string
  showZoomOptions?: boolean
  showDataSelector?: boolean
}

export function CementStrengthActionBar({
  title,
  showZoomOptions = false,
  showDataSelector = false
}: CementStrengthActionBarProps) {
  const {start, end, selectedSources, strength: strengthLevel} = useCementStrengthDomainContext()
  const {sampleSources} = usePlantCementStrengthConfig()
  const timeZone = usePlantTimeZone()
  const {setTimeRange: onTimeRangeChanged} = useTimeRangeSearchParams()
  const {setSelectedSources: onSourceSelected} = useSelectedSourcesSearchParam()
  const {setStrength: onStrengthChanged} = useStrengthSearchParam()

  return (
    <ActionBar title={<PageTitle>{title}</PageTitle>} data-test-id="cement-strength-action-bar">
      <SampleSourceSelect
        sampleSources={sampleSources}
        selectedSources={selectedSources}
        onSourceSelected={onSourceSelected}
        id="cs-sample-source-select"
      />
      <PredictionStrengthSelect
        strengthLevel={strengthLevel}
        onStrengthLevelChanged={onStrengthChanged}
      />
      <TimeRangePickerButton
        start={start}
        end={end}
        timeZone={timeZone}
        selectableTimeHorizons={CS_TIME_HORIZON_IDS}
        onTimeRangeChanged={(start: DateTimeParam, end: DateTimeParam) =>
          onTimeRangeChanged(start, end)
        }
      />
      {showZoomOptions && (
        <ZoomButtonGroup
          timeRange={{
            start: start,
            end: end
          }}
          onTimeRangeChanged={onTimeRangeChanged}
          maxEnd={end === NOW_PARAM ? NOW_PARAM : undefined}
        />
      )}
      {showDataSelector && <CementStrengthDataSelector />}
      <PredictionViewButtonGroup />
    </ActionBar>
  )
}
