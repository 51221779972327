import React, {ImgHTMLAttributes} from 'react'

// There is currently no image, so we ignore this error for now
// eslint-disable-next-line import/namespace
import * as images from '../manuals/images'

import {getFilenameFromPath, stripExtension} from '@predict/UtilsLib/general'
import {ZoomableImage} from '@predict/WebUILib/atoms/ZoomableImage/ZoomableImage'

const mapNameToSource = (sourceName?: string): string => {
  if (!sourceName) return ''
  const imageMap: Record<string, string> = images
  const name = stripExtension(getFilenameFromPath(sourceName))
  return imageMap[name] ?? ''
}

export function UserManualImage(props: ImgHTMLAttributes<HTMLImageElement>) {
  const {src, ...rest} = props
  const imageSrc = mapNameToSource(src)

  if (!imageSrc) {
    return <></>
  }
  return <ZoomableImage src={imageSrc} {...rest} />
}
