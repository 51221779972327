import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react'

import {usePlantsDomainContext} from '@predict/DomainsLib/plants'
import {DateTimeParam, TimeRange} from '@predict/UtilsLib/dateTime'

interface SDDContext {
  start: DateTimeParam
  end: DateTimeParam
  selectedTagIds: string[]
  timeRange: TimeRange<DateTimeParam>
}

const SensorDataDomainContext = createContext<SDDContext | undefined>(undefined)

export function useSensorDataDomainContext(): SDDContext {
  const context = useContext(SensorDataDomainContext)
  if (!context) {
    throw new Error('useSensorDataDomainContext used outside of SensorDataDomainProvider')
  }

  return context
}

export const SensorDataDomainConsumer = SensorDataDomainContext.Consumer

interface SDDProviderProps
  extends PropsWithChildren<Omit<SDDContext, 'selectedTagIds' | 'timeRange'>> {
  selectedTagIds?: string[]
}

export function SensorDataDomainProvider({start, end, selectedTagIds, children}: SDDProviderProps) {
  // Ensure upstream domains
  usePlantsDomainContext()

  const timeRange = useMemo(() => ({start, end}), [start, end])

  return (
    <SensorDataDomainContext.Provider
      value={{
        start: start,
        end: end,
        selectedTagIds: selectedTagIds ?? [],
        timeRange
      }}
    >
      {children}
    </SensorDataDomainContext.Provider>
  )
}
