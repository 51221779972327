import {useQuery} from 'react-query'

import {useFreeLimeDomainContext} from '../../providers/FreeLimeDomainProvider'
import {freelimeDtoToData} from '../../utils/freelimeDtoToData'

import {FL_FETCH_INTERVAL_MS, QueryKeyName} from '@predict/DomainsLib/constants'
import {FreelimePredictionsApi} from '@predict/DomainsLib/gen-api/FreelimePredictions'
import {usePlantId, usePlantTimeZone, useProductionLine} from '@predict/DomainsLib/plants'
import {
  isRelativeDateTimeParam,
  padTimeRange,
  timeRangeFromStartEnd
} from '@predict/UtilsLib/dateTime'
import {DEFAULT_TIME_MARGIN_FACTOR} from '@predict/UtilsLib/dateTime/constants'

export function useFreeLimeData() {
  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const {productionLineId} = useProductionLine()
  const {start, end} = useFreeLimeDomainContext()
  return useQuery(
    [QueryKeyName.GetFreeLime, plantId, timeZone, start, end, productionLineId],
    async () => {
      const range = padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR)
      const response = await FreelimePredictionsApi.getplantsFreeLimePredictions(plantId, {
        productionLineId,
        startTime: range.start.toJSON(),
        endTime: range.end.toJSON()
      })
      return {kilnDataSet: freelimeDtoToData(response.data)}
    },
    {
      refetchInterval: isRelativeDateTimeParam(end) && FL_FETCH_INTERVAL_MS,
      staleTime: 0
    }
  )
}
