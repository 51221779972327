import {Dialog} from '@mui/material'
import React, {ImgHTMLAttributes, useState} from 'react'

export function ZoomableImage(props: ImgHTMLAttributes<HTMLImageElement>) {
  const [isOpen, setIsOpen] = useState(false)

  const {src, alt, style, ...rest} = props

  if (!src) {
    return <></>
  }

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <img
        role="button"
        onClick={toggleDialog}
        tabIndex={0}
        src={src}
        alt={alt}
        style={{maxWidth: '100%', cursor: 'pointer', ...style}}
        data-test-id="zoomable-image"
        {...rest}
      />
      <Dialog open={isOpen} onClose={toggleDialog}>
        <img src={src} alt={alt} style={{maxWidth: '100%'}} data-test-id="zoomed-image" {...rest} />
      </Dialog>
    </>
  )
}
