import React from 'react'

import type {TargetRange} from '@predict/UtilsLib/general'
import {COLOR_LIMITS, COLOR_TARGET} from '@predict/WebUILib/Charts/helpers'

interface TargetLinesProps {
  width: number
  targetRange: TargetRange
  strokeWidth?: number
  strokeDasharray?: string
}

export function targetRangeToLinesInfo(targetRange: TargetRange) {
  const {min, max, target} = targetRange
  return [
    {
      position: target,
      color: COLOR_TARGET,
      testId: 'target-line'
    },
    {
      position: min,
      color: COLOR_LIMITS,
      testId: 'min-line'
    },
    {
      position: max,
      color: COLOR_LIMITS,
      testId: 'max-line'
    }
  ]
}

export function TargetLines({
  width,
  targetRange,
  strokeWidth = 1,
  strokeDasharray
}: TargetLinesProps) {
  const linesInfo = targetRangeToLinesInfo(targetRange)

  return (
    <>
      {linesInfo.map(({position, color, testId}, index) => (
        <line
          key={index}
          x1={0}
          x2={width}
          y1={position}
          y2={position}
          strokeWidth={strokeWidth}
          stroke={color}
          strokeDasharray={strokeDasharray}
          fill="none"
          data-test-id={testId}
        />
      ))}
    </>
  )
}
