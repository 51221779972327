import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantPageShell} from '../modules/plants'

import {usePlantFullName} from '@predict/DomainsLib/plants'
import {PageTitle} from '@predict/WebUILib/atoms/Headline/PageTitle'
import {PredictLogo} from '@predict/WebUILib/atoms/PredictLogo/PredictLogo'
import {Spacing} from '@predict/WebUILib/atoms/Spacing/Spacing'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {NarrowCenteredLayout} from '@predict/WebUILib/layouts/NarrowCenteredLayout/NarrowCenteredLayout'

export function PlantHomePage() {
  const {t} = useTranslation()
  const plantName = usePlantFullName()

  return (
    <PlantPageShell>
      <NarrowCenteredLayout>
        <div style={{textAlign: 'center'}}>
          <PageTitle>{t('plantHomePage.title')}</PageTitle>
          <PredictLogo maxSize={150} />
          <Spacing height={2} />
          <Text>{t('plantHomePage.hint', {plant: plantName})}</Text>
        </div>
      </NarrowCenteredLayout>
    </PlantPageShell>
  )
}
