import {useMemo} from 'react'

import {Material} from '../../declarations/Material'
import {useCementStrengthDomainContext} from '../../providers/CementStrengthDomainProvider'
import {getMaterialById} from '../../utils/materialUtils'

import {useMaterials} from './useMaterials'

export const useMaterial = (materialId?: number): Material | undefined => {
  const {materialId: defaultMaterialId} = useCementStrengthDomainContext()
  const {data: materials} = useMaterials()
  return useMemo(
    () => getMaterialById(materials ?? [], materialId ?? defaultMaterialId),
    [materialId, defaultMaterialId, materials]
  )
}
