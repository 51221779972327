import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantProductionLines, useProductionLine} from '@predict/DomainsLib/plants'
import {useProductionLineSearchParam} from '@predict/WebApp/modules/navigation'
import {StringValueSelect} from '@predict/WebUILib/atoms/StringValueSelect/StringValueSelect'

export function ProductionLineSelect() {
  const {productionLineId} = useProductionLine()
  const {setProductionLineId} = useProductionLineSearchParam()
  const selectableProductionLines = usePlantProductionLines()

  const getProductionLine = useCallback(
    (lineId: string) => selectableProductionLines.find(({id}) => id === lineId),
    [selectableProductionLines]
  )

  const {t} = useTranslation()

  return (
    <StringValueSelect
      onSelected={setProductionLineId}
      value={productionLineId}
      values={selectableProductionLines.map(({id}) => id)}
      fieldId="production-line-select"
      data-test-id="production-line-select"
      label={t('productionLineSelect.label')}
      valueLabel={(id: string) => {
        const productionLine = getProductionLine(id)
        return productionLine?.name ?? t('productionLineSelect.kiln', {id})
      }}
    />
  )
}
