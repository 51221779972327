import {isNumber} from 'lodash'

import {AGGREGATED_SILOS_NAME} from '../constants'
import {CsMaterialSample, MaterialData, SampleSourceData} from '../declarations/MaterialData'

import {datedCsPredictionDtoToCsSample} from './datedCsPredictionDtoToCsSample'

import {
  CementStrengthPredictionDataDto,
  DatedCementStrengthPredictionDto
} from '@predict/DomainsLib/gen-api/data-contracts'
import {TimeRange} from '@predict/UtilsLib/dateTime'

export const compareSample = (a: CsMaterialSample, b: CsMaterialSample) => a.datetime - b.datetime

export function csPredictionDataDtoToMaterialData(
  dto: CementStrengthPredictionDataDto,
  timeRange: TimeRange
): MaterialData {
  if (!isNumber(dto.materialId)) {
    throw new Error('materialId must be a number')
  }

  const millSources: SampleSourceData[] =
    dto.mills
      ?.map((millDto) => {
        const {millName, predictions} = millDto
        const records: DatedCementStrengthPredictionDto[] = predictions ?? []
        const samples = records.map((r) => datedCsPredictionDtoToCsSample(r))
        samples.sort(compareSample)
        return {name: millName ?? '', samples}
      })
      .filter(({name, samples}) => samples.length > 0 && name) ?? []
  const siloSamples =
    dto.silos?.map((r) => datedCsPredictionDtoToCsSample(r)).sort(compareSample) ?? []
  const siloSources: SampleSourceData[] =
    siloSamples.length > 0 ? [{name: AGGREGATED_SILOS_NAME, samples: siloSamples}] : []

  const sampleSources = [...siloSources, ...millSources]

  return {
    materialId: dto.materialId,
    materialName: dto.materialName ?? '',
    sampleSources,
    timeRange
  }
}
