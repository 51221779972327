import {Check, Close, Delete} from '@mui/icons-material'
import {DialogActions} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LoadingButton} from '@predict/WebUILib/atoms/LoadingButton/LoadingButton'
import {useThemePalette} from '@predict/WebUILib/helpers/hooks/useThemePalette'

interface DialogBasicActionsProps {
  onCancel?: () => void
  onDelete?: () => void
  onSave?: () => void
  cancelBtnLabel?: string
  deleteBtnLabel?: string
  saveBtnLabel?: string
  cancelTestId?: string
  deleteTestId?: string
  saveTestId?: string
  saveDisabled?: boolean
  isLoading?: boolean
}

export function DialogBasicActions({
  onCancel,
  onDelete,
  onSave,
  cancelBtnLabel,
  deleteBtnLabel,
  saveBtnLabel,
  cancelTestId,
  deleteTestId,
  saveTestId,
  saveDisabled = false,
  isLoading = false
}: DialogBasicActionsProps) {
  const {t} = useTranslation()
  const palette = useThemePalette()

  return (
    <DialogActions
      sx={(theme) => ({
        padding: 3,
        boxShadow: theme.shadows[16]
      })}
    >
      {onCancel && (
        <LoadingButton
          onClick={onCancel}
          color="secondary"
          startIcon={<Close />}
          data-test-id={cancelTestId ?? 'dialog-cancel-button'}
        >
          {cancelBtnLabel ?? t('button.cancel')}
        </LoadingButton>
      )}
      {onDelete && (
        <LoadingButton
          startIcon={<Delete />}
          btnColor={palette.error.main}
          onClick={onDelete}
          data-test-id={deleteTestId ?? 'dialog-delete-button'}
          loading={isLoading}
        >
          {deleteBtnLabel ?? t('button.delete')}
        </LoadingButton>
      )}
      {onSave && (
        <LoadingButton
          onClick={onSave}
          btnColor={palette.primary.main}
          startIcon={<Check />}
          data-test-id={saveTestId ?? 'dialog-apply-button'}
          disabled={saveDisabled}
          loading={isLoading}
        >
          {saveBtnLabel ?? t('button.apply')}
        </LoadingButton>
      )}
    </DialogActions>
  )
}
