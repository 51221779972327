import React, {PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'

import {client} from '@predict/DomainsLib/backend'
import {usePlant, usePlantTimeZone} from '@predict/DomainsLib/plants'
import {isUserAdmin, useAuthContext} from '@predict/DomainsLib/users'
import {isUserLoggedIn} from '@predict/DomainsLib/users/utils/userUtils'
import {supportedLanguages} from '@predict/localization'
import {Env} from '@predict/UtilsLib/general'
import {useNavItems} from '@predict/WebApp/modules/navigation'
import {PredictShell} from '@predict/WebUILib/layouts/PredictShell/PredictShell'
import {Notifications} from '@predict/WebUILib/molecules/Notifications/Notifications'

export function DefaultShell({children}: PropsWithChildren<unknown>) {
  const {t} = useTranslation()

  const plant = usePlant()
  const timeZone = usePlantTimeZone()
  const {user, logout, whiteList} = useAuthContext()
  const navItems = useNavItems(isUserAdmin(user))

  return (
    <PredictShell
      plantName={plant?.fullName}
      plantId={plant?.id}
      user={user}
      permissions={user?.permissions || []}
      onLogout={() => void logout()}
      env={process.env.REACT_APP_STAGE as Env}
      supportedLanguages={supportedLanguages}
      navItems={navItems}
      isFooterInHeader
      footerCopyrightNote={t('footer.copyrightNote')}
      additionalActions={
        isUserLoggedIn(user)
          ? [
              <Notifications
                key="notification-provider"
                whiteList={whiteList}
                timeZone={timeZone}
                apiClient={client.dfApi}
                secureApiClient={client.dfApi}
              />
            ]
          : undefined
      }
    >
      {children}
    </PredictShell>
  )
}
