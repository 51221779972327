import {FreeLimeConfig} from './declarations/FreeLimeConfig'

export const DEFAULT_FREE_LIME_CONFIG: FreeLimeConfig = {
  id: '',
  target: 1.0,
  min: 0.5,
  max: 1.5,
  axisMin: 0,
  axisMax: 3,
  defaultTimeDurationFreeLime: 'last12Hours'
}
