import type {TFunction} from 'i18next'

import {Resolution} from '@predict/UtilsLib/general'

export function resolutionToTimeFormat(
  resolution: Resolution,
  t: TFunction,
  type: 'main' | 'sub' = 'main'
) {
  if (resolution === Resolution.Monthly) {
    return t(`chartTimeFormats.months.${type}`)
  }
  if (resolution === Resolution.Weekly) {
    return t(`chartTimeFormats.weeks.${type}`)
  }
  return t(`chartTimeFormats.days.${type}`)
}
