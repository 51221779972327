import React from 'react'

import {ColorButton} from '../ColorButton/ColorButton'
import {Text} from '../Text/Text'

import {Box} from '@predict/WebUILib/layouts/Box/Box'

export interface ListSelectorItem {
  name: string
  value: string
}

interface ListSelectorProps {
  items: ListSelectorItem[]
  title: string
  onSelect: (value: string) => void
}

export function ListSelector({items, title, onSelect}: ListSelectorProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Text variant="h6" gutterBottom align="center">
        {title}
      </Text>
      {items.map(({name, value}) => {
        return (
          <ColorButton
            key={value}
            data-test-id={`list-selector-button-${value}`}
            variant="text"
            color="secondary"
            onClick={() => {
              onSelect(value)
            }}
          >
            {name}
          </ColorButton>
        )
      })}
    </Box>
  )
}
