import {Material, MaterialLevel} from '../declarations/Material'

import {compareStrengthLevels, getStrengthLevelFromString} from './strengthLevel'

import {MaterialDto} from '@predict/DomainsLib/gen-api/data-contracts'

export const materialDtoToMaterial = (dto: MaterialDto): Material => {
  const levels =
    (dto.levels
      ?.map((l) => ({...l, name: getStrengthLevelFromString(l.name)}))
      .filter((l) => Boolean(l.name)) as MaterialLevel[]) ?? []
  levels.sort((a: MaterialLevel, b: MaterialLevel) => compareStrengthLevels(a.name, b.name))

  return {
    materialId: dto.materialId,
    name: dto.name ?? '',
    levels
  }
}
