import {useFreeLimeData} from './useFreeLimeData'

import {useProductionLine} from '@predict/DomainsLib/plants'

export function useFreeLimeKilnLastPrediction() {
  const {productionLineId} = useProductionLine()
  const {data: freeLimeData} = useFreeLimeData()
  const kilnData = freeLimeData?.kilnDataSet.find(
    (kilnData) => kilnData.productionLineId === productionLineId
  )

  if (!kilnData) {
    return undefined
  }

  const {predictions} = kilnData
  return kilnData.predictions[predictions.length - 1]?.datetime
}
