import {styled, SxProps, Theme} from '@mui/material'
import React from 'react'

import {hoveredColor} from '@predict/WebUILib/Charts/helpers'

interface AccuracyPointProps {
  posX: number
  posY: number
  innerColor: string
  outerRadius: number
  onClick?: () => void
  outerClassName?: string
  outerSx?: SxProps<Theme>
  'data-test-id'?: string
}

const SamplePoint = styled('circle')({})

export function AccuracyPoint({
  posX,
  posY,
  onClick,
  outerRadius,
  outerClassName,
  outerSx,
  innerColor,
  'data-test-id': dataTestId
}: AccuracyPointProps) {
  const isClickable = Boolean(onClick)

  return (
    <g data-test-id={dataTestId}>
      <SamplePoint
        cx={posX}
        cy={posY}
        r={outerRadius}
        onClick={onClick}
        className={outerClassName}
        sx={[
          isClickable
            ? (theme) => ({
                fill: 'transparent',
                stroke: 'transparent',
                strokeWidth: 8,
                strokeOpacity: 0.5,
                transition: 'stroke 250ms ease-in-out',
                '&:hover': {
                  stroke: hoveredColor(theme)
                }
              })
            : {
                pointerEvents: 'none'
              },
          ...(Array.isArray(outerSx) ? outerSx : [outerSx])
        ]}
      />
      <SamplePoint
        cx={posX}
        cy={posY}
        r={4}
        fill={innerColor}
        fillOpacity={0.5}
        stroke={innerColor}
        sx={{
          pointerEvents: 'none'
        }}
      />
    </g>
  )
}
