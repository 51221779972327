import {isNumber} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {HasDisabled} from '../declarations/helperTypes'

import {useFreeLimeSettingsMutation, usePlantFreeLimeConfig} from '@predict/DomainsLib/freeLime'
import {FL_TIME_HORIZON_IDS, TimeHorizonId} from '@predict/UtilsLib/dateTime'
import {TimeHorizonSelect} from '@predict/WebApp/modules/dateTime'
import {Text} from '@predict/WebUILib/atoms/Text/Text'
import {Box} from '@predict/WebUILib/layouts/Box/Box'
import {FormBox} from '@predict/WebUILib/layouts/FormBox/FormBox'
import {GridContainer, GridItem} from '@predict/WebUILib/layouts/Grid/Grid'
import {NumberField} from '@predict/WebUILib/molecules/NumberField/NumberField'
import {useSnackbarMutationInjector} from '@predict/WebUILib/organisms/SnackbarProvider'

const isAxisRangeValid = (min: number | undefined, max: number | undefined) =>
  isNumber(min) && isNumber(max) && min < max

export function FreeLimeViewSettings({disabled}: HasDisabled) {
  const {t} = useTranslation()
  const {
    defaultTimeDurationFreeLime: defaultTimeDuration,
    axisMin,
    axisMax
  } = usePlantFreeLimeConfig()
  const [axisMinimum, setAxisMinimum] = useState<number | undefined>(axisMin)
  const [axisMaximum, setAxisMaximum] = useState<number | undefined>(axisMax)
  const isValidAxisRange = isAxisRangeValid(axisMinimum, axisMaximum)

  const [selectedTimeHorizon, setSelectedTimeHorizon] = useState<TimeHorizonId>(defaultTimeDuration)

  const {isLoading, mutate} = useFreeLimeSettingsMutation(
    useSnackbarMutationInjector(t('settings.settingsSaved'), t('settings.settingsNotSavedError'))
  )

  useEffect(() => {
    setSelectedTimeHorizon(defaultTimeDuration)
  }, [defaultTimeDuration])

  const onRevert = () => {
    setSelectedTimeHorizon(defaultTimeDuration)
    setAxisMinimum(axisMin)
    setAxisMaximum(axisMax)
  }

  const isPristine =
    selectedTimeHorizon === defaultTimeDuration &&
    axisMinimum === axisMin &&
    axisMaximum === axisMax

  const onSave = () => {
    if (isLoading || isPristine || !isValidAxisRange) return

    mutate({
      axisRange: {min: axisMinimum as number, max: axisMaximum as number},
      defaultTimeDurationFreeLime: selectedTimeHorizon
    })
  }

  return (
    <FormBox
      title={t('freeLimeViewSettings.title')}
      onSave={onSave}
      onRevert={onRevert}
      disabled={isPristine}
      isFormInvalid={!isValidAxisRange}
      data-test-id="free-lime-view-settings-form-box"
      isSaving={isLoading}
    >
      <div>
        <Box mb={1}>
          <TimeHorizonSelect
            selectable={FL_TIME_HORIZON_IDS}
            value={selectedTimeHorizon}
            onSelected={setSelectedTimeHorizon}
            disabled={disabled}
          />
        </Box>
        <GridContainer alignItems="flex-end" spacing={2}>
          <GridItem xs={12} sm="auto">
            <Text>{t('freeLimeViewSettings.axisRangeLabel')}</Text>
          </GridItem>
          <GridItem>
            <NumberField
              value={axisMinimum}
              error={!isValidAxisRange}
              disabled={disabled}
              onNumberChange={(val) => setAxisMinimum(val)}
              inputProps={{
                'data-test-id': 'free-lime-view-settings-axis-min-input'
              }}
            />
          </GridItem>
          <GridItem>
            <Text>-</Text>
          </GridItem>
          <GridItem>
            <NumberField
              value={axisMaximum}
              error={!isValidAxisRange}
              disabled={disabled}
              onNumberChange={(val) => setAxisMaximum(val)}
              inputProps={{
                'data-test-id': 'free-lime-view-settings-axis-max-input'
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    </FormBox>
  )
}
