export const calcTransformMatrix = ({
  posX,
  posY,
  dirX,
  dirY
}: {
  posX: number
  posY: number
  dirX: number
  dirY: number
}): string => {
  const dirLen = Math.sqrt(dirX * dirX + dirY * dirY)
  const hasDirection = dirLen > 0
  if (!hasDirection) {
    return `matrix(1 0 0 1 ${posX} ${posY})`
  }
  const normX = dirX / dirLen
  const normY = dirY / dirLen
  return `matrix(${normX} ${normY} ${-normY} ${normX} ${posX} ${posY})`
}
