import {isEmpty} from 'lodash'

import {AGGREGATED_SILOS_NAME} from '../constants'
import {CsSampleSource, CsSampleType} from '../declarations/CsSamples'

import {
  SAMPLE_SOURCES,
  SAMPLE_SOURCES_MAP,
  SILO_SAMPLE_SOURCE,
  SampleSourceDto
} from '@predict/DomainsLib/plants'

export const convertSampleSourceDto = (sources: SampleSourceDto): CsSampleSource[] =>
  SAMPLE_SOURCES.map((sampleType) => {
    const sourceNames = sources[SAMPLE_SOURCES_MAP[sampleType]] ?? []
    if (sampleType === SILO_SAMPLE_SOURCE) {
      // The individual silo names are not interesting for the app,
      // the silo samples are aggregated to a virtual Silo named AGGREGATED_SILOS_NAME
      return {sampleType, names: isEmpty(sourceNames) ? [] : [AGGREGATED_SILOS_NAME]}
    }
    return {sampleType, names: sourceNames}
  })

export const convertToSampleType = (type: string): CsSampleType | undefined =>
  SAMPLE_SOURCES.find((sampleType) => sampleType === type)
