import {useCallback, useEffect, useRef, useState} from 'react'
import {useKeyPressEvent, usePrevious} from 'react-use'

interface KeyEventOptions {
  firstDelayMs?: number
  secondDelayMs?: number
}

const DEFAULT_FIRST_DELAY = 1000
const DEFAULT_SECOND_DELAY = 150

export function useKeyEventUpOrHold(keyCode: string, options: KeyEventOptions = {}): boolean {
  const {firstDelayMs = DEFAULT_FIRST_DELAY, secondDelayMs = DEFAULT_SECOND_DELAY} = options
  const [count, setCount] = useState(0)
  const prevCount = usePrevious(count) ?? 0
  const timeoutHandle = useRef<number>()

  const onDown = useCallback(() => {
    const onTimeout = () => {
      window.clearTimeout(timeoutHandle.current)
      setCount((c) => c + 1)
      timeoutHandle.current = window.setTimeout(onTimeout, secondDelayMs)
    }

    setCount(0)
    timeoutHandle.current = window.setTimeout(onTimeout, firstDelayMs)
  }, [firstDelayMs, secondDelayMs])

  const onUp = useCallback(() => {
    window.clearTimeout(timeoutHandle.current)
    setCount((c) => c + 1)
  }, [])

  useKeyPressEvent(keyCode, onDown, onUp)

  useEffect(() => () => window.clearTimeout(timeoutHandle.current), [])

  return count > prevCount
}

export function useKeyEventUpOrHoldTriggered(
  onPressed: () => void,
  keyCode: string,
  options: KeyEventOptions = {}
) {
  const triggered = useKeyEventUpOrHold(keyCode, options)

  useEffect(() => {
    if (triggered) {
      onPressed()
    }
  }, [triggered, onPressed])
}
