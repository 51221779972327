import {isEmpty, isNull, trim, uniq} from 'lodash'
import {UseMutationOptions} from 'react-query'

import {StrippedNullObject} from '../declarations/utilTypes'

export function getFilenameFromPath(path: string): string {
  return path.split('/').pop() ?? ''
}

export function stripExtension(filename: string): string {
  return filename.replace(/\.[^/.]+$/, '')
}

export function uniqAdd<T>(arr: T[], item: T): T[] {
  return uniq([...arr, item])
}

export function addOrRemove<T>(arr: T[], item: T, add: boolean): T[] {
  return add ? uniqAdd(arr, item) : arr.filter((elem) => elem !== item)
}

export function stripNonNumbers(text: string | null | undefined): string {
  return text?.replace(/[^0-9]/g, '') ?? ''
}

export function isEqualCaseInsensitive(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase()
}

export function isWhiteSpace(text: string): boolean {
  return isEmpty(trim(text))
}

export function includesAll<T, U extends T>(arr: T[], other: U[]): boolean {
  return other.every((o) => arr.includes(o))
}

export function stripNull<T>(o: T | null | undefined): T | undefined {
  return isNull(o) ? undefined : o
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function stripObjectNulls<T extends Object>(o: T): StrippedNullObject<T> {
  return Object.fromEntries(
    Object.entries(o).map(([key, value]) => [key, stripNull(value)])
  ) as StrippedNullObject<T>
}

/**
 * It takes a record of type `Record<K, V>` and returns a record of type `Record<V, K>` where the keys
 * and values are swapped
 * @param record - Record<K, V>
 */
export function ReverseStringsRecord<K extends string, V extends PropertyKey>(
  record: Record<K, V>
): Record<V, K> {
  return Object.fromEntries<K>(
    Object.entries<V>(record).map<[V, K]>(([key, value]) => [value, key as K])
  ) as Record<V, K>
}

export function dummyMutationInjector<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationOptions<TData, TError, TVariables, TContext> {
  return options
}

export function replaceNonWordCharacters(name: string, replaceValue = '-'): string {
  return name.replace(/\W/g, replaceValue)
}

export function isUriValid(uri: string): boolean {
  try {
    return Boolean(new URL(uri))
  } catch (e) {
    return false
  }
}
